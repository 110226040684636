import React, { useState, useEffect } from "react";
import moment from "moment";
import ScreenShortCard from "../ScreenShotCard";

const ScreenShotList = ({ data, openModalAndSetSS }) => {
  const [ssContainer, setSSContainer] = useState({});

  const CalListPerHrs = (data) => {
    let timeArray = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ];

    let ObjOfSSperHr = {};

    for (let time in timeArray) {
      ObjOfSSperHr[time] = data.filter(
        (ss) => moment(ss.datetime_of_capture).format("H") === time
      );
    }

    setSSContainer(ObjOfSSperHr);
  };

  useEffect(() => {
    if (data.length > 0) CalListPerHrs(data);
  }, [data]);
  // console.log("ssContainer", ssContainer);

  return (
    <>
      {data.length > 0 ? (
        <ul>
          {Object.values(ssContainer).map((array_per_hr, i) => (
            <>
              {array_per_hr.length > 0 && (
                <>
                  <li key={i}
                    style={{
                      color: "lightgrey",}}>
                    {moment(array_per_hr[0].datetime_of_capture).format(
                      "hh:00 A"
                    )}{", "}
                    <span
                      style={{
                        color: "lightgrey",
                        marginLeft: "5px",
                      }}
                    >{`${array_per_hr.length} Screenshots `}</span>
                  </li>
                  <div className="cards" style={{paddingBottom:"20px"}}>
                    {array_per_hr.map((SS) => (
                      <ScreenShortCard
                        data={SS}
                        openScreenShort={openModalAndSetSS}
                        showDelecteIcon={true}
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          ))}
        </ul>
      ) : (
      <div style={{textAlign:"center"}}> <h3 className="custom-no-data">No data to show!</h3></div>
      )}
    </>
  );
};

export default ScreenShotList;
