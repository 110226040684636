import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AddNewWorkSchedule,
  getWorkScheduleList,
} from "../redux/Actions/workScheduleActions";

export default function useWorkSchedule() {
  const dispatch = useDispatch();

  //for project table
  
  const [searchValue, setSearchValue] = useState("");
  const { workSchedules, save } = useSelector((store) => store.WS);
  const [inProgress, setInProgress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const itemsPerPage = 5;
  const [pageCounts, setPageCounts] = useState(1);
  const [totalValues, setTotalValues] = useState(0);

  const { employeesList } = useSelector((store) => store.ER);
  const [searchEmployee, setSearchEmployee] = useState("");

  const createSchedule = (ID) => {
    const user = employeesList.filter((employee) => employee.id === ID)[0];
    let newWS = {
      id: `ws ${workSchedules.length + 1} new`,
      user: {
        email: user.email,
        id: user.id,
        name: user.name,
        profile_image: user.profile_image,
      },
      user_id: user.id,
      shifts: [
        {
          id: "1 new",
          day_start: "1",
          start_time: "10:00:00",
          day_end: "1",
          end_time: "23:00:00",
          minimum_shift_hours: "10:00:00",
          work_schedule: "ws 1 new",
        },
      ],
    };
    dispatch(AddNewWorkSchedule(newWS));
  };

  useEffect(() => {
    if (searchValue.length > 0) {
      dispatch(
        getWorkScheduleList(
          setInProgress,
          1,
          itemsPerPage,
          setPageCounts,
          setTotalValues,
          searchValue,
          users,
          createSchedule
        )
      );
    } else {
      dispatch(
        getWorkScheduleList(
          setInProgress,
          currentPage,
          itemsPerPage,
          setPageCounts,
          setTotalValues,
          users,
          createSchedule
        )
      );
    }
  }, [dispatch, searchValue, currentPage, users, save]);

  // useEffect(() => {
  //   dispatch(
  //     getWorkScheduleList(
  //       setInProgress,
  //       currentPage,
  //       itemsPerPage,
  //       setPageCounts,
  //       setTotalValues,
  //       users,
  //       createSchedule
  //     )
  //   );
  // }, [dispatch, save]);

// ...

useEffect(() => {
 setTimeout(() => {
      dispatch(
        getWorkScheduleList(
          setInProgress,
          currentPage,
          itemsPerPage,
          setPageCounts,
          setTotalValues,
          users,
          createSchedule
        )
      );
    }, 5000); 
}, [save]);


  const selectEmployeeId = (ID) => {
    setUsers([ID]);
  };

  return {
    searchValue,
    setSearchValue,
    inProgress,
    currentPage,
    pageCounts,
    totalValues,
    employeesList,
    searchEmployee,
    setSearchEmployee,
    workSchedules,
    setCurrentPage,
    setUsers,
    selectEmployeeId,
  };
}
