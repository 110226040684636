import React,  { useState } from "react";
import { useEffect } from "react";

import AddButton from "../../components/AddButton";
import SearchInput from "../../components/SearchInput";
import ProjectTable from "../../components/ResourceManagement/ProjectTable";
import AddProject from "../../components/common/AddProject";
import { useSelector } from "react-redux";
import useProjectAndTask from "../../hooks/useProjectAndTask";
// import useAddProject from "../../hooks/useAddProject";

export default function ProjectAndTasks() {
  const [modalOpen, setModalOpen] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const { userPermissions } = useSelector((store) => store.auth);
  const [isOwnProject, setIsOwnProject]=useState(false);
  const {
    searchValue,
    setSearchValue,
    projectsList,
    pageCounts,
    currentPage,
    setCurrentPage,
    inProgress,
    tableType,
    TabChange,
    ArchivedProject,
    RestoreProject,
    TotalCount_Archived,
    TotalCount_Active,
    projectName,
    setProjectName,
    taskName,
    setTaskName,
    tasks,
    handleProjectSubmit,
    addTask,
    editTask,
    handleCancle,
    employeesList,
    users,
    selectEmployee,
    searchEmployee,
    setSearchEmployee,
    projectErrors,
    taskErrors,
    deleteTask,
    oldTasks,
    selectAll
  } = useProjectAndTask(projectId, setModalOpen, setProjectId);

  const addProject = () => {
    setProjectId(null);
    setModalOpen(true);
  };

  const editProject = (id,OwnProject) => {

    setProjectId(id);
    setModalOpen(true);
    setIsOwnProject(OwnProject);
   
  };

  return (
    <div>
      <div className="resource-content-header">
        <div >
        <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
       </div>
        {userPermissions.includes("Can add project") && (
          <AddButton title="Add Project" onClick={() => addProject()} />
        )}
      </div>

      <ProjectTable
        editProject={(id,OwnProject) => editProject(id,OwnProject)}
        hookProps={{
          projectsList,
          pageCounts,
          currentPage,
          setCurrentPage,
          inProgress,
          tableType,
          TabChange,
          ArchivedProject,
          RestoreProject,
          TotalCount_Archived,
          TotalCount_Active,
          userPermissions,
        }}
      />

      <AddProject
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        projectId={projectId}
        hookProps={{
          projectName,
          setProjectName,
          taskName,
          setTaskName,
          tasks,
          handleProjectSubmit,
          addTask,
          editTask,
          handleCancle,
          employeesList,
          users,
          selectEmployee,
          searchEmployee,
          setSearchEmployee,
          projectErrors,
          taskErrors,
          deleteTask,
          oldTasks,
          selectAll,
          isOwnProject,
          
        }}
      />
    </div>
  );
}
