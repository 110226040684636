// src/theme.js
import { css } from '@emotion/react';
import themeJson from './theme.json';

const theme = {
  colors: {
    ...themeJson.colors,
  },
  fonts: {
    ...themeJson.fonts,
  },
  fontSizes: {
    ...themeJson.fontSizes,
  },
  fontWeights: {
    ...themeJson.fonts.sansSerif
  },
  // Add any additional global styles here
  globalStyles: css`
    body {
      font-family: ${({ fonts }) => fonts.sansSerif};
    }
  `,
};

export default theme;