import React from "react";
import NavigationBar from "../components/NavigationBar";
import "./styles.css";
import theme from "../theme/theme";

export default function LayoutWithNav({ path, component }) {
  return (
    <div className="layout">
      <div className="layout-navigation" style={{backgroundColor:theme.colors.white}}>
        <NavigationBar />
      </div>
      <div className="layout-component">{component}</div>
    </div>
  );
}
