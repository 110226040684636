import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../redux/Actions/authAction";
import { useDispatch } from "react-redux";
import { localstorage_userInfo, localStorage_userData } from "../../constants";
import { passwordValidator } from "../../Utils/fieldValidator";
import PrivacyTermComp from "./PrivacyTermComp";

export default function ResetPassword() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    password: "",
    conformPassword: "",
    showPassword: false,
    newshowPassword: false,
  });
  const { token } = useParams();
  const [errors, setErrors] = useState(false);
  const [ispasswordValid, setisPasswordValid] = useState(true);
  const [isdisabled, setdistable] = useState(true);

  useState(() => {
    localStorage.removeItem(localstorage_userInfo);
    localStorage.removeItem(localStorage_userData);
  }, []);

  // const handleClickShowPassword = () => {
  //   setValues({ ...values, showPassword: !values.showPassword });
  // };

  // const newhandleClickShowPassword = () => {
  //   setValues({ ...values, newshowPassword: !values.newshowPassword });
  // };

  const handlePasswordChange = (prop) => (event) => {
    setisPasswordValid(passwordValidator(event.target.value));
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleConformPasswordChange = (prop) => (event) => {
    setisPasswordValid(passwordValidator(event.target.value));
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    let ispasswordValid = passwordValidator(values.password);
    console.log(values.conformPassword, values.password);
    if (values.password.length > 0 && ispasswordValid) {
      if (values.password.length > 0) {
        if (values.password === values.conformPassword) {
          setdistable(false);
        } else {
          setdistable(true);
        }
      }
    }
  }, [values.conformPassword, values.password]);

  const handleLogin = () => {
    const resData = {
      password: values.password,
      confirm_password: values.conformPassword,
    };

    if (!values.password.length > 0) {
      setErrors(true);
    }

    if (values.password.length > 0 && ispasswordValid) {
      if (values.password.length > 0) {
        if (values.password === values.conformPassword) {
          dispatch(resetPassword(resData, token, Navigate));
        } else {
          setErrors(true);
        }
      }
    }
  };

  return (
    <div style={{display: 'flex', height: "100%"}}>

      <div className="left-login-container" style={{position: "relative"}}>

      {/* <div >

        <div style={{textAlign: "center", position: "relative", top: "80px", left: "16px"}}>
          <img src="/images/login_img_1.png" alt="" style={{width: "550px"}} />
        </div>

        <div style={{textAlign: "center"}}>
          <img src="/images/login_img_2.png" alt="" style={{width: "548px"}}/>
        </div>
      </div> */}

      <div style={{textAlign: "center"}}>
          <img src="/images/reset-forget-bg.png" alt="" style={{height: "100vh", width: "100%"}}/>
      </div>


      <div style={{position: "absolute", width: "100%", bottom: "3%"}}>
        <p className="company-footer">
          ©{new Date().getFullYear()} All Rights Reserved by Mechlin
          Technologies
        </p>
      </div>

      </div>


    <div className="auth-screen-form-card">
      <div className="">
        <img src="/images/clocksession-logo-new.png" alt="clocksession-logo" />
      </div>

      <div className="clock-login-header">
        <div>Reset Password?</div>
      </div>

      <p style={{fontSize: "15px", marginTop: "4px"}}>
      Try to include numbers, symbols, and both uppercase and lowercase letters.
      </p>

      <div className="auth-input" style={{marginTop: "25px"}}>
        <label>New Password</label>
        <div className="auth-password">
          <input
            autoComplete="off"
            type={values.showPassword ? "text" : "password"}
            name="password"
            placeholder="Enter New password"
            value={values.password}
            onChange={handlePasswordChange("password")}
          />
          <button
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                showPassword: !prev.showPassword,
              }))
            }
            type="button"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <img
              src={
                values.showPassword ? "/images/icons/eye-open.png" : "/images/icons/eye-close.png"
              }
              alt="visible"
              style={{ marginRight: "5px", width: "15px" }}
            />
          </button>
        </div>

        {errors &&
          (!values.password ? (
            <p style={{ color: "red" }}>New Password is required</p>
          ) : null)}
      </div>

      <div className="auth-input">
        <label>Confirm Password</label>
        <div className="auth-password">
          <input
            autoComplete="off"
            type={values.newshowPassword ? "text" : "password"}
            name="password"
            placeholder="Enter Confirm Password"
            value={values.conformPassword}
            onChange={handleConformPasswordChange("conformPassword")}
          />
          <button
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                newshowPassword: !prev.newshowPassword,
              }))
            }
            type="button"
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            <img
              src={
                values.newshowPassword === true
                  ? "/images/icons/eye-open.png"
                  : "/images/icons/eye-close.png"
              }
              alt="visible"
              style={{ marginRight: "5px", width: "15px" }}
            />
          </button>
        </div>

        {errors ? (
          !values.conformPassword ? (
            <p>Conform Password is required</p>
          ) : null
        ) : null}
        {!ispasswordValid ? (
          <p>
            Password should contain at least 1 Uppercase, 1 lowercase, 1 Special
            Character in (!@#$%^&*),1 Digit, and min 8 Characters{" "}
          </p>
        ) : null}
      </div>
      <button className="auth-btn" disabled={isdisabled} onClick={handleLogin}>
        Reset Password
      </button>



      <PrivacyTermComp/>
    </div>


    </div>
  );
}
