import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { getEmployeeList } from "../../redux/Actions/employeesAction";
import { useDispatch, useSelector } from "react-redux";

export default function SelectSingleEmployee({ onSelectEmployee, disabled }) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [search, setSearch] = useState("");
  const { employeesList } = useSelector((store) => store.ER);
  
  useEffect(() => {
    dispatch(getEmployeeList(search));
  }, [dispatch, search]);

  const clearSelection = () => {
    setUser(null);
    onSelectEmployee(null); // Call the onSelectEmployee function with null to indicate no selection
  };

  return (
    <Dropdown disabled={disabled}>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        disabled={disabled}
      >
        {user ? user.name : "Select User"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="dropdown-menu-container" style={{height:"320px"}}>
          <input
            className="dropdown-search-input"
            type="text"
            placeholder="search user.."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="dropdown-user-list-item justify-content-center" onClick={clearSelection}>
                <p>Remove Filter</p>
              </div>

          <div className="dropdown-user-list-container">
            {/* Render the employees list */}
            {employeesList.length > 0 ? (
              employeesList.map((employee) => (
                <Dropdown.Item
                  key={employee.id}
                  onClick={() => {
                    setUser(employee);
                    onSelectEmployee(employee.id);
                  }}
                >
                  <div className="dropdown-user-list-item" key={employee.id}>
                    <img
                      src={employee.profile_image}
                      alt="employee"
                      width="35"
                      height="30"
                      style={{ borderRadius: "20px" }}
                      onError={(e) => {
                        e.target.src = "/images/profilepic.png";
                      }}
                    />
                    <p>{employee.name}</p>
                  </div>
                </Dropdown.Item>
              ))
            ) : (
              <h5>No Result found</h5>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
