import React from "react";
import useFeedBack from "../../hooks/useFeedback";
import { useSelector } from "react-redux";
import Header from "../../components/common/Header";
import "./index.css";
import Dropdown from "react-bootstrap/Dropdown";

import { useState } from "react";

export default function Feedback() {
  const {
    handleChange,
    imageHandler,
    removeImage,
    handleUpload,
    setStatisfactorylevel,
    feedbackValue,
    file,
    isProgress,
    statisfactorylevel,
  } = useFeedBack();
  const { userPermissions } = useSelector((store) => store.auth);

  const imgStyle = {
    height: "40px",
    width: "40px",
  };

  const handleFeedbackUpload = () => {
    // setRating(0);
    // setSelectedOption(null);
    handleUpload(setRating,setSelectedOption);
  }

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
    handleChange({ target: { name: 'bugtypes', value } });
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [options] = useState([
    { label: "Report a bug", value: "Report a Bug" },
    { label: "Request new feature", value: "Request new feature" },
    { label: "Suggest Improvement", value: "Suggest Improvement" },
  ]);

  const [rating, setRating] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);

    switch (selectedRating) {
      case 1:
        setStatisfactorylevel("Disappointed");
        break;
      case 2:
        setStatisfactorylevel("Annoyed");
        break;
      case 3:
        setStatisfactorylevel("Satisfactory");
        break;
      case 4:
        setStatisfactorylevel("Happy");
        break;
      case 5:
        setStatisfactorylevel("Very Happy");
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="dashboard-header">
        <Header title="Feedback" style={{ fontWeight: "bold" }} />
      </div>

      <div className="feedback-container-parent">
        <div className="feedback-container">
          <div className="">
            <div className="">
              <div className="col-sm-12">
                <h4 className="heading-2 text-center">
                  <span className="feedback-header-span">
                    {" "}
                    Help Us To Improve{" "}
                  </span>
                </h4>
              </div>
            </div>
            <div className="">
              <div className="col-sm-12 py-2 text-center feedback-reactions">
                {[1, 2, 3, 4, 5].map((index) => (
                  <img
                    key={index}
                    onClick={() => handleStarClick(index)}
                    src={
                      index <= rating
                        ? "/images/feedback/blue-star.png"
                        : "/images/feedback/star.png"
                    }
                    alt={index <= rating ? "blue star" : "star"}
                    style={{ cursor: "pointer", width: "40px", height: "40px" }}
                  />
                ))}

                {/* previous logic with smily images */}
                {/* <img
                onClick={() => setStatisfactorylevel("Disappointed")}
                src="/images/feedback/disappointed.png"
                alt=""
                className={`feedback-image ${
                  statisfactorylevel === "Disappointed" && "animated-lavel-up"
                }`}
                style={imgStyle}
              />
              <img
                onClick={() => setStatisfactorylevel("Annoyed")}
                src="/images/feedback/annoyed.png"
                alt=""
                className={`feedback-image ${
                  statisfactorylevel === "Annoyed" && "animated-lavel-up"
                }`}
                style={imgStyle}
              />
              <img
                onClick={() => setStatisfactorylevel("Satisfactory")}
                src="/images/feedback/satisfactory.png"
                alt=""
                className={`feedback-image ${
                  statisfactorylevel === "Satisfactory" && "animated-lavel-up"
                }`}
                style={imgStyle}
              />
              <img
                onClick={() => setStatisfactorylevel("Happy")}
                src="/images/feedback/happy.png"
                alt=""
                className={`feedback-image ${
                  statisfactorylevel === "Happy" && "animated-lavel-up"
                }`}
                style={imgStyle}
              />
              <img
                onClick={() => setStatisfactorylevel("Very Happy")}
                src="/images/feedback/verry-happy.png"
                alt=""
                className={`feedback-image ${
                  statisfactorylevel === "Very Happy" && "animated-lavel-up"
                }`}
                style={imgStyle}
              /> */}
              </div>
            </div>
            {userPermissions.includes("Add custom feedback") && (
              <>
                <div className="">
                  <div className="col-sm-12 py-2">
                    <div style={{ fontSize: "14px" }}>
                      Select The Suggestion
                    </div>

                    {/* <div className="dropdown" >
                      <select
                        name="bugtypes"
                        value={feedbackValue?.bugtypes}
                        onChange={handleChange}
                        className="form-control"
                        style={{ fontSize: "14px"}}
                      >
                        <option className="repo" value="" disabled hidden >
                          Select here...
                        </option>
                        <option className="repo" value="Report a Bug">
                          Report a bug
                        </option>
                        <option className="repo" value="Request new feature">
                          Request new feature
                        </option>
                        <option className="repo" value="Suggest Improvement">
                          Suggest Improvement
                        </option>
                      </select>
                    </div> */}

                    <Dropdown show={showDropdown} >
                      <Dropdown.Toggle
                        variant="success"
                        className="custom-width"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          border: "2px solid #E8E8E8",
                          width: "100%",
                          fontSize: "14px",
                          height: "40px",
                          borderRadius: "4px"
                        }}
                        onClick={() => setShowDropdown(!showDropdown)}
                      >
                        <div className="select-feedback-style">
                          <span style={{ marginRight: "auto", color: selectedOption === null ? "gray" : "inherit" }}>
                          {selectedOption === null ? "Select here..." : selectedOption}
                          </span>
                          <img
                            src="/images/down-arrow-icon.svg"
                            alt="Custom Arrow"
                            className="custom-arrow-icon"
                          />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{width: "100%"}}>
                        <div className="dropdown-filter-container">
                          {options.map((option, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                label={option.label}
                                value={option.value}
                                isSelected={selectedOption === option.value}
                                onSelect={handleOptionSelect}
                                setShowDropdown={setShowDropdown}
                                showDropdown={showDropdown}
                              />
                            );
                          })}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>

                  </div>
                </div>
                <div className="">
                  <div className="col-sm-12">
                    <div style={{ fontSize: "14px" }}>Description</div>
                    <div className="comment">
                      <textarea
                        className="form-control resize-none"
                        name="usercomments"
                        rows="3"
                        form="usrform"
                        placeholder="Write your comments here.."
                        value={feedbackValue?.usercomments}
                        onChange={handleChange}
                        style={{ fontSize: "14px",border: "2px solid #E8E8E8",borderRadius: "4px" }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <div className="feedback-add-attachment-container">
                    <div className="attach-img">
                      <input
                        type="file"
                        accept="image/*"
                        name="image-upload"
                        id="imageinput"
                        onChange={imageHandler}
                      />
                      <label
                        className="attachment-para"
                        htmlFor="imageinput"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          className="att-btn"
                          src="/images/attachment_2.png"
                          alt=""
                          style={{ width: "8px", height: "18px" }}
                        />
                        Add Attachment
                      </label>
                    </div>
                  </div>

                  <div style={{width: "48%"}}>
                    <div >
                      <button className="submit btn" onClick={handleFeedbackUpload}>
                        Submit
                        {isProgress && (
                          <span className="spinner-grow spinner-grow-sm"></span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="justify-content-center mt-2">
                  {file?.map((data, ind) => {
                    return (
                      <div key={ind}>
                        <div className="col text-align-center">
                          <span className="text-center file-name">
                            {data?.name}
                          </span>
                          <span onClick={() => removeImage(ind)}>X</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function DropdownItem({ label, value, isSelected, onSelect, setShowDropdown, showDropdown }) {
  return (
    <Dropdown.Item onClick={() => onSelect(value)}>
      <div
        className={`dropdown-user-list-item ${
          isSelected ? "selected-employee" : ""
        }`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <input
          type="checkbox"
          className="custome-checkbox"
          style={{ display: "none" }}
          checked={isSelected}
          onChange={() => {}}
        />
        <label htmlFor={`select-checkbox-${value}`} style={{ width: "10%" }}>
          <img
            src={
              isSelected
                ? "/images/checked-icon.svg"
                : "/images/uncheck-icon.svg"
            }
            className="checkbox-image"
            alt={isSelected ? "Checked" : "Unchecked"}
          />
        </label>
        <div className={`dropdown-right-item gustavo`}>
          <p
            className={`custom-font gustavo font-14px ${
              isSelected ? "selected-employee" : ""
            }`}
            style={{
              marginLeft: "5px", fontSize: "14px"
            }}
          >
            {label}
          </p>
        </div>
      </div>
    </Dropdown.Item>
  );
}
