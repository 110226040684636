import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FaInfoCircle } from "react-icons/fa";
import Modal from "../../../components/common/Modal";
import ActivePlan from "../ActivePlan";
import "./style.css";
import "./AvailablePlan.css";
import useSubscription from "../../../hooks/useSubscription";
import { getAllPlanList } from "../../../redux/Actions/subscription";
import PlanFeatureDetails from "./PlanFeatureDetails";

const AvailablePlan = () => {
  const [dots, setDots] = useState(2);
  const [selectedDots, setSelectedDots] = useState(1);
  const [activeTab, setActiveTab] = useState("month");
  const [toggle, settoggle] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { ClockSession_PlanList } = useSelector((store) => store.subPlan);
  const { env } = useSelector((store) => store.auth);
  const { active_subscription_list } = useSelector((store) => store.subPlan);

  const {
    showModal,
    selectedPlan,
    isPopupVisible,
    openGatewayModal,
    openInfoPopup,
    closeInfoPopup,
    priceColors,
    closeModal,
    useOutsideCloseModal,
    setStripe_info,
  } = useSubscription();

  useOutsideCloseModal(modalRef);

  useEffect(() => {
    dispatch(getAllPlanList(env));
  }, [dispatch, env]);

  const separatePrices = (subscriptions) => {
    return subscriptions
      .map((subscription) => {
        const updatedSubscription = {
          ...subscription,
          month_price: null,
          year_price: null,
        };

        if (subscription.prices_list && subscription.prices_list.length > 0) {
          subscription.prices_list.forEach((price) => {
            if (
              price.recurring_interval === "month" &&
              (!updatedSubscription.month_price ||
                price.unit_amount > updatedSubscription.month_price.unit_amount)
            ) {
              updatedSubscription.month_price = price;
            } else if (
              price.recurring_interval === "year" &&
              (!updatedSubscription.year_price ||
                price.unit_amount > updatedSubscription.year_price.unit_amount)
            ) {
              updatedSubscription.year_price = price;
            }
          });
        }
        // let dots_length=1+((updatedSubscription.length)-5);
        //   setDots(dots_length);

        // Set dots to 2 after the initial render
        console.log("hello");

        return updatedSubscription;
      })
      .filter(
        (subscription) => subscription.month_price || subscription.year_price
      );
  };

  const subscriptionsWithSeparatedPrices = separatePrices(
    ClockSession_PlanList
  );
  console.log(
    "subscriptionsWithSeparatedPrices",
    subscriptionsWithSeparatedPrices
  );
  console.log("dots_length", subscriptionsWithSeparatedPrices.length);
  // useEffect(() => {
  //   let dots_length=1+((separatePrices(ClockSession_PlanList).length)-5);
  //   setDots(dots_length); // Set dots to 2 after the initial render
  // }, []);
  console.log("dots", dots);

  console.log("activeplan", active_subscription_list);
  const handleToggle = (tab) => {
    setActiveTab(tab);
  };
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh", // Adjust this to your needs, ensures full height
    border: "0px solid #E8E8E8",
    marginTop: "0PX",
    paddingTop: "20px",
    border: "0px solid yellow",
    paddingLeft: "0px",
    paddingRight: "0px",
  };
  const handleDotClick = (dotNumber) => {
    setSelectedDots(dotNumber); // Set the selected dot
  };
  console.log("selectedDot", selectedDots);

  return (
    <>
      <section className="pricing-container" style={containerStyle}>
        <div className="parent_div">
          <button
            style={{
              backgroundColor: activeTab === "month" ? "#007EC5" : "white",
              border: "0px solid pink",
              width: "200px",
              textAlign: "center",
              whiteSpace: "nowrap",
              color: activeTab === "month" ? "white" : "black",
              fontFamily: "FK Grotesk", // Specify font family
              fontWeight: 600, // Specify font weight
              borderRadius: "5px",
              height: "50px",
            }}
            onClick={() => handleToggle("month")}
          >
            Bill Monthly
          </button>

          <button
            style={{
              backgroundColor: activeTab === "year" ? "#007EC5" : "white",
              color: activeTab === "year" ? "white" : "black",
              border: "0px solid black",

              width: "200px",
              textAlign: "center",
              whiteSpace: "nowrap",
              fontFamily: "FK Grotesk", // Specify font family
              fontWeight: 600, // Specify font weight // Keeps text on one line only
              borderRadius: "5px",
              height: "50px",
            }}
            onClick={() => handleToggle("year")}
          >
            Bill Annually (Save 15%)
          </button>
        </div>

        {console.log("tab", activeTab)}

        {/* <div className="container">
          <div className="price-wrapper">
            <div className="price-inner">
              <div className="row"  style={{ border: "4px dotted black", display: "flex", flexWrap: "wrap" }}>
                {subscriptionsWithSeparatedPrices.map((plan, index) => (
                  console.log("index",index),
                  <div
                    key={index}
                    className="123"
                    style={{
                      border: "2px solid pink",
                      justifyContent: "center",
                      position: "relative",
                      marginTop:"20px",
                      transform: "scale(0.7)",

                    }}
                    >
                    <div
                      className="pricinglist standard123 d-flex bg-white " 
                      style={{
                        borderTop: "10px solid ",
                        borderTopColor: priceColors[index % priceColors.length],
                        width: "50vw",
                        height:"550px",
                        border:"3px solid black",
                      }}
                    >
                      <div className="plan-info-wrapper">
                        <div
                          className="plan-info-icon"
                          onClick={(e) => openInfoPopup(plan, index+1)}
                        >
                          <FaInfoCircle className="info-icon" />
                        </div>
                          {console.log("ispopupvisible",isPopupVisible)}
                          {console.log("selectedPlan",selectedPlan)}
                          {console.log("Plan",plan)}

                          

                        {isPopupVisible && selectedPlan.id === plan.id && (
                          <div className="info-popup" ref={modalRef} style={{ marginLeft: (index + 1) % 3 === 0 ? "-500px" : "0" }}>                            <p className="infopara">
                              This plan include following features:
                              <ul className="ps-0 mb-4">
                                {plan.features_details.map(
                                  (feature, featureIndex) => (
                                    <li
                                      key={featureIndex}
                                      style={{ position: "relative" }}
                                    >
                                      <span
                                        style={{
                                          color:
                                            priceColors[
                                              index % priceColors.length
                                            ],
                                        }}
                                      >
                                        {feature.name}:
                                      </span>{" "}
                                      {feature.description}
                                    </li>
                                  )
                                )}
                              </ul>
                            </p>

                            <button
                              className="close-popup"
                              onClick={closeInfoPopup}
                            >
                              Close
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="price">
  <span
    style={{
      color: priceColors[index % priceColors.length],
    }}
  >
   {activeTab === "month"
  ? plan?.month_price?.unit_amount ? `$${plan?.month_price?.unit_amount}` : "NA"
  : plan?.year_price?.unit_amount ? `$${plan?.year_price?.unit_amount}` : "NA"}

  </span>
  /{activeTab}
</div>
                      <h3 className="mb-3">{plan.plan_name}</h3>
                      <ul className="ps-0 mb-4">
                        {plan.features_details.map((feature, featureIndex) => (
                          <li
                            key={featureIndex}
                            style={{ position: "relative" }}
                          >
                            {feature.name}
                          </li>
                        ))}
                      </ul>
                      {
   (activeTab === "month" && plan?.month_price?.unit_amount) ||
   (activeTab === "yearly" && plan?.year_price?.unit_amount) ? (
    <button
      className="fw-bold btn-link"
      // onClick={() => setStripe_info(plan.id, activeTab === "month" ? plan?.month_price?.id : plan?.year_price?.id,env)}
      onClick={()=>openGatewayModal(plan)}
      disabled={plan.plan_name === active_subscription_list[0]?.subscription_details?.plan_name}
      style={{
        color: priceColors[index % priceColors.length],
        backgroundColor: "transparent",
        borderColor: priceColors[index % priceColors.length],
        transition: "background-color 0.3s, color 0.3s, border-color 0.3s",
      }}
      onMouseOver={(e) => {
        e.target.style.backgroundColor =
          priceColors[index % priceColors.length];
        e.target.style.color = "white";
      }}
      onMouseOut={(e) => {
        e.target.style.backgroundColor = "transparent";
        e.target.style.color = priceColors[index % priceColors.length];
      }}
    >
      { "Select Plan"}
    </button>
  ) : null
}

                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div> */}
        <div className="currentPlan_Parent">
          {console.log("subs_list", subscriptionsWithSeparatedPrices)}
          {subscriptionsWithSeparatedPrices
            .slice(selectedDots - 1, 4 + selectedDots)
            .map(
              (plan, index) => (
                console.log("index", index),
                (
                  <div
                    key={index}
                    className={`price_container feature-price-container-idx-${index}`}
                    style={{
                      transform: `scaleY(${
                        index === 0
                          ? 0.8
                          : index === 1
                          ? 0.9
                          : index === 2
                          ? 1
                          : index === 3
                          ? 0.9
                          : 0.8
                      })`,
                      borderTopColor: priceColors[index % priceColors.length],
                      borderStyle: "solid",
                      // borderWidth: "1px",
                      borderTopWidth: "4px",
                      borderColor: priceColors[index % priceColors.length],
                      // borderRightWidth:
                      //   index === 0 || index === 1 ? "0px" : "1px",
                      // borderLeftWidth:
                      //   index === 3 || index === 4 ? "0px" : "1px",
                    }}
                  >
                    <div
                      className="price_card"
                      style={{
                        borderTopColor: priceColors[index % priceColors.length],
                        width: "15vw",
                        // height: "300px",
                        margin: "0px",
                      }}
                    >
                      <div
                        className="price subs-price-div subs-price-header-responsive"
                        style={{
                          fontSize:
                            index == 0
                              ? "12px"
                              : index == 1
                              ? "14px"
                              : index == 2
                              ? "13px"
                              : index == 3
                              ? "14px"
                              : index == 4
                              ? "12px"
                              : "14px",
                        }}
                      >
                        <div>
                          <span
                            style={{
                              color: priceColors[index % priceColors.length],
                              border: "0px solid pink",
                              marginLeft: "8px",
                              fontWeight: "100",
                            }}
                          >
                            {activeTab === "month"
                              ? plan?.month_price?.unit_amount
                                ? `$${plan?.month_price?.unit_amount}`
                                : "NA"
                              : plan?.year_price?.unit_amount
                              ? `$${plan?.year_price?.unit_amount}`
                              : "NA"}
                          </span>
                          /{activeTab}
                        </div>

                        <div
                          className="plan-info-icon"
                          onClick={(e) => openInfoPopup(plan, index + 1)}
                          style={{
                            width: "20px",
                            height: "20px",
                            // borderRadius: "50%",
                            // backgroundColor: `${
                            //   priceColors[index % priceColors.length]
                            // }30`, 
                            textAlign: "center",
                            lineHeight: "20px",
                            // color: priceColors[index % priceColors.length],
                            marginRight: "10px",
                            cursor: "pointer",
                          }}
                        >
                          {/* <FaInfoCircle className="info-icon" /> */}
                          {/* &#8505; */}
                          <img
                            src={`/images/plan-feature-icons/alert-${index}.png`}
                            alt=""
                            // className={`feature-list-img-idx-${index}`}
                            style={{ height: "22px" }}
                          />
                        </div>
                      </div>
                      <div
                        className="plan-wrapper"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "5px",
                        }}
                      >
                        <h3
                          className="subs-plan-name-responsive mb-1"
                          style={{ marginLeft: "15px", fontWeight: "600" }}
                        >
                          {plan.plan_name}
                        </h3>
                        <div
                          className="plan-info-wrapper"
                          style={{ marginRight: "10px" }}
                        >
                          {console.log("ispopupvisible", isPopupVisible)}
                          {console.log("selectedPlan", selectedPlan)}
                          {console.log("Plan", plan)}
                          {isPopupVisible && selectedPlan.id === plan.id && (
                            <div
                              className="info-popup"
                              ref={modalRef}
                              style={{
                                marginLeft: "-440px",
                                marginTop: "-25px",
                              }}
                            >
                              <p className="">
                                This plan include following features:
                                <ul
                                  className="ps-0 mb-4"
                                  style={{ fontSize: "1em" }}
                                >
                                  {plan.features_details.map(
                                    (feature, featureIndex) => (
                                      <li
                                        key={featureIndex}
                                        style={{ position: "relative" }}
                                      >
                                        <span
                                          style={{
                                            color:
                                              priceColors[
                                                index % priceColors.length
                                              ],
                                          }}
                                        >
                                          {feature.name}:
                                        </span>{" "}
                                        {feature.description}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </p>
                              <button
                                className="close-popup"
                                style={{marginLeft: "37%"}}
                                onClick={closeInfoPopup}
                              >
                                Close
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          paddingBottom: "10px",
                          borderBottom: "1px solid lightgray",
                          width: "80%",
                          marginLeft: "15px",
                          marginBottom: "8px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            marginLeft: "0px",
                            color: "black",
                            fontWeight: "500",
                            paddingBottom: "5px",
                          }}
                        >
                          All the basics of business that are getting started
                        </p>
                      </div>

                      <PlanFeatureDetails
                        plan_features_details={plan.features_details}
                        index={index}
                        priceColors={priceColors}
                      />

                      {/* <ul
                        className="ps-0 mb-4"
                        style={{ listStyleType: "none" }}
                      >
                        {plan.features_details.map((feature, featureIndex) => (
                          <li
                            key={featureIndex}
                            style={{
                              position: "relative",
                              border: "0px dotted blue",
                              marginLeft: "10px",
                              paddingLeft: "0px",
                              marginTop: "8px",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                backgroundColor: `${
                                  priceColors[index % priceColors.length]
                                }30`, // Lighter shade of the border color with 80% opacity          marginRight: "10px",
                                textAlign: "center",
                                lineHeight: "20px",
                                color: priceColors[index % priceColors.length],
                              }}
                            >
                              &#10003;
                            </span>
                            {feature.name}
                          </li>
                        ))}
                      </ul> */}
                    </div>
                    {(activeTab === "month" &&
                      plan?.month_price?.unit_amount) ||
                    (activeTab === "yearly" &&
                      plan?.year_price?.unit_amount) ? (
                      <button
                        className="fw-bold btn-link"
                        onClick={() => openGatewayModal(plan)}
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          color: priceColors[index % priceColors.length],
                          backgroundColor: "transparent",
                          borderColor: priceColors[index % priceColors.length],
                          transition:
                            "background-color 0.3s, color 0.3s, border-color 0.3s",
                          whiteSpace: "nowrap",
                          width: "80%",
                          textAlign: "left",
                          paddingRight: "20px", // Add space for the arrow
                          overflow: "hidden", // Ensure overflow hidden for the button
                        }}
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor =
                            priceColors[index % priceColors.length];
                          e.target.style.color = "white";
                          e.target.querySelector(
                            "span:first-child"
                          ).style.background = "white"; // Set background color of first span to white on hover
                          e.target.querySelector(
                            "span:last-child"
                          ).style.borderLeftColor = "white"; // Set border color of second span to white on hover
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = "transparent";
                          e.target.style.color =
                            priceColors[index % priceColors.length];
                          e.target.querySelector(
                            "span:first-child"
                          ).style.background =
                            priceColors[index % priceColors.length]; // Restore background color of first span on mouse out
                          e.target.querySelector(
                            "span:last-child"
                          ).style.borderLeftColor =
                            priceColors[index % priceColors.length]; // Restore border color of second span on mouse out
                        }}
                      >
                        {"Get Started"}
                        <span
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: "10px",
                            height: "1px",
                            background: `${
                              priceColors[index % priceColors.length]
                            }`, // Set initial color
                          }}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            width: "0",
                            height: "0",
                            borderTop: "5px solid transparent",
                            borderBottom: "5px solid transparent",
                            borderLeft: `5px solid ${
                              priceColors[index % priceColors.length]
                            }`, // Set initial color
                          }}
                        />
                      </button>
                    ) : null}
                  </div>
                )
              )
            )}
        </div>
        <div style={{ display: "flex" }}>
          {/* Render dots dynamically based on the value of 'dots' */}
          {[...Array(dots)].map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index + 1)} // Add click handler to set the selected dot
              style={{
                width: selectedDots === index + 1 ? "20px" : "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: selectedDots === index + 1 ? "blue" : "grey", // Highlight selected dot
                margin: "5px",
                cursor: "pointer",
                top: "30px",
              }}
            ></div>
          ))}
        </div>
      </section>
      <ActivePlan
        open={showModal}
        onClose={closeModal}
        plan={selectedPlan}
        activeTab={activeTab}
      />
    </>
  );
};

export default AvailablePlan;
