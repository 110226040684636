import React, { useState, useRef, useEffect } from "react";
import useEmployeeList from "../../hooks/useEmployeeList";
import useLeaveManagement from "../../hooks/useLeaveManagement";
import DescriptionModal from "./DescriptionModal";
import EditLEaveModal from "./EditLEaveModal";
import moment from "moment";
import SelectEmployeeDD from "../SelectEmployeDD";
import ApplyLeaveModal from "./ApplyLeaveModal";
import { useSelector } from "react-redux";
import EmployeeLeaveTable from "./EmployeeLeaveTable";
import useEmployeeLeave from "../../hooks/useEmployeeLeave";
import "./style.css";
import SelectAllEmployee from "../SelectEmployeDD/SelectAllEmployee";
import SendEmail from "../common/SendEmail";
import { MonthPicker, DatePickerSingle, WeekPicker, DateRangePicker } from "../common/DatePickers";
import Pagination from "../common/Pagination/pagination";
import ToolTip from "../ToolTip";
import SelectAttendenceType from "../SelectType";




const EmployeeLeave = () => {

  const {
    startDate,
    startMonth,
    setStartMonth,
    currentTab,
    handleDecrease,
    handleIncrease,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    currentPage,
    setCurrentPage,
    pageCounts,
    leaveTransactionList,
    editEmployee,
    seeDescription,
    setModalOpen,
    userInfo,
    handleTabTypeChange,
    handleSelectChange,
    handleTextareaChange,
    selectedReason,
    text,
    handleSubmit,
    options,
    leaveTypeList,
    descriptionModal,
    setDescriptionModal,
    selectedEmployee,
    modalOpen,
    setStartDate,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    dateRangeStart,
    onChangeDateRange,
    dateRangeEnd,
    user,
    setUser,
    handleRowCheckboxChange,
    handleSelectAll,
    selectAll,
    selectedRows,
    inProgress,
    handleExport,
    exportdata,
    headers,
    csvref,
    exportState,
    editLeaveModal,
    setEditLeaveModal,
    handleSelectStatus,
    handleEditReasonChange,
    managerComment,
    handleUpdateLeave,
    leaveStatus,
    leaveOptions,
    editData,
    startMonth_status,
    setStartMonth_status,
    handleDecrease_status,
    handleIncrease_status,
    setLeaveStatus,
    handleChange,
    setIsEscalted,
    TabChange,
    activeTab,
    isEscalted,
    setStartWeekDay,
    setEndWeekDay,
    setDateRangeStart,
    setDateRangeEnd,
    setEndDate,
    setCurrentTab
  } = useEmployeeLeave();

  const { userGroups, userPermissions, userSubordinates } = useSelector((store) => store.auth);
  console.log("usersubo", userSubordinates);
  const isManager = userInfo.groups.some((item) => item.name === "Manager");
  const isHr = userInfo.groups.some((item) => item.name === "HR");
  const isSuperUser = userInfo.is_superuser;
  const calenderref = useRef(null);
  const [emailmodal, setEmailModal] = useState(false);
  const pages = Array.from({ length: pageCounts }, (_, i) => i + 1);

  const [tab_change,set_tab_change] = useState(false);

  const handle_leave_request_click = () => {
    TabChange("Leave request")
    set_tab_change(!tab_change)
  }

  const handle_Escalated_leave_request_click = () => {
    TabChange("Escalated Leave request")
    set_tab_change(!tab_change)
  }



  const sendEmail = () => {
    setEmailModal(true);
  }


  useEffect(() => {
    setUser([]);
    setStartMonth_status(new Date());
    setStartMonth(new Date());
    setStartDate(new Date())
    setCurrentTab("monthly");
    setWeekDate(new Date());
    setStartWeekDay("");
    setEndWeekDay("");

  }, [activeTab]);
  return (
    <>
      {userSubordinates.length > 0 || isHr || isSuperUser ? (<div className="leaves-table-header">

        <div
          className={`leaves-table-tab ${activeTab === "Leave request" && "active-table-tab"
            }`}
          // onClick={() => TabChange("Leave request")}
          onClick={handle_leave_request_click}
        >

          <p>Leave request</p>

        </div>
        <div
          className={`leaves-table-tab ${activeTab === "Escalated Leave request" && "active-table-tab"
            } left-margin`}
          // onClick={() => TabChange("Escalated Leave request")}
          onClick={handle_Escalated_leave_request_click}
        >
          <div>
            <p>Escalated Leave request</p>
          </div>
        </div>

      </div>) : (
        null
      )}

      <div className={`empleave-container ${userSubordinates.length > 0 || isHr || isSuperUser ? "with-border" : ""}`}>
        <div className="leave-header-btn" >


          {console.log(startMonth, "start123")}
          {userPermissions.includes("Can filter all users in reports") ||
            userPermissions.includes("Can filter own users in reports") ? (

            <SelectAllEmployee
              selectedUsers={user}
              setSelectedUsers={setUser}
              tab="dashboard"
              tab_change = {tab_change}
            />

          ) : null}
          <div className="date-filter-tabs" >



            <div className="leave-timeline" ref={wrapperRef} style={{ borderRadius: "8px", border: "2px solid #E8E8E8" }}>
              <img
                src="/images/icons/Icon - Calender-Dark.svg"
                alt="calendar"
                onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                ref={wrapperRef}
              />


              <div >

                {currentTab === "daily" && (
                  <DatePickerSingle
                    startDate={startDate}
                    ref={calendarRef}
                    setStartDate={setStartDate}
                    calendarIsOpen={calendarIsOpen}
                    setCalendarIsOpen={setCalendarIsOpen}
                    showMaxDate={true}

                  />
                )}

                {currentTab === "weekly" && (
                  <WeekPicker
                    ref={calendarRef}
                    weekDate={weekDate}
                    setWeekDate={setWeekDate}
                    startWeekDay={startWeekDay}
                    endWeekDay={endWeekDay}
                    calendarIsOpen={calendarIsOpen}
                    setCalendarIsOpen={setCalendarIsOpen}
                    showMaxDate={true}

                  />
                )}

                {currentTab === "monthly" && (
                  <MonthPicker
                    ref={calendarRef}
                    startMonth={startMonth_status}
                    setStartMonth={setStartMonth_status}
                    calendarIsOpen={calendarIsOpen}
                    setCalendarIsOpen={setCalendarIsOpen}
                    className="customize-monthpicker"
                    maxDate={null}

                  />
                )}

                {currentTab === "dateRange" && (
                  <DateRangePicker
                    ref={calendarRef}
                    dateRangeStart={dateRangeStart}
                    onChangeDateRange={onChangeDateRange}
                    dateRangeEnd={dateRangeEnd}
                    calendarIsOpen={calendarIsOpen}
                    showMaxDate={true}

                  />
                )}

              </div>








              {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly" ? (
                <button
                  className="prev-month"
                  onClick={() => {
                    handleDecrease_status();
                  }}
                ></button>
              ) : null}

              {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly" ? (
                <button
                  className="next-month"
                  onClick={() => {
                    handleIncrease_status();
                  }}
                >

                </button>
              ) : null}


            </div>

            <div className="leave-filter-dropdwon" ref={calenderref}>
              <SelectAttendenceType handleSubmit={handleChange} tab="Leave" activetab={activeTab} />

            </div>

          </div>
          <div className="emp-leave-button">
            {/* <div className="send-email-btn">
  {(userInfo.groups.map((item) => item.name).includes("HR")||userInfo.is_superuser) && (
    <button onClick={sendEmail}>Send Email</button>
  )}
</div> */}


            <div className={(leaveTransactionList.length === 0 || activeTab === "Escalated Leave request") ? "export-btn holiday-hidden" : "export-btn"} >
              {(userPermissions.includes("Can export report for all users") ||
                userPermissions.includes("Can export all reports") ||
                userPermissions.includes(
                  "Can export report for users added by or assigned to self"
                )) && (
                  <button onClick={() => handleExport(startMonth, user)} disabled={exportState} style={{ width: "110px", display: "flex", justifyContent: "center" }}>Export CSV
                    {exportState && <span class="spinner-grow spinner-grow-sm"></span>}
                    <img src="/images/icons/download.png" alt=""></img>
                  </button>
                )}
            </div>

          </div>
        </div>


        <DescriptionModal

          open={descriptionModal}
          onClose={() => setDescriptionModal(false)}
          hookProps={{
            selectedEmployee,
            isEscalted

          }}
        />
        <EditLEaveModal
          open={editLeaveModal}
          onClose={() => setEditLeaveModal(false)}
          hookProps={{
            editData,
            leaveOptions,
            handleSelectStatus,
            leaveStatus,
            managerComment,
            handleUpdateLeave,
            handleEditReasonChange,
            editLeaveModal,
            startMonth,
            setLeaveStatus,
            isEscalted

          }}
        />

        <EmployeeLeaveTable
          hookProps={{
            leaveTransactionList,
            currentPage,
            setCurrentPage,
            pageCounts,
            userInfo,
            seeDescription,
            handleRowCheckboxChange,
            handleSelectAll,
            selectAll,
            selectedRows,
            inProgress,
            editEmployee,
            userPermissions,
            activeTab,
            isManager,
            isEscalted
          }} />
        <SendEmail
          open={emailmodal}
          onClose={() => setEmailModal(false)}

        />
      </div>
      {pageCounts > 1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}

    </>
  );
};
export default EmployeeLeave;