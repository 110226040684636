import React from "react";

export default function AddButton({ title, onClick }) {
  return (
    <button className="add-btn" onClick={onClick}>
      <img src="/images/icons/add-icon.png" alt="add icon" />
      {title}
    </button>
  );
}
