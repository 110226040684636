import React, { useState, useRef } from "react";
import "./styles.css";
import AddButton from "../../components/AddButton";
import AddManualTime from "./AddManualTime";
import DatePicker from "react-datepicker";
import { DatePickerSingle } from "../../components/common/DatePickers";
import moment from "moment";
import SelectEmployeeDD from "../../components/SelectEmployeDD";
import SelectProjectsDD from "../../components/SelectProjectsDD";
import ScreenShotList from "../../components/WorkDiary/ScreenShotList";
import ScreenShotModal from "../../components/common/ScreenShotModal";
import useScreenshots from "../../hooks/useScreenshots";
import SelectAllEmployee from "../../components/SelectEmployeDD/SelectAllEmployee";
import SelectAllProject from "../../components/SelectProjectsDD/SelectAllProject";
import { Dropdown } from "react-bootstrap";

const ExampleCustomInput = ({ value, onClick }) => {
  return (
    <div>
      <input
        type="text"
        id="lname"
        className="example-custom-input"
        onClick={(e) => onClick(e.preventDefault())}
        value={moment(value).format("DD/MM/YYYY")}
        style={{
          backgroundImage: "url(/images/icons/ei_calendar.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
          backgroundSize: "30px",
          backgroundOrigin: "content-box",
          backgroundColor: "#fff",
          padding: "10px",
          color: "#777373",
          border: "1px solid #CACACA",
          width: "200px",
          height: "38px",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default function Screenshots() {
  const {
    AllScreenshots,
    users,
    setUsers,
    selectedProject,
    setSelectedProject,
    modalOpen,
    setModalOpen,
    startDate,
    setStartDate,
    inProgress,
    openSSModal,
    setOpenSSModal,
    openedSS,
    openModalAndSetSS,
    hendleNextImg,
    handlePreviousImg,
    cancleAddManualTime,
    workedTime,
    setShowWebcam,
    setShowSceenShot,
    setallscreenshot,
    setCalendarIsOpen,
    calendarIsOpen,
    handleDecrease,
    handleIncrease,
    calendarRef,
  } = useScreenshots();

  const [Webcam, setWebcam] = useState(false);
  const [Screenshot, setScreenshot] = useState(false);
  const [allScreenshot, setAllScreenshot] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('Filter');
  const { today_time } = workedTime;

  const toggleScreenshot = () => {
    setCurrentFilter("Screenshot")
    setScreenshot((prevState) => !prevState);
    setShowSceenShot((prevState) => !prevState);
    setWebcam(false);
    setShowWebcam(false);
    setAllScreenshot(false);
    setAllScreenshot(false);
  };

  const toggleAll = () => {
    setCurrentFilter("All");
    setAllScreenshot((previous) => !previous);
 
    
    setWebcam(false);
    setShowWebcam(false);
    setScreenshot(false);
    setShowSceenShot(false);
  };

  const toggleWebcam = () => {
    setCurrentFilter("Webcam")
    setWebcam((previous) => !previous);
    setShowWebcam((previous) => !previous);
    setScreenshot(false);
    setShowSceenShot(false);
    setAllScreenshot(false);
    setAllScreenshot(false);
  };

  const wrapperRef = useRef(null);

  return (
    <>
      <AddManualTime
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hookProps={{
          cancleAddManualTime,
        }}
      />

      <div style={{ display: "flex", alignItems: "center" ,border: "0px dotted pink" }}>
      <div className="row pt-1 pb-2" style={{ border: "0px dotted blue", width: "100%" }}>
        <div className="col" style={{ border: "0px solid pink", display: "flex", alignItems: "center" }}>
  <SelectAllEmployee selectedUsers={users} setSelectedUsers={setUsers} tab="Work_Diary" />
  <div style={{marginLeft: "5px"}}>
  <SelectAllProject selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
  </div>
 {/* <div
  className="col"
  style={{
    display: "inline-block",
    alignItems: "center",
    marginLeft: "10px",
    border: "2px solid blue",
    borderRadius: "7px",
    backgroundColor: "white",
  }}
>
<div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ backgroundColor: "white" ,              border: "2px solid blue",
}}>
              <img
                src="/images/icons/Icon - Calender.png"
                alt="calendar"
                onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                ref={wrapperRef}
              />
            </div>

            <DatePickerSingle
              ref={calendarRef}
              startDate={startDate}
              setStartDate={setStartDate}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
              maxDate={new Date()}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
            />

            <button
              className="prev-month"
              onClick={() => {
                handleDecrease();

              }}
            ></button>

            <button
            style={{ backgroundColor: "white" ,              border: "2px solid blue",}}
              className="next-month"
              onClick={() => {
                handleIncrease();
              }}
            ></button>
            </div>
          </div> */}
          <div className="employee-timeline" style={{border:"2px solid lightgrey"}}>
          <img
            src="/images/icons/Icon - Calender.png"
            alt="calendar"
            onClick={() => setCalendarIsOpen(!calendarIsOpen)}
            ref={wrapperRef}
            
          />

          <div >

            
              <DatePickerSingle  
               ref={calendarRef}
                startDate={startDate}
                setStartDate={setStartDate}
                calendarIsOpen={calendarIsOpen}
                setCalendarIsOpen={setCalendarIsOpen}
              ></DatePickerSingle>
           

            
           
          

          </div>



            <button
              className="prev-month"
              onClick={() => {
                handleDecrease();
              }}
            ></button>



            <button
              className="next-month"
              onClick={() => {
                handleIncrease();
              }}
             >
              
            </button>
          

        </div>
        <div class="row" style={{border:"0px dotted red"}}>
            <div class="col py-3">
            <div className="total-time" style={{ border: "0px dotted red", display: "flex", justifyContent: "flex-end", fontFamily: "FK Grotesk", fontWeight: 900, color: "black" }}>
  Your Work hrs:{" "}
  <span style={{paddingLeft: "4px"}}>{`${today_time?.hours} hrs ${today_time?.minutes} mins`}</span>
</div>

            </div>
          </div>
</div>

          {/* <div class="col">
            <SelectAllProject
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
            />
          </div> */}

          {/* <div
            className="col"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              border: "2px solid lightgrey",
              borderRadius: "7px",
              backgroundColor: "white",
            }}
          >
            <div style={{ backgroundColor: "white" }}>
              <img
                src="/images/icons/Icon - Calender.png"
                alt="calendar"
                onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                ref={wrapperRef}
              />
            </div>

            <DatePickerSingle
              ref={calendarRef}
              startDate={startDate}
              setStartDate={setStartDate}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
              maxDate={new Date()}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
            />

            <button
              className="prev-month"
              onClick={() => {
                handleDecrease();
              }}
            ></button>

            <button
              className="next-month"
              onClick={() => {
                handleIncrease();
              }}
            ></button>
          </div> */}
          {/* <div class="row">
            <div class="col py-3">
              <div className="total-time">
                Your Work hrs:{" "}
                <span>{`${today_time?.hours} hrs ${today_time?.minutes} mins`}</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div style={{ border: "2px solid #E8E8E8",borderRadius:"6px" }}>
      <div>
        <div style={{ border: "0px solid #E8E8E8" }}>
          <Dropdown  style={{ border: "0px solid blue",marginBottom:"0px",marginLeft:"5px",marginTop:"5px" }}>
            <Dropdown.Toggle
              style={{
                backgroundColor: "white",
                color: "black",
                border: "2px solid lightgrey",
                width: "260px",
                // textAlign: "right",
                borderRadius: "7px",
              }}
            >
              <div className="screenshot-select-tab-header">
                <span>{currentFilter}</span>
                <img
                  src="/images/icons/Shapearrowdown.png"
                  alt="down"
                />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{ backgroundColor: "white", color: "black", width: "250px" }}
            >
              <div style={{ marginTop: "5px", marginBottom: "5px" }} className="screenshot-page-dropdown">
                <Dropdown.Item
                  style={{
                    padding: "40px 20px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    color: "gray",
                    backgroundColor: allScreenshot
                      ? "var(--silver-phoenix)"
                      : "transparent",
                  }}
                >
                  <img
                    src={
                      allScreenshot
                        ? "/images/icons/checked-icon.svg"
                        : "/images/icons/uncheck-icon.svg"
                    }
                    alt="All"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={toggleAll}
                  />
                  <label htmlFor="all">All</label>
                </Dropdown.Item>
                <Dropdown.Item
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    color: "gray",
                    backgroundColor: Screenshot
                      ? "var(--silver-phoenix)"
                      : "transparent",
                  }}
                >
                  <img
                    src={
                      Screenshot
                        ? "/images/icons/checked-icon.svg"
                        : "/images/icons/uncheck-icon.svg"
                    }
                    alt="Screenshot"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={toggleScreenshot}
                  />
                  <label htmlFor="screenshot">Screenshot</label>
                </Dropdown.Item>
                <Dropdown.Item
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    color: "gray",
                    backgroundColor: Webcam
                      ? "var(--silver-phoenix)"
                      : "transparent",
                  }}
                >
                  <img
                    src={
                      Webcam
                        ? "/images/icons/checked-icon.svg"
                        : "/images/icons/uncheck-icon.svg"
                    }
                    alt="Webcam"
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={toggleWebcam}
                  />
                  <label htmlFor="webcam">Webcam</label>
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="work-diary-container" style={{ border: "0px solid red",marginLeft:"0%",marginRight:"4%"}}>
        <div className="screen-content">
          {inProgress ? (
           
             <div style={{textAlign:"center"}}> <h3 className="custom-no-data">Loading Screenshots ...</h3></div>
          
          ) : (
            <ScreenShotList
              data={AllScreenshots}
              openModalAndSetSS={openModalAndSetSS}
            />
          )}
        </div>
      </div>

      <ScreenShotModal
        open={openSSModal}
        onClose={() => setOpenSSModal(false)}
        setOpenModal={setModalOpen}
        hendleNextImg={hendleNextImg}
        SS={openedSS}
        screenshots={AllScreenshots}
        handlePreviousImg={handlePreviousImg}
      />
              </div>

    </>
  );
}
