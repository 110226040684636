import React from "react";
import { useState } from "react";
import LeaveAllotmentTable from "./LeaveAllotmentTable";
import { useSelector } from "react-redux";
import EmpTypeModal from "./EmpTypeModal";
import useLeaveAllotment from "../../hooks/useLeaveAllotment";
import { Axios_v2 } from "../../Utils/axios";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";
const LeaveAllotment = () => {
  const {
    employeeType,
    leaveAlloted,
    modalOpen,
    leaveAllotmentList,
    currentPage,
    setCurrentPage,
    leavepageCounts,
    pageCounts,
     userInfo,
     handleDeleteLeave,
    setModalOpen,
    handleEmployeeChange,
    handleLeaveAllotmentChange,
    editLeaveAllotment,
    handleRowCheckboxChange,
    handleSelectAll,
    selectAll,
    selectedRows,
    handleLeaveSubmit,
    openModal,
    inProgress,
    editLeaveID,

  } = useLeaveAllotment();
  const { userPermissions } = useSelector((store) => store.auth);
  const handleClick = () => {
  
    
    Axios_v2.get(`/employee-band-category/export_employee_tenure/`,HeaderToken())
      .then(response => {
        // Here you can handle the response
        console.log("Export_successful:", response);
        window.location.href = response.data.file;

        // For example, if you want to download a file from the response
 
       
      })
      .catch(error => {
        // Handle errors
        console.error("Export failed:", error);
        toast.warn("File can't be downloaded");
      });
  };
  return (
    <div className="leave-allotement">
      <div className="headerleave-btn" style={{marginBottom: "2px"}}>
      <p style={{fontWeight: "bold"}}>Leave Management</p>
        <div className="button-container mt-0">
          <div className={leaveAllotmentList.length === 0 ? "export-btn holiday-hidden" : "export-btn"}>
          <button onClick={() => handleClick()} >
        Export CSV
         <img src="/images/icons/download.png" alt=""/>
         </button>
          </div>
      


        {userPermissions.includes("Add employeebandcategory")&&(
           <button
           className="add-public-holiday-btn"
           type="submit"
           onClick={() => openModal()}
         >
           <img src="/images/icons/add-icon.png" alt="add" style={{height: "18px", marginRight: "5px"}}/>
           Add Tenure
         </button>
        )}
        </div>
      </div>
      <LeaveAllotmentTable
        hookProps={{
          currentPage,
          editLeaveAllotment,
          setCurrentPage,
          handleDeleteLeave,
          pageCounts,
          userInfo,
         pageCounts,
        leavepageCounts,
         setCurrentPage,
        leaveAllotmentList,
        editLeaveAllotment,
        userInfo,
         handleDeleteLeave,
       handleRowCheckboxChange,
       handleSelectAll,
       selectAll,
       selectedRows,
       inProgress,
       userPermissions
        }}
      />
      <EmpTypeModal 
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hookProps={{
          employeeType,
          leaveAlloted,
          handleEmployeeChange,
          handleLeaveAllotmentChange,
          handleLeaveSubmit,
          editLeaveID
        }}
      />
    </div>
  );
};
export default LeaveAllotment;