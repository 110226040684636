import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";

export const LATEST_SCREENSHORTS = "LATEST_SCREENSHORTS";
export const All_SCREENSHORTS = "All_SCREENSHORTS";
export const DELETE_SCREENSHOT = "DELETE_SCREENSHOT";

export const getlatestSecrrenShots = (
  date,
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  setTotalValues,
) => {
  return async (dispatch) => {
    Axios_v2.get(
      `/screenshots/?ordering=-datetime_of_capture&page=${currentPage}&date_of_capture=${date}&items_per_page=${itemsPerPage}`,
      // `/screenshots/?ordering=-datetime_of_capture&page=${currentPage}&items_per_page=${itemsPerPage}`, // for testing
      HeaderToken()
    )
      .then((res) => {
        console.log("latest SS response", res);
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: LATEST_SCREENSHORTS, payload: res.data.results });

          console.log("from PROJECT pagination ", res);

          setPageCounts(res.data.total_pages);
          setTotalValues(res.data.count);
          setInProgress(false);
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};

export const getAllSecrrenShots = ({
  date,
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  setTotalValues,
  setItemsPerPage,
  users,
  selectedProject,
  webcam = false,
  screenshot = false,
  all = false,

}) => {
  return async (dispatch) => {
    setInProgress(true);
    const searchUserString = users
      .map((id) => `&user=${id}`)
      .toString()
      .replace(/,/g, "");

    const searchProjectString = selectedProject
      .map((id) => `&project=${id}`)
      .toString()
      .replace(/,/g, "");
    let image_types = "all";

    if (webcam && screenshot) {
      image_types = "all";
    } else if (!webcam && screenshot) {
      image_types = "screenshot";
    } else if (webcam && !screenshot) {
      image_types = "webcam";
    } else if (all) {
      image_types = "all";
    }


    Axios_v2.get(
      `/screenshots/?page=${currentPage}&date_of_capture=${date}&items_per_page=${itemsPerPage}${
        users.length > 0 ? searchUserString : ""
      }${selectedProject.length > 0 ? searchProjectString : ""}&image_choice=${image_types}`,
      HeaderToken()
    )
      .then((res) => {
        console.log("latest SS response", res);
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: All_SCREENSHORTS, payload: res.data.results });

          console.log("from PROJECT pagination ", res);

          setPageCounts(res.data.total_pages);
          setTotalValues(res.data.count);
          setItemsPerPage(res.data.count);
          setInProgress(false);
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};

export const deleteScreenShot = (id) => {
  return async (dispatch) => {
    await Axios_v2.delete(`/screenshots/${id}/`, HeaderToken())
      .then((res) => {
        console.log("delete", res.data);
        SiteUpdatehandler(res, dispatch);
        if (res.data.status === "false") {
          toast.error(res.data.response);
        } else {
          dispatch({ type: DELETE_SCREENSHOT, payload: id });
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};
