import React from "react";
import { useState } from "react";
import moment from "moment";
import { RenderPageButtons } from "../../Utils/randerPageButtons";
import Pagination from "../common/Pagination/pagination";
 
import Tooltip from "../ToolTip";
 
 
export default function LeaveAllotmentTable({ hookProps })
 {
  const {
    currentPage,
    pageCounts,
    leavepageCounts,
    setCurrentPage,
    leaveAllotmentList,
    editLeaveAllotment,
    userInfo,
    handleDeleteLeave,
    handleRowCheckboxChange,
    handleSelectAll,
    selectAll,
    selectedRows,
    inProgress,
    userPermissions
  } = hookProps;
  const pages = [];
 
  for (let i = 1; i <= leavepageCounts; i++) {
    pages.push(i);
  }
 
  const renderPageNumbers = RenderPageButtons(
    currentPage,
    leavepageCounts,
    setCurrentPage
  );
 
  return (
    <div className="leaves-table-body" style={{marginBottom: "5px"}}>
    <table >
     
        <tr>
          {/* <th scope="col">
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
              <span className="checkmark"> </span>
            </label>
          </th> */}
          <th scope="col" style={{textAlign:"justify", paddingLeft:"38px"}}>
            Type of Tenure
          </th>
          <th scope="col" className="emp-no">
            Number of Employees
          </th>
          <th scope="col">Number of leaves assigned</th>

  {(userPermissions.includes("Change employeebandcategory") ||userPermissions.includes("Delete employeebandcategory")) &&(
  <th scope="col" style={{textAlign:"justify", paddingLeft: "15px"}}>Action</th>
)}


         
        </tr>
      
      
        {inProgress ? (
          <tr className="table-Rows-loadin">
            <td colSpan="4" className="loader">
            <h3 className="custom-no-data">Loading Data...</h3>
            </td>
          </tr>
        ) : (
          leaveAllotmentList.map((emp, index) => (
            <tr className="table-row" key={index}>
              <td style={{position: "relative"}}>
                <div style={{textAlign:"justify" ,paddingLeft:"44px"}}>{emp.category_name}</div>
                <div className="to-hide-left-border-l"></div>
              </td>
              <td>
                <div> {emp.num_employees}</div>
              </td>
              <td>
                <div>{emp.monthly_allotted_leaves}</div>
              </td>

              { (userPermissions.includes("Change employeebandcategory") || userPermissions.includes("Delete employeebandcategory")) &&
                <td>
                  <div className="table-actions">
                  {userPermissions.includes("Change employeebandcategory")&&(
                    <Tooltip position="top" title="Edit ">
                      <button onClick={() => editLeaveAllotment(emp)}>
                        <img src="/images/icons/edit-green.png" alt="edit" />
                      </button>
                    </Tooltip>
                  )}

                  {userPermissions.includes("Delete employeebandcategory")&&(
                    <Tooltip position="top" title="Delete">
                      <button onClick={() => handleDeleteLeave(emp.id)} className={emp.num_employees>=1 ? "holiday-hidden" : ""}>
                        <img src="/images/icons/delete.png" alt="archived" />
                      </button>
                    </Tooltip>
                    )}
                  </div>
                </td>
              }


            </tr>
          ))
        )}
     
    </table>

    {/* {leavepageCounts>1&&(
              <div className="projects-table-footer">
              <div className="table-pagination">
                <button
                  className="prev-scr"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  {"PREV"}
                </button>
                {renderPageNumbers}
                <button
                  className="next-scr"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === pages[pages.length - 1] ? true : false}
                >
                  {"NEXT"}
                </button>
              </div>
            </div>
    )} */}


    {leavepageCounts>1&&(
           <Pagination
           pages={pages}
           currentPage={currentPage}
           setCurrentPage={setCurrentPage}
         />
      )}
    
  </div>
);
}