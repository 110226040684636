import React, { useState, useEffect } from "react";
import Modal from "../common/Modal";
import "./style.css";
 
const DescriptionModal = ({ open, onClose, hookProps }) => {
   
    const {
         selectedEmployee,
         isEscalted
      } = hookProps;
      if (!selectedEmployee) {
        return null;
      } 
  return (
    <Modal open={open} onClose={onClose}>
      <div className="description-container">

        <div className="description-header">
          <h5>
            Description
          </h5>
          <div onClick={() => onClose()}>
            <img src="/images/icons/clear-icon.svg" alt="" />
          </div>
        </div>

        <div className="emp-name-leave-status-parent">

          <div className="emp-name-combine">
            <div className="grey-color">
              Employee Name
            </div>
            
            <div>
              {isEscalted ? selectedEmployee.user_details?.name:selectedEmployee.user.name}
            </div>
          </div>

          <div className="leave-status-combine">
            <div className="grey-color">
              Status
            </div>

            <div>
              <span
                className={
                  selectedEmployee.status === "Approved"
                    ? "approved-status"
                    : (selectedEmployee.status === "Deny" || selectedEmployee.status === "Rejected")
                    ? "not-approved-status"
                    : "need-action"
                }
              >
                {(selectedEmployee.status === "Deny" || selectedEmployee.status === "Rejected") ? "Rejected" : selectedEmployee.status}
              </span>
          </div>
          </div>


        </div>



        <div className="">
          
          <div className="emp-remark-parent">
            <div className="grey-color">
              Employee's remark
            </div>

            <div className="">
              {selectedEmployee.comment}
            </div>
          </div>


          {selectedEmployee.status === "Approved" ||  (selectedEmployee.status === "Deny" || selectedEmployee.status === "Rejected") ? (
            <div className="approve-reject-parent">
              <div className="grey-color">

                {selectedEmployee.status === "Approved" ? "Approved By" : "Rejected By"}
              </div>

              <div className="" >
            {isEscalted ? selectedEmployee. approved_by_name:selectedEmployee.approved_by_datail?.name}

              </div>

            </div>
          ) : null}



          {selectedEmployee.status === "Approved" ||  (selectedEmployee.status === "Deny" || selectedEmployee.status === "Rejected") ? (
          <div className="manager-remark-parent">
            <div className="grey-color">
              Manager's remark
            </div>


            <div>
              {/* {selectedEmployee.manager_comment} */}
              {selectedEmployee.manager_comment || "No specific remark"}
              
              
            </div>
          </div>


          ) : null}



          
        </div>

     <div style={{display:"flex",justifyContent:"center"}}>
        <button className="desc-model-close-btn" onClick={() => onClose()}>
          Close
        </button>
        </div>
      </div>
    </Modal>
  );
};

export default DescriptionModal;