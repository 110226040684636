import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../../theme/theme";
import "./styles.css";
import { logOut } from "../../../redux/Actions/authAction";
import Alert from "../Alert";

export default function Header(props) {
  const { title } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { userInfo } = useSelector((store) => store.auth);
  const [subMenu, setSubMenu] = useState(false);
  const wrapperRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (subMenu) {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target) &&
          !event.target.closest(".user-menu-dropdown-profile")
        ) {
          setSubMenu(false);
        }
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef);

  const LogoutAlert = () => {
    setModalOpen(true);
  };

  const handleLogOut = async () => {
    dispatch(logOut());
    navigate("/");
  };

  return (
    <>
    <Alert
        message="Logout"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        setOpenModal={setModalOpen}
        handleDelete={handleLogOut}
      />
      <p
        className="gustavo"
        style={{
          fontSize: theme.fontSizes.xLarge,
          // fontWeight: theme.fonts.sansSerif.fontWeight500,
          fontWeight:'bold'
        }}
      >
        {title}
      </p>
      <div className="selectdiv" onClick={() => setSubMenu((prev) => !prev)}>
        <div className="user-menu-dropdown gustavo">
          <img
            className="user-menu-dropdown-profile-img"
            src={
              userInfo.profile_image
                ? userInfo.profile_image
                : "/images/profilepic.png"
            }
            alt="profile"
            onError={(e) => {
              e.target.src = "/images/profilepic.png";
            }}
          />
          <div className="user-menu-dropdown-profile">
            <p className="gustavo" style={{ fontSize: theme.fontSizes.small }}>
              {userInfo.name}
            </p>
            <img
              src="/images/icons/arrow-down.png"
              alt="arrow"
              style={{ transform: subMenu ? "rotate(180deg)" : "" }}
            />
          </div>
        </div>
        {subMenu && (
          <div className="profile-section" ref={wrapperRef}>
            <div className="profile-section-inner">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="profile-section-left">
                  <p
                    style={{
                      color: theme.colors.primary,
                      fontSize: theme.fontSizes.small,
                      alignSelf: "center",
                    }}
                  >
                    Mechlin Technologies
                  </p>
                </div>
                <div className="profile-section-right" >
                  <Link
                    to="/"
                    onClick={() => LogoutAlert()}
                  >
                    <span
                      style={{
                        color: theme.colors.hotCortal,
                        fontSize: theme.fontSizes.small,
                      }}
                    >
                      Log Out
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="profile-section-inner">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="profile-section-left">
                  <img
                    className="user-menu-dropdown-profile-img"
                    src={
                      userInfo.profile_image
                        ? userInfo.profile_image
                        : "/images/profilepic.png"
                    }
                    alt="profile"
                    onError={(e) => {
                      e.target.src = "/images/profilepic.png";
                    }}
                  />
                </div>
                <div className="profile-section-right">
                  <p
                    className="gustavo"
                    style={{ fontSize: theme.fontSizes.small }}
                  >
                    {userInfo.name}
                  </p>
                  <p
                    className="gustavo"
                    style={{ fontSize: theme.fontSizes.xSmall }}
                  >
                    {userInfo.email}
                  </p>
                </div>
              </div>
            </div>

            <div className="profile-section-outer">
              <Link to="/ProfileSettings" className="sub-nav-menu custom-style border-radius">
                <div
                  className="menu-container"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="time-zone-container">
                  <img
                  src="/images/icons/profile.svg"
                  alt="Profile"
                  height={theme.fontSizes.large}
                  width={theme.fontSizes.large}
                />
                    <span
                      style={{
                        paddingLeft: theme.fontSizes.xSmall,
                        fontFamily: theme.fonts.sansSerif,
                      }}
                    >
                      Profile
                    </span>
                  </div>
                  <div className="icon-container">
                    <img
                      src="/images/icons/grater-arrow-icon.svg"
                      alt="arrow"
                    />
                  </div>
                </div>
              </Link>
              {/* <Link
                to="/"
                className="sub-nav-menu border-radius"
              >
                <div
                  className="menu-container"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="time-zone-container">
                    <img
                      src="/images/icons/time-zone.svg"
                      alt="time-zone"
                      height={theme.fontSizes.large}
                      width={theme.fontSizes.large}
                    />
                    <span
                      style={{
                        paddingLeft: theme.fontSizes.xSmall,
                        fontFamily: theme.fonts.sansSerif,
                      }}
                    >
                      Time Zone
                    </span>
                  </div>
                  <div className="icon-container">
                    <img
                      src="/images/icons/grater-arrow-icon.svg"
                      alt="arrow"
                    />
                  </div>
                </div>
              </Link>  */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
