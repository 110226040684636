import React from "react";
import useSubscription from "../../../hooks/useSubscription";

const PlanFeatureDetails = ({ plan_features_details, index, priceColors }) => {
  const { allPossibleFeaturesList } = useSubscription();
  const featureNames = plan_features_details.map(
    (featureDetail) => featureDetail.name
  );

  const iconStyle = {
    display: "inline-block",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: `${priceColors[index % priceColors.length]}30`, // Lighter shade of the border color with 80% opacity
    textAlign: "center",
    lineHeight: "20px",
    color: priceColors[index % priceColors.length],
    marginRight: "5px",
  };

  const liStyles = {
    position: "relative",
    border: "0px dotted blue",
    marginLeft: "10px",
    paddingLeft: "0px",
    marginTop: "15px",
    fontSize: "15px",
    display: "flex",
    alignItems: "center",
  };

  const presentFeatures = allPossibleFeaturesList.filter((feature) =>
    featureNames.includes(feature)
  );

  // Filter features where isPresent is false
  const absentFeatures = allPossibleFeaturesList.filter(
    (feature) => !featureNames.includes(feature)
  );

  return (
    <ul className="ps-0 mb-4" style={{ listStyleType: "none" }}>
      {presentFeatures.map((feature, featureIndex) => (
        <li
          key={featureIndex}
          style={liStyles}
          className="plan-feature-detail-responsive"
        >
          {/* <span>
            <span style={iconStyle}>&#10003;</span>{" "}
          </span> */}
          <img
            src={`/images/plan-feature-icons/tick-${index}.png`}
            alt=""
            className={`feature-list-img-idx-${index}`}
            style={{height: "22px"}}
          />

          <span style={{ marginLeft: "5px" }}>{feature}</span>
        </li>
      ))}

      {/* Render absent features afterwards */}
      {absentFeatures.map((feature, featureIndex) => (
        <li
          key={featureIndex}
          style={liStyles}
          className="plan-feature-detail-responsive"
        >
          {/* <span>
            <span style={iconStyle}>&#10007;</span>{" "}
          </span> */}

          <img src={`/images/plan-feature-icons/lock-${index}.png`} alt="" style={{height: "22px"}}/>

          <span
            style={{
              textDecoration: "line-through",
              color: "lightgray",
              marginLeft: "5px",
            }}
          >
            {feature}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PlanFeatureDetails;
