import React, { useEffect, useState } from "react";
import Tooltip from "../ToolTip";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewShift,
  updateShift,
  deleteShift,
  SaveNewWorkSchedule,
  RemoveNewWorkSchedule,
} from "../../redux/Actions/workScheduleActions";
// import Avatar from "../common/Avater/Avater";

const SelectComp = (props) => {
  return (
    <select
      className="form-control bg-primary"
      aria-label="Default select example"
      {...props}
    >
      <option value="1">Monday</option>
      <option value="2">Tuesday</option>
      <option value="3">Wednesday</option>
      <option value="4">Thursday</option>
      <option value="5">Friday</option>
      <option value="6">Saturday</option>
      <option value="6">Sunday</option>
    </select>
  );
};

const InputComp = (props) => {
  return <input type="text" className="form-control" {...props} />;
};

export const TableRowSingle = ({ data }) => {
  const dispatch = useDispatch();
  const [workSchedule, setWorkSchedule] = useState(data);
  const [collapsed, setCollapsed] = useState(false);
  const { userGroups, userPermissions } = useSelector((store) => store.auth);
  const { userInfo } = useSelector((store) => store.auth);

  const SaveShift = (shiftData, setEditFalse) => {
    if (
      !shiftData.start_time.length < 1 &&
      !shiftData.end_time.length < 1 &&
      !shiftData.minimum_shift_hours.length < 1
    ) {
      if (shiftData.id.includes("new")) {
        dispatch(addNewShift(shiftData, workSchedule, setWorkSchedule));
      } else {
        dispatch(
          updateShift(shiftData, workSchedule, setWorkSchedule, setEditFalse)
        );
      }
    }
  };

  const createNewWorkSchedule = (Data) => {
    console.log("new WS data", workSchedule, Data);
    dispatch(SaveNewWorkSchedule({ ...workSchedule, shifts: [Data] }));
  };

  const removeNewWorkSchedule = () => {
    dispatch(RemoveNewWorkSchedule(workSchedule));
  };

  const RowValues = ({ val, i }) => {
    const [edit, setEdit] = useState(val.id.includes("new") ? true : false);
    const [showRow, setShowRow] = useState(false);
    const setEditFalse = () => {
      setEdit(false);
    };
    const [shiftData, setShiftData] = useState({
      id: val.id,
      day_start: val.day_start,
      start_time: val.start_time,
      day_end: val.day_end,
      end_time: val.end_time,
      minimum_shift_hours: val.minimum_shift_hours,
      work_schedule: val.work_schedule,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setShiftData((prevVal) => ({ ...prevVal, [name]: value }));
    };

    if (i === 0 || !collapsed) {
      return (
        <>
          {/* {workSchedule.id.includes("new") ? null : (
            <CustomRow
              hookProps={{
                shiftData,
                i,
                collapsed,
                workSchedule,
                setCollapsed,
                handleChange,
                edit,
                createNewWorkSchedule,
                SaveShift,
                setEditFalse,
                removeNewWorkSchedule,
                val,
                removeRow,
                setEdit,
              }}
            />
          )} */}
          <tr key={shiftData.id}>
            {i === 0 && (
              <td
                rowSpan={
                  !collapsed
                    ? workSchedule.shifts.length + 1
                    : workSchedule.id.includes("new")
                      ? 2
                      : 1
                }
              >
                <div className="name-container">
                  {/* <p
                  className="sort"
                  onClick={() => setCollapsed((prev) => !prev)}
                >
                  src="/images/icons/arrow.png"
                </p> */}
                  <img
                    className="sort"
                    onClick={() => setCollapsed((prev) => !prev)}
                    src="/images/icons/arrow.png"
                    style={{ transform: !collapsed ? "rotate(90deg)" : "" }}
                    alt="arrow"
                  />
                  {workSchedule?.user?.profile_image ? (
                    <img
                      src={workSchedule?.user?.profile_image}
                      // alt="profile"
                      width="50"
                      onError={(e) => {
                        e.target.src = "/images/profilepic.png";
                      }}
                    />
                  ) : // <Avatar name={workSchedule?.user?.name} />
                    null}

                  {workSchedule?.user?.name}
                </div>
              </td>
            )}

            <td>
              <SelectComp
                value={shiftData.day_start}
                name="day_start"
                onChange={(e) => handleChange(e)}
                disabled={!edit}
              />
            </td>
            <td>
              <InputComp
                name="start_time"
                placeholder="0 hrs"
                value={shiftData.start_time}
                onChange={(e) => handleChange(e)}
                disabled={!edit}
                style={{
                  border:
                    shiftData.start_time.length < 1 ? "3px solid red" : null,
                }}
              />
            </td>
            <td>
              <SelectComp
                value={shiftData.day_end}
                name="day_end"
                onChange={(e) => handleChange(e)}
                disabled={!edit}
              />
            </td>
            <td>
              <InputComp
                name="end_time"
                placeholder="0 hrs"
                value={shiftData.end_time}
                onChange={(e) => handleChange(e)}
                disabled={!edit}
                style={{
                  border:
                    shiftData.end_time.length < 1 ? "3px solid red" : null,
                }}
              />
            </td>
            <td>
              <InputComp
                name="minimum_shift_hours"
                placeholder="0 hrs"
                value={shiftData.minimum_shift_hours}
                onChange={(e) => handleChange(e)}
                disabled={!edit}
                style={{
                  border:
                    shiftData.minimum_shift_hours.length < 1
                      ? "3px solid red"
                      : null,
                }}
              />
            </td>
            <td>
              <div style={{ display: "flex" }}>
                {edit ? (
                  <>
                    {userPermissions.includes(
                      "Add work schedules for all users"
                    ) ||
                      (userPermissions.includes("Add own work schedules") &&
                        (userInfo.id === data.user_id)) ||
                      userPermissions.includes("Add work schedules for users assigned to or added by self") ? (
                      <Tooltip
                        position="top"
                        title={
                          workSchedule.id.includes("new")
                            ? "Save Work Schedule"
                            : "Save"
                        }
                      >
                        <span
                          className="add-minus"
                          onClick={() =>
                            workSchedule.id.includes("new")
                              ? createNewWorkSchedule(shiftData)
                              : SaveShift(shiftData, setEditFalse)
                          }
                        >
                          ✔
                        </span>
                      </Tooltip>
                    ) : null}
                    {userPermissions.includes(
                      "Add work schedules for all users"
                    ) ||
                      (userPermissions.includes("Add own work schedules") &&
                        (userInfo.id === data.user_id)) ||
                      userPermissions.includes("Add work schedules for users assigned to or added by self") ? (
                      <Tooltip position="top" title="Cancel">
                        <span
                          className="add-minus"
                          onClick={() =>
                            workSchedule.id.includes("new")
                              ? removeNewWorkSchedule(workSchedule)
                              : val.id.includes("new")
                                ? removeRow(val.id)
                                : setEdit(false)
                          }
                        >
                          ❌
                        </span>
                      </Tooltip>
                    ) : null}

                  </>
                ) : (
                  <>
                    {userPermissions.includes(
                      "Change work schedules for all users"
                    ) ||
                      userPermissions.includes(
                        "Change work schedules for users assigned to or added by self"
                      ) ? (
                      <Tooltip position="top" title="Edit Shift">
                        <span
                          className="add-minus"
                          onClick={() => setEdit(true)}
                        >
                          ✏
                        </span>
                      </Tooltip>
                    ) : null}
                    {i !== 0 &&
                      (userPermissions.includes(
                        "Delete work schedules for all users"
                      ) ||
                        userPermissions.includes(
                          "Delete work schedules for users assigned to or added by self"
                        ) ? (
                        <Tooltip position="top" title="Remove Shift">
                          <span
                            className="add-minus"
                            onClick={() => removeRow(val.id)}
                          >
                            -
                          </span>
                        </Tooltip>
                      ) : null)}
                  </>
                )}
              </div>
            </td>
          </tr>
          {workSchedule.id.includes("new") && (
            <tr>

              <td colSpan={5} className="add-minus-row">
                {userPermissions.includes("Add work schedules for all users") ||
                  (userPermissions.includes("Add own work schedules") &&
                    userInfo.id === data.user_id) ||
                  userPermissions.includes(
                    "Add work schedules for users assigned to or added by self"
                  ) ? (
                  <Tooltip position="top" title="Save Work Schedule">
                    <div onClick={() => createNewWorkSchedule(shiftData)}>
                      <div>
                        <text>No work schedule planned yet. To add edit the above details and click on the icon below. </text>
                        <span className="add-minus">✔</span>
                      </div>
                    </div>
                  </Tooltip>
                ) : null}

              </td>
            </tr>
            // <tr>
            //   <td colSpan={5}>
            //     <Tooltip position="top" title="Save Work Schedule">
            //       <div>
            //         <span
            //           className="add-minus"
            //           onClick={() => setShowRow((prev) => !prev)}
            //         >
            //           +
            //         </span>
            //       </div>
            //     </Tooltip>
            //   </td>
            // </tr>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const addNewRow = () => {
    // old code

    // let newEmp = {
    //   ...workSchedule,
    //   shifts: [
    //     ...workSchedule.shifts,
    //     {
    //       id: workSchedule.shifts.length + 1 + " new",
    //       day_start: "1",
    //       start_time: "10:00:00",
    //       day_end: "1",
    //       end_time: "23:00:00",
    //       minimum_shift_hours: "10:00:00",
    //       work_schedule: workSchedule.id,
    //     },
    //   ],
    // };

    // setWorkSchedule(newEmp);

    //new logic old code modifications here
    const existingDayStarts = new Set(
      workSchedule.shifts.map((shift) => parseInt(shift.day_start))
    );
    let nextDayStart = 1;

    while (existingDayStarts.has(nextDayStart)) {
      nextDayStart++;
    }

    const newShift = {
      id: `${nextDayStart} new`,
      day_start: nextDayStart.toString(),
      start_time: "10:00:00",
      day_end: nextDayStart.toString(),
      end_time: "23:00:00",
      minimum_shift_hours: "10:00:00",
      work_schedule: workSchedule.id,
    };

    const newEmp = {
      ...workSchedule,
      shifts: [...workSchedule.shifts, newShift],
    };

    workSchedule.shifts.push(newShift);
    setWorkSchedule(newEmp);
  };
  console.log("workScheduleworkSchedule", workSchedule?.shifts.length);
  const removeRow = (ID) => {
    if (ID.includes("new")) {
      let newEmp = {
        ...workSchedule,
        shifts: workSchedule.shifts.filter((val) => val.id !== ID),
      };

      setWorkSchedule(newEmp);
    } else {
      dispatch(deleteShift(ID, workSchedule, setWorkSchedule));
    }
  };

  return (
    <>
      {workSchedule?.shifts?.map((val, i) => (
        <RowValues val={val} i={i} />
      ))}

      {!collapsed && !workSchedule.id.includes("new") && (
        <tr>
          <td colSpan={5} className="add-minus-row">
            {workSchedule.shifts.length < 5 &&
              (userPermissions.includes("Add work schedules for all users") ||
                (userPermissions.includes("Add own work schedules") &&
                  (userInfo.id === data.user_id)) ||
                userPermissions.includes(
                  "Add work schedules for users assigned to or added by self"
                ) ? (
                <Tooltip position="top" title="Add New Shift">
                  <div
                    onClick={() =>
                      workSchedule.id.includes("new")
                        ? createNewWorkSchedule(workSchedule)
                        : addNewRow()
                    }
                  >
                    {workSchedule.id.includes("new") ? (
                      <span className="add-minus">✔</span>
                    ) : (
                      <span className="add-minus">+</span>
                    )}
                  </div>
                </Tooltip>
              ) : null)}
          </td>
        </tr>
      )}
    </>
  );
};

// const CustomRow = ({ hookProps }) => {
//   const {
//     shiftData,
//     i,
//     collapsed,
//     workSchedule,
//     setCollapsed,
//     handleChange,
//     edit,
//     createNewWorkSchedule,
//     SaveShift,
//     setEditFalse,
//     removeNewWorkSchedule,
//     val,
//     removeRow,
//     setEdit,
//   } = hookProps;
//   return (
//     <>
//       <tr key={shiftData.id}>
//         {i === 0 && (
//           <td
//             rowSpan={
//               !collapsed
//                 ? workSchedule.shifts.length + 1
//                 : workSchedule.id.includes("new")
//                 ? 2
//                 : 1
//             }
//           >
//             <div className="name-container">
//               {/* <p
//                   className="sort"
//                   onClick={() => setCollapsed((prev) => !prev)}
//                 >
//                   src="/images/icons/arrow.png"
//                 </p> */}
//               <img
//                 className="sort"
//                 onClick={() => setCollapsed((prev) => !prev)}
//                 src="/images/icons/arrow.png"
//                 style={{ transform: !collapsed ? "rotate(90deg)" : "" }}
//                 alt="arrow"
//               />
//               {workSchedule?.user?.profile_image ? (
//                 <img
//                   src={workSchedule?.user?.profile_image}
//                   // alt="profile"
//                   width="50"
//                 />
//               ) : // <Avatar name={workSchedule?.user?.name} />
//               null}

//               {workSchedule?.user?.name}
//             </div>
//           </td>
//         )}

//         <td>
//           <SelectComp
//             value={shiftData.day_start}
//             name="day_start"
//             onChange={(e) => handleChange(e)}
//             disabled={!edit}
//           />
//         </td>
//         <td>
//           <InputComp
//             name="start_time"
//             placeholder="0 hrs"
//             value={shiftData.start_time}
//             onChange={(e) => handleChange(e)}
//             disabled={!edit}
//             style={{
//               border: shiftData.start_time.length < 1 ? "3px solid red" : null,
//             }}
//           />
//         </td>
//         <td>
//           <SelectComp
//             value={shiftData.day_end}
//             name="day_end"
//             onChange={(e) => handleChange(e)}
//             disabled={!edit}
//           />
//         </td>
//         <td>
//           <InputComp
//             name="end_time"
//             placeholder="0 hrs"
//             value={shiftData.end_time}
//             onChange={(e) => handleChange(e)}
//             disabled={!edit}
//             style={{
//               border: shiftData.end_time.length < 1 ? "3px solid red" : null,
//             }}
//           />
//         </td>
//         <td>
//           <InputComp
//             name="minimum_shift_hours"
//             placeholder="0 hrs"
//             value={shiftData.minimum_shift_hours}
//             onChange={(e) => handleChange(e)}
//             disabled={!edit}
//             style={{
//               border:
//                 shiftData.minimum_shift_hours.length < 1
//                   ? "3px solid red"
//                   : null,
//             }}
//           />
//         </td>
//         <td>
//           <div style={{ display: "flex" }}>
//             {edit ? (
//               <>
//                 <Tooltip
//                   position="top"
//                   title={
//                     workSchedule.id.includes("new")
//                       ? "Save Work Schedule"
//                       : "Save"
//                   }
//                 >
//                   <span
//                     className="add-minus"
//                     onClick={() =>
//                       workSchedule.id.includes("new")
//                         ? createNewWorkSchedule(shiftData)
//                         : SaveShift(shiftData, setEditFalse)
//                     }
//                   >
//                     ✔
//                   </span>
//                 </Tooltip>
//                 <Tooltip position="top" title="Cancle">
//                   <span
//                     className="add-minus"
//                     onClick={() =>
//                       workSchedule.id.includes("new")
//                         ? removeNewWorkSchedule(workSchedule)
//                         : val.id.includes("new")
//                         ? removeRow(val.id)
//                         : setEdit(false)
//                     }
//                   >
//                     ❌
//                   </span>
//                 </Tooltip>
//               </>
//             ) : (
//               <>
//                 <Tooltip position="top" title="Edit Shift">
//                   <span className="add-minus" onClick={() => setEdit(true)}>
//                     ✏
//                   </span>
//                 </Tooltip>
//                 {i !== 0 && (
//                   <Tooltip position="top" title="Remove Shift">
//                     <span
//                       className="add-minus"
//                       onClick={() => removeRow(val.id)}
//                     >
//                       -
//                     </span>
//                   </Tooltip>
//                 )}
//               </>
//             )}
//           </div>
//         </td>
//       </tr>
//     </>
//   );
// };

export const TableRows = ({ data }) => {
  return (
    <>
      {data?.map((value) => (
        <TableRowSingle key={value.id} data={value} />
      ))}
    </>
  );
};
