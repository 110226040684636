import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ImpersonationApi } from "../../redux/Actions/authAction";
import { useDispatch } from "react-redux";

const Impersonation = () => {
  const [impersonated, setImpersonated] = useState(false); // State to track impersonation

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (!impersonated) {
      dispatch(ImpersonationApi(token, navigate));
      setImpersonated(true); // Set impersonation as done
    }
  }, [dispatch, navigate, token, impersonated]);

  return null; 
}
export default Impersonation;
