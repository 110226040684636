import { Axios_v2 } from "../../Utils/axios";
import { toast } from "react-toastify";
import { HeaderToken } from "../../Utils/headerToken";
import { useSelector } from "react-redux";
import { autoLogoutOnTokenExpire } from "../../Utils/updateControler";
import { logOut } from "./authAction";

export const ALL_PLAN_LIST = "ALL_PLAN_LIST";
export const feature_list="feature_list";
export const USER_LIMIT = "USER_LIMIT";
export const active_subscription_list="active_subscription_list";

export const getAllPlanList = (env) => {
  let url = 'https://admin.dev.clocksession.com/api/subscriptionplanforpublic/';

  if (env && env['x-api-environment']) {
    console.log("Environment", env['x-api-environment']);
    if (env['x-api-environment'] === "dev") {
      url = 'https://admin.dev.clocksession.com/api/subscriptionplanforpublic/';
    } else if (env['x-api-environment'] === "stage") {
      url = 'https://admin.stage.clocksession.com/api/subscriptionplanforpublic/';
    }else if (env['x-api-environment'] === "production") {
      url = 'https://admin.clocksession.com/api/subscriptionplanforpublic/';
    }
  } else {
    console.log("No 'x-api-environment' found in env");
  }

  return (dispatch) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // You might need to include authentication headers if required
        // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN'
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const xApiEnvironmentHeader = response.headers.get('x-api-environment');
      console.log('x-api-environment:', xApiEnvironmentHeader);
      return response.json();
    })
    .then((data) => {
      console.log('API response:', data);
      console.log('DATA.RESULT', data.results);
      dispatch({ type: ALL_PLAN_LIST, payload: data.results });
      // Your additional logic here...
    })
    .catch((error) => {
      console.error('Error fetching data:', error.message);
      // Handle errors, for example dispatch an error action or log the error
      // dispatch(autoLogoutOnTokenExpire(err, dispatch));
    });
  };
};

export const stripe_checkout = async (data, env) => {
  try {
    console.log("stripe_data", data);

    let url = 'https://admin.dev.clocksession.com/api/payments/checkout/perform_checkout/';

    if (env && env['x-api-environment']) {
      console.log("Environment", env['x-api-environment']);
      if (env['x-api-environment'] === "dev") {
        url = 'https://admin.dev.clocksession.com/api/payments/checkout/perform_checkout/';
      } else if (env['x-api-environment'] === "stage") {
        url = 'https://admin.stage.clocksession.com/api/payments/checkout/perform_checkout/';
      } else if (env['x-api-environment'] === "production") {
        url = 'https://admin.clocksession.com/api/payments/checkout/perform_checkout/';
      }
    } else {
      console.log("No 'x-api-environment' found in env");
    }

    // Make the POST request
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if needed
      },
      body: JSON.stringify(data),
    });

    // Check if the request was successful (status code 2xx)
    if (response.ok) {
      const responseData = await response.json();
      const redirectUrl = responseData.redirect_url;

      // Navigate to the provided redirect_url
      window.location.href = redirectUrl;
    } else {
      // Handle the error response if needed
      console.error('Error:', response.status, response.statusText);
    }
  } catch (error) {
    // Handle any unexpected errors
    console.error('Error:', error.message);
  }
};

export const get_active_plan_features = (organisation_name) => {
  return async (dispatch) => {
    Axios_v2.get(
      `active-subscription/feature_list/?organisation_name=${organisation_name}`,
      HeaderToken()
    )
      .then((res) => {
        console.log("current_plan_response", res.data);
        if (res.status === 200) {
          const featureArray = res.data.map(item => item.name);
          console.log("fa",featureArray);
          if (featureArray.length === 0) {
            toast.warn("No active plan is available");
            localStorage.removeItem("activeSubscriptions");
            dispatch(logOut());
          } else {
            dispatch({ type: feature_list, payload: featureArray });
          }
        } 
      })
      .catch((err) => {
        console.log("error_for_features", err);
        // dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};
export const get_active_plan = (organisation_name) => {
  return async (dispatch) => {
    Axios_v2.get(
      `active-subscription/active_subscriptionplan_for_organisation/?organisation_name=${organisation_name}&ordering=-start_date`,
      HeaderToken()
    )
      .then((res) => {
        console.log("current_plan_response", res.data);
        if (res.status === 200) {
          const ActivesubsArray = res.data.results.active_subscriptions;
          if (ActivesubsArray.length > 0) {
            dispatch({ type: active_subscription_list, payload: ActivesubsArray });
            
          } else {
            localStorage.removeItem("activeSubscriptions");
            dispatch(logOut());
          }
        } 
      })
      .catch((err) => {
        console.log("error_for_features", err);
        // dispatch(autoLogoutOnTokenExpire(err, dispatch));
        toast.error(err);
      });
  };
};
export const userLimit_function = (value) => {
  return async  (dispatch) => {
    console.log("userLimit_function", value);
    dispatch({ type: USER_LIMIT, payload: value });
  };
};