import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/Actions/authAction";
import { emailValidator } from "../../Utils/fieldValidator";
import PrivacyTermComp from "./PrivacyTermComp";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [useremail, setUserEmail] = useState("");
  const [mailError, setmailError] = useState(true);
  const [showerror, setshowError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = () => {
    if (!useremail.length > 0) {
      setshowError(true);
    }

    if (useremail.length > 0 && mailError) {
      dispatch(forgotPassword(useremail, setIsProcessing));
      // To Do next implementation
    }
  };

  const emailHandler = (value) => {
    setmailError(emailValidator(value));
    setUserEmail(value);
  };

  return (
    <div style={{display: "flex", height: "100%"}}>
      <div className="left-login-container" style={{position: "relative"}}>

      {/* <div >

        <div style={{textAlign: "center", position: "relative", top: "80px", left: "16px"}}>
          <img src="/images/login_img_1.png" alt="" style={{width: "550px"}} />
        </div>

        <div style={{textAlign: "center"}}>
          <img src="/images/login_img_2.png" alt="" style={{width: "548px"}}/>
        </div>
      </div> */}

      <div style={{textAlign: "center"}}>
          <img src="/images/reset-forget-bg.png" alt="" style={{height: "100vh", width: "100%"}}/>
      </div>


      <div style={{position: "absolute", width: "100%", bottom: "3%"}}>
        <p className="company-footer">
          ©{new Date().getFullYear()} All Rights Reserved by Mechlin
          Technologies
        </p>
      </div>

    </div>

    <div className="auth-screen-form-card">

      <div className="">
        <img src="/images/clocksession-logo-new.png" alt="clocksession-logo" />
      </div>

      <div className="clock-login-header">
        <div>Forgot Password</div>
      </div>

      
      <p style={{fontSize: "15px", marginTop: "4px"}}>
        Enter the email address you used when you joined and we'll send you
        instructions to reset your password.
      </p>
      <div className="auth-input" style={{marginTop: "15px"}}>
        <label>Email</label>
        <input
          autoComplete="off"
          type="email"
          name="email"
          placeholder="Enter your email address"
          value={useremail}
          onChange={(e) => emailHandler(e.target.value)}
        />
        {mailError ? null : <p>Please enter a valid email.</p>}
        {showerror ? !useremail ? <p>Email is required</p> : null : null}
      </div>
      <button
        className="auth-btn"
        onClick={handleSubmit}
        disabled={isProcessing}
      >
        {isProcessing ? "Processing ..." : "Submit"}
      </button>


      <PrivacyTermComp/>
    </div>

    </div>
  );
}
