import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { stripe_checkout } from "../redux/Actions/subscription";
import { stripe_details } from "../redux/Actions/authAction";
import { useLocation } from "react-router-dom";
 
export default function useSubscription() {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState();
  const [plan_id, setPlanID] = useState();
  const [price_id, setPriceID] = useState();
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location
  const { env, userInfo } = useSelector((store) => store.auth);

  const allPossibleFeaturesList = ["Screenshot", "Payroll", "Attendence", "Leave Module", "Project & Task Report", "WebCam", "Employee Settings", "Work schedule", "Time Line LOGS" ] 
 
  console.log("Current_route:", location.pathname);
 
  const [currentPlan_Tab, setCurrentPlan_Tab] = useState(true); // Define popupPosition
 
  const openGatewayModal = (plan) => {
    setShowModal(true);
    setSelectedPlan(plan);
    console.log("Selected Plan:", plan);
  };
 
  const useOutsideCloseModal = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          closeInfoPopup();
        }
      };
 
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
 
  const closeModal = () => {
    setShowModal(false);
  };
 
  const openInfoPopup = (plan, position) => {
    setSelectedPlan(plan);
    setPopupPosition(position);
    setIsPopupVisible(true);
    // toast.success("hello");
  };
 
  const closeInfoPopup = () => {
    setIsPopupVisible(false);
  };
 
  const openInfoModal = (plan) => {
    setSelectedPlan(plan);
    setInfoModalOpen(true);
  };
 
  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };
 
  const setStripe_info = (planid, priceid, env) => {
    console.log("stripe_session_stared");
 
    // Extracting the subdomain from user email
    const regex = /(?:@)([^@]+)(?:\..+$)/;
    const match = userInfo.email.match(regex);
    const subdomain = match && match.length > 1 ? match[1] : null;
 
    // Check if the domain has at least two parts (e.g., "jockey" and "com")
    let domainName = subdomain;
 
    console.log('Domain Name:', domainName);
 
    let baseUrl = `https://${domainName}.dev.clocksession.com`;
 
    if (env && env['x-api-environment']) {
      if (env['x-api-environment'] === "dev") {
        console.log("dev env encoded")
        baseUrl = `https://${domainName}.dev.clocksession.com`;
      } else if (env['x-api-environment'] === "stage") {
        baseUrl = `https://${domainName}.stage.clocksession.com`;
      } else if (env['x-api-environment'] === "production") {
        baseUrl = `https://${domainName}.clocksession.com`;
      } else {
        console.log("No 'x-api-environment' found in env");
      }
    }
 
    const success_url = `${baseUrl}/app/success_payment`;
    const cancel_url = `${baseUrl}/app/failed_payment`;
 
    const stripeInfo = {
      customer_email: userInfo.email,
      organisation_name: userInfo.organisation.name,
      subdomain: subdomain+".dev",
      subscription: planid,
      price: priceid,
      success_url: success_url,
      cancel_url: cancel_url,
    };
    dispatch(stripe_checkout(stripeInfo, env));
 
    // Log the object with the values
    console.log("Stripe Info:", stripeInfo);
 
    // Set state values
    setPlanID(planid);
    setPriceID(priceid);
  };
 
  const priceColors = [
    "#36B37E",
    "#F8CD0A",
    "#B6446D",
    "#3F8A9A",
    "#563E9A",
    "#007EC5",
  ];
 
  return {
    showModal,
    setShowModal,
    selectedPlan,
    setSelectedPlan,
    infoModalOpen,
    setInfoModalOpen,
    isPopupVisible,
    setIsPopupVisible,
    popupPosition,
    openGatewayModal,
    openInfoPopup,
    closeInfoPopup,
    openInfoModal,
    closeInfoModal,
    closeModal,
    useOutsideCloseModal,
    priceColors,
    setCurrentPlan_Tab,
    currentPlan_Tab,
    setPriceID,
    setPlanID,
    setStripe_info,
    allPossibleFeaturesList
  };
}