import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import useLeaveManagement from "../../hooks/useLeaveManagement";
import { useSelector } from "react-redux";
import Header from "../../components/common/Header";


export default function Leave() {
  const { userInfo } = useLeaveManagement();
  const { userPermissions } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const isHR = userInfo.groups.some( (item) => item.name === "HR");
  const isManager = userInfo.groups.some( (item) => item.name === "Manager");
  const L1Manager = userInfo.is_superuser;

  const array = [
    {
      title: "Leave",
      link: "/Leave",
      permission: (userPermissions.includes("Can view leaves requested by all users")||
      userPermissions.includes("View leaves requested by assigned users")||
      userPermissions.includes("View own leaves")
     )
      
    },
    {
      title: isHR ? "Employee Leave": "Approve Leave Request",
      link: "/Leave/employee-leave",
      permission: (userPermissions.includes("Can view leaves requested by all users")||
      userPermissions.includes("View leaves requested by assigned users"))
    },
   
   
  ];
  
  const resourcePath = array.filter(item => item.permission);
  useEffect(() => {
    // Check if the current path is accessible; if not, find the first accessible path
    const currentTab = resourcePath.find((path) => pathname === path.link);
    if (!currentTab || !currentTab.permission) {
      const defaultTab = resourcePath.find((path) => path.permission);
      if (defaultTab) {
        navigate(defaultTab.link);
      }
    }
  }, [pathname, navigate, resourcePath]);
 
  
  


 
  
  
  return (
    <div>
       <div className="dashboard-header">
    <Header title='Leave' style='font-weight: bold;' />
  </div>
    <div className="wer">
        <div className="leave-tab-container">
          {resourcePath.map(path => (
            <div
              className={`tab-button ${pathname === path.link && "active-tab"}`}
              key={path.title}
              onClick={() => navigate(path.link)}
            >
              <p>{path.title}</p>
            </div>
          ))}
        </div>
     

        {pathname === "/Leave/public-holiday" ? (
        <div className="resource-context-container">
         <Outlet />
        </div>
      ) : (
        <div className="leaves-context-container">
          <Outlet />
        </div>
      )}
      </div>
    </div>
  );
}
