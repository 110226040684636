import { React, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../Modal";
import "./style.css";
import SearchableDropdown from "../SearchableDropdown";




const AddEmployee = ({ open, onClose, hookProps }) => {
  const { userGroups } = useSelector((store) => store.auth);
  const { userInfo } = useSelector((store) => store.auth);

  
  
  const [enableWebcam, setEnableWebcam] = useState(false);
  const { leaveTypeList } = useSelector((store) => store.leave);


  const {
    handleChange,
    handleSubmit,
    employeeValues,
    cancleEmployeeAdd,
    employeeErrors,
    employeeID,
    handleManagerIds,
    employeesList,
    selectedOptions,
    setSelectedOptions,
    selectedGroups,
    setSelectedGroups,
    handleGroup, 
  } = hookProps || {};

  const managers = employeesList;  
  console.log("employee_list",employeesList);
  console.log("manager_list",managers);
  console.log("employeevalue",employeeValues);
  const leaveshow = userInfo.groups.some(
    (item) => item.name === "Accountant" || item.name === "HR" || item.name === "Admin"
);

 console.log(leaveshow,"leaveshow");


  const handleCheckchange = () => {
    setEnableWebcam((pre) => !pre);
    handleChange({ target: { name: "webcam", value: !enableWebcam } });
  };

  

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="user-container">
          <div className="header-top" >
            <h4>{employeeID ? "Edit Existing User" : "Add Existing User"}</h4>
            <img src="/images/icons/close.svg" alt="Close" onClick={onClose} />
            
          </div>
          <hr className="underline" />
          <div style={{display:'flex'}}>
          <div >
            <label for="u-name" >
              User Name:
            </label>
            <div className="col-sm-9">
              <input
                style={{width:'230px', marginRight:'10px',borderRadius:'7px',border:'2px solid lightgrey'}}
                type="text"
                id="u-name"
                className="form-control"
                name="name"
                placeholder="Enter Name"
                value={employeeValues?.name}
                onChange={handleChange}
              />
              {employeeErrors?.name && (
                <p className="validation-error">{employeeErrors?.name}</p>
              )}
            </div>
          </div>
          <div >
            <label for="u-email">
              Email ID:
            </label>
            <div >
              <input
                 style={{width:'230px',borderRadius:'7px',border:'2px solid lightgrey'}}
                type="email"
                id="u-email"
                name="email"
                disabled={employeeID ? true : false}
                placeholder="Enter Email"
                className="form-control"
                value={employeeValues?.email}
                onChange={handleChange}
              />
             </div>
              {employeeErrors?.email && (
                <p className="validation-error">{employeeErrors?.email}</p>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <label>
               Access:
            </label>
            <div style={{width:'700px'}}>
              <SearchableDropdown 
              
                options={userGroups}
                selectedOptions={selectedGroups}
                setSelectedOptions={setSelectedGroups}
                handleManagerIds={handleGroup}
              />

              {employeeErrors?.group && (
                <p className="validation-error">{employeeErrors?.group}</p>
              )}
            </div>
          </div>
        
          

        
          <div >
            <label for="u-email" >
              Managers :
            </label>
            <div className="col-sm-9">
              <SearchableDropdown
                options={managers}
                // selectedOptions={
                //   employeeID ? employeeDetails.managers : selectedOptions
                // }
                selectedOptions={selectedOptions}
                handleChange={handleChange}
                setSelectedOptions={setSelectedOptions}
                handleManagerIds={handleManagerIds}
              />

              {employeeErrors?.email && (
                <p className="validation-error">{employeeErrors?.email}</p>
              )}
            </div>
            
          </div>




 <div style={{display:'flex',marginBottom:'10px'}}>
          <div >
            <label for="u-screenshots" >
              Screenshots interval:
            </label> 
                       
            <div >
              <input
              style={{width:'250px',border:"2px solid lightgrey",borderRadius:'7px'}}
                type="number"
                id="u-screenshots"
                className="form-control"
                name="screenshots"
                placeholder="Enter Screenshot interval"
                value={employeeValues?.screenshots}
                onChange={handleChange}
                min="1"
                max="60"
              />
              {employeeErrors?.screenshots && (
                <p className="validation-error">{employeeErrors?.screenshots}</p>
              )}
            </div>
          </div>
        

          <div>
            <div style={{marginLeft:"30px",marginTop:'30px'}} >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="webcam"
                  id="enable-webcam"
                  value={enableWebcam}
                  checked={employeeValues?.webcam}
                  onChange={handleCheckchange}
                />
                <label>
                  Enable Webcam
                </label>
              </div>
            </div>
          </div>
</div>
{leaveshow && (
  <div style={{display:'flex',marginBottom:'10px'}}>
    <div>
      <label htmlFor="u-screenshots">
        Monthly Leave Allotted
      </label>                        
      <div>
        <p style={{ width: '250px', border: "2px solid lightgrey", borderRadius: '7px', padding: '10px' }}>
          {`${employeeValues?.monthly_allotted_leaves ?? 'NA'} ${employeeValues?.category_name ? `(${employeeValues?.category_name})` : ''}`}
        </p>
      </div>
    </div>
  </div>
)}
          <div >
            <div >
              <div className="modal-btn">
                <button
                  type="button"
                  className="btn btn-primary-t"
                  onClick={handleSubmit}
                  style={{marginRight:"10px"}}
                >
                  Send Request
                </button>
                <span> </span>
                <button
                  type="button"
                  className="btn-cancel btn-secondary"
                  onClick={cancleEmployeeAdd}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddEmployee;
