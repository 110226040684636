import React from "react";
import "./workschedule.css";
import { TableRows } from "../../components/ResourceManagement/WorkSchedule";
import SelectSingleEmployee from "../../components/SelectSingleEmployee";
import useWorkSchedule from "../../hooks/useWorkSchedule";
import Pagination from "../../components/common/Pagination/pagination";
import {useSelector} from "react-redux"
export default function WorkSchedules() {
  const {
    pageCounts,
    currentPage,
    setCurrentPage,
    workSchedules,
    inProgress,
    setUsers,
    selectEmployeeId
  } = useWorkSchedule();
  const { userPermissions } = useSelector((store) => store.auth);
  const pages = [];

  for (let i = 1; i <= pageCounts; i++) {
    pages.push(i);
  }

  const renderPageNumbers = pages.map((number) => {
    return (
      <button
        onClick={() => setCurrentPage(Number(number))}
        className={currentPage === number ? "active-page" : null}
      >
        {number}
      </button>
    );
  });

  return (
    <div>
      <div className="workschedules-container">
        <div class="container">
          <div class="row my-3">
            <div class="col-sm-4 text-start">
              {userPermissions.includes("Can view all users in the organization")||
              userPermissions.includes("Can view users added by or assigned to self")
              ?(
                <SelectSingleEmployee
                  onSelectEmployee={(ID) => selectEmployeeId(ID)}
                  disabled={
                    workSchedules.filter((schedule) =>
                      schedule.id.includes("new")
                    ).length > 0
                  }
                />
              ):null}
              {/* <select
                aria-label="Default select example"
                onChange={(e) => createNewWorkSchedule(e.target.value)}
                disabled={
                  workSchedules.filter((schedule) =>
                    schedule.id.includes("new")
                  ).length > 0
                }
              >
                <option selected>Select User</option>
                {employeesList.map((employee) => (
                  <option value={employee.id}>{employee.name}</option>
                ))}
              </select> */}
            </div>
            {/* <div class="col-sm-6 text-end">
              <button className="btn btn-primary btn-2" onClick={() => setUsers([])}>Remove Filter</button>
            </div> */}
          </div>
          <div className="table-responsive">
            <table class="table bg-white text-start">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Day Start</td>
                  <td>Shift Start</td>
                  <td>Day End</td>
                  <td>Shift End</td>
                  <td>Minimum Shift</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
              {inProgress &&
            <tr>
                <td colSpan="8">
                  <h4 style={{textAlign: "center", fontSize: "16px"}}>Loading Data...</h4>
                </td>
              </tr>
          }
                {!inProgress && workSchedules.length === 0 ? (
                  <h4>
                     data not found
                  </h4>
                ) : (
                  <TableRows data={workSchedules} />
                )}
              </tbody>
            </table>
          </div>

          {
      pageCounts>1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )
    }


          {/* <div className="projects-table-footer">
            <div className="table-pagination">
              <button
                className="prev-scr"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === pages[0] ? true : false}
              >
                {"PREV"}
              </button>
              {renderPageNumbers}
              <button
                className="next-scr"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={
                  currentPage === pages[pages.length - 1] ? true : false
                }
              >
                {"NEXT"}
              </button>
            </div>
          </div> */}


        </div>
      </div>
    </div>
  );
}
