import React, { useEffect } from "react";
import LayoutWithNav from "./LayoutWithNav";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveType } from "../redux/Actions/leaveAction";
import {
  getAllGroups,
  getPermissions,
  stripe_details,
  getSubordinates,
} from "../redux/Actions/authAction";
import AuthenticationLayout from "../pages/Authentication/AuthenticationLayout";
import ResetPassword from "../pages/Authentication/ResetPassword";
import DashBoard from "../pages/DashBoard";
import WorkDiary from "../pages/WorkDiary";
import ResourceManagement from "../pages/ResourceManagement";
import Reports from "../pages/Reports";
import Feedback from "../pages/Feedback";
import Payroll from "../pages/Payroll";
import DownloadApplication from "../pages/DownloadApplication";
import Profile from "../pages/Profile";
import Subscription from "../pages/Subscription";
import Leave from "../pages/Leave";
import Settings from "../pages/Settings";
import NotFound from "../pages/404";
import ProjectAndTasks from "../pages/ResourceManagement/ProjectAndTasks";
import EmployeeSettings from "../pages/ResourceManagement/EmployeeSettings";
import WorkSchedules from "../pages/ResourceManagement/WorkSchedules";
import Work from "../pages/WorkDiary/Screenshots";
// import ReviewManual from "../pages/WorkDiary/ReviewManual";
import Attendance from "../components/Reports/Attendance";
import TimelineLogs from "../components/Reports/TimelineLogs";
import ProjectAndTasksReport from "../components/Reports/ProjectAndTasksReport";
import ActivePlan from "../components/Subscription/ActivePlan";
import DownLoadPayslip from "../components/Payroll/Download/DownLoadPayslip";
import DownLoadForm from "../components/Payroll/Download/DownLoadForm";
import UploadForm from "../components/Payroll/Uploads/UploadForm";
import UploadPayslip from "../components/Payroll/Uploads/UploadPayslip";
import LeaveType from "../components/LeavesManagement/LeaveType";
import LeaveStatus from "../components/LeavesManagement/LeaveStatus";
import PublicHoliday from "../components/LeavesManagement/PublicHoliday";
import EmployeeLeave from "../components/LeavesManagement/EmployeeLeave";
import LeaveAllotment from "../components/LeavesManagement/LeaveAllotment";
import RoleSettings from "../pages/Settings/RoleSettings";
import Impersonation from "../pages/Authentication/Impersonation";
import CurrentPlan from "../components/Subscription/Plan";
import AvailablePlan from "../components/Subscription/Plan/AvailablePlan";
import DownloadApplication1 from "../pages/DownloadApplication/table";
import PaymentSuccess from "../components/Paymetn_redirect/success";
import PaymentFailure from "../components/Paymetn_redirect/failure";
import { get_active_plan_features } from "../redux/Actions/subscription";
import { get_active_plan } from "../redux/Actions/subscription";
import FormComponent from "../pages/ResourceManagement/AddNewuser";
import ORGANIZATION_SETTING from "../pages/Settings/organiztionalSetting";
import LeaveReassignment from "../components/Settings/LeaveReaasignment";
import { PlanExpoiredScreen } from "../components/Subscription/Plan/PlanExpiration";
import AvilableSubscription from "../components/Subscription/Plan/AvailableSubscription";

import Userlimit from "../components/LeavesManagement/userLimit";

export default function AuthenticatedRoutes() {
  const { userInfo } = useSelector((store) => store.auth);
  const { userPermissions } = useSelector((store) => store.auth);
  const { env } = useSelector((store) => store.auth);
  const { feature_list } = useSelector((store) => store.subPlan);
  const { active_subscription_list } = useSelector((store) => store.subPlan);

  console.log("harshenv",env);
  console.log("active",active_subscription_list);


  // const isAccountant = userInfo.groups.some(
  //   (item) => item.name === "Accountant"
  // );

  // const isAdmin = userInfo.groups.some(
  //   (item) => item.name === "Accountant"
  // );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllGroups());
    getLeaveType();
    dispatch(getPermissions());
    dispatch(getSubordinates(userInfo.id));
    dispatch(get_active_plan_features(userInfo.organisation.name));
  
  

  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<LayoutWithNav component={<DashBoard />} />} />
      <Route
        path="/WorkDiary"
        element={<LayoutWithNav component={<WorkDiary />} />}
      >
        {(userPermissions.includes("View own users' screenshots/webcam") ||
        userPermissions.includes("View all users' screenshots/webcam"))&&feature_list.includes("Screenshot") ?(
          <Route index element={<Work />} />
        ) : null}
        {/* <Route path="reviewmanual" element={<ReviewManual />} /> */}
      </Route>
      <Route path="/ResourceManagement/employeesettings/add-new-employee" element={<LayoutWithNav component={<FormComponent />} />} />

      <Route
        path="/ResourceManagement"
        element={<LayoutWithNav component={<ResourceManagement />} />}
      >

        {(userPermissions.includes("Can view project") ||
        userPermissions.includes("Can view all projects"))&&feature_list.includes("Project & Task Report") ? (
          <Route index element={<ProjectAndTasks />} />
        ) : null}

        {(userPermissions.includes("Can view project") ||
        userPermissions.includes("Can view all projects"))&&feature_list.includes("Project & Task Report") ? (
          <Route index element={<ProjectAndTasks />} />
        ) : null}

        
        <Route path="employeesettings" element={<EmployeeSettings />} />

        {userPermissions.includes("Can view work schedule") && feature_list.includes("Work schedule") ? (
          <Route path="workschedules" element={<WorkSchedules />} />
        ) : null}
      </Route>

      <Route
        path="/Reports"
        element={<LayoutWithNav component={<Reports />} />}
      >
        {(userPermissions.includes("View own users attendance reports") ||
        userPermissions.includes("View all attendance reports")) && feature_list.includes("Attendence") ? (
          <Route index element={<Attendance />} />
        ) : null}
        {feature_list.includes("Project & Task Report")?
        <Route
          path="project-and-task"
          element={<ProjectAndTasksReport />}
        ></Route>:null}
         <Route path="timelinelogs" element={<TimelineLogs />} /> 
      </Route>
      <Route
        path="/Payroll"
        element={
          <LayoutWithNav
            component={<Payroll/>}
          />
        }
      >
        <Route
          index
          element={
              userPermissions.includes("Can upload payslip") ? (
              <UploadPayslip />
            ) : userPermissions.includes("Can view payslip") ? (
              <DownLoadPayslip />
            ) : null
          } 
        />
        {userPermissions.includes("Can view payslip") ? (
          <Route path="DownLoadpayslip" element={<DownLoadPayslip />} />
        ) : null}

        {userPermissions.includes("Can upload Form 16") ? (
          <>
           
            <Route path="UploadForm" element={<UploadForm />} />
          </>
        ) : null}
        {userPermissions.includes("Can view Form 16") ? (
          <>
            <Route path="DownLoadForm" element={<DownLoadForm />} />
           
          </>
        ) : null}
      </Route>
      <Route
        path="/Leave"
        element={<LayoutWithNav component={<Leave />} />}
      >
        {userPermissions.includes("View own leaves") ||
        userPermissions.includes("Can view leaves requested by all users") ||
        userPermissions.includes("View leaves requested by assigned users") ? (
          <Route index element={<LeaveStatus />} />
        ) : null}

        {userPermissions.includes("Can view leaves requested by all users") ||
        userPermissions.includes("View leaves requested by assigned users") ? (
          <Route path="employee-leave" element={<EmployeeLeave />} />
        ) : null}

        {userPermissions.includes("Can view public holiday") ? (
          <Route path="public-holiday" element={<PublicHoliday />} />
        ) : null}

        <Route path="leave-type" element={<LeaveType />} />
        <Route path="leave-allotment" element={<LeaveAllotment />} />
      </Route>

      {userPermissions.includes("View custom feedback") && (
        <Route
          path="/Feedback"
          element={<LayoutWithNav component={<Feedback />} />}
        />
      )}
<Route
  path="/Settings"
  element={<LayoutWithNav component={<Settings />} />}
>
  <Route index element={<RoleSettings />} />

  <Route
    path="leave"
    element={<ORGANIZATION_SETTING />}
  >
    <Route  index element={<LeaveAllotment />} />
    <Route path="leave_type" element={<LeaveType />} />
    <Route path="holiday" element={<PublicHoliday />} />

  </Route>
  <Route path="limit" element={<Userlimit />} />

</Route>





 
   {userPermissions.includes("Can view tracker download links")&&(
     <Route
     path="/DownloadApplication"
     element={<LayoutWithNav component={<DownloadApplication />} />}
   />
   )}
      

      <Route
        path="/DownloadApplication/Previous-Tracker-Versions"
        element={<LayoutWithNav component={<DownloadApplication1 />} />}
      />
      <Route
        path="/ProfileSettings"
        element={<LayoutWithNav component={<Profile />} />}

      />

      <Route
        path="/ResetPassword/:token"
        element={
          <AuthenticationLayout component={<ResetPassword />} />
        }
      />
      <Route
        path="/impersonation/login/:token"
        element={
          <AuthenticationLayout component={<Impersonation />} />
        }
      />
   {(userPermissions.includes("View Subscriptions") || userPermissions.includes("View Subscription")) && (
  <Route
    path="/Subscription"
    element={<LayoutWithNav component={<Subscription />} />}
  />
  
)}

   {(userPermissions.includes("View Subscriptions") || userPermissions.includes("View Subscription")) && (
  <Route
    path="/Subscription/availablePlans"
    element={<LayoutWithNav component={<AvilableSubscription />} />}
  />
  
)}
  
  

     


    
     

      <Route
        path="*"
        element={<LayoutWithNav component={<NotFound />} />}
      />
       <Route
        path="/success_payment"
        element={<LayoutWithNav component={<PaymentSuccess />} />}
      />
       <Route
        path="/failed_payment"
        element={<LayoutWithNav component={<PaymentFailure />} />}
      />
    </Routes>
  );
}
