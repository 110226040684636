import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export const ATTENDENCE_FOR_ALL = "ATTENDENCE_FOR_ALL";
export const WORK_TIME = "WORK_TIME";
export const ALL_ATTENDENCE = "ALL_ATTENDENCE";
export const PROJECT_TASK_REPORT = "PROJECT_TASK_REPORT";

export const getAttendanceForAllUsers = (req, user, setInProgress) => {
  return async (dispatch) => {
    const searchUserString = user
      .map((id) => `&user_ids=${id}`)
      .toString()
      .replace(/,/g, "");

    setInProgress(true);
    await Axios_v2.post(
      `/reports/attendance_for_all/?activated=true${
        user.length > 0 ? searchUserString : ""
      }`,
      req,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          // dispatch({ type: ATTENDENCE_FOR_ALL, payload: res.data.results });
          setInProgress(false);
        }
      })
      .catch((err) => {
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
        setInProgress(false);
      });
  };
};

const convertAttendanceresToreport = (results) => {

  let users = [];
  let oldRepResponse = [];
results.sort((a, b) => {
    const dateA = new Date(a.date.replace(/-/g, '/'));
    const dateB = new Date(b.date.replace(/-/g, '/'));
    return dateA - dateB;
  });


  results?.forEach((res) => {
    if (!users.includes(res.user)) {
      users.push(res.user);
    }
  });

  if (users.length > 0) {
    users.forEach((user) => {
      let userAllData = results.filter((data) => data.user === user);

      let list_of_days = userAllData.map((data) => ({
        current_date: data.date,
        shift_start_time: data.shift_start,
        actual_start_time: data.actual_shift_start,
        actual_hours_worked_within_shift: data.hours_worked_within_shift,
        total_hours_worked: data.total_hours_worked,
        over_time: data.overtime,
        status: data.status,
        reason:data.reason,
        id:data.id

      }));

      let userObj = {
        leave_id:userAllData[0].id,
        id: userAllData[0].user_details.id,
        name: userAllData[0].user_details.name,
        email: userAllData[0].user_details.email,
        profile_image: userAllData[0].user_details.profile_image,
        attendance: {
          list_of_days: list_of_days,
        },
      };

      oldRepResponse.push(userObj);
    });
  }

  return oldRepResponse;
};

const convertProjectAndTaskToreport = (results) => {
  let users = [];

  let reportResponse = [];

  results?.forEach((res) => {
    if (!users.includes(res.user)) {
      users.push(res.user);
    }
  });

  if (users.length > 0) {
    users.forEach((user) => {
      let userAllData = results.filter((data) => data.user === user);

      let list_of_project = userAllData.map((data) => data.worklogs.projects);
      let list_of_data = list_of_project.map((data) => ({
        date: data.data.date,
        total_hours_worked: data.totaltimeinhours,
        project_name: data.name,
        task_name: data.task,
      }));
      let userObj = {
        id: userAllData[0].user_id,
        name: userAllData[0].user,
        report: {
          projects: list_of_data,
        },
      };
      reportResponse.push(userObj);
    });
  }
  return reportResponse;
};

// export const getAttendance = (req, user, setInProgress) => {
//   return async (dispatch) => {
//     const searchUserString = user
//       .map((id) => `&user=${id}`)
//       .toString()
//       .replace(/,/g, "");

//     let dateRangeString = `&date_after=${moment(req.start_date).format(
//       "YYYY-MM-DD"
//     )}&date_before=${moment(req.end_date).format("YYYY-MM-DD")}`;
//     let monthlydate = `&date_after=${moment(req.start_date).format(
//       "YYYY-MM-DD"
//     )}&date_before=${moment(req.start_date).format("YYYY-MM-DD")}`;
//     setInProgress(true);
//     await Axios_v2.get(
//       `/attendance/?items_per_page=1000&report_type=${
//         req.report_type === "dateRange" ? "date_range" : req.report_type
//       }${user.length > 0 ? searchUserString : ""}${
//         req.report_type === "dateRange" ? dateRangeString : ""
//       }${req.report_type === "daily" ? dateRangeString : ""}${
//         req.report_type === "weekly" ? dateRangeString : ""
//       }${req.report_type === "monthly" ? monthlydate : ""}&page=${1}`,
//       HeaderToken()
//     )
//       .then((res) => {
//         SiteUpdatehandler(res, dispatch);
//         if (res.status === 200) {
//           let result = res.data.results;
//           let oldRes = convertAttendanceresToreport(result);
//           dispatch({ type: ATTENDENCE_FOR_ALL, payload: oldRes });
//         }
//         setInProgress(false);
//       })
//       .catch((err) => {
//         // dispatch(autoLogoutOnTokenExpire(err, dispatch));
//         setInProgress(false);
//       });
//   };
// };
export const getAttendanceWithPagination = (
  req,
  user,
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  setTotalValues
) => {
   
  return async (dispatch) => {
    const searchUserString = user
      .map((id) => `&user=${id}`)
      .toString()
      .replace(/,/g, "");

    let dateRangeString = `&date_after=${moment(req.start_date).format(
      "YYYY-MM-DD"
    )}&date_before=${moment(req.end_date).format("YYYY-MM-DD")}`;
    let monthlydate = `&date_after=${moment(req.start_date).format(
      "YYYY-MM-DD"
    )}&date_before=${moment(req.start_date).format("YYYY-MM-DD")}`;
    setInProgress(true);
    

    await Axios_v2.get(
      `/attendance/?items_per_page=${itemsPerPage}&page=${currentPage}&report_type=${
        req.report_type === "dateRange" ? "date_range" : req.report_type
      }${user.length > 0 ? searchUserString : ""}${
        req.report_type === "dateRange" ? dateRangeString : ""
      }${req.report_type === "daily" ? dateRangeString : ""}${
        req.report_type === "weekly" ? dateRangeString : ""
      }${req.report_type === "monthly" ? monthlydate : ""}`,  
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          let result = res.data.results;

          let oldRes = convertAttendanceresToreport(result);
          
          dispatch({ type: ALL_ATTENDENCE, payload: oldRes });
        }
        setPageCounts(res.data.total_pages);
        setTotalValues(res.data.count);
        setInProgress(false);
      })
      .catch((err) => {
        // dispatch(autoLogoutOnTokenExpire(err, dispatch));
        setInProgress(false);
      });
  };
};

export const getAttendance = (
  req,
  user,
  setInProgress,
  setCurrentPage,
  itemsPerPage,
  setPageCounts,
  setTotalValues
) => {
   
  return async (dispatch) => {
    const searchUserString = user
      .map((id) => `&user=${id}`)
      .toString()
      .replace(/,/g, "");

    let dateRangeString = `&date_after=${moment(req.start_date).format(
      "YYYY-MM-DD"
    )}&date_before=${moment(req.end_date).format("YYYY-MM-DD")}`;
    let monthlydate = `&date_after=${moment(req.start_date).format(
      "YYYY-MM-DD"
    )}&date_before=${moment(req.start_date).format("YYYY-MM-DD")}`;
    setInProgress(true);
    

    await Axios_v2.get(
      `/attendance/?items_per_page=${itemsPerPage}&page=${1}&report_type=${
        req.report_type === "dateRange" ? "date_range" : req.report_type
      }${user.length > 0 ? searchUserString : ""}${
        req.report_type === "dateRange" ? dateRangeString : ""
      }${req.report_type === "daily" ? dateRangeString : ""}${
        req.report_type === "weekly" ? dateRangeString : ""
      }${req.report_type === "monthly" ? monthlydate : ""}`,  
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          let result = res.data.results;

          let oldRes = convertAttendanceresToreport(result);
          
          dispatch({ type: ALL_ATTENDENCE, payload: oldRes });
        }
        setCurrentPage(1);
        setPageCounts(res.data.total_pages);
        setTotalValues(res.data.count);
        setInProgress(false);
      })
      .catch((err) => {
        // dispatch(autoLogoutOnTokenExpire(err, dispatch));
        setInProgress(false);
      });
  };
};

export const exportAllAttendance = (req, setExportState) => {
  setExportState(true);
  Axios_v2.post(`/export/export_timesheet_for_all/`, req, HeaderToken())
    .then((res) => {
      if (res.data.status === true) {
        window.location.href = res.data.result.file;
      }
      setExportState(false);
    })
    .catch((err) => {
      setExportState(false);
    });
};

export const getWorkedTimeDetails = (date) => {
  return async (dispatch) => {
    Axios_v2.post(
      `/reports/dashbord_brief/`,
      {
        todaysDate: date,
      },
      HeaderToken()
    )
      .then((res) => {
        dispatch({ type: WORK_TIME, payload: res.data.result });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getUserTimeLog = (userId, dateAfter, reportType) => {
  return async (dispatch) => {
    Axios_v2.get(
      `/api/attendance/?user=${userId}&date_after=${dateAfter}&report_type=${reportType}`,

      HeaderToken()
    ).then((res) => {});
  };
};

export const getReportWithProjectAndTask = (req, setInProgress) => {
  setInProgress(true);
  return async (dispatch) => {
    await Axios_v2.post("/reports/projects/", req, HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status == 200) {
          let result = res.data.result;
          dispatch({ type: PROJECT_TASK_REPORT, payload: result });
          let oldRes = convertProjectAndTaskToreport(result);
          setInProgress(false);
        }
      })
      .catch((err) => {
        setInProgress(false);
        // dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};

export const exportProjectAndTask = (req, setExportState) => {
  setExportState(true);
  Axios_v2.post(`/reports/export_project_reports/`, req, HeaderToken())
    .then((res) => {
      if (res.data.status === true) {
        window.location.href = res.data.result.file;
      }
      setExportState(false);
    })
    .catch((err) => {
      setExportState(false);
    });
};


export const attendance_update = (id,reason,callAttendanceList) => {

  const data = {
    reason: reason.toString()
  };

  Axios_v2.patch(`/attendance/${id}/save_reason/`, data, HeaderToken())
    .then((res) => {
    
      console.log("response",res);
      if(res.data.status===true){
      toast.success(res.data.message);
      callAttendanceList();
      }
    })
    .catch((err) => {
      console.log("error",err);
    });
};