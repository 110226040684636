import React, { useState, useEffect } from "react";
import Modal from "../common/Modal";
import "./style.css";
import Accordion from "./Accordion";

const AddPermissionModal = ({ open, onClose, hookProps }) => {
  const {
    roleValue,
    handlePermissionSelection,
    handleSelectAllModuleItems,
    selectedPermissions,
    groupedPermissions,
    handleSavePermissions,
    setSelectedPermissions,
    userPermissions,
  } = hookProps;
  console.log("roleper", roleValue);

  const selectedPermissionIds = selectedPermissions.map((item) => item.id);
  const isPermissionSelected = (id) => selectedPermissionIds.includes(id);

  const isAllPermissionsSelected = (module) => {
    const modulePermissions = groupedPermissions[module];
    // Check if all permissions are selected
    return modulePermissions.every((permission) =>
      isPermissionSelected(permission.id)
    );
  };

  console.log("selectedpermission", selectedPermissions);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="role-modal-container">
        <div
          className="header"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="modal-header">
            <h3>Role</h3>
          </div>

          <div className="cross-icon">
            <button className="close-btn" onClick={onClose}>
              <img
                height="25px"
                width="25px"
                src="/images/close.png"
                alt="Project-info-icon"
              />
            </button>
          </div>
        </div>

        <div className="row">
          <label htmlFor="p-name" className="col-sm-3 col-form-label">
            <strong>Name:</strong>
          </label>
          <div className="col-sm-9 align-self-center">{roleValue.name}</div>
        </div>

        <div className="row">
          <label htmlFor="p-description" className="col-sm-3 col-form-label">
            <strong>Description:</strong>
          </label>
          <div className="col-sm-9 align-self-center">
            {roleValue.description}
          </div>
        </div>

        <div className="row">
          <label htmlFor="p-description" className="col-sm-3 col-form-label">
            <strong>Function Type:</strong>
          </label>
          <div className="col-sm-9 align-self-center">
            {roleValue.selectedFunction}
          </div>
        </div>

        <div className="row mt-3">
          <h3>Permissions</h3>
        </div>
        <div className="permission-container">
          
        {Object.keys(groupedPermissions).map((module) => {
  console.log("modulem", module); // Move the console.log here
  if (module) {
    return (
      <Accordion
        key={module}
        module={module}
        permissions={groupedPermissions[module]}
        onPermissionSelection={handlePermissionSelection}
        onModuleSelection={handleSelectAllModuleItems}
        selectedPermissions={selectedPermissions}
        isPermissionSelected={isPermissionSelected}
        isAllPermissionsSelected={isAllPermissionsSelected}
      />
    );
  }
  return null; // If module is empty, return null to skip rendering the Accordion
})}
        
        
        </div>

        <div className="row mt-4">
          <div className="col-sm-9 offset-sm-3 d-flex justify-content-end">
            <div className="modal-btn">
              <button
                onClick={onClose}
                type="button"
                className="btn custom-btn btn-secondary"
                style={{ marginRight: "10px" }}
              >
                Close
                </button>
                {(userPermissions.includes("Can view and edit roles and permissions") || 
                userPermissions.includes("Can change role custom")) && (
    <button
      type="button"
      className="btn custom-btn btn-secondary"
      style={{ marginRight: "10px" }}
      onClick={() => handleSavePermissions(roleValue?.id)}
    >
      Save
    </button>
)}

            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddPermissionModal;
