import React, { useEffect, useState, useRef  } from "react";
import { FormControl, InputGroup, ListGroup, Badge } from "react-bootstrap";
import "./styles.css";

const SearchableDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
  handleManagerIds,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value?.toLowerCase());
  };

  const handleSelect = (option) => {
    handleManagerIds(option);
    if (!selectedOptions?.some((selected) => selected.id === option.id)) {
      setSelectedOptions([...selectedOptions, option]);
    }
    setSearchTerm("");
    setIsOpen(false);
  };

  const handleRemove = (option) => {
    handleManagerIds(option);
    const updatedOptions = selectedOptions.filter(
      (item) => item.id !== option.id
    );
    setSelectedOptions(updatedOptions);
  };

  const filteredOptions = options?.filter(
    (option) =>
      !selectedOptions?.some((selected) => selected.id === option.id) &&
      option.name?.toLowerCase().includes(searchTerm)
  );

  return (
    <div className={`custom-select ${isOpen ? "open" : ""}`} ref={wrapperRef}>
  <InputGroup style={{ width: '470px', border: "2px solid lightgrey", borderRadius: '7px' }}>
  <FormControl
    style={{ border: 'none' }}
    placeholder="Search..."
    value={searchTerm}
    onClick={handleToggle}
    onChange={handleSearch}
    readOnly={!isOpen}
  />
  <img
    src="/images/icons/arrow-down.png"
    alt="down-arrow Icon"
    style={{ width: "8px", height: "6px", marginTop: "20px", marginRight: '5px', cursor: 'pointer' }}
    onClick={handleToggle} // Add onClick event to open handleToggle
  />
</InputGroup>
      {isOpen && (
        <ListGroup className="options-list">
          {filteredOptions?.map((option) => (
            <ListGroup.Item
              key={option.id}
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      <div className="selected-options">
        {selectedOptions?.map((option) => (
          <Badge
            key={option.id}
            className="selected-chip"
            onClick={() => handleRemove(option)}
          >
            {option.name} &times;
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default SearchableDropdown;