import { Routes,Route } from "react-router-dom";
import { PlanExpoiredScreen } from "../components/Subscription/Plan/PlanExpiration";
import AvailablePlan from "../components/Subscription/Plan/AvailablePlan";
import AuthenticationLayout from "../pages/Authentication/AuthenticationLayout";
import Login from "../pages/Authentication/Login";
 export default function PlanExpiredRoutes() {
  console.log("expiresro");
   
     return(
        <Routes>
             <Route
          path="/"
          element={<AuthenticationLayout component={<Login />} />}
        />
            <Route
          path="/Planexpire"
          element={< PlanExpoiredScreen/>}

        /> 
        <Route
          path="/change-plan"
          element={<AvailablePlan />}

        />
        <Route
        path="*"
        element={<AuthenticationLayout component={<Login />} />}

      />
        </Routes>
       
     );
 } 
