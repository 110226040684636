import "./styles.css";
import React from "react";
import { Link } from "react-router-dom";

export default function AuthenticationLayout({ component }) {
  return (
    <div className="auth-screen">
      {/* <div className="auth-screen-header">
        <Link to="/">
          <img src="/images/clocksession-logo.png" alt="clocksession-logo" />
        </Link>
      </div> */}

      <div className="auth-screen-form-container">
        <div className="auth-screen-card-container">{component}</div>

        {/* <div className="copyright-container">
          <p className="label-name">ClockSession</p>
          <p>
            ©{new Date().getFullYear()} All Rights Reserved by Mechlin
            Technologies
          </p>
        </div> */}
      </div>
    </div>
  );
}
