import {  Axios_v2,Axios } from "../../Utils/axios";

import {
  useSiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { localstorage_userInfo, localStorage_userData, localstorage_userSubordinates } from "../../constants";
import { toast } from "react-toastify";
import { HeaderToken } from "../../Utils/headerToken";
import * as Sentry from "@sentry/react";
export const LOG_IN = "LOG_IN";
export const active_subscription_list="active_subscription_list";
export const SIGN_UP = "SIGN_UP";
export const LOG_OUT = "LOG_OUT";
export const FORGOT_PASS = "FORGOT_PASS";
export const RESET_PASS = "RESET_PASS";
export const CHANGE_PASS = "CHANGE_PASS";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILE_IMAGE = "PROFILE_IMAGE";
export const GET_TIME_ZONE = "GET_TIME_ZONE";
export const USER_GROUP = "USER_GROUP";
export const USER_PERMISSIONS = "USER_PERMISSIONS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const USER_SUBORDINATES="USER_SUBORDINATES";
export const ENV="ENV";

export const resetPassword = (resData, token, navigate) => {
  return async (dispatch) => {
    let config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    await Axios_v2.post("auth/reset_password/", resData, config)
      .then((res) => {
        useSiteUpdatehandler(res, dispatch);
        if (res.data.status === "Success") {
          toast.success("Password has been successfully changed");
          dispatch({ type: RESET_PASS, payload: res.data });
          navigate("/Login");
          
        }
        if (res.data.result === "false") {
          toast.warn(res.data.response);
        }
      })
      .catch((err) => {
        // console.log("reset res catch ",err.response)
        autoLogoutOnTokenExpire(err, dispatch);
        toast.warn(err.response.data.response);
        console.log("token_response",err);
        navigate("/LinkExpire");


      });
  };
};

export const forgotPassword = (useremail, setIsProcessing) => {
  let forgetData = {
    email: useremail,
    redirecturl: `${window.location.origin}/ResetPassword/`,
  };

  return async (dispatch) => {
    setIsProcessing(true);
    await Axios_v2.post("/auth/forgot_password/", forgetData)
      .then((res) => {
        useSiteUpdatehandler(res, dispatch);
        if (res.data.result === "false") {
          toast.warn("Email not exist");
        }
        if (res.data.status === "Success") {
          toast.success(
            "Reset password link sent to your email please check your mail"
          );
          dispatch({ type: FORGOT_PASS, payload: res.data });
        }
        setIsProcessing(false);
      })
      .catch((err) => {
        console.log({ error: err.response });
        autoLogoutOnTokenExpire(err, dispatch);
        toast.error(err.response.data.non_field_errors[0]);
        setIsProcessing(false);
      });
  };
};

export const logIn = (userInfo, navigate, setIsProcessing) => {
  return async (dispatch) => {
    setIsProcessing(true);
    try {
      // Proceed with login
      const loginResponse = await Axios_v2.post("/auth/login/", userInfo);

      // Handle login response
      useSiteUpdatehandler(loginResponse, dispatch);

      // Handle different scenarios based on login response
      if (loginResponse.data === "Email not exist") {
        toast.warn("Email not exist"); 
      } else if (loginResponse.data === "password not matched") {
        toast.warn("password not matched");
      } else if (loginResponse.data.status === "Fail") {
        dispatch({
          type: "SET_ERROR_MSG",
          payload: "Invalid login attempt or email not validated! Please try again.",
        });
      } else if (loginResponse.data.status === "Success") {
        console.log("header", loginResponse.headers); 
        toast.success("Login Successfully");
        localStorage.setItem(
          localStorage_userData,
          JSON.stringify({
            ...loginResponse.data.data.user,
            token: loginResponse.data.data.token,
          })
        );
        Sentry.setUser({
          ...loginResponse.data.data.user,
          token: loginResponse.data.data.token, 
        });

        // Set user info in localStorage before making active plan API call
        const userInfoFromStorage = JSON.parse(localStorage.getItem(localStorage_userData));

        try {
          const activePlanResponse = await Axios_v2.get(
            `active-subscription/feature_list/?organisation_name=${userInfoFromStorage.organisation.name}&ordering=-start_date`,
            HeaderToken()
          );
          console.log("activeplan", activePlanResponse);

          // Dispatch action for active subscriptions
          if (activePlanResponse.status === 200) {
            const ActivesubsArray = activePlanResponse.data;
            console.log("activesub",ActivesubsArray);
            if (ActivesubsArray.length > 0) {
              localStorage.setItem('activeSubscriptions', JSON.stringify(ActivesubsArray));
              dispatch({ type: active_subscription_list, payload: ActivesubsArray });

              dispatch({
                type: LOG_IN,
                payload: {
                  ...loginResponse.data.data.user,
                  token: loginResponse.data.data.token,
                },
              });
              navigate("/");
            } else {
              // toast.warn("No plan available");
              dispatch({
                type: LOG_IN,
                payload: {
                  ...loginResponse.data.data.user,
                  token: loginResponse.data.data.token,
                },
              });
              navigate("/Planexpire");
            }
          } 
        } catch (activePlanError) {
          console.log("Error fetching active plan:", activePlanError);
          // Handle error for active plan API call here
          toast.error(activePlanError.response.data.detail);
        }

        if (userInfo.rememberMe === true) {
          localStorage.setItem(localstorage_userInfo, JSON.stringify(userInfo));
        } else {
          localStorage.removeItem(localstorage_userInfo);
        }

      }
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      console.log({ error: error.response });
      toast.warn(error.response.data.non_field_errors[0]);
      dispatch({ type: "SET_ERROR_MSG", payload: error.response.data.message });
    }
  };
};

let isLoggedIn = false;

export const ImpersonationApi = (token, navigate) => {
  let config = {
    headers: {
      Authorization: `Token ${token}`
    }
  };

  return async (dispatch) => {
    try {
      const res = await Axios_v2.get("/auth/myProfile/", config);
      navigate("/");
      if (res.data.status === "Success" && !isLoggedIn) {
        isLoggedIn = true;

        toast.success("Login Successfully");
        localStorage.setItem(
          localStorage_userData,
          JSON.stringify({
            ...res.data.data.user,
            token: res.data.data.token,
          })
        );
      
        Sentry.setUser({
          ...res.data.data.user,
          token: res.data.data.token,
        });
       
        dispatch({
          type: LOG_IN,
          payload: {
            ...res.data.data.user,
            token: res.data.data.token,
          },
        });

         
      }
    } catch (err) {
      if(err.response.data.detail === "Invalid token."){
        toast.error("Invalid token. Please try again.");
      }
      console.log({ error: err.response });
      toast.warn(err.response.data.non_field_errors[0]);
      dispatch({ type: "SET_ERROR_MSG", payload: err.response.data.message });
    }
  };
};




export const getSubordinates = (id) => {
  return async (dispatch) => {
    await Axios_v2.get(`/employees/${id}/subordinates/?items_per_page=${1000}`,HeaderToken())
      .then((res) => {
        dispatch({ type:"USER_SUBORDINATES", payload: res.data.results });
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
        //      toast.error("Network Error");
      });
  };
};
export const signUp = (data) => {
  return (dispatch) => {
    return dispatch({
      type: SIGN_UP,
      payload: data,
    });
  };
};

export const logOut = () => {
  return (dispatch) => {
    localStorage.removeItem(localStorage_userData);
    return dispatch({
      type: LOG_OUT,
    });
  };
};

export const getTimeZone = () => {
  return async (dispatch) => {
    await Axios.get(`/TimeZones/get_common_time_zones/`, HeaderToken())
      .then((res) => {
        dispatch({ type: GET_TIME_ZONE, payload: res.data.TimeZone_Avaible });
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
        //      toast.error("Network Error");
      });
  };
};

export const updateTimeZone = (data, ID) => {
  return async (dispatch) => {
    Axios_v2.patch(`/employees/${ID}/`, data, HeaderToken())
      .then((res) => {
        if (res.status === 200) {
          // let localData = JSON.parse(
          //   localStorage.getItem(localStorage_userData)
          // );
          // localStorage.setItem(
          //   localStorage_userData,
          //   JSON.stringify({
          //     ...localData,
          //     ...res.data,
          //   })
          // ); 
        }
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );
      });
  };
};
function getCookie(name) {
  const cookieValue = document.cookie.match(
    "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  return cookieValue ? cookieValue.pop() : "";
}

//hamdel api error

function handleErrorResponse(err) {
  if (err.response && err.response.data) {
    const errors = err.response.data;

    for (let key in errors) {
      if (Array.isArray(errors[key])) {
        toast.error(errors[key][0]);
      } else {
        toast.error(errors[key]);
      }
    }
  } else {
    toast.error("Something went wrong. Please try again.");
  }
}

//contect us
export const contectUsApi = (data) => {
  const csrftoken = getCookie("csrftoken");
  const headers = {
    "X-CSRFToken": csrftoken,
  };
  return async (dispatch) => {
    await Axios_v2.post(`/contact/`, data)
      .then((res) => {
        if (res.data.result === "fail") {
          toast.error(res.data.response);
        } else {
          toast.success("Your details are  added.");
        }
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };
};

//get groups
export const getAllGroups = () => {
  
  return async (dispatch) => {
    await Axios_v2.get(`/groups/?items_per_page=1000`, HeaderToken())
      .then((res) => {
        // console.log("res", res);
        dispatch({ type: USER_GROUP, payload: res.data.results });
      })
      .catch((err) => {
        console.log("contact err", err);
      });
  };
};

// get permissions to access
export const getPermissions = () => {
  // Add a request interceptor for Axios instances
  const localStorageValue = localStorage.getItem(localStorage_userData);

  if (localStorageValue) {
    const parsedValue = JSON.parse(localStorageValue);
    console.log("LocalStorage", parsedValue);
  
    // Extract and set the portion after '@' from the email separately
    const userEmail = parsedValue.email;
    if (userEmail) {
      const domain = userEmail.split('@')[1];
      localStorage.setItem("domain", domain);
      console.log("Domain:", domain);
  
      // Access the domain value elsewhere using localStorage.getItem("domain")
      const storedDomain = localStorage.getItem("domain");
      console.log("Stored Domain:", storedDomain);
    } else {
      console.log("Email not available in the localStorage object.");
    }
  } else {
    console.log("LocalStorage Value is not available.");
  }
  

  



  return async (dispatch) => {
    await Axios_v2.get(`/permissions/?items_per_page=1000000`, HeaderToken())
      .then((res) => {
        //console.log("res", res);
        if (res.status === 200) {
          dispatch({
            type: USER_PERMISSIONS,
            payload: res.data.results.map((per) => per.name),
          });
          dispatch({
            type: ENV,
            payload: res.headers,          
          });
        }
      })
      .catch((err) => {
        console.log("contact err", err);
      });
  };
};

export const stripe_details = (env) => {
  try {
    const pages = [1, 2];
    const fetchPromises = [];

    let baseUrl = 'https://admin.dev.clocksession.com/api/customer/';

    if (env && env['x-api-environment']) {
      console.log("Environment", env['x-api-environment']);
      if (env['x-api-environment'] === "dev") {
        baseUrl = 'https://admin.dev.clocksession.com/api/customer/';
      } else if (env['x-api-environment'] === "stage") {
        baseUrl = 'https://admin.stage.clocksession.com/api/customer/';
      } else if (env['x-api-environment'] === "production") {
        baseUrl = 'https://admin.clocksession.com/api/customer/';
      }
    } else {
      console.log("No 'x-api-environment' found in env");
    }

    pages.forEach(page => {
      const fetchPromise = fetch(`${baseUrl}?page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if required
        },
        // Add any other options if needed
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Network response was not ok (${response.status} - ${response.statusText})`);
          }
          return response.json();
        })
        .then(data => {
          console.log(`Customer response for page ${page}:`, data.results);
          return data.results; // Return the results array for further processing
        })
        .catch(error => {
          console.error(`Error fetching data for page ${page}:`, error);
          throw error; // Propagate the error for Promise.all rejection
        });

      fetchPromises.push(fetchPromise);
    });

    // Use Promise.all to wait for all fetch requests to complete
    Promise.all(fetchPromises)
      .then(allResults => {
        // Combine all results into a single array
        const combinedResults = [].concat(...allResults);

        // Filter out the objects whose email ends with the stored domain
        const storedDomain = localStorage.getItem("domain");
        const filteredResults = combinedResults.filter(obj => obj.email.endsWith(storedDomain));

        // Process or log the filteredResults as needed
        const singleObject = filteredResults[0];

        // Process or log the singleObject as needed
        console.log("Filtered_data:", singleObject);
        localStorage.setItem("filteredData", JSON.stringify(singleObject));
        console.log("stripe_customer");
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  } catch (error) {
    // Handle any unexpected errors
    console.error('Error:', error.message);
  }
};




//change password

export const changePassword = (changePassData, navigation) => {
  return async (dispatch) => {
    await Axios_v2.post("/auth/change_password/", changePassData, HeaderToken())
      .then((res) => {
        console.log("res===", res.data);
        useSiteUpdatehandler(res, dispatch);
        if (res.data.result === "false") {
          toast.error(res.data.message);
        }
        if (res.data.status === "Success") {
          navigation("/");
          toast.success(res.data.message);
          dispatch({ type: CHANGE_PASS, payload: res.data });
          localStorage.removeItem(localStorage_userData);
          // localStorage.removeItem(localstorage_userInfo);
        }
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
        console.log({ error: err.response });
        toast.error(err.response.data.current_password[0]);
      });
  };
};

export const updateProfile = (formData, ID) => {

  function getAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const diffMs = Date.now() - dob.getTime();
    const ageDt = new Date(diffMs); 
    return Math.abs(ageDt.getUTCFullYear() - 1970);
  }

  return async (dispatch) => {
    Axios_v2.patch(`/employees/${ID}/`, formData, HeaderToken())
      .then((res) => {
        console.log("update employee res", res);
        console.log("dob is: ", res.data.date_of_birth);

        const age = getAge(res.data.date_of_birth);
        if (age < 18) {
          toast.error("Employee must be at least 18 years old.");
          return; // Exit the function if the age is less than 18
        }

        if (res.status === 200) {
          dispatch({ type: UPDATE_PROFILE, payload: res.data });
          let localData = JSON.parse(
            localStorage.getItem(localStorage_userData)
          );
          localStorage.setItem(
            localStorage_userData,
            JSON.stringify({
              ...localData,
              ...res.data,
            })
          );
          toast.success("successfully updated");
        }
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const getTimeLog = async (req) => {
  return async (dispatch) =>
    Axios_v2.get(`/working-hour-logs/`, HeaderToken())
      .then((res) => {
        dispatch({ type: "TIMELOG_HOURS", payload: res.data });
        console.log("getTimeLog_API_RESPONSE", res.data);
      })
      .catch();
};
