import React from "react";
import { useSelector } from "react-redux"; // Assuming you're using Redux
import SelectEmployeeDD from "../SelectEmployeDD";
import EmployeeListTable from "../common/EmployeeListTable";
import useEmployeeList from "../../hooks/useEmployeeList";
//Date Pickers
import SelectAllEmployee from "../SelectEmployeDD/SelectAllEmployee";
import {
  DatePickerSingle,
  WeekPicker,
  MonthPicker,
  DateRangePicker,
} from "../common/DatePickers";

import "./style.css";

const Attendance = () => {
  const {
    startDate,
    setStartDate,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    dateRangeEnd,
    currentTab,
    onChangeDateRange,
    handleTabTypeChange,
    handleDecrease,
    handleIncrease,
    attendanceForAllUsers,
    inProgress,
    user,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    handleExportAll,
    exportState,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    allempAlltendence,
    callAttendanceList
  } = useEmployeeList();

  const { userGroups, userPermissions } = useSelector((store) => store.auth);

  return (
    <div id="Attendances">
      <div className="header-btn">
      {userPermissions.includes("Can view users added by or assigned to self")||
          userPermissions.includes("Can view all users in the organization")
          ?(
        <div className="mem-btn" >
         <SelectAllEmployee selectedUsers={user} setSelectedUsers={setUser} tab="attendence" />
        </div>
         ):null}
        <div className="employee-timeline">
          <img
            src="/images/icons/Icon - Calender.png"
            alt="calendar"
            onClick={() => setCalendarIsOpen(!calendarIsOpen)}
            ref={wrapperRef}
          />


          {currentTab === "daily" && (
            <DatePickerSingle
              startDate={startDate}
              ref={calendarRef}
              setStartDate={setStartDate}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}
          {currentTab === "weekly" && (
            <WeekPicker
              ref={calendarRef}
              weekDate={weekDate}
              setWeekDate={setWeekDate}
              startWeekDay={startWeekDay}
              endWeekDay={endWeekDay}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}

          {currentTab === "monthly" && (
            <MonthPicker
              ref={calendarRef}
              startMonth={startMonth}
              setStartMonth={setStartMonth}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}
          {currentTab === "dateRange" && (
            <DateRangePicker
              ref={calendarRef}
              dateRangeStart={dateRangeStart}
              onChangeDateRange={onChangeDateRange}
              dateRangeEnd={dateRangeEnd}
              calendarIsOpen={calendarIsOpen}
            />
          )}


          {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly"? (
            <button
              className="prev-month"
              onClick={() => {
                handleDecrease();
              }}
            >
              {""}
            </button>
          ) : null}



          {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly"? (
            <button
              className="next-month"
              onClick={() => {
                handleIncrease();
              }}
              disabled={
                (currentTab === "daily" && startDate.toDateString() === new Date().toDateString()) ||
                (currentTab === "weekly" && 
                (startWeekDay.toDateString() === new Date().toDateString() || 
                (startWeekDay.getDate() + 6 >= new Date().getDate() && 
                startWeekDay.getMonth() === new Date().getMonth()))
              ) ||
                (currentTab === "monthly" && startMonth.getMonth() === new Date().getMonth())
              }
            >
               {""}
            </button>
          ) : null}


        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "20%" }}>
          <div>
            <select
              id="u-access"
              name="group"
              className="form-control"
              value={currentTab}
              onChange={(e) => handleTabTypeChange(e)}
            >
              <option value="daily"> Daily</option>
              <option value="weekly"> Weekly</option>
              <option value="monthly"> Monthly</option>
              <option value="dateRange">Date-Range</option>
            </select>
          </div>
        </div>
        {(userPermissions.includes("Can export all attendance reports") ||
          userPermissions.includes("Can export attendance report for users added by or assigned to self") ||
          userPermissions.includes("Can export report for all users") ||
          userPermissions.includes("Can export all reports") ||
          userPermissions.includes("Can export report for users added by or assigned to self")) && (
          <button
            className={allempAlltendence.length === 0 ? "exp-btn holiday-hidden" : "exp-btn"}
            type="submit"
            onClick={handleExportAll}
            disabled={exportState}
          >
            Export
            {exportState && <span className="spinner-grow spinner-grow-sm"></span>}
            {/* <img src="/images/icons/download.png" alt="" /> */}
          </button>
        )}

      </div>
       {console.log("empDetails",allempAlltendence)}
      <div>
        <EmployeeListTable
          data={allempAlltendence}
          currentTab={currentTab}
          inProgress={inProgress}
          isActionAvailable={true}
          callAttendanceList={callAttendanceList}
          hookProps={{
            currentPage,
            setCurrentPage,
            pageCounts,
            setPageCounts,
          }}
        />
      </div>
    </div>
  );
};

export default Attendance;
