
import { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
 
import { useDispatch, useSelector } from "react-redux";
import { applyLeave,deleteLeaveAllotment, getLeaveList,getFilterLeaveList, updayeLeave ,getAllotmentList,AddTypeofEmployee,UpdateLeaveAllotment,getLeaveType,UpdateUserLeave,ExportLeave, getAllLeavesofUser, getSortedLeaveListofUser_paginated, getFilterLeaveList_Sorted} from "../redux/Actions/leaveAction";
import { getFilterLeaveList_paginated } from "../redux/Actions/leaveAction";
import { deleteLeave_Status } from "../redux/Actions/leaveAction";
import { toast } from "react-toastify";
import { Axios_v2 } from "../Utils/axios";
import { HeaderToken } from "../Utils/headerToken";
import { getSortedLeaveList_approved } from "../redux/Actions/leaveAction";
import useLeaveStatus from "./useLeaveStatus";
const leaveOptions = [
  "Pending",
  "Rejected",
  "Approved",
];
 
export default function useLeaveManagement() {
 
  const csvref=useRef(null);
  const[userleavedata,setuserleavedata]=useState([]);
  const dispatch = useDispatch();
  const[exportdata,setExportData]=useState([])
  const [inProgress, setInProgress] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [user, setUser] = useState([]);
  const [weekDate, setWeekDate] = useState(new Date());
  const [startWeekDay, setStartWeekDay] = useState("");
  const [endWeekDay, setEndWeekDay] = useState("");
  const [startMonth, setStartMonth] = useState(new Date());
  const [dateRangeStart, setDateRangeStart] = useState();
  const [dateRangeEnd, setDateRangeEnd] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(1);
  const [leavepageCounts, setLeavePageCounts] = useState(1);
  const [statuspageCounts, setStatusPageCounts] = useState(1);
  const itemsPerPage = 10;
  const [modalOpen, setModalOpen] = useState(false);
  const [editLeaveModal, setEditLeaveModal] = useState(false);
  const { userInfo } = useSelector((store) => store.auth);
const {leaveUserList, leaveTransactionList, leaveAllotmentList,leaveTypeList,leaveTransactionFilterList} = useSelector((store) => store.leave);
  const [text, setText] = useState("");
  const [selectedReason, setSelectedReason] = useState("select reason");
  const [managerComment, setManagerComment] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("Select Status");
  const [editData,setEditdata] = useState(null);
  const [exportState, setExportState] = useState(false);
  const [editLeaveID,setEditLeaveID] = useState(null);
  const [edituserLeaveID,setedituserLeaveID] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [employeeType, setEmployeeType] = useState("");
  const [leaveAlloted, setLeaveAlloted] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTab, setCurrentTab] = useState("monthly");
  const [startMonth_status,setStartMonth_status]= useState(new Date());
  const [isupdated,setIsupdated]=useState(false);
  const { userPermissions } = useSelector((store) => store.auth);
  const [ModalCalender,setModalCalender]=useState(false);
  const [currentYEAR, setCURRENTYEAR] = useState(new Date().getFullYear());
  const { appliedLeaves } = useSelector((store) => store.leave);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [approvedLeave_count,setApprovedLeave_count]=useState();
  const [halfday_selection,setHALFDAYSELECTION]=useState([]);
  const [halfday_json,setHalfday_json]=useState([]);
  const [approved_leave_current_month,setAPPROVED_MONTH_LEAVE]=useState();
  const [toggle, setToggle] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentMonth_approvedleave,setCURRENTMONTH_APPROVEDLEAVE]=useState();
  const [halfday,setHalfDay]=useState([]);
  const [totalRequest_loader,setTotalRequest_Loader]=useState(true);
  const [leave_Available,setLeave_Available]=useState();
  const [leave_balance_loader,setLeaveLoader]=useState(true);
  const { leaveCount } = useSelector((store) => store.leave);
  const [iseditoradd,setiseditoradd]=useState(false);
  const [prevTab,setprevTAB]=useState("daily");
  const [prevDate,setprevDate]=useState(new Date());





  console.log("current_year",currentYEAR);
  console.log("current_month",currentMonth);
  console.log("applied_leave",appliedLeaves);
  console.log("approved_leave_count",approvedLeave_count);
  console.log("upodated_half_day_count",halfday_selection);
  console.log("Day of the week:", moment(startDate).format('dddd'));
  console.log("half_day_length",halfday_json.length);
  console.log("approved_for_the_month ",approved_leave_current_month);
  console.log("Toggle",toggle);
  console.log("Start_Date",startDate);
  console.log("prevTab",prevTab);
  console.log("iseditoradd",iseditoradd);
  console.log("ismodalopen",modalOpen)
  const [isEscalted, setIsEscalted] = useState(false);


  useEffect(() => {
    if(!iseditoradd && !modalOpen){
      setCurrentTab(prevTab);
    }
    if(iseditoradd){
      setiseditoradd(false);
    }
   

    
  }, [modalOpen]);  
 
 
  // Calender icon click functionality start
  const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
 
  const options =leaveTypeList.map(item => item.reason);
  const handleDecrease_status = () => {
 
    if (currentTab === "daily") {
      const Decrement = startDate;
      Decrement.setDate(Decrement.getDate() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
      console.log("daily_date",startDate)
    }
 
    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() - 7);
      setWeekDate(newWeekDate);
      console.log("weekly_date",newWeekDate)

    }
 
    if (currentTab === "monthly") {
      const Decrement = startMonth_status;
      Decrement.setMonth(Decrement.getMonth() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]");
      setStartMonth_status(new Date(op));
    }
};
const handleIncrease_status = () => {
   
  if (currentTab === "daily") {
    const Increment = startDate;
    Increment.setDate(Increment.getDate() + 1);
    const output = Increment.toLocaleString();
    const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
    setStartDate(new Date(op));
  }
 
  if (currentTab === "weekly") {
    const newWeekDate = new Date(weekDate);
    newWeekDate.setDate(newWeekDate.getDate() + 7);
    setWeekDate(newWeekDate);
  }
 
  if (currentTab === "monthly") {
    const Increment = startMonth_status;
    Increment.setMonth(Increment.getMonth() + 1);
    const output = Increment.toLocaleString();
    const op = moment(output).format(
      "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
    );
    setStartMonth_status(new Date(op));
  }
 
};



 
  function useOutsideAlerter(ref, calendarRef) {
    function handleClickOutside(event) {
      if (calendarIsOpen) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          calendarRef &&
          !calendarRef.current.contains(event.target)
        ) {
          setModalCalender(!calendarIsOpen);
          setCalendarIsOpen(!calendarIsOpen);
        }
      }
    }
   
 
    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef, calendarRef);
  // Calender icon click functionality End
 
  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setDateRangeStart(start);
    setDateRangeEnd(end);
  };
 
  function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }
 
  function getDateOfWeek(weekNumber, year) {
    //Create a date object starting january first of chosen year, plus the number of days in a week multiplied by the week number to get the right date.
    return new Date(year, 0, 1 + (weekNumber - 1) * 7);
  }
  function getFirstDateOfWeeks(weekNumber, year) {
    // Get the first day of the year
    var firstDayOfYear = new Date(year, 0, 1);
    // Get the first Monday of the year
    var firstMondayOfYear = new Date(
      year,
      0,
      1 + ((8 - firstDayOfYear.getDay()) % 7)
    );
 
    // Get the date of the Monday of the requested week
    var dateOfFirstMondayOfWeek = new Date(
      firstMondayOfYear.getFullYear(),
      0,
      firstMondayOfYear.getDate() + (weekNumber - 1) * 7
    );
 
    // Return the date of the first Monday of the requested week
    return dateOfFirstMondayOfWeek;
  }
  function getLastDateOfWeek(weekNumber, year) {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const lastDayOfWeek = date.getDate() + 7;
    const lastDateOfWeek = new Date(date.setDate(lastDayOfWeek));
    return lastDateOfWeek;
  }

  useEffect(() => {
    dispatch(getSortedLeaveList_approved(userInfo,currentYEAR,currentMonth));
 }, [currentYEAR, toggle]);


 const PresentMonth = new Date().getMonth() + 1;


 

useEffect(() => {
  console.log("current_month_changed")

  const filteredArray = appliedLeaves?.filter(obj => {
    const startDate = new Date(obj.leave_start_date);
    return startDate.getMonth() + 1 == currentMonth; // Adding 1 because getMonth() returns a zero-based index
  });
  
  console.log(filteredArray);

  setAPPROVED_MONTH_LEAVE(filteredArray?.length);
}, [currentMonth][appliedLeaves]);

useEffect(() => {
  const filteredArray = appliedLeaves?.filter(obj => {
    const startDate = new Date(obj.leave_start_date);
    const isCurrentMonth = startDate.getMonth() + 1 === currentMonth; // Adding 1 because getMonth() returns a zero-based index
    const isApproved = obj.status === "Approved";
    return isCurrentMonth && isApproved;
  });

  console.log(filteredArray);

  const totalLeaveCount = filteredArray?.reduce((total, leave) => {
    const leaveCount = parseFloat(leave.leave_count) || 0; // Ensure leave_count is a number
    return total + leaveCount;
  }, 0);

  console.log(`Total leave count for approved leaves in month ${currentMonth}:`, totalLeaveCount);

  setCURRENTMONTH_APPROVEDLEAVE(totalLeaveCount);}, [currentMonth, appliedLeaves]); // Corrected the dependency array



useEffect(() => {
 
  let halfday_json = halfday_selection?.map(date => ({ date }));
  setHalfday_json(halfday_json);
  console.log("halfday_json",halfday_json);
  
  
  console.log("half_day_json",halfday_json);
}, [halfday_selection]);

  useEffect(() => {
    var curr = new Date(weekDate); // get current date
    var result = getWeekNumber(weekDate); // get week no and year [year, weekno]
    var first_day = getFirstDateOfWeeks(result[1], result[0]);
 
    var last_day = getLastDateOfWeek(result[1], result[0]);
    last_day.setDate(last_day.getDate() - 1);
 
    console.log("week dates", {
      curr,
      weekDate,
      first_day,
      last_day,
      date: new Date(),
      result,
    });
    setStartWeekDay(first_day);
    setEndWeekDay(last_day);
    console.log("first_day_of_week",first_day);
  }, [weekDate]);
 
  const handleTabTypeChange = (e) => {
     
 
    setCurrentTab(e);
  };

  useEffect(() => {
    function handleapprovedleave_count(appliedLeaves) {
      const approvedData = appliedLeaves.filter(obj => obj.status === "Approved");
      const totalDifference = approvedData.reduce((total, obj) => {
        if (obj.leave_count !== null) {
          const difference = obj.leave_count - obj.unpaid_leave_count;
          return total + difference;
        } else {
          return total;
        }
      }, 0);
      setApprovedLeave_count(totalDifference)

    }

    handleapprovedleave_count(appliedLeaves);

    // Specify currentMonth as a dependency
  }, [currentMonth][currentYEAR]);
 
  let data;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
 
      const canViewLeaves =
      userPermissions.includes("Can view leaves requested by all users") ||
      userPermissions.includes("View leaves requested by assigned users") ||
      userPermissions.includes("View own leaves");
      let endDate = new Date();
 
      switch (currentTab) {
        case "daily":
          data = {
            report_type: currentTab,
            start_date: moment(startDate).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
            end_date: moment(startDate).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
          };
          console.log("daily_date",startDate)
          setCURRENTYEAR((new Date(startDate)).getFullYear());
          setCurrentMonth((new Date(startDate)).getMonth() + 1);
        
          break;
        case "weekly":
          data = {
            report_type: currentTab,
            start_date: moment(startWeekDay).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
            end_date: moment(endWeekDay).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
          };
          console.log("weekly_date",startWeekDay)
          setCURRENTYEAR((new Date(startWeekDay)).getFullYear());
          setCurrentMonth((new Date(startWeekDay)).getMonth() + 1);



          break;
        case "monthly":
          const lastDayOfMonth = moment(startMonth_status).endOf('month').format('YYYY-MM-DD');
          const firstDayOfMonth = moment(startMonth_status).startOf('month').format('YYYY-MM-DD');
          data = {
            report_type: currentTab,
            start_date: firstDayOfMonth + "T00:00:00" + moment().format("Z"),          
            end_date: lastDayOfMonth + "T23:59:59" + moment().format("Z"),
            };
            console.log("start_month_date",firstDayOfMonth);
            setCURRENTYEAR((new Date(firstDayOfMonth)).getFullYear());
            setCurrentMonth((new Date(firstDayOfMonth)).getMonth() + 1);



          break;
        case "dateRange":
          data = {
            report_type: currentTab,
            start_date: moment(dateRangeStart).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
            end_date: moment(dateRangeEnd).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
          };
          console.log("date_range_start",dateRangeStart)
          const currentDate = new Date(dateRangeStart);
          let currentYear = currentDate.getFullYear();
          let currentMonth = currentDate.getMonth() + 1; // Adding 1 as getMonth() returns zero-based index
          
          if (isNaN(currentYear)) {
              currentYear = new Date().getFullYear();
              currentMonth = new Date().getMonth() + 1; // Adding 1 as getMonth() returns zero-based index
          }
          
          setCURRENTYEAR(currentYear);
          setCurrentMonth(currentMonth);        



          break;
        default: {
          data = {
            report_type: currentTab,
            start_date: startDate,
            end_date: endDate,
          };
        }
      }
    if (canViewLeaves) {
      dispatch(getFilterLeaveList({
        setInProgress,
        currentPage,
        itemsPerPage,
        setStatusPageCounts,
        userInfo,
        startMonth_status,
        setCurrentPage,
        isupdated,
       setIsupdated,
        data,
       
        // leave_start_date
      }));
      dispatch(getFilterLeaveList_Sorted({
        setInProgress,
        currentPage,
        itemsPerPage,
        setStatusPageCounts,
        userInfo,
        startMonth_status,
        setCurrentPage,
        isupdated,
       setIsupdated,
       data,
       isEscalted,
        // leave_start_date
      }));
     
    }
     
    }, 3000);
 
    return () => clearTimeout(timeoutId);
 
  }, [dispatch, startMonth_status, startDate,startWeekDay,endWeekDay,dateRangeStart,dateRangeEnd,currentTab]);
 
  useEffect(()=>{
    setInProgress(true);
  },[startDate,startWeekDay,startMonth_status,currentTab,dateRangeEnd])



  
    // setLeave_Available(currentMonth < PresentMonth ? 0 : Math.max(0, (currentMonth === PresentMonth ? leaveCount + 2 : 2) - currentMonth_approvedleave));
    // Move setLeaveLoader(false) inside a setTimeout or a promise to execute after setLeave_Available has been updated
  

 
 
  const handleClick = () => {
    let data;
    let endDate = new Date();
 
    switch (currentTab) {
      case "daily":
        data = {
          report_type: currentTab,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(startDate).format("YYYY-MM-DD")  ,
        };
        break;
      case "weekly":
        data = {
          report_type: currentTab,
          start_date: moment(startWeekDay).format("YYYY-MM-DD") ,
          end_date: moment(endWeekDay).format("YYYY-MM-DD"),
        };
        break;
      case "monthly":
        const lastDayOfMonth = moment(startMonth_status).endOf('month').format('YYYY-MM-DD');
        const firstDayOfMonth = moment(startMonth_status).startOf('month').format('YYYY-MM-DD');
        data = {
          report_type: currentTab,
          start_date: firstDayOfMonth ,          
          end_date: lastDayOfMonth  ,
          };
        break;
      case "dateRange":
        data = {
          report_type: currentTab,
          start_date: moment(dateRangeStart).format("YYYY-MM-DD"),
          end_date: moment(dateRangeEnd).format("YYYY-MM-DD") ,
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }}
      setExportState(true);
    
    Axios_v2.get(`/leave-transaction-history/export_leave/?start_date=${data.start_date}&end_date=${data.end_date}&user=${userInfo.id}`,HeaderToken())
      .then(response => {
        setExportState(false);
        console.log("Export_successful:", response);
        window.location.href = response.data.file;
        // For example, if you want to download a file from the response
 
       
      })
      .catch(error => {
        setExportState(false);
        console.error("Export failed:", error);
        toast.error("Export failed");
      });
  };
 
 
 
  useEffect(() => {
    const canViewLeaves =
    userPermissions.includes("Can view leaves requested by all users") ||
    userPermissions.includes("View leaves requested by assigned users") ||
    userPermissions.includes("view own leaves");
    let data;
    let endDate = new Date();
 
    switch (currentTab) {
      case "daily":
        data = {
          report_type: currentTab,
          start_date: moment(startDate).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
          end_date: moment(startDate).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
        };
        break;
      case "weekly":
        data = {
          report_type: currentTab,
          start_date: moment(startWeekDay).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
          end_date: moment(endWeekDay).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
        };
        break;
      case "monthly":
        const lastDayOfMonth = moment(startMonth_status).endOf('month').format('YYYY-MM-DD');
        const firstDayOfMonth = moment(startMonth_status).startOf('month').format('YYYY-MM-DD');
        data = {
          report_type: currentTab,
          start_date: firstDayOfMonth + "T00:00:00" + moment().format("Z"),          
          end_date: lastDayOfMonth + "T23:59:59" + moment().format("Z"),
          };
        break;
      case "dateRange":
        data = {
           report_type: currentTab,
          start_date: moment(dateRangeStart).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
          end_date: moment(dateRangeEnd).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }
    }
  if (canViewLeaves) {
    dispatch(getFilterLeaveList_paginated({
      setInProgress,
      currentPage,
      itemsPerPage,
      setStatusPageCounts,
      userInfo,
      startMonth_status,
      isupdated,
      setIsupdated,
      data
     
      // leave_start_date
    }));
    dispatch(getSortedLeaveListofUser_paginated({
      setInProgress,
      currentPage,
      itemsPerPage,
      setStatusPageCounts,
      userInfo,
      startMonth_status,
      isupdated,
      setIsupdated,
      data
    }));
  }
  }, [currentPage]);
 
 
  // useEffect(() => {
 
  //   dispatch(
  //     getAllotmentList({
  //       setInProgress,
  //       currentPage,
  //       itemsPerPage,
  //       setLeavePageCounts
  //       // setPageCounts
 
  //     })
  //   );
  // }, [dispatch, startDate, currentTab,  currentPage,itemsPerPage]);
console.log("listg",leaveUserList)
  useEffect(() => {
    dispatch(getLeaveType());
    dispatch(getAllLeavesofUser(userInfo));
  }, []);
 
  // useEffect(() => {
  //   dispatch(getFilterLeaveList({
  //     setInProgress,
  //     currentPage,
  //     itemsPerPage,
  //     setStatusPageCounts,
  //     userInfo,
  //   }));
  // }, [dispatch,currentPage,itemsPerPage]);
 
 
 
 
 
  const handleDecrease = () => {
    if (currentTab === "daily") {
      const Decrement = startDate;
      Decrement.setDate(Decrement.getDate() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }
 
    // if (currentTab === "weekly") {
    //   var curr = weekDate;
    //   var result = getWeekNumber(curr);
 
    //   var first = getDateOfWeek(result[1] - 1, result[0]).getDate(); // get first day of the week
 
    //   var firstdayD = new Date(curr.setDate(first));
 
    //   console.log("week decrement", {
    //     dN: getDateOfWeek(result[1] - 1, result[0]).getDate(),
    //     d: getDateOfWeek(result[1] - 1, result[0]),
    //     result,
    //     weekDate,
    //     firstdayD,
    //     reqW: result[1] - 1,
    //   });
    //   setWeekDate(firstdayD);
    //   console.log("testing")
    // }


    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() - 7);
      setWeekDate(newWeekDate);
      console.log("weekly_date",newWeekDate)

    }
    
 
    if (currentTab === "monthly") {
      const Decrement = startMonth;
      Decrement.setMonth(Decrement.getMonth() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format(
        "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
      );
      setStartMonth(new Date(op));
    }
  };
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
 
    if (!selectAll) {
      setSelectedRows(Array(leaveTransactionList.length).fill(true));
     
      // Log the IDs of all selected employees
      const selectedEmployeeIDs = leaveTransactionList.map((employee) => employee.id);
      console.log("Selected All Employee IDs: ", selectedEmployeeIDs);
    } else {
      setSelectedRows([]);
    }
  };
  const handleRowCheckboxChange = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
 
    if (newSelectedRows[index]) {
      console.log("Selected Employee ID: ", leaveTransactionList[index].id);
    }
  };
  const handleIncrease = () => {
    if (currentTab === "daily") {
      const Increment = startDate;
      Increment.setDate(Increment.getDate() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }
 
    // if (currentTab === "weekly") {
    //   var result = getWeekNumber(weekDate);
 
    //   var first = getDateOfWeek(result[1] + 1, result[0]).getDate();
 
    //   var firstdayD = new Date(weekDate.setDate(first + 2));
    //   console.log("week Inc", {
    //     firstdayD,
    //     dN: getDateOfWeek(result[1] + 1, result[0]).getDate(),
    //     d: getDateOfWeek(result[1] + 1, result[0]),
    //     result,
    //     reqW: result[1] + 1,
    //     weekDate,
    //   });
    //   setWeekDate(firstdayD);
    // }


    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() + 7);
      setWeekDate(newWeekDate);
    }
    

    
 
    if (currentTab === "monthly") {
      const Increment = startMonth;
      Increment.setMonth(Increment.getMonth() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format(
        "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
      );
      setStartMonth(new Date(op));
    }
  };
  const handleSelectChange = (value) => {
    setSelectedReason(value);
  };
  const handleSelectStatus = (e) => {
    setLeaveStatus(e.target.value);
  };
  const callLeaveList = useCallback(() => {
    return dispatch(
      getLeaveList({
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
      })
    );
  },[dispatch, currentPage, itemsPerPage]);
 
  function callFilteredLeaveList() {
    console.log(startMonth_status,"status");
    dispatch(
      getFilterLeaveList_paginated({
        setInProgress,
  currentPage,
  itemsPerPage,
  setStatusPageCounts,
  userInfo,
  startMonth_status,
 
      })
    );
  }
 
 
  const callAllotmentList = useCallback(() => {
    return dispatch(
      getAllotmentList({
        setInProgress,
        currentPage,
        itemsPerPage,
        setLeavePageCounts,
      })
    );
  },[dispatch, currentPage, itemsPerPage]);
 
  const callLeaveUserList = useCallback(() => {
    return dispatch(
      getAllLeavesofUser(
        userInfo)
    );
  },[dispatch]);
 
  function Check_Weekend(leaveStartDate, leaveEndDate) {
    // Convert the start and end dates to moment objects
    let startDate = moment(leaveStartDate);
    let endDate = moment(leaveEndDate);
    
    // Initialize counters for Saturdays and Sundays
    let saturdays = 0;
    let sundays = 0;
    
    // Loop through each day between start and end dates
    let currentDate = startDate.clone(); // Clone the start date to avoid mutating it
    while (currentDate.isSameOrBefore(endDate)) {
        // Check if the current day is Saturday or Sunday
        if (currentDate.day() === 6) {
            saturdays++;
        } else if (currentDate.day() === 0) {
            sundays++;
        }
        // Move to the next day
        currentDate.add(1, 'day');
    }

    // Log the results
    console.log("Start Date:", leaveStartDate);
    console.log("End Date:", leaveEndDate);
    console.log("Number_of_Saturdays:", saturdays);
    console.log("Number_of_Sundays:", sundays);
    let total_weekend_count=sundays+saturdays;
    return total_weekend_count;
}

 
 
 
  const cancleModal = () => {
    setModalOpen(false);
    setEditLeaveID(null);
  };
  const openModal=()=>{
    setModalOpen(true);
    setSelectedReason("select reason");
    setText("");
    setStartDate(new Date());
    setEndDate(new Date());
    setedituserLeaveID(null);
    setCurrentTab("daily");
    setHALFDAYSELECTION([]);
  }
  const handleSubmit = (leave_balance) => {
    let data;
    let leavesLength;
    let leaveLengthInDays;
    const containsOnlySpaces = text.trim() == '';
    if(containsOnlySpaces){
      toast.warn("Please enter some valid comment");
      return  0;
    }
    console.log("is_blank",containsOnlySpaces);
    console.log("text",text);
 
    // let endDate = new Date();
    switch (currentTab) {
      case "daily":
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date:
          moment(startDate).format("YYYY-MM-DD") + "T00:00:00Z",
           
          leave_end_date:
            moment(startDate).format("YYYY-MM-DD") + "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "weekly":
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date:
            moment(startWeekDay).format("YYYY-MM-DD") +
            "T00:00:00Z",
          leave_end_date:
            moment(endWeekDay).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "dateRange":
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date:
            moment(dateRangeStart).format("YYYY-MM-DD") +
            "T00:00:00Z",
          leave_end_date:
            moment(dateRangeEnd).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      default: {
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date: startDate,
          leave_end_date: endDate,
        };
      }
    }
    data.half_day_leaves=halfday_json;
    
    if (!edituserLeaveID) {
      data.user = userInfo?.id;
    }
    setIsupdated(true);
      let halfday_count=(halfday_json.length)/2;
      console.log("halfday_count_123",halfday_count);
      console.log("startdate",data.leave_start_date);
      console.log("end_date",data.leave_end_date);
      const leaveStartDate = moment(data.leave_start_date).format("DD");      
      const leaveEndDate = moment(data.leave_end_date).format("DD");
      console.log("LeaveEndDate",leaveEndDate);
      leaveLengthInDays = ((leaveEndDate-leaveStartDate)-halfday_count)+1;
      console.log("leaveLeangth",leaveLengthInDays);
    let weekend_count=Check_Weekend(data.leave_start_date,data.leave_end_date);
    
    console.log("LeaveEndDate",leaveEndDate);
    leaveLengthInDays = leaveLengthInDays - weekend_count;

    // Ensure leaveLengthInDays is not negative
    leaveLengthInDays = Math.max(leaveLengthInDays, 0);   
     data.leave_count=leaveLengthInDays;
    console.log("_data",data);
    if(leave_balance<data.leave_count)
    {
    data.unpaid_leave_count=data.leave_count-leave_balance;
    }

    // if((4 * currentMonth - approvedLeave_count)<=appliedLeaves.length){
    //   let halfday_count=(halfday_json.length)/2;

    //   const leaveStartDate = new Date(data.leave_start_date);
    //   const leaveEndDate = new Date(data.leave_end_date);

    //   leaveLengthInDays = Math.ceil((leaveEndDate - leaveStartDate) / (1000 * 60 * 60 * 24))-halfday_count;
    //   data.unpaid_leave_count=leaveLengthInDays
    // }



    if (edituserLeaveID) {
      dispatch(UpdateUserLeave(data, edituserLeaveID, cancleModal, callFilteredLeaveList,callLeaveUserList,setiseditoradd));
    } else {
      dispatch(applyLeave(data, cancleModal, callFilteredLeaveList,leaveUserList,callLeaveUserList,setHALFDAYSELECTION, isSubmitting, setIsSubmitting,setiseditoradd));
    }
  };
 
 
  const handleUpdateLeave = () => {
  let payload ={
      "approved_by": userInfo.id,
      "status": leaveStatus,
      "manager_comment": managerComment,
  }
 
  dispatch(updayeLeave(payload, editData.id, setEditLeaveModal, callLeaveList,setLeaveStatus,setManagerComment,))
  console.log("payload",payload)
  }
 
 
    const handleDeleteLeave = (ID) => {
     console.log(ID);
      dispatch(deleteLeaveAllotment(ID,callAllotmentList));
    };
 
    const handleDelete_Status =(ID)=>{
      console.log(ID,"ID TO BE DELETED");
      dispatch(deleteLeave_Status(ID,callFilteredLeaveList,callLeaveUserList));
     
    }
 
 
 
 
  const handleLeaveSubmit = () => {
  let payload ={
      "category_name": employeeType,
      "monthly_allotted_leaves": leaveAlloted,
     
  }
  if (editLeaveID) {
    dispatch(UpdateLeaveAllotment(payload,editLeaveID, cancleModal, callAllotmentList))
  } else {
    dispatch(AddTypeofEmployee(payload,  cancleModal, callAllotmentList))
  }
 
  }
 
  const handleTextareaChange = (event) => {
    setText(event.target.value);
  };
  const handleEmployeeChange = (event) => {
   
    setEmployeeType(event.target.value);
  };
  const handleLeaveAllotmentChange = (event) => {
    setLeaveAlloted(event.target.value);
  };
 
  const editEmployee = (value) => {
    console.log("editEmployeeid", value);
    setEditLeaveModal(true);
    setEditdata(value);
  };
  const edituserleave=(value)=>{
    console.log("clikced",value);
    
    // Convert the string dates to Date objects
    const startDate = new Date(value.leave_start_date);
    const endDate = new Date(value.leave_end_date);

    // Function to get weekday name
    const getWeekdayName = (date) => {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return daysOfWeek[date.getDay()];
    };

    // Get the weekday names
    const startWeekday_local = getWeekdayName(startDate);
    const endWeekday_local = getWeekdayName(endDate);

    console.log("Start date weekday:", startWeekday_local);
    console.log("End date weekday:", endWeekday_local);

    setHALFDAYSELECTION([]);
    console.log("halfday_child",(value.half_day_leaves));
    setuserleavedata(value);
    if (value.id) {
      setSelectedReason(value.leave_type);
      setText(value.comment);
      setedituserLeaveID(value.id);
      console.log("leavecount",value.leave_count);
      const leaveCount = parseFloat(value.leave_count);
      const dateArray = value.half_day_leaves.map(obj => obj.date);
      setHALFDAYSELECTION(dateArray);
 
if (!isNaN(leaveCount)) {
  if ((leaveCount === 1 || leaveCount==0)) {
    setCurrentTab("daily");
    setStartDate(new Date(value.leave_start_date));
  } else if (leaveCount===5 && startWeekday_local=="Monday" && endWeekday_local=="Sunday") {
    setCurrentTab("weekly");
    setWeekDate(new Date(value.leave_start_date));
   
  } else {
    setCurrentTab("dateRange");
    setDateRangeStart(new Date(value.leave_start_date));
    setDateRangeEnd(new Date(value.leave_end_date));
  }
}
 
      setModalOpen(true);
    }
  }
  const editLeaveAllotment = (value) => {
    if(value.id){
      setEmployeeType(value.category_name);
      setLeaveAlloted(value.monthly_allotted_leaves)
      setEditLeaveID(value.id);
      setModalOpen(true);
    }
  };
  const handleChange = (value) => {
    if (value.option == "dateRange") {
      setCurrentTab(value.option);
      setDateRangeStart(value.startDate);
      setDateRangeEnd(value.endDate);
    } else {
      setCurrentTab(value);
    }
  };
  const seeDescription = (value) => {
    console.log("seeEmployee", value);
    setDescriptionModal(true);
    setSelectedEmployee(value);
  };
 
  const handleEditReasonChange = (event) => {
    setManagerComment(event.target.value);
  };
 
  const handleExport = () => {
    // const selectedRowIndex = selectedRows.findIndex((selected) => selected);
 
    // if (selectedRowIndex !== -1) {
    //   const selectedUserId = leaveTransactionList[selectedRowIndex].user.id;
    //   const payload = {
    //     user: selectedUserId, // Use the selected user's ID
       
    //   };
   
     
      dispatch(ExportLeave(setExportState));
 
      // console.log("Exporting selected user with ID: ", selectedUserId);
    // } else {
    //   toast.warn("No user selected for export");
    //   console.log("No user selected for export.");
    // }
  }
  
   return {
    startDate,
    setStartDate,
    user,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    setStartWeekDay,
    endWeekDay,
    setEndWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    currentTab,
    setCurrentTab,
    onChangeDateRange,
    handleTabTypeChange,
    handleDecrease,
    handleIncrease,
    inProgress,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    currentPage,
    setCurrentPage,
    pageCounts,
    leavepageCounts,
    setPageCounts,
    leaveTransactionList,
    leaveAllotmentList,
    handleSelectChange,
    handleTextareaChange,
    selectedReason,
    setSelectedReason,
    text,
    setText,
    handleSubmit,
    editEmployee,
    editLeaveAllotment,
    seeDescription,
    options,
    leaveTypeList,
    modalOpen,
    setModalOpen,
    userInfo,
    editLeaveModal,
    setEditLeaveModal,
    descriptionModal,
    setDescriptionModal,
    editData,
    editLeaveID,
    leaveOptions,
    handleSelectStatus,
    leaveStatus,
    setLeaveStatus,
    managerComment,
    handleEditReasonChange,
    handleUpdateLeave,
    setSelectedEmployee,
    employeeType,
    selectedEmployee,
    handleEmployeeChange,
    leaveAlloted,
    handleLeaveAllotmentChange,
    handleDeleteLeave,
    selectAll,
    selectedRows,
    handleSelectAll,
    handleRowCheckboxChange,
    handleLeaveSubmit,
    openModal,
    leaveTransactionFilterList,
    statuspageCounts,
    handleExport,
    exportdata,
    csvref,
    exportState,
    edituserleave,
    edituserLeaveID,
    startMonth_status,
    setStartMonth_status,
    handleIncrease_status,
    handleDecrease_status,
    handleDelete_Status,
    setIsupdated,
    userPermissions,
    ModalCalender,
    setModalCalender,
    handleClick,
    currentMonth,
    approvedLeave_count,
    halfday_selection,
    setHALFDAYSELECTION,
    approved_leave_current_month,
    handleChange,
    toggle,
    setToggle,
    isSubmitting,
    setIsSubmitting,
    currentMonth_approvedleave,
    halfday,
    userleavedata,
    setuserleavedata,
    leave_balance_loader,
    leave_Available,
    prevTab,
    setprevTAB,
    iseditoradd,
    setiseditoradd,
 
  };
}
 