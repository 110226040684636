import { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  exportAllAttendance,
  getAttendance,
  getAttendanceWithPagination,
} from "../redux/Actions/reportAction";
import { getLeaveType } from "../redux/Actions/leaveAction";

export default function useEmployeeList() {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const { userInfo } = useSelector((store) => store.auth);
  const [user, setUser] = useState([userInfo.id]);
  const { attendanceForAllUsers, allempAlltendence } = useSelector(
    (store) => store.ReportR
  );
  const [weekDate, setWeekDate] = useState(new Date());
  const [startWeekDay, setStartWeekDay] = useState("");
  const [endWeekDay, setEndWeekDay] = useState("");
  const [startMonth, setStartMonth] = useState(new Date());
  const [dateRangeStart, setDateRangeStart] = useState();
  const [dateRangeEnd, setDateRangeEnd] = useState();
  const [exportState, setExportState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(1);
  const itemsPerPage = 10;
  const [totalValues, setTotalValues] = useState(0); 
  const [selectedProject, setSelectedProject] = useState([]);

  const [currentTab, setCurrentTab] = useState("daily");
  const[delay,setdelay]=useState(1);

  // calender icon click functionality start
  const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  // useEffect(() => {
  //   let data = JSON.parse(localStorage.getItem(localStorage_userData));
  //   setUserId(data.id);
  // }, []);
  useEffect(() => {
    dispatch(getLeaveType());
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setdelay(prevDelay => prevDelay + 1);
      console.log("DELAY_COUNTER", delay);
      setInProgress(true);
    }, 3000);
  
    return () => clearTimeout(timeoutId);
  
  }, [user]);
  
  
  function useOutsideAlerter(ref, calendarRef) {
    function handleClickOutside(event) {
      if (calendarIsOpen) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          calendarRef &&
          !calendarRef.current.contains(event.target)
        ) {
          setCalendarIsOpen(!calendarIsOpen);
        }
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef, calendarRef);
  // calender icon click functionality End

  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setDateRangeStart(start);
    setDateRangeEnd(end);
  };

  function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  function getDateOfWeek(weekNumber, year) {
    //Create a date object starting january first of chosen year, plus the number of days in a week multiplied by the week number to get the right date.
    return new Date(year, 0, 1 + (weekNumber - 1) * 7);
  }
  function getFirstDateOfWeeks(weekNumber, year) {
    // Get the first day of the year
    var firstDayOfYear = new Date(year, 0, 1);
    // Get the first Monday of the year
    var firstMondayOfYear = new Date(
      year,
      0,
      1 + ((8 - firstDayOfYear.getDay()) % 7)
    );

    // Get the date of the Monday of the requested week
    var dateOfFirstMondayOfWeek = new Date(
      firstMondayOfYear.getFullYear(),
      0,
      firstMondayOfYear.getDate() + (weekNumber - 1) * 7
    );

    // Return the date of the first Monday of the requested week
    return dateOfFirstMondayOfWeek;
  }
  function getLastDateOfWeek(weekNumber, year) {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const lastDayOfWeek = date.getDate() + 7;
    const lastDateOfWeek = new Date(date.setDate(lastDayOfWeek));
    return lastDateOfWeek;
  }

  useEffect(() => {
    var curr = new Date(weekDate); // get current date
    var result = getWeekNumber(weekDate); // get week no and year [year, weekno]
    var first_day = getFirstDateOfWeeks(result[1], result[0]);

    var last_day = getLastDateOfWeek(result[1], result[0]);
    last_day.setDate(last_day.getDate() - 1);

    console.log("week dates", {
      curr,
      weekDate,
      first_day,
      last_day,
      date: new Date(),
      result,
    });
    setStartWeekDay(first_day);
    setEndWeekDay(last_day);
  }, [weekDate]);

  const handleTabTypeChange = (e) => {
    const { value } = e.target;

    setCurrentTab(value);
  };

  const handleChange = (value) => {
    if (value.option == "dateRange") {
      setCurrentTab(value.option);
      setDateRangeStart(value.startDate);
      setDateRangeEnd(value.endDate);
    } else {
      setCurrentTab(value);
    }
  };

  useEffect(()=>{
    setInProgress(true);
  },[startDate,startWeekDay,startMonth,currentTab])

  useEffect(() => {
    const timeoutId = setTimeout(() => {

      let data;
      let endDate = new Date();
  
      switch (currentTab) {
        case "daily":
          data = {
            report_type: currentTab,
            start_date:
              moment(startDate).format("YYYY-MM-DD") +
              "T00:00:00" +
              moment().format("Z"),
            end_date:
              moment(endDate).format("YYYY-MM-DD") +
              "T23:59:59" +
              moment().format("Z"),
          };
          break;
        case "weekly":
          data = {
            report_type: currentTab,
            start_date:
              moment(startWeekDay).format("YYYY-MM-DD") +
              "T00:00:00" +
              moment().format("Z"),
            end_date:
              moment(endWeekDay).format("YYYY-MM-DD") +
              "T23:59:59" +
              moment().format("Z"),
          };
  
          break;
        case "monthly":
          data = {
            report_type: currentTab,
            start_date:
              moment(startMonth).format("YYYY-MM-DD") +
              "T00:00:00" +
              moment().format("Z"),
            end_date:
              moment(endDate).format("YYYY-MM-DD") +
              "T23:59:59" +
              moment().format("Z"),
          };
  
          break;
        case "dateRange":
          data = {
            report_type: currentTab,
            start_date:
              moment(dateRangeStart).format("YYYY-MM-DD") +
              "T00:00:00" +
              moment().format("Z"),
            end_date:
              moment(dateRangeEnd).format("YYYY-MM-DD") +
              "T23:59:59" +
              moment().format("Z"),
          };
          break;
        default: {
          data = {
            report_type: currentTab,
            start_date: startDate,
            end_date: endDate,
          };
        }
      }
      console.log(" report request ", data);
      //  dispatch(getAttendance(data, user, setInProgress));

      dispatch(
        getAttendance(
          data,
          user,
          setInProgress,
          setCurrentPage,
          itemsPerPage,
          setPageCounts,
          setTotalValues
        )
      );

    }, 3000);
  
    return () => clearTimeout(timeoutId);
  
  }, [
    dispatch,
    startDate,
    currentTab,
    startWeekDay,
    endWeekDay,
    startMonth,
    dateRangeStart,
    dateRangeEnd,
    delay,
  ]);


  const getData = () => {
    let data;
    let endDate = new Date();

    switch (currentTab) {
      case "daily":
        data = {
          report_type: currentTab,
          start_date:
            moment(startDate).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(endDate).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "weekly":
        data = {
          report_type: currentTab,
          start_date:
            moment(startWeekDay).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(endWeekDay).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "monthly":
        data = {
          report_type: currentTab,
          start_date:
            moment(startMonth).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(endDate).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "dateRange":
        data = {
          report_type: currentTab,
          start_date:
            moment(dateRangeStart).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(dateRangeEnd).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }
    }

    return data;
  };

  useEffect(() => {
    const data = getData();
    console.log(" report request ", data);

    //  dispatch(getAttendance(data, user, setInProgress));
    dispatch(
      getAttendanceWithPagination(
        data,
        user,
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
        setTotalValues
      )
    );
  }, [currentPage]);

  const callAttendanceList = useCallback(() => {
    const data = getData();

    return dispatch(
      getAttendanceWithPagination(
        data,
        user,
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
        setTotalValues
      )
    );
  }, [
    dispatch,
    startDate,
    currentPage,
    itemsPerPage,
    setPageCounts,
    setTotalValues,
  ]);

  const handleDecrease = () => {
    if (currentTab === "daily") {
      const Decrement = startDate;
      Decrement.setDate(Decrement.getDate() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }

    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() - 7);
      setWeekDate(newWeekDate);
    }

    if (currentTab === "monthly") {
      const Decrement = startMonth;
      Decrement.setMonth(Decrement.getMonth() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format(
        "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
      );
      setStartMonth(new Date(op));
    }
  };

  const handleIncrease = () => {
    if (currentTab === "daily") {
      const Increment = startDate;
      Increment.setDate(Increment.getDate() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }

    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() + 7);
      setWeekDate(newWeekDate);
    }

    if (currentTab === "monthly") {
      const Increment = startMonth;
      Increment.setMonth(Increment.getMonth() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format(
        "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
      );
      setStartMonth(new Date(op));
    }
  };

  const handleExportAll = () => {
    if (currentTab === "daily") {
      const req = {
        user_id: userInfo.id,
        users: user,
        report_type: currentTab,
        start_date: moment(startDate).format(),
        end_date: moment(startDate).format(),
      };
      exportAllAttendance(req, setExportState);
    }

    if (currentTab === "weekly") {
      const req = {
        user_id: userInfo.id,
        users: user,
        report_type: currentTab,
        start_date: moment(startWeekDay).format(),
        end_date: moment(endWeekDay).format(),
      };
      exportAllAttendance(req, setExportState);
    }

    if (currentTab === "monthly") {
      const req = {
        user_id: userInfo.id,
        users: user,
        report_type: currentTab,
        start_date: moment(startMonth).format(),
        end_date: moment(startMonth).format(),
      };
      exportAllAttendance(req, setExportState);
    }
    if (currentTab === "dateRange") {
      const req = {
        user_id: userInfo.id,
        users: user,
        report_type: currentTab,
        start_date:
          moment(dateRangeStart).format("YYYY-MM-DD") +
          "T00:00:00" +
          moment().format("Z"),
        end_date:
          moment(dateRangeEnd).format("YYYY-MM-DD") +
          "T23:59:59.999" +
          moment().format("Z"),
      };
      exportAllAttendance(req, setExportState);
    }
    // const req = {
    //   user_id: userInfo.id,
    //   report_type: currentTab,
    //   start_date: moment(startMonth).format(),
    //   end_date: moment(startMonth).format(),
    // };
    // exportAllAttendance(req, setExportState);
  };

  return {
    startDate,
    setStartDate,
    user,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    setStartWeekDay,
    endWeekDay,
    setEndWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    currentTab,
    setCurrentTab,
    onChangeDateRange,
    handleTabTypeChange,
    handleDecrease,
    handleIncrease,
    // attendanceForAllUsers,
    inProgress,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    handleExportAll,
    exportState,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    allempAlltendence,
    selectedProject,
    setSelectedProject,
    callAttendanceList,
    handleChange,
  };
}
