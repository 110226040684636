import {
  EMPLOYEE_LIST,
  ALL_EMPLOYEES,
  EMPLOYEE_DETAILS,
  TIMELOG_HOURS,
} from "../Actions/employeesAction";

const initialState = {
  employeesList: [],
  allEmployees: [],
  employeeDetails: {},
  timelog: {},
};

const employeeReduser = (store = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_LIST: {
      return {
        ...store,
        employeesList: action.payload,
      };
    }
    case ALL_EMPLOYEES: {
      return {
        ...store,
        allEmployees: action.payload,
      };
    }
    case EMPLOYEE_DETAILS: {
      return {
        ...store,
        employeeDetails: action.payload,
      };
    }
    case TIMELOG_HOURS: {
      return {
        ...store,
        timelog: action.payload,
      };
    }
    default: {
      return { ...store };
    }
  }
};

export default employeeReduser;
