import { GET_ROLE_LIST,GET_PERMISSION_LIST } from "../Actions/roleAction";
  
  const initialState = {
    AllRoleList: [],
    AllPermissionList:[]
  };
  
  const roleReduser = (store = initialState, action) =>{
    switch (action.type) {
      case GET_ROLE_LIST: {
        return {
          ...store,
          AllRoleList: action.payload,
        };
      }
    
      case GET_PERMISSION_LIST: {
        return {
          ...store,
          AllPermissionList: action.payload,
        };
      }
      default: {
        return { ...store };
      }
    }
  };
  
export default roleReduser;
  