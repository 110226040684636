import {
  PROJECT_LIST,
  PROJECT_DETAILS,
} from "../Actions/resourceManagementActions";

const initialState = {
  projectsList: [],
  projectDetails: {},
};

const resourceManagementActions = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_LIST: {
      return {
        ...state,
        projectsList: action.payload,
      };
    }
    case PROJECT_DETAILS: {
      return {
        ...state,
        projectDetails: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export default resourceManagementActions;
