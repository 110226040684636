import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";

export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_PERMISSION_LIST = "GET_PERMISSION_LIST";

export const getRoleList = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
}) => {
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    Axios_v2.get(
      `/customrole/?items_per_page=${10}&page=${currentPage}&ordering=${`name`}&rand=${rand}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          console.log("GET_ROLE_LIST", res.data);
          dispatch({ type: GET_ROLE_LIST, payload: res.data.results });
          setPageCounts(res.data.total_pages);
          setInProgress(false);
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};
export const getAllPermissionList = ({ itemsonPage, setInProgress }) => {
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    Axios_v2.get(`permissions/list_all_permissions/?items_per_page=${itemsonPage}`,HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          console.log("GET_PERMISSION_LIST API call successful", res.data);
          dispatch({ type: GET_PERMISSION_LIST, payload: res.data.results });
          setInProgress(false);
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        console.error("GET_PERMISSION_LIST API call error", err);
        toast.error(err);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};




export const addRole = (
  data, setModalOpen, callRoleList, emptyState
) => {
  return async (dispatch) => {
    Axios_v2.post("/customrole/", data, HeaderToken())
      .then((res) => {
        setModalOpen(false)
        callRoleList();
        toast.success("Role Created");
        emptyState();
      })
      .catch((err) => {
        if (err.response && err.response.status >= 500) {
          toast.warn("Internal Server Error");
        } else if (err.response && err.response.data) {
          Object.keys(err.response.data).forEach((msg) =>
            toast.warn(`${msg} ${err.response.data[msg][0]}`)
          );
        } else {
          // Handle other errors or situations as needed
        }
      });
  };
};
export const editRole = (
  data,editId,setModalOpen,callRoleList,emptyState
) => {
  return async (dispatch) => {
    Axios_v2.put(`/customrole/${editId}/`, data, HeaderToken())
      .then((res) => {
        callRoleList();
        toast.success("Updated Successfully");
        setModalOpen(false)
        emptyState();
      })
      .catch((err) => {
        if (err.response && err.response.status >= 500) {
          toast.warn("This Role Can't be changed");
        } else if (err.response && err.response.data) {
          Object.keys(err.response.data).forEach((msg) =>
            toast.warn(`${msg} ${err.response.data[msg][0]}`)
          );
        } else {
          // Handle other errors or situations as needed
        }
      });
      
  }
  
};

export const updatePermission = (id, data, callRoleList, setShowPermissionModal, setSelectedPermissions) => {
  return async (dispatch) => {
    Axios_v2.patch(`/customrole/${id}/`, data, HeaderToken())
      .then((res) => {
        setShowPermissionModal(false)
        toast.success("Successfully Permission updated");
        callRoleList();
        setSelectedPermissions([])
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );
      });
  };
};


export const deleteRole = (id, callRoleList) => {
  return async (dispatch) => {
    Axios_v2.delete(`/customrole/${id}/`, HeaderToken())
      .then((res) => {
        callRoleList()
        toast.success("Successfully Deleted");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};








