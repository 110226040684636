import React, { useState ,useRef,useEffect} from "react";
import { RenderPageButtons } from "../../../../Utils/randerPageButtons";
import { download_pdf } from "../../../../redux/Actions/payrollAction";
import { useDispatch } from "react-redux";
import "./table.css";
import Pagination from "../../Pagination/pagination";




const EmpPayrollTable = ({ 
  document,
   list,
   hookProps, 
   }) => {
  const {
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    isLoading,

  } = hookProps;
  const pages = [];

 
 for (let i = 1; i <= pageCounts; i++) {
      pages.push(i);
    }


  const renderPageButtons = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );
  const dispatch=useDispatch();
  
  
 
 
 
  

 
  

  


 

  return (
    <div style={{ border: "0px solid #000" }}>
      <table className="table">
        <thead>
          <tr>
            
            <th scope="col" style={{ textAlign: "left", paddingLeft: "15px" }}>
              Name
            </th>
            <th scope="col" style={{ textAlign: "left" }}>
              Email
            </th>
            <th scope="col" style={{ textAlign: "left" }}>
              Document
            </th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
  {isLoading ? (
    <tr className="table-Rows-loading">
      <td colSpan="4">Loading data...</td>
    </tr>
  ) : (
    list.map((emp, index) => (
      <tr className="table-Rows" key={index}>
        <td>
          <div className="emp-name">
            <img src="/images/icons/prof.png" alt="profile" />
            {emp.name}
          </div>
        </td>
        <td>
          <div className="emp-email">{emp.email}</div>
        </td>
        <td>
          <div className="emp-document">{`${emp.name}_${document}`}</div>
        </td>
        <td>
          <button className="send-btn" onClick={() => dispatch(download_pdf(emp.id, emp.name, emp.type))}>
            <img src="/images/icons/export.png" alt="resend" />
          </button>
        </td>
      </tr>
    ))
  )}
</tbody>
      </table>
      <div className="projects-table-footer">

      {
      pageCounts>1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )
    }



    {/* <div className="table-pagination">
      <button
        className="prev-scr"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === pages[0] ? true : false}
      >
        {"PREV"}
      </button>
      
      {renderPageButtons}

      <button
        className="next-scr"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === pages[pages.length - 1] ? true : false}
      >
        {"NEXT"}
      </button>
    </div> */}
  </div>
    </div>
    
  );
};

export default EmpPayrollTable;
