import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  ProjectsLIstPagination,
  deleteProject,
  restoreProject,
  getTotalCount,
  getProjectDetails,
  addProjectTask,
  addNewproject,
  updateProject,
  UpdateTask,
  DeleteTask,
} from "../redux/Actions/resourceManagementActions";
import { getEmployeeList } from "../redux/Actions/employeesAction";

export default function useProjectAndTask(
  projectId,
  setModalOpen,
  setProjectId
) {
  const dispatch = useDispatch();

  //for project table
  const [tableType, setTableType] = useState("active"); // "active" or "archived"
  const [searchValue, setSearchValue] = useState("");
  const { projectsList } = useSelector((store) => store.RM);
  const [inProgress, setInProgress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [pageCounts, setPageCounts] = useState(1);
  const [totalValues, setTotalValues] = useState(0);

  const [TotalCount_Active, setTotalCount_Active] = useState(0);
  const [TotalCount_Archived, setTotalCount_Archived] = useState(0);

  let isActive = tableType === "active";

  // for project  edit or create
  const { projectDetails } = useSelector((store) => store.RM);
  const { employeesList } = useSelector((store) => store.ER);
  const { userInfo } = useSelector((store) => store.auth);
  const [projectName, setProjectName] = useState("");
  const [taskName, setTaskName] = useState("");
  const [oldTasks, setOldTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([userInfo.id]);
  const [searchEmployee, setSearchEmployee] = useState("");

  const [projectErrors, setProjectErrors] = useState({});
  const [taskErrors, setTaksErrors] = useState({});

  //get employees List from api
  useEffect(() => {
    dispatch(getEmployeeList(searchEmployee));
  }, [dispatch, searchEmployee]);

  // get project details form api
  useEffect(() => {
    if (projectId !== null) {
      dispatch(getProjectDetails(projectId));
    }
  }, [dispatch, projectId]);

  useEffect(() => {
    if (projectDetails.id) {
      console.log("projectDetails", projectDetails);
      setProjectName(projectDetails.name);
      setTasks(projectDetails.tasks);
      setOldTasks(projectDetails.tasks.map((task) => task.id));
      setUsers(projectDetails.assigned_to_users?.map((user) => user.id));
    }
  }, [projectDetails]);

  const handleProjectSubmit = () => {
    let error = {};

    if (projectName.length < 1) {
      error["name"] = "Name Required";
    }

    if (!error.name) {
      const req = {
        name: projectName,
        assigned_to_users: users,
        tasks: tasks.map((task) => task.id),
      };
      if (projectId !== null) {
        dispatch(updateProject(req, projectId, handleCancle, callProjectsList));
      } else {
        dispatch(addNewproject(req, handleCancle, callProjectsList));
      }
      setProjectErrors({});
    } else {
      setProjectErrors(error);
    }
  };

  const addTask = () => {
    let error = {};

    if (taskName.length < 1) {
      error["name"] = "Task name required";
    }

    if (!error.name) {
      dispatch(addProjectTask(taskName, setTasks));
      setTaskName("");
      setTaksErrors({});
    } else {
      setTaksErrors(error);
    }
  };

  const editTask = (value, ID) => {
    dispatch(UpdateTask(value, ID, tasks, setTasks));
    // let newTasksArray = [...tasks];
    // const Index = tasks.findIndex((task) => task.id === ID);
    // newTasksArray.splice(Index, 1, { id: ID, name: value });
    // setTasks(newTasksArray);
  };

  const handleCancle = () => {
    setProjectName("");
    setTasks([]);
    setUsers([]);
    setProjectErrors({});
    setTaksErrors({});
    setModalOpen(false);
    setProjectId(null);
  };

  const selectEmployee = (ID) => {
    if (users.includes(ID)) {
      setUsers((prevValue) => prevValue.filter((id) => id !== ID));
    } else {
      setUsers((prevValue) => [...prevValue, ID]);
    }
  };

  const selectAll = () => {
    // If all items are already selected, clear the selection
    if (users.length === employeesList.length) {
      setUsers([]);
    } else {
      // Otherwise, set all items as selected by extracting their IDs
      const updatedCheckboxes = employeesList.map((checkbox) => checkbox.id);
      setUsers(updatedCheckboxes);
    }
  };

  const TabChange = () => {
    setCurrentPage(1);
    setTableType((prevValue) =>
      prevValue === "active" ? "archived" : "active"
    );
  };

  const getTotalProgectTypeCount = useCallback(() => {
    dispatch(getTotalCount(setTotalCount_Active, true));
    dispatch(getTotalCount(setTotalCount_Archived, false));
  }, [dispatch]);

  const callProjectsList = useCallback(() => {
    return dispatch(
      ProjectsLIstPagination(
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
        setTotalValues,
        isActive
      )
    );
  }, [dispatch, currentPage, itemsPerPage, isActive]);

  useEffect(() => {
    callProjectsList();
    getTotalProgectTypeCount();
  }, [callProjectsList, getTotalProgectTypeCount]);

  useEffect(() => {
    if (searchValue.length > 0) {
      dispatch(
        ProjectsLIstPagination(
          setInProgress,
          1,
          itemsPerPage,
          setPageCounts,
          setTotalValues,
          isActive,
          searchValue
        )
      );
    } else {
      dispatch(
        ProjectsLIstPagination(
          setInProgress,
          currentPage,
          itemsPerPage,
          setPageCounts,
          setTotalValues,
          isActive
        )
      );
    }
  }, [dispatch, searchValue, currentPage, isActive]);

  const refreshTable = () => {
    getTotalProgectTypeCount();
    if (
      currentPage === pageCounts &&
      projectsList.length === 1 &&
      currentPage !== 1
    ) {
      setCurrentPage((prevValue) => (prevValue === 1 ? 1 : prevValue - 1));
    } else {
      return dispatch(
        ProjectsLIstPagination(
          setInProgress,
          currentPage,
          itemsPerPage,
          setPageCounts,
          setTotalValues,
          isActive
        )
      );
    }
  };

  const ArchivedProject = (ID) => {
    dispatch(deleteProject(ID, refreshTable));
  };

  const RestoreProject = (ID) => {
    dispatch(restoreProject(ID, refreshTable));
  };

  const deleteTask = (ID) => {
    dispatch(DeleteTask(ID, tasks, setTasks));
  };

  return {
    searchValue,
    setSearchValue,
    projectsList,
    inProgress,
    currentPage,
    setCurrentPage,
    pageCounts,
    totalValues,
    tableType,
    setTableType,
    refreshTable,
    TabChange,
    ArchivedProject,
    RestoreProject,
    TotalCount_Archived,
    TotalCount_Active,
    callProjectsList,
    projectDetails,
    projectName,
    setProjectName,
    taskName,
    setTaskName,
    tasks,
    setTasks,
    users,
    setUsers,
    handleProjectSubmit,
    addTask,
    editTask,
    handleCancle,
    selectEmployee,
    employeesList,
    searchEmployee,
    setSearchEmployee,
    projectErrors,
    taskErrors,
    deleteTask,
    oldTasks,
    selectAll
  };
}
