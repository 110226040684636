import { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { applyLeave,deleteLeaveAllotment, getLeaveList,getFilterLeaveList, updayeLeave ,getAllotmentList,AddTypeofEmployee,UpdateLeaveAllotment,getLeaveType,UpdateUserLeave,ExportLeave, getSortedLeaveList_paginated, getSortedLeaveList} from "../redux/Actions/leaveAction";
import { toast } from "react-toastify";
import useLeaveStatus from "./useLeaveStatus";
import { getFilterLeaveList_paginated,getLeaveList_paginated } from "../redux/Actions/leaveAction";
import { getSubordinates } from "../redux/Actions/authAction";
// const{callFilteredLeaveList}=useLeaveStatus


const leaveOptions = [
  "Rejected",
  "Approved",
];

export default function useLeaveManagement() {
  const csvref=useRef(null);
  const[userleavedata,setuserleavedata]=useState([]);
  const dispatch = useDispatch();
  const[exportdata,setExportData]=useState([])
  const [inProgress, setInProgress] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [user, setUser] = useState([]);
  const [weekDate, setWeekDate] = useState(new Date());
  const [startWeekDay, setStartWeekDay] = useState("");
  const [endWeekDay, setEndWeekDay] = useState("");
  const [startMonth, setStartMonth] = useState(new Date());
  const [dateRangeStart, setDateRangeStart] = useState();
  const [dateRangeEnd, setDateRangeEnd] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(1);
  const [leavepages, setTotalpages] = useState(1);
  const [leavepageCounts, setLeavePageCounts] = useState(1);
  const [statuspageCounts, setStatusPageCounts] = useState(1);
  const [startMonth_status,setStartMonth_status]= useState(new Date());
  const itemsPerPage = 10;
  const [modalOpen, setModalOpen] = useState(false);
  const [editLeaveModal, setEditLeaveModal] = useState(false); 
  const { userInfo } = useSelector((store) => store.auth);
const { leaveTransactionList, leaveAllotmentList,leaveTypeList,leaveTransactionFilterList} = useSelector((store) => store.leave);
  const [text, setText] = useState("");
  const [selectedReason, setSelectedReason] = useState("select reason");
  const [managerComment, setManagerComment] = useState("");
  const [leaveStatus, setLeaveStatus] = useState("Select Status");
  const [editData,setEditdata] = useState(null)
  const [exportState, setExportState] = useState(false);
  const [editLeaveID,setEditLeaveID] = useState(null);
  const [edituserLeaveID,setedituserLeaveID] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [descriptionModal, setDescriptionModal] = useState(false); 
  const [employeeType, setEmployeeType] = useState(""); 
  const [leaveAlloted, setLeaveAlloted] = useState(""); 
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTab, setCurrentTab] = useState("monthly");
  const[delay,setdelay]=useState(1);
  const [isEscalted, setIsEscalted] = useState(false);
  const [activeTab, setActiveTab] = useState("Leave request");
  const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const { userPermissions } = useSelector((store) => store.auth);


  const options =leaveTypeList.map(item => item.reason);
  useEffect(() => {
    setInProgress(true);

    const timeoutId = setTimeout(() => {
      setdelay(prevDelay => prevDelay + 1);
      console.log("DELAY_COUNTER", delay);
    }, 3000); return () => clearTimeout(timeoutId);
  
  }, [user]);
  
  const TabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "Escalated Leave request") {
      setIsEscalted(true); // Toggle isEscalted to true when changing to Escalated Leave Request tab
    } else {
      setIsEscalted(false); // Toggle isEscalted to false for other tabs
    }
  };

  function useOutsideAlerter(ref, calendarRef) {
    function handleClickOutside(event) {
      if (calendarIsOpen) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          calendarRef &&
          !calendarRef.current.contains(event.target)
        ) {
          setCalendarIsOpen(!calendarIsOpen);
        }
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef, calendarRef);
  // calender icon click functionality End

  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setDateRangeStart(start);
    setDateRangeEnd(end);
  };

  function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  function getDateOfWeek(weekNumber, year) {
    //Create a date object starting january first of chosen year, plus the number of days in a week multiplied by the week number to get the right date.
    return new Date(year, 0, 1 + (weekNumber - 1) * 7);
  }
  function getFirstDateOfWeeks(weekNumber, year) {
    // Get the first day of the year
    var firstDayOfYear = new Date(year, 0, 1);
    // Get the first Monday of the year
    var firstMondayOfYear = new Date(
      year,
      0,
      1 + ((8 - firstDayOfYear.getDay()) % 7)
    );

    // Get the date of the Monday of the requested week
    var dateOfFirstMondayOfWeek = new Date(
      firstMondayOfYear.getFullYear(),
      0,
      firstMondayOfYear.getDate() + (weekNumber - 1) * 7
    );

    // Return the date of the first Monday of the requested week
    return dateOfFirstMondayOfWeek;
  }
  function getLastDateOfWeek(weekNumber, year) {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const lastDayOfWeek = date.getDate() + 7;
    const lastDateOfWeek = new Date(date.setDate(lastDayOfWeek));
    return lastDateOfWeek;
  }


  const handleExport = (startMoth,selectedUsers = []) => {
   
    let data;
    let endDate = new Date();
  
    switch (currentTab) {
      case "daily":
        data = {
          report_type: currentTab,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(startDate).format("YYYY-MM-DD"),
        };
        break;
      case "weekly":
        data = {
          report_type: currentTab,
          start_date: moment(startWeekDay).format("YYYY-MM-DD"),
          end_date: moment(endWeekDay).format("YYYY-MM-DD"),
        };
        break;
      case "monthly":
        const lastDayOfMonth = moment(startMonth_status).endOf('month').format('YYYY-MM-DD');
        const firstDayOfMonth = moment(startMonth_status).startOf('month').format('YYYY-MM-DD');
        data = {
          report_type: currentTab,
          start_date: firstDayOfMonth,
          end_date: lastDayOfMonth,
        };
        break;
      case "dateRange":
        data = {
          report_type: currentTab,
          start_date: moment(dateRangeStart).format("YYYY-MM-DD"),
          end_date: moment(dateRangeEnd).format("YYYY-MM-DD"),
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }
    }

    dispatch(ExportLeave(setExportState,data,selectedUsers));

  
  
  };

  useEffect(() => {
    var curr = new Date(weekDate); // get current date
    var result = getWeekNumber(weekDate); // get week no and year [year, weekno]
    var first_day = getFirstDateOfWeeks(result[1], result[0]);
    var last_day = getLastDateOfWeek(result[1], result[0]);
    last_day.setDate(last_day.getDate() - 1);

    console.log("week dates", {
      curr,
      weekDate,
      first_day,
      last_day,
      date: new Date(),
      result,
    });
    setStartWeekDay(first_day);
    setEndWeekDay(last_day);
  }, [weekDate]);

  const handleTabTypeChange = (e) => {
    const { value } = e.target;

    setCurrentTab(value);
  };
  const handleDecrease_status = () => {
    setPageCounts(1);
   
    if (currentTab === "daily") {
      const Decrement = startDate;
      Decrement.setDate(Decrement.getDate() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }
 
    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() - 7);
      setWeekDate(newWeekDate);
    }
 
    if (currentTab === "monthly") {
      const Decrement = startMonth_status;
      Decrement.setMonth(Decrement.getMonth() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]");
      setStartMonth_status(new Date(op));
    }
};
const handleIncrease_status = () => {
   setPageCounts(1);
  if (currentTab === "daily") {
    const Increment = startDate;
    Increment.setDate(Increment.getDate() + 1);
    const output = Increment.toLocaleString();
    const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
    setStartDate(new Date(op));
  }
 
  if (currentTab === "weekly") {
    const newWeekDate = new Date(weekDate);
    newWeekDate.setDate(newWeekDate.getDate() + 7);
    setWeekDate(newWeekDate);
  }
 
  if (currentTab === "monthly") {
    const Increment = startMonth_status;
    Increment.setMonth(Increment.getMonth() + 1);
    const output = Increment.toLocaleString();
    const op = moment(output).format(
      "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
    );
    setStartMonth_status(new Date(op));
  }
 
};
  useEffect(() => {
      const timeoutId = setTimeout(() => {
      const canViewLeaves =
      userPermissions.includes("Can view leaves requested by all users") ||
      userPermissions.includes("View leaves requested by assigned users") ||
      userPermissions.includes("view own leaves");
      let data;
      let endDate = new Date();
   
      switch (currentTab) {
        case "daily":
          data = {
            report_type: currentTab,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(startDate).format("YYYY-MM-DD")  ,
          };
          break;
        case "weekly":
          data = {
            report_type: currentTab,
            start_date: moment(startWeekDay).format("YYYY-MM-DD") ,
            end_date: moment(endWeekDay).format("YYYY-MM-DD"),
          };
          break;
        case "monthly":
          const lastDayOfMonth = moment(startMonth_status).endOf('month').format('YYYY-MM-DD');
          const firstDayOfMonth = moment(startMonth_status).startOf('month').format('YYYY-MM-DD');
          data = {
            report_type: currentTab,
            start_date: firstDayOfMonth ,          
            end_date: lastDayOfMonth  ,
            };
          break;
        case "dateRange":
          data = {
            report_type: currentTab,
            start_date: moment(dateRangeStart).format("YYYY-MM-DD"),
            end_date: moment(dateRangeEnd).format("YYYY-MM-DD") ,
          };
          break;
        default: {
          data = {
            report_type: currentTab,
            start_date: startDate,
            end_date: endDate,
          };
        }}
      if (canViewLeaves) {
        dispatch(
          getLeaveList({
            setInProgress,
            currentPage,
            itemsPerPage,
            setPageCounts,
            user,
            startMonth_status,
            setCurrentPage,
            setTotalpages,
            data,
            isEscalted,
            userInfo
          })
        );
        dispatch(
          getSortedLeaveList({
            setInProgress,
            currentPage,
            itemsPerPage,
            setPageCounts,
            user,
            startMonth_status,
            setCurrentPage,
            setTotalpages,
            data,
            isEscalted,
            userInfo
          })
        );
      }


      }, 3000);
  
    return () => clearTimeout(timeoutId);
  
  }, [startDate, currentTab, startMonth_status, delay, userPermissions,startWeekDay,endWeekDay,dateRangeStart,dateRangeEnd,currentTab,isEscalted]);

  useEffect(()=>{
    setInProgress(true);
  },[currentTab,startDate,startMonth_status,startWeekDay,dateRangeStart]);

  
  useEffect(() => {
    const timeoutId = setTimeout(() => {

    const canViewLeaves =
    userPermissions.includes("Can view leaves requested by all users") ||
    userPermissions.includes("View leaves requested by assigned users") ||
    userPermissions.includes("view own leaves");
    let data;
    let endDate = new Date();
 
    switch (currentTab) {
      case "daily":
        data = {
          report_type: currentTab,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(startDate).format("YYYY-MM-DD")  ,
        };
        break;
      case "weekly":
        data = {
          report_type: currentTab,
          start_date: moment(startWeekDay).format("YYYY-MM-DD") ,
          end_date: moment(endWeekDay).format("YYYY-MM-DD"),
        };
        break;
      case "monthly":
        const lastDayOfMonth = moment(startMonth_status).endOf('month').format('YYYY-MM-DD');
        const firstDayOfMonth = moment(startMonth_status).startOf('month').format('YYYY-MM-DD');
        data = {
          report_type: currentTab,
          start_date: firstDayOfMonth ,          
          end_date: lastDayOfMonth  ,
          };
        break;
      case "dateRange":
        data = {
          report_type: currentTab,
          start_date: moment(dateRangeStart).format("YYYY-MM-DD"),
          end_date: moment(dateRangeEnd).format("YYYY-MM-DD") ,
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }}
    if (canViewLeaves) {
      dispatch(
              getLeaveList_paginated ({
                setInProgress,
                currentPage,
                itemsPerPage,
                setPageCounts,
                user,
                startMonth_status,
                data,
                setTotalpages,
                isEscalted,
                userInfo
              })
            );
      dispatch(
              getSortedLeaveList_paginated ({
                setInProgress,
                currentPage,
                itemsPerPage,
                setPageCounts,
                user,
                startMonth_status,
                data,
                setTotalpages,
                isEscalted,
                userInfo
              })
            );
    }

        }, 3000);
        
        return () => clearTimeout(timeoutId);


          }, [ currentPage ,startMonth_status,isEscalted]);


  useEffect(()=>{
    setInProgress(true);
  },[startMonth_status]);
      
          
        
  
  
  // useEffect(() => {

  //   dispatch(
  //     getAllotmentList({
  //       setInProgress,
  //       currentPage,
  //       itemsPerPage,
  //       setLeavePageCounts
  //       // setPageCounts

  //     })
  //   );
  // }, [dispatch, startDate, currentTab,  currentPage,itemsPerPage]);

  useEffect(() => {
    dispatch(getLeaveType());
    dispatch(getSubordinates(userInfo.id));
  }, []);

  // useEffect(() => {
  //   dispatch(getFilterLeaveList({
  //     setInProgress,
  //     currentPage,
  //     itemsPerPage,
  //     setStatusPageCounts,
  //     userInfo,
  //   }));
  // }, [dispatch,currentPage,itemsPerPage]);

  



  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  
    if (!selectAll) {
      setSelectedRows(Array(leaveTransactionList.length).fill(true));
      
      // Log the IDs of all selected employees
      const selectedEmployeeIDs = leaveTransactionList.map((employee) => employee.id);
      console.log("Selected All Employee IDs: ", selectedEmployeeIDs);
    } else {
      setSelectedRows([]);
    }
  };
  const handleRowCheckboxChange = (index) => {
    const newSelectedRows = [...selectedRows];
    newSelectedRows[index] = !newSelectedRows[index];
    setSelectedRows(newSelectedRows);
  
    if (newSelectedRows[index]) {
      console.log("Selected Employee ID: ", leaveTransactionList[index].id);
    }
  };
  
  const handleSelectChange = (e) => {
    setSelectedReason(e.target.value);
  };
  const handleSelectStatus = (e) => {
    setLeaveStatus(e.target.value);
  };
  function callLeaveList() {
         console.log(startMonth, "321");
         dispatch(
           getLeaveList_paginated({
             setInProgress,
             currentPage,
             itemsPerPage,
             setPageCounts,
             user,
             startMonth_status,
             isEscalted,
             userInfo
           })
         );
       }
       

  const callFilteredLeaveList = useCallback(() => {
    return dispatch(
      getFilterLeaveList({
        setInProgress,
        currentPage,
        itemsPerPage,
        setStatusPageCounts,
        userInfo,
       userleavedata,
       isEscalted,
      })
    );
  },[dispatch, currentPage, itemsPerPage,isEscalted]);
  

  const callAllotmentList = useCallback(() => {
    return dispatch(
      getAllotmentList({
        setInProgress,
        currentPage,
        itemsPerPage,
        setLeavePageCounts,
      })
    );
  },[dispatch, currentPage, itemsPerPage]);

 



  const cancleModal = () => {
    setModalOpen(false);
    setEditLeaveID(null);
  };
  const openModal=()=>{
    setModalOpen(true);
    setSelectedReason("select reason");
    setText("");
    setStartDate(new Date());
    setEndDate(new Date());
    setedituserLeaveID(null);
    setCurrentTab("daily");
    


  }
  const handleSubmit = () => {
    let data;
    // let endDate = new Date();
   
    switch (currentTab) {
      case "daily":
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date:
          moment(startDate).format("YYYY-MM-DD") + "T00:00:00Z",
            
          leave_end_date:
            moment(startDate).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "weekly":
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date:
            moment(startWeekDay).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          leave_end_date:
            moment(endWeekDay).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "dateRange":
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date:
            moment(dateRangeStart).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          leave_end_date:
            moment(dateRangeEnd).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      default: {
        data = {
          leave_type: selectedReason,
          comment: text,
          leave_start_date: startDate,
          leave_end_date: endDate,
        };
      }
    }
  
    if (!edituserLeaveID) {
      data.user = userInfo?.id;
    }
  
    if (edituserLeaveID) {
      dispatch(UpdateUserLeave(data, edituserLeaveID, cancleModal, callFilteredLeaveList));
    } else {
      dispatch(applyLeave(data, cancleModal, callLeaveList));
    }
  };


  const handleUpdateLeave = () => {
  let payload ={
      "approved_by": userInfo.id,
      "status": leaveStatus,
      "manager_comment": managerComment
}
  dispatch(updayeLeave(payload,editData.id,setEditLeaveModal,callLeaveList,setLeaveStatus,setManagerComment,))
  console.log("payload",payload)
  }

 
    const handleDeleteLeave = (ID) => {
     console.log(ID);
      dispatch(deleteLeaveAllotment(ID,callAllotmentList));
    };
  
  


  const handleLeaveSubmit = () => {
  let payload ={
      "category_name": employeeType,
      "monthly_allotted_leaves": leaveAlloted,
      
  }
  if (editLeaveID) {
    dispatch(UpdateLeaveAllotment(payload,editLeaveID, cancleModal, callAllotmentList))

  } else {
    dispatch(AddTypeofEmployee(payload,  cancleModal, callAllotmentList))
  }
  
  }

  const handleTextareaChange = (event) => {
    setText(event.target.value);
  };
  const handleEmployeeChange = (event) => {
   
    setEmployeeType(event.target.value);
  };
  const handleLeaveAllotmentChange = (event) => {
    setLeaveAlloted(event.target.value);
  };

  const editEmployee = (value) => {
    console.log("editEmployeeid", value);
    setEditLeaveModal(true);
    setEditdata(value);
  };
  const edituserleave=(value)=>{
    console.log("clikced",value);
    setuserleavedata(value);
    if (value.id) {
      setSelectedReason(value.leave_type);
      setText(value.comment);
      setedituserLeaveID(value.id);
      console.log("leavecount",value.leave_count);
      
      const leaveCount = parseFloat(value.leave_count);

if (!isNaN(leaveCount)) {
  if (leaveCount === 1) {
    setCurrentTab("daily");
    setStartDate(new Date(value.leave_start_date));
  } else if (leaveCount===7) {
    setCurrentTab("weekly");
    setWeekDate(new Date(value.leave_start_date));
   
  } else {
    setCurrentTab("dateRange");
    setDateRangeStart(new Date(value.leave_start_date));
    setDateRangeEnd(new Date(value.leave_end_date));
  }
}
  
      setModalOpen(true);
    }
  }
  const editLeaveAllotment = (value) => {
    if(value.id){
      setEmployeeType(value.category_name);
      setLeaveAlloted(value.monthly_allotted_leaves)
      setEditLeaveID(value.id);
      setModalOpen(true);
    }
  };

  const seeDescription = (value) => {
    console.log("seeEmployee", value);
    setDescriptionModal(true);
    setSelectedEmployee(value);
  };

  const handleEditReasonChange = (event) => {
    setManagerComment(event.target.value);
  };

  // const handleExport = (startMoth,selectedUsers = []) => {
  //   dispatch(ExportLeave(setExportState, startMoth,selectedUsers));
  // }
  const handleChange = (value) => {
    if (value.option == "dateRange") {
      setCurrentTab(value.option);
      setDateRangeStart(value.startDate);
      setDateRangeEnd(value.endDate);
    } else {
      setCurrentTab(value);
    }
  };

   return {
    startDate,
    setStartDate,
    user,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    setStartWeekDay,
    endWeekDay,
    setEndWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    currentTab,
    setCurrentTab,
    onChangeDateRange,
    handleTabTypeChange,
    inProgress,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    currentPage,
    setCurrentPage,
    pageCounts,
    leavepageCounts,
    setPageCounts,
    leaveTransactionList,
    leaveAllotmentList,
    handleSelectChange,
    handleTextareaChange,
    selectedReason,
    setSelectedReason,
    text,
    setText,
    handleSubmit,
    editEmployee,
    editLeaveAllotment,
    seeDescription,
    options,
    leaveTypeList,
    modalOpen,
    setModalOpen,
    userInfo,
    editLeaveModal, 
    setEditLeaveModal,
    descriptionModal,
    setDescriptionModal,
    editData,
    editLeaveID,
    leaveOptions,
    handleSelectStatus,
    leaveStatus, 
    setLeaveStatus,
    managerComment,
    handleEditReasonChange,
    handleUpdateLeave,
    setSelectedEmployee,
    employeeType,
    selectedEmployee,
    handleEmployeeChange,
    leaveAlloted,
    handleLeaveAllotmentChange,
    handleDeleteLeave,
    selectAll,
    selectedRows,
    handleSelectAll,
    handleRowCheckboxChange,
    handleLeaveSubmit,
    openModal,
    leaveTransactionFilterList,
    statuspageCounts,
    handleExport,
    exportdata,
    
    csvref,
    exportState,
    edituserleave,
    edituserLeaveID,
    startMonth_status,
    setStartMonth_status,
     handleDecrease_status,
    handleIncrease_status,
    pageCounts,
    setLeaveStatus,
    handleChange,
    setIsEscalted,
    TabChange,
    activeTab,
    isEscalted,
    setStartWeekDay,
    setEndWeekDay,
    setDateRangeStart,
    setDateRangeEnd,
    setEndDate,
    setCurrentTab
  };
}
