import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../redux/Actions/authAction";
import { getAllPlanList } from "../../../redux/Actions/subscription";
import Alert from "../../common/Alert";
import "./style.css";

export const PlanExpoiredScreen = () => {
  const { env, userInfo } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    dispatch(logOut());
    navigate("/");
  };

  const isSuperuser = userInfo?.is_superuser;
  const isAdmin = userInfo?.groups?.some((item) => item.name === "Admin");
  const handleUpgrade = async () => {
    navigate("/change-plan");
  };

  const [modalOpen, setModalOpen] = useState(false);

  const logoutAlert = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    dispatch(getAllPlanList(env));
  }, [dispatch, env]);

  return (
    <>
      <Alert
        message="Logout"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        setOpenModal={setModalOpen}
        handleDelete={handleLogOut}
      />

      <div className="plan-layout">
        <div className="plan-main-header" style={{ display: "flex", width: "100%" }}>
          <div className="nav-logo-plan" style={{ width: "25%" }}>
            <img src="/images/clocksession-logo-new.png" alt="clocksession-logo" />
          </div>
          <div className="plan-header">
            <p className="gustavo" style={{ fontSize: "x-large", fontWeight: "bold" }}>
              Plan Expired
            </p>
            <div className="selectdiv">
              <div className="user-menu-dropdown gustavo" style={{ paddingRight: userInfo.name ? "25px" : "0" }}>
                <img
                  className="user-menu-dropdown-profile-img"
                  src={userInfo.profile_image ? userInfo.profile_image : "/images/profilepic.png"}
                  alt="profile"
                  onError={(e) => {
                    e.target.src = "/images/profilepic.png";
                  }}
                />
                <div className="user-menu-dropdown-profile">
                  <p className="gustavo" style={{ fontSize: "16px" }}>
                    {userInfo.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wer">
          <div className="plan-expoired-screen">
            <main className="section" style={{ borderRadius: "6px" }}>
              <div className="section-bg" />
              <section className="mid" style={{ borderRadius: "6px" }}>
                <div className="mid-bg" />
                <div className="upgrade-button-wrapper">
                  <div className="upgrade-button">
                    <h1 className="your-plan-has">Your Plan Has Expired</h1>
                    <div className="data-removal-warning">
                      <div className="plan-expired-label">
                        <div className="once-the-plan">
                          Once the plan is expired. You will only able to login to your account. However, the features you had in your free trial will be disabled
                        </div>
                        <div className="options-label">
                          <div className="we-have-two">
                          {isSuperuser || isAdmin ? (
                              "We have two options for you"
                            ) : (
                              "Please contact to your admin!"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="frame-parent-btn">
                        <div className="frame-group-btn">
                          {isSuperuser || isAdmin ? (
                            <>
                              <button className="frame-container-btn" style={{ border: "2px solid #F75E54", fontSize: "16px" }} onClick={logoutAlert}>
                                <div className="vector-wrapper">
                                  <img className="logout-icon-btn" alt="" src="/images/icons/logout-icon.svg" />
                                </div>
                                <div className="log-out">Log out</div>
                              </button>
                              <button className="upgrade-btn-container" style={{ background: "#007EC5" }} onClick={handleUpgrade}>
                                <div className="upgrade">Upgrade</div>
                              </button>
                            </>
                          ) : (
                            <button className="frame-container-btn" style={{ border: "2px solid #F75E54", fontSize: "16px" }} onClick={logoutAlert}>
                              <div className="vector-wrapper">
                                <img className="logout-icon-btn" alt="" src="/images/icons/logout-icon.svg" />
                              </div>
                              <div className="log-out">Log out</div>
                            </button>
                          )}
                        </div>
                        <div className="after-15-days-all-your-data-w-wrapper">
                          <div className="after-15-days-container">
                            <p className="after-15-days">After 15 days, All your data will be removed</p>
                            <p className="from-clock-sessions">from Clock Session’s database</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "4%" }}>
                  <img className="group-icon" loading="lazy" alt="" src="/images/planexpire.svg" />
                </div>
              </section>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
