import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";

export const EMPLOYEE_LIST = "EMPLOYEE_LIST";
export const ALL_EMPLOYEES = "ALL_EMPLOYEES";
export const EMPLOYEE_DETAILS = "EMPLOYEE_DETAILS";
export const TIMELOG_HOURS = "TIMELOG_HOURS";

export const getTotalEmployeeCount = (setTotalCount, isActive, type) => {
  console.log("isActive",isActive)
  return async (dispatch) => {
    await Axios_v2.get(
      `/employees/?page=1&items_per_page=1${isActive == "active" ? "&activated=true":""}${isActive == "pending" ? `&pending_request=true`:""}${isActive == "archived" ? `&archived=true` :""}`,
      HeaderToken()
    )
      .then((res) => {
        setTotalCount(res.data.count);
      })
      .catch((err) => {});
  };
};

// to get emp list for create project
export const getEmployeeList = (search) => {
  return async (dispatch) => {
    Axios_v2.get(
      `/employees/?items_per_page=1000${
        search ? `&search=${search}` : ""
      }&activated=true`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: EMPLOYEE_LIST, payload: res.data.results });
        }
      })
      .catch((err) => {
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};

export const getAllEmployes = (
 { 
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  setTotalValues,
  isActive,
  tableType,
  searchValue,
  setTotalPages
}
) => {
  console.log("tableType",tableType,searchValue,itemsPerPage,currentPage)
  return async (dispatch) => {
    const rand=Math.random();
    Axios_v2.get(
      `/employees/?ordering=-id&items_per_page=${itemsPerPage}&rand=${rand}&page=${currentPage}${tableType == "pending" ? `&pending_request=true` : ''}${tableType == "active" ? `&activated=true` : ''}${tableType == "archived" ? `&archived=true` : ''}${
        searchValue ? `&search=${searchValue}` : ""
      }`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: ALL_EMPLOYEES, payload: res.data.results });
          setPageCounts(res.data.total_pages);
          setTotalValues(res.data.count);
          setTotalPages(res.data.total_pages);
          setInProgress(false);
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};

export const deleteEmployee = (ID, refreshTable) => {
  return async (dispatch) => {
    Axios_v2.delete(`/employees/${ID}/`, HeaderToken())
      .then((res) => {
        refreshTable();
        toast.success("Successfully Deleted Employee");
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const getEmployeeDetails = (ID) => {
  return async (dispatch) => {
    Axios_v2.get(`/employees/${ID}/`, HeaderToken())
      .then((res) => {
        dispatch({ type: EMPLOYEE_DETAILS, payload: res.data });
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const addNewEmployee = (req, cancleEmployeeAdd, callEmployeeList) => {
  return async (dispatch) => {
    Axios_v2.post("/employees/", req, HeaderToken())
      .then((res) => {
        cancleEmployeeAdd();
        callEmployeeList();
        toast.success("Invite Sent ");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const updateEmployee = (
  req,
  ID,
  cancleEmployeeAdd,
  callEmployeeList
) => {
  return async (dispatch) => {
    Axios_v2.put(`/employees/${ID}/`, req, HeaderToken())
      .then((res) => {
        cancleEmployeeAdd();
        callEmployeeList();
        toast.success("Successfully Updated");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const resendInvite = (ID) => {
  return async (dispatch) => {
    Axios_v2.get(`/employees/${ID}/resend_invite/`, HeaderToken()).then(
      (res) => {
        if (res.data.status === "Success") {
          toast.success("Invite Sent ");
        }
      }
    );
  };
};

export const cancelInvite = (ID, refreshTable) => {
  return async (dispatch) => {
    Axios_v2.get(`/employees/${ID}/cancel_invite/`, HeaderToken()).then(
      (res) => {
        if (res.data.status === "Success") {
          toast.success("Invite Cancelled");
          refreshTable();
        }
      }
    );
  };
};

export const getTimeLog = async (user, date) => {
  return async (dispatch) =>
    Axios_v2.get(`/working-hour-logs/`, HeaderToken())
      .then((res) => {
        console.log("getTimeLog_API_RESPONSE", res);
      })
      .catch();
};
