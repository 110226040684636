import React from "react";
import Tooltip from "../ToolTip";
import { useSelector } from "react-redux";
import { RenderPageButtons } from "../../Utils/randerPageButtons";
import Pagination from "../common/Pagination/pagination";

export default function EmployeesTable({ editEmployee, hookProps }) {
  const { userGroups, userPermissions,userSubordinates} = useSelector((store) => store.auth);
  const {
    allEmployees,
    inProgress,
    currentPage,
    setCurrentPage,
    pageCounts,
    tableType,
    TotalCount_Archived,
    TotalCount_Active,
    RemoveUser,
    TabChange,
    ResendInvite,
    CancelInvite,
    TotalCount_Pending,
    userInfo,
    totalpages
  } = hookProps;
  console.log("userinfo", userInfo);
  const AccessComponent = ({ groups ,employee}) => {
   
    const groupNames = groups.map((group) => group.name);
    const formattedNames = groupNames.join(", ");
    return (
      <div className="access-component">
        { employee.is_superuser ? (
          <div className="superuser">Superuser</div>
        ) : (
          formattedNames
        )}
      </div>
    );
  };

  const pages = [];

  for (let i = 1; i <= pageCounts; i++) {
    pages.push(i);
  }

  // const renderPageNumbers = pages.map((number) => {
  //   return (
  //     <button
  //       onClick={() => setCurrentPage(Number(number))}
  //       className={currentPage === number ? "active-page" : null}
  //     >
  //       {number}
  //     </button>
  //   );
  // });

  const renderPageButtons = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );
console.log("allemp",allEmployees);
console.log("pages",totalpages);
  return (
    <div className="projects-table">
      <div className="projects-table-header">
        <div
          className={`project-table-tab ${
            tableType === "active" && "active-table-tab"
          }`}
          onClick={() => TabChange("active")}
        >
          <h1>{TotalCount_Active}</h1>
          <div>
            <h5>Active Members</h5>
            {/* <p>Members</p> */}
          </div>
        </div>

        <div
          className={`project-table-tab ${
            tableType === "pending" && "active-table-tab"
          }`}
          onClick={() => TabChange("pending")}
        >
          <h1>{TotalCount_Pending}</h1>
          <div>
            <h5>Pending Requests</h5>
            {/* <p>Members</p> */}
          </div>
        </div>
        <div
          className={`project-table-tab ${
            tableType === "archived" && "active-table-tab"
          }`}
          onClick={() => TabChange("archived")}
        >
          <h1>{TotalCount_Archived}</h1>
          <div>
            <h5>Archived Members</h5>
            {/* <p>Members</p> */}
          </div>
        </div>
      </div>
    
     
      <div className="projects-table-body">
     
        <table>
          <tr>
            <th style={{paddingLeft:"54px"}}>Name</th>
            <th style={tableType === "active" ? { width:"18%"}  : {}}>Email</th>
            <th style={tableType === "active" ? { width:"38%",textAlign:"center"}  : {textAlign:"center"}}>Access</th>
            {tableType === "active" ? <></> : <th>User Account Status</th>}
            <th className="w-140" style={{paddingLeft: "23px"}}>Actions</th>
          </tr>

          {console.log(allEmployees,"employee")}
          {console.log(userInfo.id,"userinfo123")}

          {inProgress &&
            <tr>
                <td colSpan="4">
                  <h4 style={{textAlign: "center", fontSize: "16px"}}>Loading Data...</h4>
                </td>
              </tr>
          }

          {!inProgress &&
            allEmployees?.map((employee) => (
              
              <tr key={employee.id}>
                <td>
                  <div className="employee-name">
                    <img
                      src={
                        employee.profile_image
                          ? employee.profile_image
                          : "/images/profilepic.png"
                      }
                      alt="profile"
                      onError={(e) => {
                        e.target.src = "/images/profilepic.png";
                      }}
                    />
                    {employee.name}
                  </div>
                </td>
                <td>
                  <div className="employee-email">{employee.email}</div>
                </td>
                <td>
                  <AccessComponent groups={employee.groups} employee={employee}/>
                </td>
                {tableType === "active" ? (
                  <></>
                ) : (
                  <td>
                    <div>
                      <span>{employee.status}</span>
                    </div>
                  </td>
                )}
                <td>
                  <div className="table-actions">
                    {tableType === "active" ? (
                    <>

                     {(() => {
                        const canUpdateAll = userPermissions.includes(
                          "Can update anyone's profile"
                        );
                        const canUpdateOwn = userPermissions.includes(
                          "Can update own profile"
                        );

                        const canUpdateTeam=userPermissions.includes(
                          "Can update team profile assigned to or added by self")
                          {console.log(userSubordinates,"usersubordicates")}

                        const isUserSubordinate = userSubordinates?.some(
                          (subordinate) =>
                            subordinate.id == employee.id
                        );
                 const isAdmin = employee.groups.map((item) => item.name).includes("Admin");
                       
                        return (
                          (userInfo.is_superuser)||
                          (canUpdateAll  && !(employee.is_superuser || (isAdmin ))||
                          ((canUpdateOwn && employee.id == userInfo.id) ||
                            (canUpdateTeam && isUserSubordinate&&!isAdmin))
                        ));
                      })() && (
                        <Tooltip position="top" title="Edit Employee">
                        <button onClick={() => editEmployee(employee.id)}>
                          <img className="edit-icon" src="/images/icons/edit-green.png" alt="edit"/>
                        </button>
                      </Tooltip>
                      )}
                   {/* {
                 
                  ((userPermissions.includes("Can update own profile") )&& (employee.id === userInfo.id)) ||
                  ((userPermissions.includes("Can update team profile assigned to or added by self") &&
                    userSubordinates?.some((subordinate) => subordinate.id === employee.id)) ? (
                      <Tooltip position="top" title="Edit Employee">
                        <button onClick={() => editEmployee(employee.id)}>
                          <img src="/images/icons/edit-green.png" alt="edit" />
                        </button>
                      </Tooltip>
                    ) : null
                  )
                } */}



                    { (
                          <>
                {userInfo.is_superuser || userPermissions.includes("Can delete user")&&!employee.groups.map(item => item.name).includes("Admin")&&!employee.is_superuser||
                       (userPermissions.includes("Can delete users added by or assigned to self") &&
                        (employee.managers.some(
                                (manager) => manager.id === userInfo.id
                              ) ||
                                employee.id === userInfo.id)) ? (
                              <Tooltip position="top" title="Remove Employee">
                                <button onClick={() => RemoveUser(employee.id)}>
                                  <img 
                                    src="/images/icons/delete.png"
                                    alt="delete"
                                    className="delete-icon"
                                  />
                                </button>
                              </Tooltip>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Tooltip position="top" title="Resend Invite">
                          <button onClick={() => ResendInvite(employee.id)}>
                            <img className="resend-icon" src="/images/icons/re-send.png" alt="resend" />
                          </button>
                        </Tooltip>

                        <Tooltip position="top" title="Cancel Invite">
                          <button onClick={() => CancelInvite(employee.id)}>
                            <img className="delete-icon" src="/images/icons/delete.png" alt="delete"/>
                          </button>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </table>
      </div>
       

      {
      pageCounts>1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )
    }
   
     {/* {totalpages>1&&(
      <div className="projects-table-footer">
        <div className="table-pagination">
          <button
            className="prev-scr"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === pages[0] ? true : false}
          >
            {"PREV"}
          </button>
          {renderPageButtons}
          <button
            className="next-scr"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === pages[pages.length - 1] ? true : false}
          >
            {"NEXT"}
          </button>
        </div>
      </div>
     )} */}
      
    </div>
   
 
  );
}