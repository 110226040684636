import React from "react";
import { useSelector } from "react-redux";
import useRoleAndSettings from "../../hooks/useRoleAndSettings";
import AddRole from "../../components/Settings/AddRole";
import RoleListTable from "../../components/Settings/RoleListTable";
import AddPermissionModal from "../../components/Settings/AddPermissionModal";
import AddButton from "../../components/AddButton";
export default function RoleSettings() {
  const { userPermissions } = useSelector((store) => store.auth);
 console.log(userPermissions);
  const {
    modalOpen,
    setModalOpen,
    roleValue,
    handleChange,
    selectedFunction,
    setSelectedFunction,
    handleFunctionChange,
    handleSave,
    currentPage,
    setCurrentPage,
    pageCounts,
    userInfo,
    AllRoleList,
    AllPermissionList,
    handleDelete,
    showPermissionModal,
    setShowPermissionModal,
    openPermissionModal,
    handleEdit,
    editId,
    openModal,
    nameError,
    descriptionError,
    inProgress,
    assignedPermissions,
    selectedPermissions,
    setSelectedPermissions,
    handlePermissionSelection,
    handleSelectAllModuleItems,
    groupedPermissions,
    handleSavePermissions,
  } = useRoleAndSettings();
 console.log(userPermissions);
  return (
    <>
      <div className="settings-content-header">
        <p className="role-setting-header">Role</p>

        <div style={{display:"flex",width:"100px"}}>
        {userPermissions.includes("Can add role custom")&&(
       
          <AddButton title="Add Role" onClick={() => openModal()}  />

        )}
        </div>
      </div>
      <AddRole
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hookProps={{
          roleValue,
          handleChange,
          selectedFunction,
          setSelectedFunction,
          handleFunctionChange,
          handleSave,
          editId,
          nameError,
          descriptionError,
          userPermissions
        }}
      />
      <RoleListTable
        hookProps={{
          AllRoleList,
          currentPage,
          setCurrentPage,
          pageCounts,
          userInfo,
          handleDelete,
          openPermissionModal,
          handleEdit,
          inProgress,
          userInfo,
          userPermissions
        }}
      />
      <AddPermissionModal
        open={showPermissionModal}
        onClose={() => setShowPermissionModal(false)}
        hookProps={{
          roleValue,
          AllPermissionList,
          inProgress,
          AllRoleList,
          assignedPermissions,
          selectedPermissions,
          setSelectedPermissions,
          handlePermissionSelection,
          handleSelectAllModuleItems,
          selectedPermissions,
          groupedPermissions,
          handleSavePermissions,
          userPermissions
        }}
        
      />
    </>
  );
}
