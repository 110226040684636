import React, { useRef, useEffect } from "react";
import useLeaveManagement from "../../hooks/useLeaveManagement";
import useLeaveStatus from "../../hooks/useLeaveStatus";
import {
  MonthPicker,
  DatePickerSingle,
  WeekPicker,
  DateRangePicker,
} from "../common/DatePickers";
import "./style.css";
import ApplyLeaveModal from "./ApplyLeaveModal";
import LeaveStatusTable from "./LeaveStatusTable";
import DescriptionModal from "./DescriptionModal";
import ToolTip from "../ToolTip";
import SelectAttendanceType from "../SelectType";

const LeaveStatus = () => {
  const {
    startDate,
    startMonth,
    setStartMonth,
    currentTab,
    handleDecrease,
    handleIncrease,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    pageCounts,
    leaveTransactionList,
    editEmployee,
    modalOpen,
    setModalOpen,
    setStartDate,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    dateRangeStart,
    onChangeDateRange,
    dateRangeEnd,
    handleTabTypeChange,
    handleSelectChange,
    handleTextareaChange,
    selectedReason,
    text,
    handleSubmit,
    options,
    userInfo,
    editLeaveModal,
    setEditLeaveModal,
    editData,
    leaveOptions,
    handleSelectStatus,
    leaveStatus,
    managerComment,
    handleUpdateLeave,
    handleEditReasonChange,
    user,
    setUser,
    edituserLeaveID,
    edituserleave,
    openModal,
    seeDescription,
    descriptionModal,
    setDescriptionModal,
    selectedEmployee,
    startMonth_status,
    setStartMonth_status,
    handleDecrease_status,
    handleIncrease_status,
    inProgress,
    statuspageCounts,
    currentPage,
    setCurrentPage,
    handleDelete_Status,
    leaveTransactionFilterList,
    setIsupdated,
    userPermissions,
    ModalCalender,
    setModalCalender,
    handleClick,
    approvedLeave_count,
    currentMonth,
    halfday_selection,
    setHALFDAYSELECTION,
    exportState,
    handleChange,
    approved_leave_current_month,
    toggle,
    setToggle,
    isSubmitting,
    setIsSubmitting,
    currentMonth_approvedleave,
    halfday,
    userleavedata,
    setuserleavedata,
    prevTab,
    setprevTAB,
    iseditoradd,
    setiseditoradd,
  } = useLeaveManagement();

  return (
    <div id="leave-status" style={{ marginTop: "10px" }}>
      {console.log("currentTab", currentTab)}
      <div className="leave-page-header-btn">
        <div style={{ display: "flex" }}>
          <div
            className="leave-timeline"
            ref={wrapperRef}
            style={{ borderRadius: "8px", border: "2px solid #E8E8E8", marginLeft: "0px" }}
          >
            <img
              src="/images/icons/Icon - Calender-Dark.svg"
              alt="calendar"
              onClick={() => setCalendarIsOpen(!calendarIsOpen)}
              ref={wrapperRef}
              style={{ color: "#292929" }}
            />

            <div>
              {currentTab === "daily" && (
                <DatePickerSingle
                  startDate={startDate}
                  ref={calendarRef}
                  setStartDate={setStartDate}
                  calendarIsOpen={calendarIsOpen}
                  setCalendarIsOpen={setCalendarIsOpen}
                  showMaxDate={true}
                />
              )}

              {currentTab === "weekly" && (
                <WeekPicker
                  ref={calendarRef}
                  weekDate={weekDate}
                  setWeekDate={setWeekDate}
                  startWeekDay={startWeekDay}
                  endWeekDay={endWeekDay}
                  calendarIsOpen={calendarIsOpen}
                  setCalendarIsOpen={setCalendarIsOpen}
                  showMaxDate={true}
                />
              )}

              {currentTab === "monthly" && (
                <MonthPicker
                  ref={calendarRef}
                  startMonth={startMonth_status}
                  setStartMonth={setStartMonth_status}
                  calendarIsOpen={calendarIsOpen}
                  setCalendarIsOpen={setCalendarIsOpen}
                  className="customize-monthpicker"
                  maxDate={null}
                />
              )}

              {currentTab === "dateRange" && (
                <DateRangePicker
                  ref={calendarRef}
                  dateRangeStart={dateRangeStart}
                  onChangeDateRange={onChangeDateRange}
                  dateRangeEnd={dateRangeEnd}
                  calendarIsOpen={calendarIsOpen}
                  showMaxDate={true}
                />
              )}
            </div>

            {["daily", "monthly", "weekly"].includes(currentTab) && (
              <>
                <button
                  className="prev-month"
                  onClick={() => handleDecrease_status()}
                ></button>
                <button
                  className="next-month"
                  onClick={() => handleIncrease_status()}
                ></button>
              </>
            )}
          </div>

          <div className="leave-filter-dropdown" style={{marginLeft: "5px"}}>
            <ToolTip position="top" title="Select Attendance Type">
              <SelectAttendanceType handleSubmit={handleChange} tab="Leave123" activetab={currentTab} />
            </ToolTip>
          </div>
        </div>

        <div className="emp-leave-button" style={{ marginTop: "3px" }}>
          {userPermissions.includes("Can apply for leave") && (
            <button
              className="leave-page-btn"
              type="submit"
              onClick={() => {
                setprevTAB(currentTab);

                openModal();
                setToggle((prevToggle) => !prevToggle);
              }}
              style={{ border: "0px solid red", marginLeft: "15px" }}
            >
              Apply Leave
            </button>
          )}
        </div>
      </div>

      <ApplyLeaveModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hookProps={{
          startDate,
          startMonth,
          setStartMonth,
          currentTab,
          handleDecrease,
          handleIncrease,
          calendarRef,
          wrapperRef,
          calendarIsOpen,
          setCalendarIsOpen,
          setStartDate,
          weekDate,
          setWeekDate,
          startWeekDay,
          endWeekDay,
          dateRangeStart,
          onChangeDateRange,
          dateRangeEnd,
          handleTabTypeChange,
          handleSelectChange,
          handleTextareaChange,
          selectedReason,
          text,
          handleSubmit,
          options,
          edituserLeaveID,
          setIsupdated,
          ModalCalender,
          setModalCalender,
          approvedLeave_count,
          currentMonth,
          halfday_selection,
          setHALFDAYSELECTION,
          approved_leave_current_month,
          toggle,
          setToggle,
          isSubmitting,
          setIsSubmitting,
          currentMonth_approvedleave,
          halfday,
          userleavedata,
          setuserleavedata,
          
        }}
      />
      <editLeaveModal
        open={editLeaveModal}
        onClose={() => setEditLeaveModal(false)}
        hookProps={{
          editData,
          leaveOptions,
          handleSelectStatus,
          leaveStatus,
          managerComment,
          handleUpdateLeave,
          handleEditReasonChange,
        }}
      />
      <DescriptionModal
        open={descriptionModal}
        onClose={() => setDescriptionModal(false)}
        hookProps={{ selectedEmployee }}
      />
      <LeaveStatusTable
        hookProps={{
          leaveTransactionList,
          currentPage,
          setCurrentPage,
          pageCounts,
          userInfo,
          editEmployee,
          setEditLeaveModal,
          leaveTransactionFilterList,
          statuspageCounts,
          inProgress,
          edituserleave,
          seeDescription,
          handleDelete_Status,
          userPermissions,
          setprevTAB,
          currentTab,
        }}
      />
    </div>
  );
};

export default LeaveStatus;
