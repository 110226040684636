import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles.css";
import { CustomSingleDatePicker } from "./CustomDatePicker";
import theme from "../../theme/theme";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

export default function SelectLeaveStatus({ handleSubmit ,wrapperRef}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDropdown, setShowDropdown] = useState(false);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [endCalendarIsOpen, setEndCalendarIsOpen] = useState(false);
  // const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const endCalendarRef = useRef(null);
  const [options] = useState([
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
  ]);

  const handleOptionSelect = (value) => {
    console.log("av",value);
    setSelectedOption(value);
    setShowDropdown(false);
    handleSubmit(value);
  };

  const handleApplyFilter = () => {
    if (selectedOption === "dateRange") {
      let value = {
        option: selectedOption,
        startDate: startDate,
        endDate: endDate,
      };
      handleSubmit(value);
    } else {
      handleSubmit(selectedOption);
    }
    setShowDropdown(false);
  };

  const handleClearFilters = () => {
    if(selectedOption){
      setSelectedOption(null);
    }
    else{
      setShowDropdown(false);
    }

  };

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  

   

  useOutsideAlerter(wrapperRef);


  return (
    <div >
    <Dropdown id="leave-status-drop"  show={showDropdown} >
      <Dropdown.Toggle
        // variant="success"
        id="dropdown-basic"
        as="div"
        // className="custom-dropdown"
        style={{backgroundColor:'white' ,color:'black', height: "auto"}}
        onClick={() => setShowDropdown(!showDropdown)}
      >
       <div className="select-status-style">
  <span style={{ marginLeft:"13px",marginRight: "auto", color: selectedOption ? "#292929" : "#949494" }}>
    {selectedOption?
         selectedOption
      : "Select Status"}
  </span>
  <img
    src="/images/down-arrow-icon.svg"
    alt="Download Arrow"
    className="download-arrow"
    style={{marginRight:"4px"}}
  />
</div>

      </Dropdown.Toggle > 

      <Dropdown.Menu id="leave-status-drop">
        <div className="status-dropdown-container" style={{width:"419px"}}>

          <div className="filter-container-leave custom-style-filter">
            <p className="mx-2 " style={{color:"#292929",fontSize:"13px",paddingLeft:"7px"}}>Leave Status</p>
            <div style={{ marginLeft: "auto" }} onClick={handleClearFilters}>
              <img
                src="/images/icons/clear-icon.svg"
                alt="Clear Searh"
                className="clear-icon"
                style={{marginRight:"11px", marginBottom:"11px"}}
              />
            </div>
          </div>

          {/* <hr style={{ margin: "0px" }} /> */}

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0px 0px 8px ",
            }}
          >
            <p className="mx-2 custom-font gustavo">Select Calendar</p>
          </div> */}

          <div className="dropdown-filter-container">
            {options.map((option, index) => {
              if (option.value === "dateRange") {
                return (
                  <div key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "5px",
                      }}
                    >
                      <hr style={{ width: "40%", margin: "0 10px" }} />
                      <p
                        className="gustavo"
                        style={{ margin: "0", fontSize: "14px" }}
                      >
                        Or
                      </p>
                      <hr style={{ width: "40%", margin: "0 10px" }} />
                    </div>
                    <DropdownItem
                      label={option.label}
                      value={option.value}
                      isSelected={selectedOption === option.value}
                      onSelect={handleOptionSelect}
                    />
                    {selectedOption === "dateRange" && (
                      <div className="date-range">
                        <div>
                          <p
                            style={{
                              color: "#949494",
                              fontSize: theme.fontSizes.small,
                              paddingLeft: "5px",
                            }}
                          >
                            Start Date
                          </p>
                          <div className="calender-container">
                            <CustomSingleDatePicker
                              startDate={startDate}
                              setStartDate={setStartDate}
                              ref={calendarRef}
                              calendarIsOpen={calendarIsOpen}
                              closeCalendar={() =>
                                setCalendarIsOpen(!calendarIsOpen)
                              }
                            />
                            <img
                              src="/images/icons/ei_calendar.png"
                              alt="calendar"
                              onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                              ref={wrapperRef}
                              style={{ marginLeft: "5px" }}
                            />
                          </div>
                        </div>

                        {/* <p className="to-style gustavo">To</p> */}

                        <div>
                          <p
                            style={{
                              color: "#949494",
                              fontSize: theme.fontSizes.small,
                              paddingLeft: "5px",
                            }}
                          >
                            End Date
                          </p>
                          <div className="calender-container">
                            <CustomSingleDatePicker
                              startDate={endDate}
                              setStartDate={setEndDate}
                              ref={endCalendarRef}
                              calendarIsOpen={endCalendarIsOpen}
                              closeCalendar={() =>
                                setEndCalendarIsOpen(!endCalendarIsOpen)
                              }
                            />

                            <img
                              src="/images/icons/ei_calendar.png"
                              alt="calendar"
                              onClick={() =>
                                setEndCalendarIsOpen(!endCalendarIsOpen)
                              }
                              ref={wrapperRef}
                              style={{ marginLeft: "5px" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              } else {
                return (
                    
            
                  <DropdownItem
                    key={index}
                    label={option.label}
                    value={option.value}
                    isSelected={selectedOption === option.value}
                    onSelect={handleOptionSelect}
                  />
                 
                );
              }
            })}
            
          </div>
        </div>
      </Dropdown.Menu>

    </Dropdown>
    </div>
  );
}

function DropdownItem({ label, value, isSelected, onSelect }) {
  return (
    <Dropdown.Item onClick={() => onSelect(value)}>
      <div
        className={`dropdown-user-list-item ${
          isSelected ? "selected-employee" : ""
          
        }`}
        style={{margin:"0px"}}
      >
        <input
          type="checkbox"
          className="checkbox"
          style={{ display: "none" }}
          checked={isSelected}
        />
        <label htmlFor={`select-checkbox-${value}`} >
          <img src={ isSelected ? "/images/icons/checked-icon.svg" : "/images/icons/uncheck-icon.svg" }
            className="checkbox-image"
            alt={isSelected ? "Checked" : "Unchecked"}
          />
        </label>
        <div className={`dropdown-right-item gustavo`}>
          <p
            className={`status-font gustavo ${
              isSelected ? "selected-employee" : ""
            }`}
            style={{
              marginLeft: "5px",
              marginBottom:"3px"
            }}
          >
            {label}
          </p>
        </div>
      </div>
    </Dropdown.Item>
  );
}
