import React, { useState, useRef ,useEffect,forwardRef } from "react";
import SelectEmployeePR from "../../SelectEmpPayroll/SelectEmployeePR";
import usePayrollList from "../../../hooks/usePayroll";
import EmpPayrollTable from "../../common/PayrollTable/Upload_Table/EmpPayrollTable_Upload";
import { useDispatch } from 'react-redux';
import { get_document_list, get_document_list_paginated } from "../../../redux/Actions/payrollAction";
import { useSelector } from "react-redux";

import "./style.css";



const UploadForm =()=>{
  const {
    setStartMonth,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    handleDecrease,
    handleIncrease,
    user,
    setUser,
    filetoupload,
    setFiletoupload,
    selectedFile, 
    setSelectedFile,
    data, 
    setData,
    currentPage, 
    setCurrentPage,
    pageCounts, 
    setPageCounts,
    fileAction,
    setFileAction,
    name,
    setName,
    typeValue,
    setTypeValue,
    documentLists,
    handleUpload,
    monthYearValue,
    setMonthYearValue,
    isNextButtonDisabled, 
    setIsNextButtonDisabled,
    selectedYear, 
    setselectedYear,
    handleIncrease_year,
    handleDecrease_year,
    isLoading,
    setIsLoading,
    
   
    
   } = usePayrollList();
   
   
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const[apiType,setapiType]=useState("post");
  const[doc_id,setDoc_id]=useState("");
  console.log("documentlist",documentLists);
  const { userPermissions } = useSelector((store) => store.auth);




   useEffect(() => {
    const monthYearValue = `${selectedYear.format("YYYY")}-03-01`;
    const typeValue = "form16";// covert this in a object
    setMonthYearValue(`${selectedYear.format("YYYY")}-03-01`);
    setTypeValue("form16")
   
    dispatch(get_document_list(user,monthYearValue,typeValue,setPageCounts,setCurrentPage,setIsLoading));
 }, [selectedYear,user,fileAction]);

 useEffect(() => {
  const monthYearValue = `${selectedYear.format("YYYY")}-03-01`;
  const typeValue = "form16";// covert this in a object
  dispatch(get_document_list_paginated(user,monthYearValue,typeValue,setPageCounts,currentPage,setIsLoading));
}, [currentPage]);






 



 
  
  












// Functions to upload pdf from the pc
  function handleLabelClick() {
    fileInputRef.current.click();
  }
  
  const handleFileChange = async (e) => {
   const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Check if the selected file is the same as the one currently in filetoupload
      const reader = new FileReader();
  
      // Wrap the asynchronous file reading in a Promise
      const readFilePromise = new Promise((resolve) => {
        reader.onload = () => {
          if (reader.readyState === 2) {
            resolve(reader.result);
          }
        };
      });
  
      reader.readAsDataURL(e.target.files[0]);
  
      // Wait for the file reading to complete before setting e.target.value to null
      await readFilePromise;
  
      setSelectedFile(reader.result);
      setFiletoupload(e.target.files[0]);
    }
  
    // Reset the input field's value to null to trigger the event next time, even if the same file is selected
    e.target.value = null;
  };

  const resetpdf=()=>{
    setFiletoupload();
    setSelectedFile();
  }
  
  


  
  
  

 
  

  
  return (
    <div>
      
        <div id="uploadpayslip">
        <div className="header-btn">
          <div className="mem-btn">
            <SelectEmployeePR selectedUsers={user} setSelectedUsers={setUser} name={name} setName={setName} />
          </div>
          
          <div className="employee-timeline-form ms-5">
            <button className="prev-month" onClick={handleDecrease_year}>
              {" "}
            </button>
            <button>{selectedYear.format("YYYY")}</button>
            <button className="next-month" onClick={handleIncrease_year}>
              {" "}
            </button>
          </div>
        </div>
      </div>
      <div className="pdf-container d-flex">
        <div className="userpdf">
          <p className="username">User</p>
          <p className="name">{name}</p>
        </div>
        {userPermissions.includes("Can upload Form 16") ? (

        <div className="file-input-form" style={{ border: "0px solid #000" }}>
        <button onClick={handleLabelClick} className="document-label-form">
            <img
              src="/images/icons/upload.png"
              alt="File Upload"
              className="icon me-3"
            />
            Upload Pdf
           
          </button>
          
         
          

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }} 
            onChange={handleFileChange}
          />
          <div className="pdf-box" onClick={resetpdf}>
            
          {filetoupload && filetoupload.name && (
        <p>{filetoupload.name}</p>
      )}
          </div>

        
        </div>
        ) : null}
       {userPermissions.includes("Can upload Form 16") ? (


        <div className="pdf-sub">
          <button type="button" onClick={() => handleUpload(monthYearValue, typeValue,user,apiType,doc_id,setapiType)} class="btn btn-primary rounded ms-5">
            Submit
          </button>
        </div>
        ) : null}

      
      </div>
     
    <EmpPayrollTable 
        document="form16" 
        list={documentLists} 
        handleLabelClick={handleLabelClick}
        handleFileChange={handleFileChange}
        monthYearValue={monthYearValue}
        setUser={setUser}
        setName={setName}
        setapiType={setapiType}
        setDoc_id={setDoc_id}
        
        hookProps={{
          currentPage,
          setCurrentPage,
          pageCounts,
          setPageCounts,
          isLoading,
        }}  />
     

    </div>
  );
};
export default UploadForm;

