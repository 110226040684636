import React from "react";
import useReportProjectAndTask from './../../hooks/useReportProjectAndTask';
import { useSelector } from "react-redux"; // Assuming you're using Redux

//Date Pickers
import {
  DatePickerSingle,
  WeekPicker,
  MonthPicker,
  DateRangePicker,
} from "../common/DatePickers";

import "./style.css";
import ProjectReportTable from "../common/ProjectReportTable";
import SelectAllProject from "../SelectProjectsDD/SelectAllProject";
import SelectAllEmployee from "../SelectEmployeDD/SelectAllEmployee";

const ProjectAndTasksReport = () => {
  const {
    startDate,
    setStartDate,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    dateRangeEnd,
    currentTab,
    onChangeDateRange,
    handleTabTypeChange,
    handleDecrease,
    handleIncrease,
    inProgress,
    user,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    exportState,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    selectedProject,
    setSelectedProject,
    projectAndTaskReport,
    handleExport
  } = useReportProjectAndTask();
  const { userPermissions } = useSelector((store) => store.auth);
  return (
    <div id="Attendances">
      <div className="header-btn">
      {userPermissions.includes("Can filter all users in reports")||
          userPermissions.includes("Can filter own users in reports")?(
        <div className="mem-btn">
          <SelectAllEmployee selectedUsers={user} setSelectedUsers={setUser} />
        </div>
          ):null}
        <div className="mem-btn mx-2">
        <SelectAllProject
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
        </div>
        <div className="employee-timeline" style={{marginLeft: "100px"}}>

          <img
            src="/images/icons/Icon - Calender.png"
            alt="calendar"
            onClick={() => setCalendarIsOpen(!calendarIsOpen)}
            ref={wrapperRef}
          />
            

          {currentTab === "daily" && (
            <DatePickerSingle
              startDate={startDate}
              ref={calendarRef}
              setStartDate={setStartDate}
              // calendarRef={calendarRef}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}
          {currentTab === "weekly" && (
            <WeekPicker
              ref={calendarRef}
              weekDate={weekDate}
              setWeekDate={setWeekDate}
              startWeekDay={startWeekDay}
              endWeekDay={endWeekDay}
              // calendarRef={calendarRef}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}

          {currentTab === "monthly" && (
            <MonthPicker
              ref={calendarRef}
              startMonth={startMonth}
              setStartMonth={setStartMonth}
              // calendarRef={calendarRef}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}
          {currentTab === "dateRange" && (
            <DateRangePicker
              ref={calendarRef}
              dateRangeStart={dateRangeStart}
              onChangeDateRange={onChangeDateRange}
              dateRangeEnd={dateRangeEnd}
              // calendarRef={calendarRef}
              calendarIsOpen={calendarIsOpen}
            />
          )}

          {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly"? (
            <button
              className="prev-month"
              onClick={() => {
                handleDecrease();
              }}
            >
              {""}
            </button>
          ) : null}

          {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly"? (
            <button
              className="next-month"
              onClick={() => {
                handleIncrease();
              }}
              disabled={
                (currentTab === "daily" && startDate.toDateString() === new Date().toDateString()) ||
                (currentTab === "weekly" && 
                (startWeekDay.toDateString() === new Date().toDateString() || 
                (startWeekDay.getDate() + 6 >= new Date().getDate() && 
                startWeekDay.getMonth() === new Date().getMonth()))
              ) ||
                (currentTab === "monthly" && startMonth.getMonth() === new Date().getMonth())
              }
            >
              {""}
            </button>
          ) : null}


        </div>
        <div style={{ display: "flex", justifyContent: "space-around", width: "20%" }}>
          <div>
            <select
              id="u-access"
              name="group"
              className="form-control"
              value={currentTab}
              onChange={(e) => handleTabTypeChange(e)}
            >
              <option value="daily"> Daily</option>
              <option value="weekly"> Weekly</option>
              <option value="monthly"> Monthly</option>
              <option value="dateRange">Date-Range</option>
            </select>
          </div>
        </div>

        {(userPermissions.includes("Can export report for all users") ||
          userPermissions.includes("Can export all reports") ||
          userPermissions.includes("Can export report for users added by or assigned to self" )||
          userPermissions.includes("Can export project and task report for users assigned to or added by self")||
          userPermissions.includes("Can export project and task report for all users" )
          )
           && (
            <button
            className={projectAndTaskReport.length === 0 ? "exp-btn holiday-hidden" : "exp-btn"}
            type="submit"
            onClick={handleExport}
            disabled={exportState}
          >
            Export
            {exportState && <span class="spinner-grow spinner-grow-sm"></span>}
            {/* <img src="/images/icons/download.png" alt=""></img> */}
          </button>
        )}
      </div>

      <div>
        <ProjectReportTable
          data={projectAndTaskReport}
          currentTab={currentTab}
          inProgress={inProgress}
          isActionAvailable={true}
          hookProps={{
            currentPage,
            setCurrentPage,
            pageCounts,
            setPageCounts
          }}
        />
      </div>
    </div>
  );
};

export default ProjectAndTasksReport;
