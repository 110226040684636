import React from "react";

export default function WindowsStep() {
  return (
    <>
      <p style={{fontWeight:"bold", fontSize: "28px"}}><span className="download-underline"> For Windows </span></p>
      <ol className="custom-ol">
        <li>Download the .Exe file from the above link.</li>
        <li>
          After downloading the application install the Application to your
          system.
        </li>
        <li>
          {" "}
          Search for the icon in installed location or just search for
          Alpha Desktop application and press Enter.
        </li>
        <li>Then login to the signup screen and run the tracker.</li>
      </ol>
    </>
  );
}
