import { DOCUMENT_LIST } from "../Actions/payrollAction";
import { DOCUMENT_LIST_DOWNLOAD } from "../Actions/payrollAction";

const initialState = {
  documentLists: [],
  documentListsDownload:[],
};

const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_LIST: {
      return {
        ...state,
        documentLists: action.payload,
      };
    }
    case DOCUMENT_LIST_DOWNLOAD: {
      return {
        ...state,
        documentListsDownload: action.payload,
      };
    }
    default: {
      return state; // Make sure to return the state for cases where no action matches.
    }
  }
};

export default payrollReducer;
