import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { sendemailofleave } from "../../../redux/Actions/leaveAction";
import SelectAllEmployee from "../../SelectEmployeDD/SelectAllEmployee";

const SendEmail = ({ open, onClose }) => {
  const [text, setText] = useState("");
  const [email, setEmail] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [textError, setTextError] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { employeesList } = useSelector((store) => store.ER);

  const handleTextareaChange = (event) => {
    setText(event.target.value);
  };

  const handleAttachmentChange = (event) => {
    const file = event.target.files[0];
    setAttachment(file);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    console.log("insidesubmit")
    if (user.length === 0) {
      setEmailError(" select a user");
      
    } else {
      setEmailError("");
    }
   
    if (!text.trim()) {
      setTextError("Message is required");
      
    } else {
      setTextError("");
    }
  
    let req = {
      user: user,
      comment: text,
      report_file: attachment,
      // groups_ids: userInfo.groups_ids,
      // profile_image: profileImg,
    };

    let formData = new FormData();

    Object.keys(req).forEach((key) => {
      formData.append(key, req[key]);
    });
    console.log("form_data",formData);




    dispatch(sendemailofleave(formData,setLoading,onClose));
  
    // setEmail("");
    // setText("");
    // setAttachment(null);
    // onClose();
  };
  

  const resetForm = () => {
    setText("");
    setEmail("");
    setAttachment(null);
    setEmailError("");
    setTextError("");
  };

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="user-container">
        <div className="header-email">
          <h4 style={{ textAlign: "center" }}>Send Email</h4>
        </div>

        <div className="row mb-3">
        <SelectAllEmployee selectedUsers={user} setSelectedUsers={setUser} />
        {emailError && <p className="text-danger">{emailError}</p>}

        </div>

        <div className="row mb-4">
          <label htmlFor="attachment" className="col-sm-3 col-form-label">
            Attachment:
          </label>
          <div className="col-sm-9">
            <input
              type="file"
              id="attachment"
              name="attachment"
              className="form-control"
              onChange={handleAttachmentChange}
            />
          </div>
        </div>

        <div className="row mb-4">
          <textarea
            rows="4"
            cols="40"
            className="form-control"
            placeholder="Message"
            value={text}
            onChange={handleTextareaChange}
          />
          {textError && <p className="text-danger">{textError}</p>}
        </div>

        <div className="row mb-2">
          <div className="col-sm-9 offset-sm-3">
            <div className="modal-btn">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {loading ? "Sending..." : "Send Email"}
              </button>
              <span> </span>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SendEmail;
