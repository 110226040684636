import React from "react";
import Tooltip from "../ToolTip";
import { RenderPageButtons } from "../../Utils/randerPageButtons";
import moment from "moment";
import "./style.css";
import { toast } from "react-toastify";
import Pagination from "../common/Pagination/pagination";

export default function RoleListTable({ hookProps }) {
  const {
    AllRoleList,
    currentPage,
    setCurrentPage,
    pageCounts,
    handleDelete,
    openPermissionModal,
    handleEdit,
    inProgress,
    userInfo,
    userPermissions,
  } = hookProps;

  const pages = [];
  const res_role = ["User", "Manager", "Admin", "HR", "Accountant"];
  function cant_edit() {
    toast.warn("Can't change this role");
  }
  function cant_delete() {
    toast.warn("Can't delete this role");
  }

  for (let i = 1; i <= pageCounts; i++) {
    pages.push(i);
  }

  function formatDateRange(modified_at) {
    const formattedStartDate = moment(modified_at).format("ddd, DD MMM YYYY");
    return `${formattedStartDate}`;
  }

  const renderPageNumbers = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );

  return (
    <>
    <div className="role-setting-table">
      <div className="role-table-header"></div>

      <div className="role-table-body">
        <table >
          <thead>
            <tr>
              <th className="role-th" style={{paddingLeft:"30px"}}>Role</th>
              <th>Scope</th>
              <th>Last Modified On</th>
              <th>Role defined Type</th>
              {userPermissions.includes(
                "Can view and edit roles and permissionsmin"
              ) ||
              userPermissions.includes("Can delete role custom") ||
              userPermissions.includes("Can change role custom") ? (
                <th>Action</th>
              ) : null}
            </tr>
          </thead>
          <tbody className="role-tbody">
            {inProgress ? (
              <tr className="table-Rows-loading">
                 <td colSpan="7" style={{ textAlign: "center" }}>
                  <h3 className="custom-no-data">Loading Data...</h3>
                  </td>
              </tr>
            ) : (
              AllRoleList?.map((role) => (
                <tr key={role.id}>
                  <td>
                    {userPermissions.includes("Can view role permission") ||
                    userPermissions.includes("Can view permission") ||
                    userPermissions.includes("Can change role custom") ? (
                      <div
                        className="role-name"
                        style={{paddingLeft:"32px"}}
                        onClick={() => openPermissionModal(role)}
                      >
                        {role.name}
                      </div>
                    ) : (
                      <div className="no-wrap role-th" style={{paddingLeft:"32px"}}>{role.name}</div>
                    )}
                  </td>
                  <td>
                    <div className="no-wrap">
                      {role?.scope ? role?.scope : "not define"}
                    </div>
                  </td>
                  <td>
                    <div className="no-wrap">
                      {role?.modified_at
                        ? formatDateRange(role?.modified_at)
                        : "not define"}
                    </div>
                  </td>
                  <td>
                    <div className="no-wrap">
                      {role?.created_by?.groups[0]?.name
                        ? role?.created_by?.groups[0]?.name
                        : "not define"}
                    </div>
                  </td>

                  <td>
                    <div className="table-actions">
                      {userPermissions.includes("Can change role custom") &&
                        !res_role.includes(role.name) && (
                          <Tooltip position="top" title="Edit Role">
                            <button  onClick={() => handleEdit(role)}>
                              <img className="edit-icon"
                                src="/images/icons/edit-green.png"
                                alt="edit"
                              />
                            </button>
                          </Tooltip>
                        )}
                    
                  
                      {userPermissions.includes("Can delete role custom") &&
                        !res_role.includes(role.name) && (
                          <Tooltip position="top" title="delete Role">
                            <button onClick={() => handleDelete(role.id)}>
                              <img  className="delete-icon"  src="/images/icons/delete.png" alt="delete" />
                            </button>
                          </Tooltip>
                        )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      
    </div>

    {
      pageCounts>1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )
    }

    {/* {pageCounts>1&&(
       <div className="projects-table-footer" style={{border:"none"}}>
       <div className="table-pagination">
         <button
           className="prev-scr"
           onClick={() => setCurrentPage(currentPage - 1)}
           disabled={currentPage === pages[0] ? true : false}
         >
           {"PREV"}
         </button>
         {renderPageNumbers}
         <button
           className="next-scr"
           onClick={() => setCurrentPage(currentPage + 1)}
           disabled={currentPage === pages[pages.length - 1] ? true : false}
         >
           {"NEXT"}
         </button>
       </div>
     </div>
    )} */}
   
</>
  );
}
