import React from "react";

const IncludePlans = ({ name }) => {
  const imageSrc = `/images/icons/${name}.png`;

  let borderColor;
  switch (name) {
    case "WebCam":
      borderColor = "#36B37E";
      break;
    case "Project & Task Report":
      borderColor = "#3F8A9A";
      break;
    case "Screenshot":
      borderColor = "#563E9A";
      break;
    case "Attendence":
      borderColor = "#B6446D";
      break;
    case "Employee Settings":
      borderColor = "#B96247";
      break;
    case "Leave Module":
      borderColor = "#007EC5";
      break;
    case "Payroll":
      borderColor = "#36B37E";
      break;
    case "Time Line LOGS":
      borderColor = "#563E9A";
      break;
    case "Work schedule":
      borderColor = "#B6446D";
      break;


    default:
      borderColor = "#b6e1e7"; // Default border color
  }

  // Style object for the parent div
  const parentDivStyle = {
    border: `1px solid ${borderColor}`,
  };

  const dynamicImageDivStyle = {
    border: `1px solid ${borderColor}`,
  };

  return (
    <div className="plan-feature-container" style={parentDivStyle} >
      <div style={{display: "flex", position: "relative"}}>

        <div  className="dynamic-image-div" style={dynamicImageDivStyle}>
          <img src={imageSrc} alt="" height={15} />
        </div>

        <div style={{ padding: "2px 11px" }}>{name}</div>
      </div>

    </div>
  );
};

export default IncludePlans;
