import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./selectEmployeePR.css";
import { getEmployeeList } from "../../redux/Actions/employeesAction";
import { useDispatch, useSelector } from "react-redux";

export default function SelectEmployeePR({ selectedUsers, setSelectedUsers,name,setName }) {
  const dispatch = useDispatch();
  const [Search, setSearch] = useState("");
  const { employeesList } = useSelector((store) => store.ER);

  // const sortedemployeesList = [...employeesList].sort((a, b) => {
  //   if (selectedUsers.includes(a.id) && !selectedUsers.includes(b.id)) {
  //     return -1;
  //   } else if (!selectedUsers.includes(a.id) && selectedUsers.includes(b.id)) {
  //     return 1; // b should come before a
  //   } else {
  //     return 0;
  //   }
  // });

  useEffect(() => {
    dispatch(getEmployeeList(Search));
  }, [dispatch, Search]);

  const handleSelectUser = (ID,NAME) => {
    if (selectedUsers.includes(ID)) {
      setSelectedUsers( []);
      setName();
    } else {
      setSelectedUsers( [ID]);
      setName(NAME);
    }
  };

  const clearall=(e)=>{
    setSelectedUsers([]);
    setName();

  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Select User
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="dropdown-menu-container">
          <input
            className="dropdown-search-input"
            type="text"
            placeholder="search user.."
            value={Search}
            onChange={(e) => setSearch(e.target.value)}
          />

<div className="dropdown-user-list-container">
  {employeesList.length > 0 ? (
    [ // Wrap the list in an array to insert the "Clear All Filters" option at the beginning
      <Dropdown.Item key="clear-all">
        <div
          className="dropdown-user-list-item clear-all-item"
          onClick={() => clearall()}
        >
          Clear All Filters
        </div>
      </Dropdown.Item>,
      ...employeesList.map((employee) => (
        <Dropdown.Item key={employee.id}>
          <div
            className="dropdown-user-list-item"
            onClick={() => handleSelectUser(employee.id, employee.name)}
          >
            <input
              type="checkbox"
              checked={selectedUsers.includes(employee.id)}
            />{" "}
            <img
              src={employee.profile_image}
              alt=""
              width="35"
              height="30"
              style={{ borderRadius: "20px" }}
            />{" "}
            <p>{employee.name}</p>
          </div>
        </Dropdown.Item>
      ))
    ]
  ) : (
    <h5>No Result found</h5>
  )}
</div>

        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
