import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getTimeZone,
  updateTimeZone,
  getSubordinates,
} from "../../redux/Actions/authAction";
import LatestScreenShorts from "../../components/DashBoard/LatestScreenShorts";
import EmployeeList from "../../components/DashBoard/EmployeeList";
import { localStorage_userData } from "../../constants";
import TotalTimeWorked from "../../components/DashBoard/TotalTimeWorked";
import Header from "../../components/common/Header";
import { get_active_plan, get_active_plan_features } from "../../redux/Actions/subscription";
import { useNavigate } from "react-router-dom";

export default function DashBoard() {
  const dispatch = useDispatch();
  const { timeZones, userInfo } = useSelector((store) => store.auth);
  const [TimeZone, setTimeZone] = useState(userInfo?.timezone);
  const { userPermissions } = useSelector((store) => store.auth);
  const { feature_list } = useSelector((store) => store.subPlan);
  const navigate=useNavigate();

  useEffect(() => {
    dispatch(getTimeZone());
    dispatch(getSubordinates(userInfo.id));
    dispatch(get_active_plan_features(userInfo.organisation.name));
  

  }, [dispatch]);

  useEffect(() => {
    let id = userInfo?.id;
    let timezone = {
      timezone: TimeZone,
    };
    if (userInfo?.id) {
      dispatch(updateTimeZone(timezone, id, userInfo));
    }
  }, [TimeZone, userInfo?.timezone]);



  useEffect(() => {
    let data = JSON.parse(localStorage.getItem(localStorage_userData));
    if (data !== null) {
      let newData = { ...data, timezone: TimeZone };
      localStorage.setItem(localStorage_userData, JSON.stringify(newData));
      if (userInfo?.timezone !== TimeZone) {
        window.location.reload();
      }
    }
  }, [TimeZone, userInfo?.timezone]);

  return (
    <div className="page-container">
      <div className="dashboard-header">
        <Header title={userInfo?.organisation?.name} />
      </div>

      <div className="dashboard-content-container">
        <div className="dashboard-left-section">
          <TotalTimeWorked />
          <EmployeeList />
        </div>
        <div className="dashboard-right-section">
          {(userPermissions.includes("View own users' screenshots/webcam") ||
            userPermissions.includes("View all users' screenshots/webcam")) &&
          feature_list.includes("Screenshot") ? (
            <LatestScreenShorts />
          ) : null}
        </div>
      </div>
    </div>
  );
}
