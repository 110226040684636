import React from 'react';

const PaymentSuccess = () => {
  const styles = {
    body: {
      margin: 0,
      fontFamily: 'Arial, sans-serif',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f2f2f2',
    },
    container: {
      textAlign: 'center',
    },
    card: {
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'white',
      textAlign: 'center',
    },
    heading: {
      color: '#28a745',
      fontSize: '2em',
      marginBottom: '20px',
    },
    message: {
      fontSize: '1.2em',
      marginBottom: '30px',
    },
    tickmarkContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    tickmark: {
      width: '50px',
      height: '50px',
      backgroundColor: '#28a745',
      maskImage: 'url(\'data:image/svg+xml,<svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>\')',
      maskSize: 'cover',
      display: 'inline-block',
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div style={styles.card}>
          <h1 style={styles.heading}>Payment Successful</h1>
          <p style={styles.message}>Thank you for your purchase! Your payment was successful.</p>
          <div style={styles.tickmarkContainer}>
            <div style={styles.tickmark}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
