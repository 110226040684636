import React from "react";
import "./styles.css";
import Modal from "../Modal";
import moment from "moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ScreenShortModal({
  open,
  onClose,
  hendleNextImg,
  SS,
  screenshots,
  handlePreviousImg,
  currentPage,
  pageCounts,
}) {
  // console.log("SSSSSS", SS);
  return (
    <Modal open={open} onClose={onClose}>
      {SS && (
        <>
          <div className="Screenshort-modal-container">
            <div className="Screenshort-modal-img-container">
              <img
                className="Screenshort-modal-img-container-image"
                src={SS ? SS.userScreenshot : ""}
                alt="ss"
              />
            </div>

            {(currentPage > 1 ||
              screenshots.findIndex((item) => item.id === SS.id) !== 0) && (
              <button
                onClick={handlePreviousImg}
                className="Screenshort-modal-container-switch-btn previous"
                style={{ left: 0 }}
              >
                <img
                  height="20px"
                  width="20px"
                  src="/images/icons/arrow-filled-left.png"
                  alt="SS-previous-btn"
                />
              </button>
            )}

            {(currentPage < pageCounts ||
              screenshots.findIndex((item) => item.id === SS.id) !==
                screenshots.length - 1) && (
              <button
                onClick={hendleNextImg}
                className="Screenshort-modal-container-switch-btn next"
                style={{ right: 0 }}
              >
                <img
                  height="20px"
                  width="20px"
                  src="/images/icons/arrow-filled-right.png"
                  alt="SS-next-btn"
                />
              </button>
            )}
          </div>

          <div className="Screenshort-modal-details-container">
            <Container>
              <Row>
                <Col xs={8}>
                  <Container>
                    <Row>
                      <Col>
                        <span className="Screenshort-modal-user-name">
                          {SS?.user?.name}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="Screenshort-modal-details">
                          Screenshot Time:{" "}
                          {moment(SS?.datetime_of_capture).format("h:mm A")}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="Screenshort-modal-details">
                          Project:
                          <span>
                            {SS?.project == null ? "N/A" : SS?.project.name}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="Screenshort-modal-details">
                          Task:
                          <span>{SS?.task_name}</span>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Col>

                <Col xs={4}>
                  <Container>
                    <Row>
                      <div>
                        Activity Level: <progress value={20} max={100} />
                      </div>
                    </Row>
                    <Row>{/*  To do Add table  */}</Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </Modal>
  );
}
