import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddButton from "../../components/AddButton";
import SearchInput from "../../components/SearchInput";
import EmployeesTable from "../../components/ResourceManagement/EmployeesTable";
import AddUser from "../../components/common/AddUser";
import { useNavigate } from "react-router";
import useEmployeeAndSettings from "../../hooks/useEmployeeAndSettings";
import { toast } from "react-toastify";
import { useEffect } from "react";


export default function EmployeeSettings() {
  const { userPermissions } = useSelector((store) => store.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);
  const [daysLeft, setDaysLeft] = useState(4);
  const { active_subscription_list } = useSelector((store) => store.subPlan);
  const navigate=useNavigate();
  const {
    searchValue,
    setSearchValue,
    allEmployees,
    TabChange,
    inProgress,
    currentPage,
    setCurrentPage,
    pageCounts,
    totalValues,
    tableType,
    setTableType,
    TotalCount_Archived,
    TotalCount_Active,
    RemoveUser,
    handleChange,
    handleSubmit,
    employeeValues,
    cancleEmployeeAdd,
    employeeErrors,
    ResendInvite,
    CancelInvite,
    handleManagerIds,
    employeesList,
    selectedOptions,
    setSelectedOptions, 
    TotalCount_Pending,
    selectedGroups,
    setSelectedGroups,
    handleGroup,
    userInfo,
    totalpages
  } = useEmployeeAndSettings(employeeID, setModalOpen, setEmployeeID);
  useEffect(() => {
    if (active_subscription_list && active_subscription_list.length > 0) {
      const expiryDate = new Date(active_subscription_list[0].expiry_date);
      const currentDate = new Date();
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const daysLeft = Math.ceil((expiryDate - currentDate) / millisecondsPerDay);
      setDaysLeft(daysLeft);
    }
  }, [active_subscription_list]);

  const {userlimit}  = useSelector((store) => store.subPlan);



  const addEmployee = () => {
    
    setEmployeeID(null);
    setModalOpen(true);
    
  };
  // const addNewEmployee = () => {
  //   setEmployeeID(null);
  //   setNewUserModal(true);
  // };

  const editEmployee = (id) => {
    setEmployeeID(id);
    setModalOpen(true);
  };
  const handleAddNewUser = () => {
   
   
    navigate("add-new-employee");
    

  };

  return (
    <div>
      <div className="resource-content-header-style">
      <div >
        <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
       </div>
        <div className="adduser-btn-header">
        <div>
  {userPermissions?.includes("Can add user") && (
    <AddButton title="Add Existing User" onClick={addEmployee} />
  )}
</div>

       
          {/* <div className="btn-new-user">
          {userPermissions?.includes("Can add user") && (
          <AddButton title="Add New User"  onClick={handleAddNewUser}/>
          )}

          </div> */}
          </div>

      </div>

      <EmployeesTable
        editEmployee={(id) => editEmployee(id)}
        hookProps={{
          allEmployees,
          inProgress,
          currentPage,
          setCurrentPage,
          pageCounts,
          totalValues,
          tableType,
          setTableType,
          TotalCount_Archived,
          TotalCount_Active,
          RemoveUser,
          TabChange,
          ResendInvite,
          CancelInvite,
          TotalCount_Pending,
          userInfo,
          totalpages
        }}
      />

      <AddUser
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hookProps={{
          handleChange,
          handleSubmit,
          employeeValues,
          cancleEmployeeAdd,
          employeeErrors,
          employeeID,
          allEmployees,
          handleManagerIds,
          employeesList,
          selectedOptions,
          setSelectedOptions,
          selectedGroups,
          setSelectedGroups,
          handleGroup, 
        }}
      />
    </div>
  );
}
