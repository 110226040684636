import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";
import moment from "moment";

export const WORKING_HOUR_LIST = " WORKING_HOUR_LIST";
export const ACTIVITY_BAR = " ACTIVITY_BAR";


export const getworkinghourList = (
  data,
  user,
  project,
  setLoading,
  itemsperpage,
  setPageCounts,
  currentPage,
  dateRangeStart,
  dateRangeEnd
) => {
  return async (dispatch) => {
    let queryParams = `?items_per_page=${itemsperpage}&ordering=-logStartdateandTime&page=${currentPage}`;
  
  if (data) {
    const start_date = moment(data.start_date).format("YYYY-MM-DD");
    const end_date = moment(data.end_date).format("YYYY-MM-DD");
    queryParams += `&start_date=${start_date}&end_date=${end_date}`;
  } console.log("userid",user);
  console.log("projectid",project);
  const selectedUserIds = user.length > 0 ? user.join('&user=') : null;
  const userQueryParam = selectedUserIds ? `&user=${selectedUserIds}` : ``;
  
  const selectedProjectIds = project.length > 0 ? project.join('&project=') : null;
  const projectQueryParam = selectedProjectIds ? `&project=${selectedProjectIds}` : ``;
  
  const finalQuery = queryParams + userQueryParam + projectQueryParam;
  

    try {
      setLoading(true);
      const response = await Axios_v2.get(`/working-hour-logs/${finalQuery}`, HeaderToken());

      //   if (response.data.length > 0) {
      //     const user_id = response.data[0].user_id;
      //     setUser([user_id]);
      //     await getEmployeedAttendanceData();
      //   }
      if (response.status === 200) {
        dispatch({ type: WORKING_HOUR_LIST, payload: response.data.results });
        setPageCounts(response.data.total_pages);
      }

      

      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);
      // Handle error
    }
  };
};
export const getActivityBar = (
  data,
  user,
  project,
  setLoading,
  itemsperpage,
  setPageCounts,
  currentPage,
  dateRangeStart,
  dateRangeEnd
) => {
  return async (dispatch) => {
    let queryParams = `?items_per_page=${1000}`;

    if (data) {
      const start_date = moment(data.start_date).format("YYYY-MM-DD");
      const end_date = moment(data.end_date).format("YYYY-MM-DD");
      queryParams += `&date=${start_date}`;
    }
    console.log("userid", user);
    console.log("projectid", project);
    const selectedUserIds = user.length > 0 ? user.join('&user=') : null;
    const userQueryParam = selectedUserIds ? `&user=${selectedUserIds}` : ``;

    const finalQuery = queryParams + userQueryParam;

    try {
      setLoading(true);
      const response = await Axios_v2.get(`/progress-bar/${finalQuery}`, HeaderToken());
  
      if (response.status === 200) {
          console.log("Api", response);
          const maxSecondsInDay = 24 * 60 * 60; // Total seconds in a day
          let totalPercentage = 0; // Variable to store the total percentage
  
          response.data.forEach((log, index) => {
              const startDate = new Date(log.logStartdateandTime);
              const endDate = new Date(log.logEnddateandTime);
              const timeDiffMillis = endDate - startDate;
              const timeDiffSeconds = timeDiffMillis / 1000;
              const percentage = (timeDiffSeconds / maxSecondsInDay) * 100;
  
              // Update totalPercentage
              totalPercentage += percentage;
  
              // Format the time
              const hours = Math.floor(timeDiffSeconds / 3600);
              const minutes = Math.floor((timeDiffSeconds % 3600) / 60);
              const seconds = Math.floor(timeDiffSeconds % 60);
              const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
              // Add the percentage field to the log object
              log.percentage = percentage.toFixed(2) + '%';
              log.Total_Time = formattedTime;
              log.Start_Time = moment(log.logStartdateandTime).format(" h:mm A");
              log.End_Time = moment(log.logEnddateandTime).format(" h:mm A");
          });
  
          // Check if totalPercentage exceeds 100
          if (totalPercentage > 100) {
              // Adjust percentages proportionally
              const adjustmentFactor = 100 / totalPercentage;
              response.data.forEach(log => {
                  log.percentage = (parseFloat(log.percentage) * adjustmentFactor).toFixed(2) + '%';
              });
          }
  
          console.log('Total_Percentage:', totalPercentage.toFixed(2) + '%'); // Logging the sum of all percentages
          console.log("new_array", response.data);
          dispatch({ type: ACTIVITY_BAR, payload: response.data });
  
          response.data?.map((s, index) => {
              const startDate = moment(s.logStartdateandTime).format("DD-MM-YYYY, h:mm A");
              const endDate = moment(s.logEnddateandTime).format("DD-MM-YYYY, h:mm A");
              console.log("Start_Date", startDate);
              console.log("End_Date", endDate);
          });
      }
  
      setLoading(false);
  } catch (error) {
      console.error("Error:", error);
      setLoading(false);
  }
  

  };
};
