import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import { useSelector } from "react-redux";




export default function Payroll({ isAccountant }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userPermissions } = useSelector((store) => store.auth);

  console.log("location", location);

  let array=[];

  // if (!isAccountant) {
  //    array=[
  //     {
  //      title: "Download Payslip",
  //      link: "/Payroll",
  //      permission: (userPermissions.includes("Can view payslip")),
       
  //    },
  //    {
  //     title: "Download Form 16",
  //     link: "/Payroll/DownLoadform",
  //     permission: (userPermissions.includes("Can view Form 16")),

  //    },
  //   ];
  // } else {
     array=[
        
      {
        title: "Upload Payslip",
        link: "/Payroll",
        permission: (userPermissions.includes("Can upload payslip")),

      },
      {
        title: "Upload Form 16",
        link: "/Payroll/Uploadform",
        permission: (userPermissions.includes("Can upload Form 16")),

      },
      {
       title: "Download Payslip",
       link: "/Payroll/DownLoadpayslip",
       permission: (userPermissions.includes("Can view payslip")),

     },
     {
      title: "Download Form 16",
      link: "/Payroll/DownLoadform",
      permission: (userPermissions.includes("Can view Form 16")),
    },
    ];
  // }

  const resourcePath = array.filter(item => item.permission);
  useEffect(() => {
    // Check if the current path is accessible; if not, find the first accessible path
    const currentTab = resourcePath.find((path) => pathname === path.link);
    if (!currentTab || !currentTab.permission) {
      const defaultTab = resourcePath.find((path) => path.permission);
      if (defaultTab) {
        navigate(defaultTab.link);
      }
    }
  }, [pathname, navigate, resourcePath]);


  return (
    <div className="Parent-Div">
      <div className="resource-header">
        <h1>Payroll</h1>
        <div className="resource-tab-container">
          {resourcePath.map((path) => (
            <div
              className={`tab-button ${pathname === path.link && "active-tab"}`}
              key={path.title}
              onClick={() => navigate(path.link)}
            >
              <p>{path.title}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="resource-context-container">
        <Outlet />
      </div>
    </div>
  );
}
