import React from "react";

export default function SearchInput(props) {
  return (
    <div className="search-containert">
      <img src="/images/icons/search.png" alt="search"  style={{marginRight:'10px'}}/>
      <input style={{width:'160px',border:'none',height:'30px'}} type="text"  placeholder="Search..." {...props} />
    </div>
  );
}
