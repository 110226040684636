import Dropdown from "react-bootstrap/Dropdown";

import React, { useEffect, useState } from "react";
import { getProjectList } from "../../redux/Actions/resourceManagementActions";
import { useDispatch } from "react-redux";

export default function SelectAllProject({
  selectedProject,
  setSelectedProject,
}) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [projectsList, setProjectList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    dispatch(getProjectList(search, setProjectList));
  }, [dispatch, search, setProjectList]);

  const handleSelectProject = (ID) => {
    if (selectedProject.includes(ID)) {
      setSelectedProject((prevValue) => prevValue?.filter((val) => val !== ID));
    } else {
      setSelectedProject((prevValue) => [...prevValue, ID]);
    }
    setSelectAll(false);
  };
 
  const handleSelectAll = () => {
    if (!selectAll) {
      const updatedCheckboxes = projectsList.map((checkbox) => checkbox.id);
      setSelectedProject(updatedCheckboxes);
      console.log("updatedCheckboxes", updatedCheckboxes);
    } else {
      setSelectedProject([]);
    }
    setSelectAll(!selectAll);
  };
   const handleClearSearch = () => {
    setSearch(""); // Resetting the search query
    dispatch(getProjectList("", setProjectList)); // Fetching projects again with an empty search query
  };

  return (
    <Dropdown>
    <Dropdown.Toggle
      variant="success"
      id="select-project-workdiary"
      className="custom-drop"
     
     
    >
      <span className="select-text">Select Project</span>
      {/* Add your custom arrow icon here */}
      <div style={{marginLeft:'100px'}}>
      <img
        src="/images/icons/Shapearrowdown.png"
        alt="Arrow"
       
      />
      </div>
    </Dropdown.Toggle>

      <Dropdown.Menu style={{height:'275px'}}>
        <div className="dropdown-menu-container overflow-hidden">
          <div className="">
            {/* <SearchInput type="text"
              placeHolder="Search..."
              value={search} 
              onChange={(e) => setSearch(e.target.value)} /> */}

              <div className="search-container custom-style-search">
                <img src="/images/icons/search.png" alt="search" className="search-icon"/>
                <input type="text"  
                  placeholder="Search..." 
                  onChange={(e) => setSearch(e.target.value)} 
                  style={{width:"170px"}} 
                  value={search}
                  className="dropdown-search-input custom-font custom-placeholder-color"
                />

                {search && (
                  <img
                    src="/images/icons/clear-icon.svg"
                    alt="Clear Search"
                    className="clear-icon"
                    onClick={handleClearSearch} 
                  />
                )}

              </div>
              {/* <div style={{ marginLeft: "auto" }} >
              <img
                src="/images/icons/clear-icon.svg"
                alt="Clear Search"
                className="clear-icon"
                style={{marginRight:"5px"}}
                onClick={handleClearSearch} 
              />
            </div> */}
          </div>
          {/* <input
            
            type="text"
            placeHolder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
          <hr style={{ margin: "0px" }} />
          <div style={{ display: "flex", margin: "5px 0px 0px 9px" }}>
            <img
              src={selectAll ? "/images/icons/checked-icon.svg" : "/images/icons/uncheck-icon.svg"}
              alt={selectAll ? "Checked" : "Unchecked"}
              onClick={handleSelectAll}
              style={{ cursor: "pointer", marginRight: "5px" }}
            />
            <p className="mx-2" style={{ cursor: "pointer", fontSize:'16px', color:'grey'}} onClick={handleSelectAll}>Select All</p>
          </div>

          {/* <Dropdown.Divider /> */}

          <div className="dropdown-user-list-container">
            {projectsList.length > 0 ? (
              projectsList.map((employee) => (
                <div
                  className="dropdown-user-list-item"
                  key={employee.id}
                  onClick={() => handleSelectProject(employee.id)}
                >
                  <div style={{ display: "flex", margin: "5px 0px 0px 9px" }}>
  <img
    src={selectedProject.includes(employee.id) ? "/images/icons/checked-icon.svg" : "/images/icons/uncheck-icon.svg"}
    alt={selectedProject.includes(employee.id) ? "Checked" : "Unchecked"}
    style={{ cursor: "pointer", marginRight: "5px" }}
  
  />
  <p
    style={{
      fontSize: '16px', 
     
      color: selectedProject.includes(employee.id) ? 'black' : 'grey',
      backgroundColor: selectedProject.includes(employee.id) ? 'var(--silver-phoenix)' : 'transparent',
      padding: '5px',
      borderRadius: '5px',
      width:'230px',
      height:'30px',
      display:'flex',
      textAlign:'left'
      
      
    }}
  >
    {employee.name}
  </p>
</div>
                </div>
              ))
            ) : (
              <h5 className="custom-no-data">No Result found</h5>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
