import React, { useState } from "react";
import moment from "moment";
import { RenderPageButtons } from "../../../Utils/randerPageButtons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { attendance_update } from "../../../redux/Actions/reportAction";
import { useDispatch } from "react-redux";
import useEmployeeList from "../../../hooks/useEmployeeList";
import theme from "../../../theme/theme";
import Pagination from "../Pagination/pagination";

const EmpTableRow = ({
  employeeData,
  currentTab,
  isActionAvailable,
  callAttendanceList,
  expanded,
  toggleExpansion,
}) => {
  const { leaveTypeList } = useSelector((store) => store.leave);
  const options = leaveTypeList.map((item) => item.reason);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.auth);

  const { name, attendance, profile_image, leave_id, id } = employeeData;
  const { list_of_days } = attendance;

  return list_of_days.map((dayData, index) => {
    console.log("daydata", dayData);

    let workedTime = dayData.total_hours_worked;
    let workedTimeWithinShift = dayData.actual_hours_worked_within_shift;

    const handleOptionChange = (event, reasonid) => {
      const selectedValue = event.target.value;
      if (selectedValue !== "select reason") {
        dispatch(
          attendance_update(reasonid, selectedValue, callAttendanceList)
        );
      }
    };

    if (index == 0 || expanded) {
      return (
        <tr key={dayData.id}>
          <td className="first-td">
            {index === 0 && (
              <div className="name-container">
                {currentTab !== "daily" && (
                  <img
                    className="sort-list"
                    src="/images/icons/arrow.png"
                    alt="arrow"
                    style={{ transform: expanded ? "rotate(90deg)" : "" }}
                    onClick={toggleExpansion}
                    onError={(e) => {
                      e.target.src = "/images/profilepic.png";
                    }}
                  />
                )}
                {/* <img
                  src={profile_image ? profile_image : "/images/profile.png"}
                  width="40px"
                  height="30"
                  style={{ borderRadius: "20px" }}
                  alt="profile"
                  onError={(e) => {
                    e.target.src = "/images/profilepic.png";
                  }}
                />{" "} */}
                {name}
              </div>
            )}
          </td>
          <td>{moment(dayData.current_date).format("ddd, DD MMM")}</td>
          <td>
            {dayData.shift_start_time
              ? moment(dayData.shift_start_time).format("LT")
              : "N/A"}
          </td>
          <td>
            {dayData.actual_start_time
              ? moment(dayData.actual_start_time).format("LT")
              : "N/A"}
          </td>
          <td>{`${workedTimeWithinShift?.hours}H ${workedTimeWithinShift?.minutes}M`}</td>
          <td>{`${workedTime.hours}H ${workedTime.minutes}M`}</td>
          <td style={{ textTransform: "uppercase" }}>
            {dayData.over_time
              ? `${dayData.over_time.hours}H ${dayData.over_time.minutes}M`
              : "N/A"}
          </td>
          <td
            style={{
              color:
                dayData.status === "Present"
                  ? null
                  : dayData.status === "Partially Absent"
                  ? "var(--hotCortal)"
                  : "red",
            }}
          >
            {dayData.status}
          </td>

          {isActionAvailable && (
            <td>
              {dayData.status !== "Present" &&
                (() => {
                  let isvalid = false;

                  return (
                    <>
                      {dayData.reason && (
                        <>
                          {console.log("name", name)}
                          {console.log("reason", dayData.reason)}
                          {console.log(
                            "dayData.reason",
                            JSON.stringify(dayData.reason)
                          )}
                          {console.log("Data_type", typeof dayData.reason)}

                          {(isvalid = true)}
                        </>
                      )}
                      {!dayData.reason && (
                        <>
                          {console.log("name", name)}
                          {console.log("no reason")}
                          {(isvalid = false)}
                        </>
                      )}
                      {console.log("isvalid", isvalid.toString())}
                      {console.log("id", id)}
                      {console.log("userinfo", userInfo.id)}

                      {id == userInfo.id && !isvalid ? (
                        <select
                          value={dayData.reason}
                          onChange={(event) =>
                            handleOptionChange(event, dayData.id)
                          }
                          disabled={isvalid}
                        >
                          <option value="select reason">Select Reason</option>
                          {options.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "whtie",
                            color: "blacj",
                            padding: "10px",
                          }}
                        >
                          {dayData.reason}
                        </div>
                      )}
                    </>
                  );
                })()}
            </td>
          )}
        </tr>
      );
    }
    return null;
  });
};

const EmployeeListTable = ({
  data,
  currentTab,
  inProgress,
  isActionAvailable,
  callAttendanceList,
  hookProps,
}) => {
  const { currentPage, setCurrentPage, pageCounts, setPageCounts } = hookProps;
  const pages = [];

  for (let i = 1; i <= pageCounts; i++) {
    pages.push(i);
  }
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };
  const renderPageButtons = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );
  console.log("data", data.length, currentPage, renderPageButtons?.length);

  return (
    <>
      <table className="employee-list-table">
        <thead>
          <tr>
            <th className="custom-font first-th">Name</th>
            <th className="custom-font">Date</th>
            <th className="custom-font">Shift Start</th>
            <th className="custom-font">Start time</th>
            <th className="custom-font">Shift Hours</th>
            <th className="custom-font">Total Hours</th>
            <th className="custom-font">Overtime</th>
            <th className="custom-font">Status</th>
            {isActionAvailable && <th>Reason</th>}
          </tr>
        </thead>
        <tbody>
          {inProgress==true ? (
            <tr>
              <td colSpan={12}>
                <h4 className="custom-no-data">Loading Data...</h4>  
              </td>
            </tr>
          ) : data?.length > 0 ? (
            data.map((employeeData) => (
              <EmpTableRow
                employeeData={employeeData}
                currentTab={currentTab}
                isActionAvailable={isActionAvailable}
                callAttendanceList={callAttendanceList}
                expanded={expandedRows[employeeData.id]}
                toggleExpansion={() => toggleRowExpansion(employeeData.id)}
              />
            ))
          ) : (
            <tr>
              <td colSpan={12}>
                <h3 className="custom-no-data">No Data to Show!</h3>
              </td>
            </tr>
          )}
        </tbody>
      </table>


      {
        renderPageButtons.length>1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )
    }

      {/* {renderPageButtons.length > 1 && (
        <div className="projects-table-footer">
          <div className="table-pagination">
            <button
              className="prev-scr"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === pages[0] ? true : false}
            >
              {"PREV"}
            </button>

            {renderPageButtons}
            <button
              className="next-scr"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === pages[pages.length - 1] ? true : false}
            >
              {"NEXT"}
            </button>
          </div>
        </div>

      )}  */}
    </>
  );
};

export default EmployeeListTable;
