// MyTable.js
import React, { useState } from "react";

const MyTable = () => {
  const [values, setValues] = useState({
    Basic:{ monthly: "26,000", annually: "312,000" },
    HRA: { monthly: "10,400", annually: "124,800" },
    "Medical Allowance": { monthly: "-", annually: "-" },
    "Conveyance Allowance": { monthly:  "-", annually:  "-" },
    "Special Allowance": { monthly: "27,212", annually: 32652 },
    "Night Allowance": { monthly:  "-", annually:  "-" },
    "Gross": { monthly: "63,611", annually: 0 },
    "Employee PF": { monthly:  "-", annually:  "-" },
    "Employer ESI": { monthly:  "-", annually:  "-" },
    "Gratuity (Provision)": { monthly: "1,251", annually: "15,007" },
    "Bonus (Provision)": { monthly:  "-", annually:  "-" },
    "Group Medical Insurance": { monthly: 138, annually: "1,656" },
    "CTC": { monthly: "65,000", annually: "780,000" },
    "Deduction": { monthly: 0,  },
    "PF-Employee": { monthly:  "-", },
    "ESI Employee": { monthly:  "-", },
    "Punjab Professional Tax": { monthly:  "-",  },
    "TDS": { monthly:  "-", },
    "Total Deductions": { monthly:  "-" },
    "Net Pay": { monthly: "63,611" },
    "Bonus Payment": { monthly:  "-" },
    "Monthly Take Home Salary": { monthly: "63,611" },
  });

  const handleCellEdit = (event, name, type) => {
    const inputValue = event.target.value.trim();
  
    // If the value is a valid number or "-", update the state
    const regex = /^-?\d*\.?\d*$|^-?$/;
    if (regex.test(inputValue)) {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: { ...prevValues[name], [type]: inputValue },
      }));
  
      // Set the text alignment to right
      event.target.style.textAlign = "right";
  
      // Ensure the content is displayed correctly
      event.target.value = parseFloat(inputValue) || inputValue;
    } else {
      // If the input is invalid, reset the value to the previous valid value
      event.target.value = event.target.value.slice(0, -1);
    }
  };
  
  

  return ( 
    <>
    <div className="offer-table-container">
    <h3 className="table-header">Annexure-I</h3>
    <table className="offer-table">
      <thead>
        <tr>
        <th className="header-row">Components</th>
          <th className="header-row" style={{textAlign:"right"}}>Monthly</th>
          <th className="header-row"style={{textAlign:"right"}}>Annually</th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <td>Basic</td>
          <td
          onInput={(e) => handleCellEdit(e, "Basic", "monthly")}
            onBlur={(e) => handleCellEdit(e, 'Basic', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values.Basic.monthly}
          </td>
          <td
          onInput={(e) => handleCellEdit(e, "Basic", "monthly")}
            onBlur={(e) => handleCellEdit(e, 'Basic', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values.Basic.annually}
          </td>
        </tr>
        <tr>
          <td>HRA</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'HRA', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values.HRA.monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'HRA', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values.HRA.annually}
          </td>
        </tr>
        <tr>
          <td>Medical Allowance</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Medical Allowance', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Medical Allowance"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Medical Allowance', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Medical Allowance"].annually}
          </td>
        </tr>
        <tr>
          <td>Conveyance Allowance</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Conveyance Allowance', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Conveyance Allowance"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Conveyance Allowance', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Conveyance Allowance"].annually}
          </td>
        </tr>
        <tr>
          <td>Special Allowance</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Special Allowance', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Special Allowance"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Special Allowance', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Special Allowance"].annually}
          </td>
        </tr>
        <tr>
          <td>Night Allowance</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Night Allowance', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Night Allowance"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Night Allowance', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Night Allowance"].annually}
          </td>
        </tr>
        <tr>
        <td colSpan="3"></td>
        </tr>
        <tr className="header-row" >
          <td style={{border:"2px solid black"}} >Gross</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Gross', 'monthly')}
            contentEditable
            suppressContentEditableWarning
            style={{border:"2px solid black"}}
          >
            {values["Gross"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Gross', 'annually')}
            contentEditable
            suppressContentEditableWarning
            style={{border:"2px solid black"}}
          >
            {values["Gross"].annually}
          </td>
        </tr>
        <tr>
          <td>Employee PF</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Employee PF', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Employee PF"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Employee PF', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Employee PF"].annually}
          </td>
        </tr>
        <tr>
          <td>Employer ESI</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Employer ESI', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Employer ESI"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Employer ESI', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Employer ESI"].annually}
          </td>
        </tr>
        <tr>
          <td>Gratuity (Provision)</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Gratuity (Provision)', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Gratuity (Provision)"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Gratuity (Provision)', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Gratuity (Provision)"].annually}
          </td>
        </tr>
        <tr>
          <td>Bonus (Provision)</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Bonus (Provision)', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Bonus (Provision)"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Bonus (Provision)', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
           {values["Bonus (Provision)"].annually}
          </td>
        </tr>
        <tr>
          <td>Group Medical Insurance</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Group Medical Insurance', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Group Medical Insurance"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Group Medical Insurance', 'annually')}
            contentEditable
            suppressContentEditableWarning
          >
           {values["Group Medical Insurance"].annually}
          </td>
        </tr>
        
        <tr className="header-row"> 
          <td style={{border:"2px solid black"}}>CTC</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'CTC', 'monthly')}
            contentEditable
            suppressContentEditableWarning
            style={{border:"2px solid black"}}
          >
            {values["CTC"].monthly}
          </td>
          <td
            onBlur={(e) => handleCellEdit(e, 'CTC', 'annually')}
            contentEditable
            suppressContentEditableWarning
            style={{background:"#92D050",color:"black",fontWeight:"bold",border:"2px solid black"}}
          >
           {values["CTC"].annually}
          </td>
        </tr>
        <tr>
        <td colSpan="3"></td>
        </tr>
        <tr>
          <td>Deduction</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Deduction', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Deduction"].monthly}
          </td>
          <td
            style={{borderBottomColor:" #D6D6D6"}}
          >
            {values["Deduction"].annually}
          </td>
        </tr>
        <tr>
          <td>PF-Employee</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'PF-Employee', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["PF-Employee"].monthly}
          </td>
          <td
            style={{borderBottomColor:" #D6D6D6"}}

          >
            {values["PF-Employee"].annually}
          </td>
        </tr>
        <tr>
          <td>ESI Employee</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'ESI Employee', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["ESI Employee"].monthly}
          </td>
          <td
            style={{borderBottomColor:" #D6D6D6"}}

          >
            {values["ESI Employee"].annually}
          </td>
        </tr>
        <tr>
          <td>Punjab Professional Tax</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'Punjab Professional Tax', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Punjab Professional Tax"].monthly}
          </td>
          <td
            style={{borderBottomColor:" #D6D6D6"}}

          >
            {values["Punjab Professional Tax"].annually}
          </td>
        </tr>
        <tr>
          <td>TDS</td>
          <td
            onBlur={(e) => handleCellEdit(e, 'TDS', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["TDS"].monthly}
          </td>
          <td
            style={{borderBottomColor:" #D6D6D6"}}

          >
            {values["TDS"].annually}
          </td>
        </tr>
        <tr>
          <td className="header-row">Total Deductions</td>
          <td className="header-row"
            onBlur={(e) => handleCellEdit(e, 'Total Deductions', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Total Deductions"].monthly}
          </td>
            
          <td style={{borderBottomColor:" #D6D6D6"}} >
            {values["Total Deductions"].annually}
          </td>
        </tr>
        <tr>
        <td colSpan="3" style={{borderBottomColor:" #D6D6D6"}}></td>
        </tr>
        <tr>
          <td className="header-row">Net Pay</td>
          <td className="header-row"
            onBlur={(e) => handleCellEdit(e, 'Net Pay', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Net Pay"].monthly}
          </td>
          <td style={{borderBottomColor:" #D6D6D6"}}>
            {values["Net Pay"].annually}
          </td>
        </tr>
        <tr>
          <td className="header-row">Bonus Payment</td>
          <td className="header-row"
            onBlur={(e) => handleCellEdit(e, 'Bonus Payment', 'monthly')}
            contentEditable
            suppressContentEditableWarning
          >
            {values["Bonus Payment"].monthly}
          </td>
          <td style={{borderBottomColor:" #D6D6D6"}}>
            {values["Bonus Payment"].annually}
          </td>
        </tr>
        <tr >
          <td style={{color:"black",fontWeight:"bold",background:"#01B051"}}>Monthly Take Home Salary</td>
          <td
            
            onBlur={(e) => handleCellEdit(e, 'Monthly Take Home Salary', 'monthly')}
            contentEditable
            suppressContentEditableWarning
            style={{color:"black",fontWeight:"bold",background:"#01B051"}}
          >
            {values["Monthly Take Home Salary"].monthly}
          </td>
          <td style={{borderBottomColor:"  #D6D6D6"}}> 
           
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    </>
  );
};

export default MyTable;
