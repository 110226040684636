import React, { useState } from "react";

const Accordion = ({
  module,
  permissions,
  onPermissionSelection,
  onModuleSelection,
  isPermissionSelected,
  isAllPermissionsSelected
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };
  console.log("module", module);
  
    const removeCanFromString = (inputString) => {
      // Split the input string into words
      if(inputString=="Can reshare payslip"){
        return "reshare document";
      }
      const words = inputString.split(' ');
  
      // Remove the word "Can" (case-insensitive)
      const modifiedWords = words.filter(word => word.toLowerCase() !== 'can');
  
      // Join the modified words back into a string
      const modifiedString = modifiedWords.join(' ');
  
      return modifiedString;
    };
  
  
  return (
    <div className="accordion">
      <div
        className={`accordion-header border px-3  ${
          isExpanded ? "expanded" : ""
        }`}
        onClick={toggleAccordion}
      >
        <div className="row align-items-center">
          <div className="col-auto">
            <input
              type="checkbox"
              onChange={(e) => onModuleSelection(module, e.target.checked)}
              checked={isAllPermissionsSelected(module)}
            />
          </div>
          <div className="col">
            <h5>{module ? module : "Miscellaneous"}</h5>
          </div>
          <div className="col-auto">{isExpanded ? "▲" : "▼"}</div>
        </div>
      </div>
      {isExpanded && (
        <div className="accordion-content mb-2">
          {permissions.map((item, index) => (
            <div key={index} className="permission-item">
              <label className="mx-3">
                <input
                  type="checkbox"
                  onChange={() => onPermissionSelection(item.id, item.name,module)}
                  checked={isPermissionSelected(item.id)} 
                />
                <span className="mx-2">{removeCanFromString(item.name)}</span>
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Accordion;
