import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { post_api } from "../redux/Actions/payrollAction";
import { patch_api } from "../redux/Actions/payrollAction";

export default function usePayrollList() {
  const dispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);
  const [user, setUser] = useState([]);
  const { userInfo } = useSelector((store) => store.auth);
  const [startMonth, setStartMonth] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(1);
  const [filetoupload, setFiletoupload] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState(false);
  const [fileAction, setFileAction] = useState(false);
  const [name, setName] = useState();
  const [monthYearValue, setMonthYearValue] = useState();
  const [typeValue, setTypeValue] = useState("hello");
  const { documentLists } = useSelector((store) => store.Payr);
  const {documentListsDownload} = useSelector((store) => store.Payr);
  const fileInputRef = useRef(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [selectedYear, setselectedYear] = useState(moment());
  const currentYear = moment().year();
  const [isLoading, setIsLoading] = useState(true);

  const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [apiType, setAPI] = useState();

  function useOutsideAlerter(ref, calendarRef) {
    function handleClickOutside(event) {
      if (calendarIsOpen) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          calendarRef &&
          !calendarRef.current.contains(event.target)
        ) {
          setCalendarIsOpen(!calendarIsOpen);
        }
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }

  useOutsideAlerter(wrapperRef, calendarRef);

  const handleDecrease = () => {
    const Decrement = startMonth;
    Decrement.setMonth(Decrement.getMonth() - 1);
    const output = Decrement.toLocaleString();
    const op = moment(output).format(
      "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
    );
    setStartMonth(new Date(op));
  };

  const handleIncrease = () => {
    const Increment = startMonth;
    Increment.setMonth(Increment.getMonth() + 1);
    const output = Increment.toLocaleString();
    const op = moment(output).format(
      "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
    );
    setStartMonth(new Date(op));
  };

  const handleUpload = async (
    monthYearValue,
    typeValue,
    user,
    apiType,
    doc_id,
    setapiType
  ) => {
    if (apiType == "post") {
      const hasMatching_document = documentLists.some(
        (documentList) => documentList.user === user[0]
      );
      if (hasMatching_document) {
        toast.warn("can't add multipe documetns");
        return;
      }
    }

    let req = {
      user: user[0],
      month_year: monthYearValue,
      type: typeValue,
      // groups_ids: userInfo.groups_ids,
      // profile_image: profileImg,
    };

    let formData = new FormData();

    Object.keys(req).forEach((key) => {
      formData.append(key, req[key]);
    });

    if (filetoupload !== null) {
      formData.append("file", filetoupload, filetoupload.name);
    }
    if (apiType == "post") {
      dispatch(
        post_api(
          formData,
          setUser,
          setName,
          setSelectedFile,
          setFiletoupload,
          name,
          doc_id
        )
      );
    } else {
      dispatch(
        patch_api(
          formData,
          setUser,
          setName,
          setSelectedFile,
          setFiletoupload,
          name,
          doc_id,
          setapiType
        )
      );
    }
  };

  const handleDecrease_year = () => {
    setselectedYear(selectedYear.clone().subtract(1, "year"));
  };

  const handleIncrease_year = () => {
    const nextMonth = selectedYear.clone().add(1, "year");
    const nextYear = nextMonth.year();

    if (nextYear <= currentYear) {
      setselectedYear(nextMonth);
    } else {
      setIsNextButtonDisabled(true);
    }
  };

  return {
    user,
    setUser,
    startMonth,
    setStartMonth,
    handleDecrease,
    handleIncrease,
    inProgress,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    startMonth,
    setStartMonth,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    handleDecrease,
    handleIncrease,
    user,
    setUser,
    filetoupload,
    setFiletoupload,
    selectedFile,
    setSelectedFile,
    data,
    setData,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    fileAction,
    setFileAction,
    name,
    setName,
    typeValue,
    setTypeValue,
    documentLists,
    handleUpload,
    monthYearValue,
    setMonthYearValue,
    selectedYear,
    setselectedYear,
    isNextButtonDisabled,
    setIsNextButtonDisabled,
    handleIncrease_year,
    handleDecrease_year,
    isLoading,
    setIsLoading,
    documentListsDownload,
  };
}
