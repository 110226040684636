import {
  LATEST_SCREENSHORTS,
  All_SCREENSHORTS,
  DELETE_SCREENSHOT,
} from "../Actions/screenShortsAction";

const initialState = {
  LatestScreenshots: [],
  AllScreenshots: [],
};

const screenShortsReduser = (store = initialState, action) => {
  switch (action.type) {
    case LATEST_SCREENSHORTS: {
      return {
        ...store,
        LatestScreenshots: action.payload,
      };
    }
    case All_SCREENSHORTS: {
      return {
        ...store,
        AllScreenshots: action.payload,
      };
    }
    case DELETE_SCREENSHOT: {
      let screenshotList = store.AllScreenshots.filter(
        (doc) => doc.id !== action.payload
      );
      // let screenshotLatestList = store.LatestScreenshots.filter(
      //   (doc) => doc.id !== action.payload
      // );
      return {
        ...store,
        AllScreenshots: screenshotList,
        // LatestScreenshots: screenshotLatestList,
      };
    }
    default: {
      return { ...store };
    }
  }
};

export default screenShortsReduser;
