import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux"
import "./style.css";
import Modal from "../../common/Modal";
import Select from "react-select";
import { countries } from "countries-list";
import useSubscription from "../../../hooks/useSubscription";

const ActivePlan = ({ open, onClose ,plan, activeTab}) => {
  const { env } = useSelector((store) => store.auth);
  const [countryOptions, setCountryOptions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = useState();
  const [yearDate,setYearDate]=useState();
 
  const {setStripe_info} = useSubscription();
  const { active_subscription_list } = useSelector((store) => store.subPlan);
console.log("activeplan",active_subscription_list);
console.log("activetab",activeTab);
console.log("slected plan",plan);


const expiryDate = new Date(active_subscription_list[0]?.expiry_date);

// Increment the expiry date by one day
expiryDate.setDate(expiryDate.getDate() + 1);

// Get the day, month, and year from the updated date object
const day = expiryDate.getDate().toString().padStart(2, '0');
const month = (expiryDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
const year = expiryDate.getFullYear();

const formattedDate = `${day}-${month}-${year}`;



  useEffect(() => {
    var startDate = new Date();
    var endDateMoment = moment(startDate);
    endDateMoment.add(1, "months");
    setDate(moment(endDateMoment).format("DD/MM/YYYY"));
    
  }, []);


  useEffect(() => {
    var startDate = new Date();
    var endDateMoment = moment(startDate);
    endDateMoment.add(1, "year");
    var oneYearLater = moment(endDateMoment).format("DD/MM/YYYY");
    setYearDate(oneYearLater);
  }, []);
  

  useEffect(() => {
    // Transform country data into required format for react-select
    const countryOptionsArray = Object.keys(countries).map((countryCode) => ({
      value: countryCode,
      label: countries[countryCode].name,
    }));

    setCountryOptions(countryOptionsArray);
  }, []);

  const handleClick = () => {
    navigate("/Subscription");
  };

  const isPriceLessThanCurrentPlan = () => {
  const currentSubscriptionPrice = active_subscription_list[0]?.subscription_details?.prices_list[0].unit_amount
    ;
    const selectedPlanPrice = plan?.month_price.unit_amount    ;

    if (!currentSubscriptionPrice || !selectedPlanPrice) {
        console.error("One or both price are undefined.");
        return false; // or handle this case based on your requirements
    }

    console.log("Current Subscription price:", currentSubscriptionPrice);
    console.log("Selected Plan price:", selectedPlanPrice);

    return currentSubscriptionPrice < selectedPlanPrice;
};


  return (
    <Modal 
    open={open} onClose={onClose}
    customStyles={{
     maxWidth: "70%", 
      maxHeight: "90vh", 
      borderRadius: "6px"
    }}>
      <div className="payment-modal">

        <div className="payment-modal-header">
          <div>
            <h3 style={{fontSize: "x-large", fontWeight:"bold"}}>Selected Plan</h3>
          </div>

          <div className="cross-icon ">
            <button className="payment-modal-close-btn" onClick={onClose}>
              <img
                height="20px"
                width="20px"
                src="/images/icons/Icon - Close.png"
                alt="Project-info-icon"
              />
            </button>
          </div>
        </div>

        <div>
          <div >
            <div className="plan-details ">
           
    
  
              <div className=" left-content-billing mt-4">

                <div className="plan-price-parent-container">

                <div className="mb-3 border-bottom-width-45">
                  <p className="gray-color" style={{fontSize: "14px"}}>Plan</p>
                  <h5 className="no-wrap" style={{fontSize: "16px"}}>
                    {plan?.plan_name}
                  </h5>
                </div>
                {/* <div className="mb-3">
  <h3>Features</h3>
  <div className="features-list">
    {plan?.features_details.map((feature, index) => (
      <div key={index} className="feature-item">
        <span className="tick-mark">✔</span>
         <span>{feature.name}</span>
      </div>
    ))}
  </div>
</div> */}
                <div  className="mb-3 border-bottom-width-45">
                  <p className="gray-color" style={{fontSize: "14px"}}>Price</p>
                  <h5 style={{fontSize: "16px"}}>
                  ${activeTab === 'month' ? plan?.month_price?.unit_amount : plan?.year_price?.unit_amount}/{
                    activeTab === 'monthly' ? 'Monthly' : 'Monthly'}
                  </h5>
                </div>

                </div>

                <div className="start-end-date-parent-container">

                <div className="mb-3 border-bottom-width-45">
                  <p className="no-wrap gray-color" style={{fontSize: "14px"}}>Effective Start Date</p>
                  <h5 className="no-wrap" style={{fontSize: "16px"}}>
                  {
                  isPriceLessThanCurrentPlan() 
                 ?  new Date().toLocaleDateString('en-GB').replace(/\//g, '-') 
                :  formattedDate
               }


                  </h5>
                </div>

                <div className="mb-3 border-bottom-width-45">
                  <p className="no-wrap gray-color" style={{fontSize: "14px"}}>Effective Ending Date</p>
                  <h5 className="no-wrap" style={{fontSize: "16px"}}>
                  {
                  isPriceLessThanCurrentPlan() 
                 ?  new Date().toLocaleDateString('en-GB').replace(/\//g, '-') 
                :  formattedDate
               }


                  </h5>
                </div>


                </div>


                <div className="confirm-btn">

                
                <button
                  type="submit"
                  className="change-btn"
                  onClick={() => setStripe_info(plan.id, activeTab === "month" ? plan?.month_price?.id : plan?.year_price?.id,env)}
                >
                  Confirm
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActivePlan;
