import React from 'react';
import { useNavigate, useParams } from "react-router-dom";


function EXPIRE_LINK() {
    const Navigate = useNavigate();

  const containerStyle = {
    textAlign: 'center',
  };

  const h1Style = {
    color: "black",
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "15px"
  };

  const pStyle = {
    color: "#949494",
    margin: "auto",
    width: "50%",
    textAlign: "center",
    fontSize: "14px"
  };

  const btnStyle = {
    backgroundColor: '#007EC5',
    color: 'white',
    padding: '10px 65px',
    border: 'none',
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontSize: "11px",
    height: "40px",
    marginTop: "12px"
  };

  const btnHoverStyle = {
    backgroundColor: '#45a049',
  };


  const endPStyle = {
    textAlign: "center",
    fontSize: "12px",
    position: "absolute",
    bottom: "0px",
    marginBottom: "10px",
    width: "100%"
  }
  const handleResetPassword = () => {
    // Add your logic here for resetting the password
    Navigate("/ForgotPassword");


  };

  return (
    <div style={{width: "100%",background: "#F2F5FA"}}>

      <div className="expire-header" >
        <div style={{display: "flex", justifyContent: "space-evenly", width: "22%"}}>
          <img src="/images/clocksession-logo-new.png" alt="clocksession-logo" />
          <div className='expire-straight-line'></div>
        </div>
      </div>


      <div className='expire-div-container'>

        <div className='mt-2 text-center'>
          <img src="/images/expired-link-img-1.png" alt="" style={{width: "480px"}} />
        </div>

        <h1 style={h1Style}>Password Link Expired</h1>
        <p style={pStyle}>The password reset link you are using has expired. <br />
        Please click the button below to resend the password reset link.</p>

        <div style={{width: '100%', textAlign: "center"}}>
          <button style={btnStyle} onClick={handleResetPassword}>Reset Password</button>    
        </div>


        <p style={endPStyle}>
          ©{new Date().getFullYear()} All Rights Reserved by Mechlin
          Technologies
        </p>
      
      </div>

    </div>
  );
}

export default EXPIRE_LINK;
