import { combineReducers } from "redux";
import ReduxThunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";

// Redusers
import authReduser from "./Redusers/authReduser";
import resourceManagementActions from "./Redusers/resourceManagementReduser";
import screenShortsReduser from "./Redusers/screenShortsReduser";
import employeeReduser from "./Redusers/employeeReduser";
import workScheduleReduser from "./Redusers/workScheduleReduser";
import downloadReduser from "./Redusers/downloadReduser";
import reportReduser from "./Redusers/reportReduser";
import payrollReducer from "./Redusers/payrollReducer";
import leaveReduser from "./Redusers/leaveReduser";
import roleReduser from "./Redusers/roleReduser";
import subscriptionReduser from "./Redusers/subscriptionReducer";
import workingHourReducer from "./Redusers/workingHourReducer";

const rootReducer = combineReducers({
  auth: authReduser,
  RM: resourceManagementActions,
  SS: screenShortsReduser,
  ER: employeeReduser,
  WS: workScheduleReduser,
  downloadapp: downloadReduser,
  ReportR: reportReduser,
  Payr:payrollReducer,
  leave: leaveReduser,
  role:roleReduser,
  subPlan:subscriptionReduser,
  WH:workingHourReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ReduxThunk),
});

export default store;
