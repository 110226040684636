import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import useLeaveManagement from "./useLeaveManagement";
import {
  getLeaveType,
  addLeaveType,
  deleteLeaveType,
  updateLeaveType,
} from "../redux/Actions/leaveAction";

export default function useLeaveType() {
  const dispatch = useDispatch();
  const { userInfo,userPermissions } = useSelector((store) => store.auth);
  const { leaveTypeList } = useSelector((store) => store.leave);
  const [addUser, setAddUser] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [leave_id,setLeave_id]=useState();
  const [ismodalopen,setIsModalopen]=useState(true);
  

  useEffect(() => {
    dispatch(getLeaveType());
  }, []);

  const callLeaveTypeList = useCallback(() => {
    return dispatch(getLeaveType());
  }, []);

  const handleAddUser = (setModalOpen) => {
    console.log("inputValue", inputValue);
    let data = {
      reason: inputValue,
    };
    if (inputValue) {
      setAddUser([...addUser, inputValue]);
      setInputValue("");
      if(leave_id){
        dispatch(updateLeaveType(leave_id.id,data,callLeaveTypeList,setLeave_id,setModalOpen));
      }
      else
      dispatch(addLeaveType(data, callLeaveTypeList,setModalOpen));

    }

  };

  const editLeave=(value)=>{
    setLeave_id(value);
    setInputValue(value.reason);
  }
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleDelete = (id) => {
    // let arr = [...addUser];
    // let index = arr.indexOf(user);
    // if (index !== -1) {
    //   arr.splice(index, 1);
    //   setAddUser(arr);
    // }
    if (id) {
      dispatch(deleteLeaveType(id, callLeaveTypeList));
    }
  };

  const handleEdit = (user) => {
    let arr = [...addUser];
    let index = arr.indexOf(user);
    console.log("user", user);
  };

  return {
    leaveTypeList,
    addUser,
    setAddUser,
    inputValue,
    setInputValue,
    handleEdit,
    handleDelete,
    handleInputChange,
    handleAddUser,
    callLeaveTypeList,
    userInfo,
    userPermissions,
    editLeave,
    leave_id,
    ismodalopen,
    setIsModalopen,
    setLeave_id

  };
}
