import { React, useEffect, useState,useRef, } from "react";
import { useSelector } from "react-redux";
import Modal from "../common/Modal";
import SelectLeaveReason from "../SelectType/leaveReason";
import SelectCalenderType from "../SelectType/calenderSelect";
import useLeaveManagement from "../../hooks/useLeaveManagement";
import "./style.css";
import {
  DatePickerSingle,
  DateRangePicker,
  MonthPicker,
  WeekPicker,
} from "../common/DatePickers";
import { toast } from "react-toastify";

const ApplyLeaveModal = ({ open, onClose, hookProps }) => { 
  
  const {
    startDate,
    startMonth,
    setStartMonth,
    currentTab,
    handleDecrease,
    handleIncrease,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    setStartDate,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    dateRangeStart,
    onChangeDateRange,
    dateRangeEnd,
    handleTabTypeChange,
    handleSelectChange,
    handleTextareaChange,
    selectedReason,
    text,
    handleSubmit,
    options,
    edituserLeaveID,
    setIsupdated,
    ModalCalender,
    setModalCalender,
    approvedLeave_count,
    currentMonth,
    halfday_selection,
    setHALFDAYSELECTION,
    approved_leave_current_month,
    toggle,
    setToggle,
    isSubmitting,
    setIsSubmitting,
    currentMonth_approvedleave,
    halfDay,
    userleavedata,
    setuserleavedata,


  } = hookProps;
 

  const { appliedLeaves,leaveCount } = useSelector((store) => store.leave);
  const { userInfo } = useSelector((store) => store.auth);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown_calender, setShowDropdown_calender] = useState(false);
  const [wantHalfDay,setWantHalfDay] = useState(true);
  const fileInputRef = useRef(null);

  console.log("applied_leave",appliedLeaves);
  console.log("half_day123",halfDay);
  console.log("currentMonth_apply",currentMonth);
  console.log("half_day_selection",halfday_selection);

  const PresentMonth = new Date().getMonth() + 1;


  const leave_Available = currentMonth < PresentMonth ? 0 : Math.max(0, (currentMonth === PresentMonth ? leaveCount + 2 : 2) - currentMonth_approvedleave);

   // Close the apply leave calendar when the user selects a start date or weekday
useEffect(() => {
  setModalCalender(false);
}, [startWeekDay, startDate]);




 // Close the apply leave calendar when the user selects a start date or weekday
useEffect(() => {
  setModalCalender(false);
}, [startWeekDay, startDate]);

// Close the apply leave calendar when the user selects an end date for the date range
useEffect(() => {
  if (dateRangeEnd) {
    setModalCalender(false);
  }
}, [dateRangeEnd]);
console.log("userleaveData",userleavedata)
  useEffect(() => {
    if (!userleavedata) {
      setHALFDAYSELECTION([]);
      console.log("function_is_here")
    }
   
    setuserleavedata();
    
  }, [currentTab,startDate,weekDate,dateRangeStart,dateRangeEnd]);





  useEffect(() => {
    if (!open) {
      setShowDropdown(false);
      setShowDropdown_calender(false);
      setWantHalfDay(true);
    }
  }, [open]);

  console.log("halfday_selection",halfday_selection);

  const generateDates = () => {
    const dates = [];
    if (currentTab === "daily" && startDate) {
      dates.push(startDate);
    } else if (currentTab === "weekly" && weekDate){
      let current = new Date(startWeekDay);
      const end = new Date(endWeekDay);
      while (current <= end) {
        dates.push(new Date(current));
        current.setDate(current.getDate() + 1);
      }
    } else if (currentTab === "dateRange" && dateRangeStart && dateRangeEnd){
      let current = new Date(dateRangeStart);
      const end = new Date(dateRangeEnd);
      while (current <= end) {
        dates.push(new Date(current));
        current.setDate(current.getDate() + 1);
      }
    }
    return dates;
  };




  const handleHalfDayChange = (date) => {
    if (halfday_selection.includes(date)) {
      setHALFDAYSELECTION(halfday_selection.filter(d => d !== date));
    } else {
      setHALFDAYSELECTION([...halfday_selection, date]);
    }
  };
  
  const getFormattedDate = (date) => {
    const dateObject = new Date(date);
  
    const weekday = dateObject.toLocaleString('en-US', { weekday: 'short' });
  
    const day = dateObject.getDate();
  
    const month = dateObject.toLocaleString('en-US', { month: 'short' });
  
    return `${weekday} ${day}, ${month}`;
  };


  function handleLabelClick() {
    fileInputRef.current.click();
  }


  const handleFileChange = async (e) => {
    toast.success("File selected");
  
    const selectedFile = e.target.files[0];
    
    if (selectedFile) {
      // Check if the selected file is the same as the one currently in filetoupload
  
      const reader = new FileReader();
  
      // Wrap the asynchronous file reading in a Promise
      const readFilePromise = new Promise((resolve) => {
        reader.onload = () => {
          if (reader.readyState === 2) {
            resolve(reader.result);
          }
        };
      });
  
      reader.readAsDataURL(e.target.files[0]);
  
      // Wait for the file reading to complete before setting e.target.value to null
      await readFilePromise;
  
      // setSelectedFile(reader.result);
      // setFiletoupload(e.target.files[0]);
    }
  
    // Reset the input field's value to null to trigger the event next time, even if the same file is selected
    e.target.value = null;
  };
  
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="apply-edit-leave-container">
          <div className="leave-apply-edit-modal-header">
            <div>
            {edituserLeaveID ? <h5  >Edit Details</h5> : <h5 >Apply Leave</h5>}
            </div>

            <div className="" onClick={() => onClose()}>
              <img
                height="25px"
                src="/images/icons/clear-icon.svg"
                alt="Project-info-icon"
              />
            </div>
          </div>

          <div className="leave-balance-request-container">
            <div className="leave-balance-box">
            <p>Leave Available</p>
            <p>
            {leave_Available}
        </p>            </div>

            <div className="leave-request-box">
            <p >Total Request</p>
              <p>{approved_leave_current_month}</p>
            </div>
          </div>

          <div className="leave-calendar-type-combine">
              <div
              className="modal-leave-type-container"
              style={{ width: "47%", border: "0px solid black" }}
              >
              <label
                htmlFor="u-access"
                className=""
                style={{ fontSize: "12px" }}
              >
                Leave *
              </label>
              <div style={{ border: "2px solid #A2A2A2", borderRadius: "4px", height: "30px" }} onClick={() => {
                            setShowDropdown_calender(false);
                            setModalCalender(false);
                            setShowDropdown(!showDropdown);
                          }}>
              <SelectLeaveReason handleSubmit={handleSelectChange} wrapperRef={wrapperRef} options={options} selectedReason={selectedReason}  showDropdown={showDropdown} setShowDropdown={setShowDropdown}             
            
             />
 </div>

              </div>

             <div
              className="modal-leave-type-container"
              style={{ width: "47%", border: "0px solid black" }}
              >
              <label
                htmlFor="u-access"
                className=""
                style={{ fontSize: "12px" }}
              >
                Calendar Type*
              </label>
              <div style={{ border: "2px solid #A2A2A2", borderRadius: "4px", height: "30px" }} onClick={() => {
                            setShowDropdown_calender(!showDropdown_calender);
                            setModalCalender(false);
                            setShowDropdown(false);
                          }}>

             <SelectCalenderType handleSubmit={handleTabTypeChange} wrapperRef={wrapperRef}  selectedReason={currentTab} showDropdown={showDropdown_calender} setShowDropdown={setShowDropdown_calender}             
            
             />
 </div>

              </div>
          </div>

          <div className="">
            <div
              className=""
              style={{
                marginTop: "10px",
                border: "1px solid #A2A2A2",
                borderRadius: "5px",
              }}
            >
             <div className="apply-edit-leave-timeline modal-calander" style={{ position: "relative" }}>
        
  <div className="icon-calendar-input-container"  ref={calendarRef} style={{ width: "500px" }}   >
    {(currentTab === "daily" || currentTab === "weekly" || currentTab === "dateRange") && (
      <img
        src="/images/icons/Icon - Calender.png"
        alt="calendar"
        onClick={() => {
          setModalCalender(!ModalCalender);
          setShowDropdown(false);
          setShowDropdown_calender(false);
      }}
      
        ref={wrapperRef}
      />
    )}

    <div style={{ fontSize: "12px" }}>
      {currentTab === "daily" && (
        <DatePickerSingle
          startDate={startDate}
          ref={calendarRef}
          setStartDate={setStartDate}
          calendarIsOpen={ModalCalender}
          showMaxDate={true}
        />
      )}
      {currentTab === "weekly" && (
        <WeekPicker
          ref={calendarRef}
          weekDate={weekDate}
          setWeekDate={setWeekDate}
          startWeekDay={startWeekDay}
          endWeekDay={endWeekDay}
          calendarIsOpen={ModalCalender}
          showMaxDate={true}
        />
      )}

      {currentTab === "dateRange" && (
        <DateRangePicker
          ref={calendarRef}
          dateRangeStart={dateRangeStart}
          onChangeDateRange={onChangeDateRange}
          dateRangeEnd={dateRangeEnd}
          calendarIsOpen={ModalCalender}
          showMaxDate={true}
        />
      )}
    </div>
  </div>

  <div
    style={{
      position: "absolute",
      bottom: "5px",
      right: "0px",
      display: "flex",
      left: "88%",
    }}
  >
    {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly" ? (
      <button
        className="prev-month"
        onClick={() => {
          handleDecrease();
        }}
        style={{ marginRight: "5px" }}
      >
        {""}
      </button>
    ) : null} 

    {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly" ? (
      <button
        className="next-month"
        onClick={() => {
          handleIncrease();
        }}
      >
        {""}
      </button>
    ) : null}
  </div>
</div>

            </div>
          </div>

          <div style={{ fontSize: "11.5px" }}>
            <p
              className="grey-color"
              style={{ fontSize: "11px", marginTop: "6px" }}
            >
              Do you want to request half days?
            </p>

            <div style={{ marginTop: "6px" }}>
              <input
                type="radio"
                id="yesHalfDay"
                name="halfDayOption"
                checked={wantHalfDay}
                onChange={() => setWantHalfDay(true)}
              />
              <label htmlFor="yesHalfDay" style={{ marginLeft: "5px" }}>
                Yes
              </label>

              <input
                style={{ marginLeft: "10px" }}
                type="radio"
                id="noHalfDay"
                name="halfDayOption"
                checked={!wantHalfDay}
                onChange={() => setWantHalfDay(false)}
              />
              <label htmlFor="noHalfDay" style={{ marginLeft: "5px" }}>
                No
              </label>
            </div>
          </div>

          {wantHalfDay && (
            <div
              style={{
                overflowY: "auto",
                maxHeight: "115px",
                minHeight: currentTab === "daily" ? "60px" : "100px",
                border: "3px solid #E8E8E8",
                fontSize: "11.5px",
                padding:"8px",
                borderRadius:"6px",
              }}
            >
              <table style={{ width: "100%", tableLayout: "fixed" }}>
              <thead style={{ backgroundColor: "#DCDCDC", border: "0px solid pink", }}>
                  <tr>
                    <th>Date</th>
                    <th>Half Day</th>
                  </tr>
                </thead>
                <tbody>
                  {generateDates().map((date, index) => (
                    <tr key={index}>
                      {/* <td>{date.toLocaleDateString()}</td> */}
                      <td>{getFormattedDate(date)}</td>
                      <td>
                        <label className="switches">
                          <input
                            type="checkbox"
                            className="leave-custom-checkbox"
                            checked={halfday_selection.includes(
                              date.toLocaleDateString()
                            )}
                            onChange={() =>
                              handleHalfDayChange(date.toLocaleDateString())
                            }
                          />
                          <span class="sliders round"></span>
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <p
            style={{ fontSize: "12px", marginTop: "10px", marginBottom: "2px" }}
          >
            Additional Information
          </p>

          <div className="">
            <textarea
              style={{ resize: "none", fontSize: "12px", borderRadius: "5px" }}
              rows="3"
              cols="40"
              className="form-control"
              placeholder="Write your comments here..."
              value={text}
              onChange={handleTextareaChange}
            />
          </div>

          <div className="mb-2 align-self-center mt-2">
            <div className="">
              <div className="modal-btn">
                  <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    handleSubmit(leave_Available);
                    setToggle(prevetoggle=>!prevetoggle)
                  }}
                  disabled={isSubmitting}
                                    style={{
                    fontSize: "12px",
                    width: "160px",
                    height: "43px",
                    borderRadius: "5px",
                  }}
                >
                {edituserLeaveID ? "Update Leave" : "Apply Leave"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ApplyLeaveModal;
