import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaInfoCircle } from "react-icons/fa";
import Modal from "../../../components/common/Modal";
import ActivePlan from "../ActivePlan";
import "./style.css";
import useSubscription from "../../../hooks/useSubscription";
import plansData from "../dummyData";
import CurrentPlanCard from "./currentPlan";
import PricingDetails from "./pricingDetailss";
import "../ActivePlan/style.css"
import AvailablePlan from "./AvailablePlan";
import { stripe_details } from "../../../redux/Actions/authAction";
import { useDispatch } from "react-redux";
import IncludePlans from "./IncludePlans";



const CurrentPlan = () => {
  const navigate = useNavigate(); 
  const modalRef = useRef(null);
  const {
    showModal,
    selectedPlan,
    isPopupVisible,
    openGatewayModal,
    openInfoPopup,
    closeInfoPopup,
    priceColors,
    closeModal,
    useOutsideCloseModal,
    setCurrentPlan_Tab,
    currentPlan_Tab,
  } = useSubscription();

  useOutsideCloseModal(modalRef);
  const dispatch = useDispatch();
  const { env } = useSelector((store) => store.auth);
  const { active_subscription_list } = useSelector((store) => store.subPlan);
  console.log("activeplan", active_subscription_list);

  const expiryDate = new Date(active_subscription_list[0]?.expiry_date);

  const feature_list = active_subscription_list[0]?.subscription_details?.features_list;
  console.log("featureList", feature_list)



// Get the day, month, and year from the date object
const day = expiryDate.getDate().toString().padStart(2, '0');
const month = (expiryDate.getMonth() + 1).toString().padStart(2, '0'); 
const year = expiryDate.getFullYear();


const formattedDate = `${day}-${month}-${year}`;

  const { ClockSession_PlanList } = useSelector((store) => store.subPlan);
  console.log("plan", ClockSession_PlanList);
  const [activeTab, setActiveTab] = useState('monthly');

  const handleToggle = (tab) => {
    setActiveTab(tab);
    // Add logic to handle the tab change, such as fetching new data based on the selected tab
  };
  const handle_click = () => {
navigate("/Subscription/availablePlans")
// Add logic to handle the tab change, such as fetching new data based on the selected tab
  };

  // const ToggleButton = ({ activeTab, onToggle }) => {
    //   return (
      //     <div className="switch-wrapper">
        //       <input
          //         id="monthly"
          //         type="radio"
          //         name="switch"
          //         checked={activeTab === 'monthly'}
          //         onChange={() => onToggle('monthly')}
        //       />
        //       <input
          //         id="yearly"
          //         type="radio"
          //         name="switch"
          //         checked={activeTab === 'yearly'}
          //         onChange={() => onToggle('yearly')}
        //       />
        //       <label htmlFor="monthly" style={{ marginLeft: '40px' }}>
          //         Monthly
        //       </label>
        //       <label htmlFor="yearly" style={{ marginLeft: '92px' }}>
          //         Yearly
        //       </label>
        //       <span className="highlighter"></span>
      //     </div>
    //   );
  // };

  const containerStyle = {
    display: 'flex',
    marginRight: "10px",
    marginLeft: "10px"
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    // minHeight: '100vh',
    // border: '0px solid pink',
  }; 
  
  const SPANstyle = {
    fontSize: '20px',
    marginLeft: '4px' ,
    fontWeight: 'bold',
    color: 'black',
  };

  return (
    <>
      <section className="mt-3" style={containerStyle}>
        {/* <ToggleButton activeTab={activeTab} onToggle={handleToggle} />  */} 
        <div className="subs-payment-modal" style={{backgroundColor:'white'}}>
        {/* <div
          className="header"
          style={{ display: "flex",border:'3px solid yellow', justifyContent: "space-between" }}
        >
          <div className="modal-header"></div>

          <div className="cross-icon ">
            <button className="close-btn" >
              <img
                height="25px"
                width="25px"
                src="/images/close.png"
                alt="Project-info-icon"
              />
            </button>
          </div>
        </div> */}
        <div>
          <div className=""  >
            <div className="" >
              <h3 className="no-wrap">
                <span style={{borderBottom: "2px solid #007EC5", color:"#007EC5", fontSize:"14px", fontWeight:"bold"}}> BILLING DETAILS </span>
              </h3>

              <div className="">

                <div className="subs-plan-price-date-container mb-3 mt-3">

                <div className="mb-3 subs-plan-price-date-child">
                  <p>Plan</p>
                  <p>:</p>
                  <p className="no-wrap">
                    {active_subscription_list[0]?.subscription_details?.plan_name}
                  </p>
                </div>
                <div className="mb-3 subs-plan-price-date-child">
                  <p>Price</p>
                  <p>:</p>
                  <p className="">
                  ${activeTab === 'monthly' ? (active_subscription_list[0]?.subscription_details?.prices_list[0]?.unit_amount) : "annual_price"}
                   
                  </p>
                </div>
                <div className="mb-3 subs-plan-price-date-child">
                  <p className="no-wrap">Next Payment amount</p>
                  <p>:</p>
                  <p className="">
                  ${activeTab === 'monthly' ?(active_subscription_list[0]?.subscription_details?.prices_list[0]?.unit_amount) : "annual_price"}
                  </p>

                </div>

                <div className="subs-plan-price-date-child" style={{border: "none"}}>
                  <p className="no-wrap">Due Date</p>
                  <p>:</p>
                  <p className="no-wrap">{activeTab === 'monthly' ?
    formattedDate : "yearDate"}
                  </p>

                </div>
                


                </div>

                <div style={{width: "100%"}}>
                <button
                  type="submit"
                  className="change-btn"
                  onClick={() => handle_click()}
                  style={{width: "100%"}}
                >
                  Change Plan
                </button>
                </div>

              </div>
            </div>
            {/* <div className="vertical-line col-sm-1" ></div> */}
            {/* <div className="card-details col-sm-7"  style={{border:'0px  dotted black'}} >
              <h4 className="card-header">Card information</h4>
              <div className="col-sm-12">
                <div className="row mb-4">
                  <div className="col-12">
                    <input
                      type="name"
                      className="form-control"
                      id="company-name"
                      placeholder="Full Name"
                      name="company-name"
                    />
                  </div>
                </div>
                <div className="row mb-4">
        <div className="col-12">
          <div className="card-input">
           
            <input
              type="tel"
              className="form-control"
              id="card-number"
              placeholder="Card Number"
              name="card-number"
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-6">
          <input
            type="tel"
            className="form-control"
            id="expiry"
            placeholder="Expiry"
            name="expiry"
          />
        </div>
        <div className="col-6">
          <input
            type="tel"
            className="form-control"
            id="cvc"
            placeholder="CVC"
            name="cvc"
          />
        </div>
      </div>
                <div className="row mb-4">
                  <div className="col-12">
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <input
                      type="name"
                      className="form-control"
                      id="name"
                      placeholder="State"
                      name="name"
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="name"
                      className="form-control"
                      id="name"
                      placeholder="Zip/Postal"
                      name="name"
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <p>
                    Any future amount will be charged to this card. You can
                    cancel your plan at any time. By clicking on confirm, you
                    agree to the{" "}
                    <a href="mailto:contact@clocksession.com">
                      Terms of Service
                    </a>
                  </p>
                  <div className="card-btn">
                    <button className="change-btn cancel-btn" >
                      Cancel
                    </button>
                    <button type="submit" className="change-btn ">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>*/}
          </div> 
        </div>
      </div>


      <div className="included-in-plan-container" >
        <div style={{margin: "20px 30px"}}>

          <div>
            <h3>
              <span style={{borderBottom: "2px solid #007EC5", color:"#007EC5", fontSize:"14px", fontWeight:"bold"}}>INCLUDED IN THE PLAN</span>
            </h3>
          </div>

          <div className="plan-container">
            <div className="feature-list">
                {feature_list?.map((feature, index) => (
                    <IncludePlans key={index} name={feature.name} />
                ))}
            </div>
          </div>


        </div>
      </div>

      </section>
    </>

  );
};

export default CurrentPlan;
