import React from "react";
import Modal from "../../../components/common/Modal";
import "./style.css";

const AddManualTime = ({ open, onClose, hookProps }) => {
  const { handleSubmit, cancleAddManualTime } = hookProps || {};

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="user-container">
          <div className="header">
            <h4>Add Manual Time</h4>
          </div>
          <div className="row mt-4 mb-3">
            <label for="u-name" class="col-sm-3 col-form-label">
              Project Name:
            </label>
            <div class="col-sm-9">
              <select id="u-access" name="group" className="form-control">
                <option selected value="">
                  {" "}
                  Select Project
                </option>
                <option selected value="">
                  {" "}
                  Project 1
                </option>
                <option selected value="">
                  {" "}
                  Project 1
                </option>
                <option selected value="">
                  {" "}
                  Project 1
                </option>
              </select>
            </div>
          </div>

          <div className="row mt-4 mb-3">
            <label for="u-name" class="col-sm-3 col-form-label">
              Task :
            </label>
            <div class="col-sm-9">
              <select id="u-access" name="group" className="form-control">
                <option selected value="">
                  {" "}
                  Select Task
                </option>
                <option selected value="">
                  {" "}
                  Task 1
                </option>
                <option selected value="">
                  {" "}
                  Task 1
                </option>
                <option selected value="">
                  {" "}
                  Task 1
                </option>
              </select>
            </div>
          </div>

          <div className="row mb-3">
            <label for="u-calender" class="col-sm-3 col-form-label">
              Date:
            </label>
            <div class="col-sm-9">
              <input
                type="date"
                id="u-date"
                name="date"
                placeholder="Start Time"
                className="form-control"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label for="u-email" class="col-sm-3 col-form-label">
              Time:
            </label>
            <div class="col-sm-9">
              <div class="row">
                <div class="col-sm-6">
                  <input
                    type="time"
                    id="u-time"
                    name="time"
                    placeholder="Start Time"
                    className="form-control"
                  />
                </div>
                <div class="col-sm-6">
                  <input
                    type="time"
                    id="u-time"
                    name="time"
                    placeholder="End Time"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label for="u-text" class="col-sm-3 col-form-label">
              Reason:
            </label>
            <div class="col-sm-9">
              <textarea
                type="text"
                id="u-reason"
                name="reason"
                placeholder="Reason"
                className="form-control"
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-9 offset-sm-3">
              <div className="modal-btn">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Add
                </button>
                <span> </span>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={cancleAddManualTime}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AddManualTime;
