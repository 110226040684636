import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { useState,useEffect } from "react";
const ExampleCustomInput = ({ value, onClick }) => {
  return (
    <div>
      <input
        readOnly
        className="example-custom-input"
        onClick={(e) => onClick(e.preventDefault())}
        value={moment(value).format("MMMM DD, YYYY")}
      />
    </div>
  );
};

const ExampleCustomInputforWeek = ({
  value,
  onClick,
  startWeekDay,
  endWeekDay,
}) => {
  return (
    <div>
      <input
        readOnly
        type="text"
        id="lname"
        className="example-custom-input custom-week-date-style"
        onClick={(e) => onClick(e.preventDefault())}
        value={`${moment(startWeekDay).format("MMM DD, YYYY")} - ${moment(
          endWeekDay
        ).format("MMM DD, YYYY")} `}
      />
    </div>
  );
};

const ExampleCustomInputforDateRange = ({
  value,
  onClick,
  dateRangeStart,
  dateRangeEnd,
}) => {
  return (
    <div>
      <input
        readOnly
        type="text"
        id="lname"
        className="example-custom-input custom-date-range-style"
        onClick={(e) => onClick(e.preventDefault())}
        value={`${moment(dateRangeStart).format(
          "MMM DD, YYYY"
        )} - ${moment(
          dateRangeEnd === null ? dateRangeStart : dateRangeEnd
        ).format("MMM DD, YYYY")} `}
      />
    </div>
  );
};

const ExampleCustomInputforMonth = ({ value, onClick,className }) => {
  const dynamicClassName = className ? 'className' : '';

  // Combine the existing class with the dynamic class
  const combinedClassName = `example-custom-input ${dynamicClassName}`;
  return (
    <div>
      <input
        readOnly
        type="text"
        id="lname"
        className={combinedClassName}
        onClick={(e) => onClick(e.preventDefault())}
        value={moment(value).format("MMMM, YYYY ")}
      />
    </div>
  );
};

export const DatePickerSingle = forwardRef(
  ({ startDate, setStartDate, calendarRef, calendarIsOpen, showMaxDate, setCalendarIsOpen, }, ref) => {
    return (
      <div ref={ref}>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            setCalendarIsOpen(false);
          }}
          format="DD-MM-YYYY"
          peekNextMonth={false}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<ExampleCustomInput />}
          maxDate={showMaxDate ? "" : new Date()}
          open={calendarIsOpen}
          useWeekdaysShort={true}
        />
      </div>
    );
  }
);

export const WeekPicker = forwardRef(
  (
    {
      weekDate,
      setWeekDate,
      startWeekDay,
      endWeekDay,
      calendarIsOpen,
      calendarRef,
      showMaxDate,
      startDate, // New prop to get the selected date from DatePickerSingle
      setCalendarIsOpen
    },
    ref
  ) => {
    const [selectedDate, setSelectedDate] = useState(startDate || new Date());

    useEffect(() => {
      if (startDate) {
        setSelectedDate(startDate);
        setWeekDate(startDate);
      }
    }, [startDate, setWeekDate]);

    return (
      <div ref={ref}>
        <DatePicker
          dateFormat="MMM dd, yyyy"
          selected={weekDate}
          showWeekNumbers={false}
          peekNextMonth={false}
          showMonthDropdown
          showYearDropdown
          onChange={(date) => {
            setWeekDate(date);
            setSelectedDate(date);
            setCalendarIsOpen(false);
          }}
          highlightDates={new Array(7).fill().map((_, i) => {
            const d = new Date(startWeekDay);
            d.setDate(d.getDate() + i);
            return d;
          })}
          dropdownMode="select"
          customInput={
            <ExampleCustomInputforWeek startWeekDay={startWeekDay} endWeekDay={endWeekDay} />
          }
          maxDate={showMaxDate ? '' : new Date()}
          open={calendarIsOpen}
        />
      </div>
    );
  }
);


export const MonthPicker = forwardRef(
  ({ startMonth, setStartMonth, calendarIsOpen, calendarRef, className, maxDate, startDate, setCalendarIsOpen }, ref) => {
    const [selectedDate, setSelectedDate] = useState(startDate || new Date());

    useEffect(() => {
      if (startDate) {
        setSelectedDate(startDate);
        setStartMonth(startDate);
      }
    }, [startDate, setStartMonth]);

    return (
      <div ref={ref}>
        <DatePicker
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          selected={startMonth}
          onChange={(date) => {
            setStartMonth(date);
            setSelectedDate(date);
            setCalendarIsOpen(false);
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<ExampleCustomInputforMonth className={className} />}
          maxDate={maxDate === null ? null : new Date()}
          open={calendarIsOpen}
        />
      </div>
    );
  }
);


export const DateRangePicker = forwardRef(
  (
    {
      dateRangeStart,
      onChangeDateRange,
      dateRangeEnd,
      calendarIsOpen,
      calendarRef,
      showMaxDate,
      startDate,
      setDateRangeStart,
      setDateRangeEnd,
    },
    ref
  ) => {
   

    useEffect(() => {
      // Update date range if startDate changes
      if (startDate) {
        setDateRangeStart(startDate);
        setDateRangeEnd(startDate);
      }
    }, [startDate]);

    return (
      <div ref={ref}>
        <DatePicker
          selected={dateRangeStart}
          onChange={onChangeDateRange}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          startDate={dateRangeStart}
          endDate={dateRangeEnd}
          selectsRange
          customInput={
            <ExampleCustomInputforDateRange
              dateRangeStart={dateRangeStart}
              dateRangeEnd={dateRangeEnd}
            />
          }
          maxDate={showMaxDate ? "" : new Date()}
          open={calendarIsOpen}
        />
      </div>
    );
  }
);
