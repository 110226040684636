import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles.css";
import { getEmployeeList } from "../../redux/Actions/employeesAction";
import { useDispatch, useSelector } from "react-redux";

export default function SelectAllEmployee({
  selectedUsers,
  setSelectedUsers,
  tab,
  tab_change
}) {
  const dispatch = useDispatch();
  const [Search, setSearch] = useState("");
  const { employeesList } = useSelector((store) => store.ER);
  const [selectAll, setSelectAll] = useState(false);
  const { userPermissions } = useSelector((store) => store.auth);
  const { userInfo } = useSelector((store) => store.auth);

  let sortedemployeesList = [...employeesList].sort((a, b) => {
    if (selectedUsers.includes(a.id) && !selectedUsers.includes(b.id)) {
      return -1;
    } else if (!selectedUsers.includes(a.id) && selectedUsers.includes(b.id)) {
      return 1; // b should come before a
    } else {
      return 0;
    }
  });

  const isAccountant =
    userInfo.groups.some((group) => group.name === "Accountant") &&
    !userInfo.is_superuser;
  if (isAccountant && tab === "Work_Diary") {
    sortedemployeesList = sortedemployeesList.filter(
      (employee) => employee.id === userInfo.id
    );
  }

  useEffect(() => {
    dispatch(getEmployeeList(Search));
  }, [dispatch, Search]);

  const handleSelectUser = (ID, event) => {
    event.stopPropagation();
    
    if (selectedUsers.includes(ID)) {
      setSelectedUsers((prevValue) => prevValue.filter((val) => val !== ID));
    } else {
      setSelectedUsers((prevValue) => [...prevValue, ID]);
    }

    setSelectAll(false);
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      const updatedCheckboxes = employeesList.map((checkbox) => checkbox.id);
      setSelectedUsers(updatedCheckboxes);
    } else {
      setSelectedUsers([]);
    }
    setSelectAll(!selectAll);
  };

  useEffect(()=>{
    setSelectAll(false);
  }, [tab_change])

  return (
    <Dropdown className="abc">
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="custom-dropdown custom-toggle"
        style={{
          width:
            tab === "Work_Diary" || tab === "dashboard" ? "250px" : undefined,
          // marginRight:
          //   tab === "Work_Diary" || tab === "dashboard" ? "20px" : undefined,
        }}
      >
        <span
          className="select-text"
          style={{ border: "none", marginRight: "10px", paddingRight: "0px" }}
        >
          Select Users
        </span>
        {/* Add your custom arrow icon here */}
        <img
          src="/images/arrow-icon.svg"
          alt="Custom Arrow"
          className="custom-arrow-icon"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="dropdown-menu-container overflow-hidden">
          <div className="search-container custom-style-search">
            <img
              src="/images/icons/search-icon.svg"
              alt="Custom Arrow"
              className="search-icon"
            />
            <input
              className="dropdown-search-input custom-font custom-placeholder-color"
              type="text"
              placeholder="Search..."
              value={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {Search && (
              <img
                src="/images/icons/clear-icon.svg"
                alt="Clear Search"
                className="clear-icon"
                onClick={() => setSearch("")}
              />
            )}
          </div>
          <hr style={{ margin: "0px" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0px 0px 8px ",
            }}
          >
            <input
              type="checkbox"
              className="custom-checkbox"
              id="select-all-checkbox"
              checked={selectAll}
              style={{ display: "none" }}
              onChange={handleSelectAll}
            />
            <label htmlFor="select-all-checkbox">
              <img
                src={
                  selectAll
                    ? "/images/icons/checked-icon.svg"
                    : "/images/icons/uncheck-icon.svg"
                }
                alt={selectAll ? "Checked" : "Unchecked"}
                className="checkbox-image"
              />
            </label>
            <p style={{marginLeft:"20px"}} className=" custom-font gustavo">Select All</p>
          </div>

          <div className="dropdown-user-list-container">
            {sortedemployeesList.length > 0 ? (
              sortedemployeesList.map((employee) => (
                <Dropdown.Item key={employee.id}>
                  <div
                    className={`dropdown-user-list-item ${
                      selectedUsers.includes(employee.id)
                        ? "selected-employee"
                        : ""
                    }`}
                    onClick={(event) => handleSelectUser(employee.id, event)}
                  >
                    <input
                      type="checkbox"
                      className="custome-checkbox"
                      style={{ display: "none" }}
                      checked={selectedUsers.includes(employee.id)}
                    />{" "}
                    <label htmlFor="select-checkbox" style={{ width: "10%" }}>
                      <img
                        src={
                          selectedUsers.includes(employee.id)
                            ? "/images/icons/checked-icon.svg"
                            : "/images/icons/uncheck-icon.svg"
                        }
                        alt={
                          selectedUsers.includes(employee.id)
                            ? "Checked"
                            : "Unchecked"
                        }
                        className="checkbox-image"
                      />
                    </label>
                    <div
                      className={`dropdown-right-item gustavo ${
                        selectedUsers.includes(employee.id)
                          ? "selected-background-color"
                          : ""
                      }`}
                    >
                      {/* <img
                        src={employee.profile_image}
                        alt=""
                        width="35"
                        height="30"
                        style={{
                          borderRadius: "20px",
                          marginLeft: "5px",
                        }}
                        onError={(e) => {
                          e.target.src = "/images/profilepic.png";
                        }}
                      /> */}
                      <p
                        className={`custom-font gustavo ${
                          selectedUsers.includes(employee.id)
                            ? "selected-employee"
                            : ""
                        }`}
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        {employee.name}
                      </p>
                    </div>
                  </div>
                </Dropdown.Item>
              ))
            ) : (
              <h5 className="custom-no-data">No Result found</h5>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
