import React, { useState, useRef ,useEffect,forwardRef } from "react";
import usePayrollList from "../../../hooks/usePayroll";
import "./style.css";
import EmpPayrollTable from "../../common/PayrollTable/DownLoad_Table/EmpPayrollTable_download";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { get_document_list_download } from "../../../redux/Actions/payrollAction";

const DownLoadPayslip = () => {
  const {
    startMonth,
    currentPage, 
    setCurrentPage,
    pageCounts, 
    setPageCounts,
    setTypeValue,
    documentLists,
    setMonthYearValue,
    handleIncrease_year,
     handleDecrease_year,
     selectedYear,
     isLoading,
     setIsLoading,
     documentListsDownload,
    } = usePayrollList();
   
  const { userInfo } = useSelector((store) => store.auth);
  const [user,setUser]=([userInfo.id]);
  const dispatch=useDispatch();


  useEffect(() => {
    const monthYearValue = `${selectedYear.format("YYYY")}-03-01`;
    const typeValue = "form16";
    dispatch(get_document_list_download(user,monthYearValue,typeValue,setPageCounts,setCurrentPage,setIsLoading));
    setMonthYearValue(`${startMonth.getFullYear()}-${startMonth.getMonth() + 1}-01`);
    setTypeValue("form16");
},  [selectedYear]);

return (
    <div>
      <div id="DownLoadPayslip">
        <div className="header-btn">
          <div className="employee-timeline">
            <button className="prev-month" onClick={handleDecrease_year}></button>
            <button>{selectedYear.format("YYYY")}</button>
            <button className="next-month" onClick={handleIncrease_year}></button>
          </div>
        </div>
      </div>

      {/* Pass the "document" prop to EmpPayrollTable */}
      <EmpPayrollTable 
        document="form16" 
        list={documentListsDownload} 
        hookProps={{
          currentPage,
          setCurrentPage,
          pageCounts,
          setPageCounts,
          isLoading,
        }}  />
    </div>
  );
};

export default DownLoadPayslip;
