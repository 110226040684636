import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles.css";
import { CustomSingleDatePicker } from "./CustomDatePicker";
import theme from "../../theme/theme";

export default function SelectAttendenceType({ handleSubmit, tab, activetab }) {
  const [selectedOption, setSelectedOption] = useState(activetab);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDropdown, setShowDropdown] = useState(false);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [endCalendarIsOpen, setEndCalendarIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const endCalendarRef = useRef(null);
  const [options] = useState([
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Date-Range", value: "dateRange" },
  ]);
  const calendarIconRef = useRef(null);
  const endCalendarIconRef = useRef(null);

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
  };
  useEffect(() => {
    if (tab === "Leave") {
      setSelectedOption("monthly");
    } else {
      setSelectedOption(activetab);
    }
  }, [tab, activetab]);


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 
  
  const handleClickOutside = (event) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)&&
      selectedOption !== "dateRange" 
    ) {
      setShowDropdown(false);
    }
  };


  const handleApplyFilter = () => {
    if (selectedOption === "dateRange") {
      let value = {
        option: selectedOption,
        startDate: startDate,
        endDate: endDate,
      };
      handleSubmit(value);
    } else {
      handleSubmit(selectedOption);
    }
    setShowDropdown(false);
  };

  const handleClearFilters = () => {
    setSelectedOption(null);
    setStartDate(new Date());
    setEndDate(new Date());
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCalendarClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleCalendarClickOutside);
    };
  }, []);
  
  const handleCalendarClickOutside = (event) => {
    if (
      (!calendarRef.current || !calendarRef.current.contains(event.target)) &&
      (!endCalendarRef.current || !endCalendarRef.current.contains(event.target)) 
      
    ) {
      setCalendarIsOpen(false);
      setEndCalendarIsOpen(false);
    }
  };
  
  

  return (
  
      <Dropdown show={showDropdown} ref={wrapperRef} >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          className="custom-width"
          style={{ backgroundColor: 'white', color: 'black', border: "2px solid #E8E8E8" }}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className="select-calender-style">
            <span style={{ marginRight: "auto" }}>
              {selectedOption
                ? selectedOption == "dateRange"
                  ? "Date-Range"
                  : selectedOption
                : "Select Calendar"}
            </span>
            <img
              src="/images/down-arrow-icon.svg"
              alt="Custom Arrow"
              className="custom-arrow-icon"
            />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="dropdown-container"
            // style={{
            //   width:
            //     tab === "Leave" ? "270px" : undefined,
            // }}
          >

            {/* <div className="filter-container custom-style-filter">
            <p className="mx-2 custom-font gustavo">Filter</p>
            <div style={{ marginLeft: "auto" }} onClick={handleClearFilters}>
              <img
                src="images/clear-icon.svg"
                alt="Clear Search"
                className="clear-icon"
              />
            </div>
          </div> */}

            <hr style={{ margin: "0px" }} />

            {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0px 0px 8px ",
            }}
          >
            <p className="mx-2 custom-font gustavo">Select Calendar</p>
          </div> */}

            <div className="dropdown-filter-container">
              {options.map((option, index) => {
                if (option.value === "dateRange") {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "5px",
                        }}
                      >
                        <hr style={{ width: "40%", margin: "0 10px" }} />
                        <p
                          className="gustavo"
                          style={{ margin: "0", fontSize: "14px" }}
                        >
                          Or
                        </p>
                        <hr style={{ width: "40%", margin: "0 10px" }} />
                      </div>
                      <DropdownItem
                        label={option.label}
                        value={option.value}
                        isSelected={selectedOption === option.value}
                        onSelect={handleOptionSelect}
                      />
                      {selectedOption === "dateRange" && (
                        <div className="date-range">
                          <div>
                            <p
                              style={{
                                color: "#949494",
                                fontSize: theme.fontSizes.small,
                                paddingLeft: "5px",
                              }}
                            >
                              Starting Date
                            </p>
                            <div className="calender-container">
                              <img
                                src="/images/icons/Icon - Calender-Dark.svg"
                                alt="calendar"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCalendarIsOpen(!calendarIsOpen);
                                }}
                                ref={calendarIconRef}
                                style={{ marginRight: "5px" }}
                              />

                              <div style={{marginLeft:"-5px"}}>
                                <CustomSingleDatePicker
                                  startDate={startDate}
                                  setStartDate={setStartDate}
                                  ref={calendarRef}
                                  calendarIsOpen={calendarIsOpen}
                                  closeCalendar={() =>
                                    setCalendarIsOpen(!calendarIsOpen)
                                  }
                                  showMaxDate={true}

                                />
                              </div>


                            </div>
                          </div>

                          {/* <p className="to-style gustavo">To</p> */}

                          <div>
                            <p
                              style={{
                                color: "#949494",
                                fontSize: theme.fontSizes.small,
                                paddingLeft: "5px",
                              }}
                            >
                              Ending Date
                            </p>
                            <div className="calender-container">
                              <img
                                src="/images/icons/Icon - Calender-Dark.svg"
                                alt="calendar"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEndCalendarIsOpen(!endCalendarIsOpen);
                                }}
                                ref={endCalendarIconRef}
                                style={{ marginRight: "5px" }}
                              />

                              <div style={{marginLeft:"-5px"}}>
                                <CustomSingleDatePicker
                                  startDate={endDate}
                                  setStartDate={setEndDate}
                                  ref={endCalendarRef}
                                  calendarIsOpen={endCalendarIsOpen}
                                  closeCalendar={() =>
                                    setEndCalendarIsOpen(!endCalendarIsOpen)
                                  }
                                  showMaxDate={true}

                                />
                              </div>



                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <DropdownItem
                      key={index}
                      label={option.label}
                      value={option.value}
                      isSelected={selectedOption === option.value}
                      onSelect={handleOptionSelect}
                    />
                  );
                }
              })}
              <div className="done-btn">
                <button
                  className="btn btn-primary gustavo custom-btn"
                  onClick={handleApplyFilter}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    
  );
}

function DropdownItem({ label, value, isSelected, onSelect }) {
  return (
    <Dropdown.Item onClick={() => onSelect(value)}>
      <div
        className={`dropdown-user-list-item ${isSelected ? "selected-employee" : ""
          }`}
      >
        <input
          type="checkbox"
          className="custome-checkbox"
          style={{ display: "none" }}
          checked={isSelected}
        />
        <label htmlFor={`select-checkbox-${value}`} style={{ width: "10%" }}>
          <img
            src={
              isSelected ? "/images/checked-icon.svg" : "/images/uncheck-icon.svg"
            }
            className="checkbox-image"
            alt={isSelected ? "Checked" : "Unchecked"}
          />
        </label>
        <div className={`dropdown-right-item gustavo`}>
          <p
            className={`custom-font gustavo ${isSelected ? "selected-employee" : ""
              }`}
            style={{
              marginLeft: "5px",
            }}
          >
            {label}
          </p>
        </div>
      </div>
    </Dropdown.Item>
  );
}
