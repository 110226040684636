import React, { useEffect } from "react";
import "./styles.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/common/Header";
export default function ResourceManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userPermissions } = useSelector((store) => store.auth);
  const { feature_list } = useSelector((store) => store.subPlan);


  const array = [
    {
      title: "Projects & Tasks",
      link: "/ResourceManagement",
      permission: (userPermissions.includes("Can view project") || userPermissions.includes("Can view all projects")) && feature_list.includes("Project & Task Report"),
    },
    {
      title: "Employee Settings",
      link: "/ResourceManagement/employeesettings",
      permission:
        (userPermissions.includes("Can view all users in the organization") ||
          userPermissions.includes("Can view users added by or assigned to self")) && feature_list.includes("Work schedule"),
    },
    {
      title: "Work Schedules",
      link: "/ResourceManagement/workschedules",
      permission: (userPermissions.includes("View own work schedule") || userPermissions.includes("View all work schedule")) && feature_list.includes("Work schedule"),
    },
  ];

  const resourcePath = array.filter(item => item.permission);


  useEffect(() => {
    // Check if the current path is accessible; if not, find the first accessible path
    const currentTab = resourcePath.find((path) => pathname === path.link);
    if (!currentTab || !currentTab.permission) {
      const defaultTab = resourcePath.find((path) => path.permission);
      if (defaultTab) {
        navigate(defaultTab.link);
      }
    }
  }, [pathname, navigate, resourcePath]);

  return (<div>
    <div className="dashboard-header">
    <Header title='Resource Management' style='font-weight: bold;' />
  </div>
    <div className="wer">
      {/* <div className="resource-header"> */}

        <div className="header-tab" style={{marginTop: "10px"}}>
          <div className="resource-tab-container-emp">
            {resourcePath.map((path) => (
              <>
                {path.permission && (
                  <div
                    className={`tab-button ${pathname === path.link && "active-tab"
                      } `}
                    key={path.title}
                    onClick={() => navigate(path.link)}
                  >
                    <p>{path.title}</p>
                  </div>
                )}
              </>
            ))}
          </div>
      </div>
      <div className="resource-context-container">
        <Outlet />
      </div>
      </div>
    </div>
  // </div>

  );
}
