import React, { useState, useRef ,useEffect,forwardRef } from "react";
import usePayrollList from "../../../hooks/usePayroll";
import "./style.css";
import EmpPayrollTable from "../../common/PayrollTable/DownLoad_Table/EmpPayrollTable_download";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { get_document_list_download } from "../../../redux/Actions/payrollAction";
import { MonthPicker } from "../../common/DatePickers";


const DownLoadPayslip = () => {
  const {
    startMonth,
    setStartMonth,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    handleDecrease,
    handleIncrease,
    currentPage, 
    setCurrentPage,
    pageCounts, 
    setPageCounts,
    setTypeValue,
    documentLists,
    setMonthYearValue,
    isLoading,
    setIsLoading,
    documentListsDownload,
  } = usePayrollList();
   
  const { userInfo } = useSelector((store) => store.auth);
  const [user,setUser]=([userInfo.id]);
  const dispatch=useDispatch();

  useEffect(() => {
    const timeoutId = setTimeout(() => {

      const monthYearValue = `${startMonth.getFullYear()}-${startMonth.getMonth() + 1}-01`;
      const typeValue = "payslip";// covert this in a object
      dispatch( get_document_list_download(user,monthYearValue,typeValue,setPageCounts,setCurrentPage,setIsLoading));
      setMonthYearValue(`${startMonth.getFullYear()}-${startMonth.getMonth() + 1}-01`);
      setTypeValue("payslip")// covert this in a object
      setCalendarIsOpen(false);

    }, 3000);
  
    return () => clearTimeout(timeoutId);
  
  }, [startMonth]);

  useEffect(()=>{
    setIsLoading(true);
  },[startMonth]);

return (
    <div>
      <div id="DownLoadPayslip">
        {console.log("download",documentListsDownload)}
        <div className="header-btn">
          <div className="employee-timeline">
          <img
            src="/images/icons/Icon - Calender.png"
            alt="calendar"
            onClick={() => setCalendarIsOpen(!calendarIsOpen)}
            ref={wrapperRef}
          />
            
            <button> { <MonthPicker 
               ref={calendarRef}
               startMonth={startMonth}
               setStartMonth={setStartMonth}
               // calendarRef={calendarRef}
               calendarIsOpen={calendarIsOpen}
               setCalendarIsOpen={setCalendarIsOpen}
              
             />}</button>

            <button className="prev-month" onClick={handleDecrease}></button>
            <button className="next-month" onClick={handleIncrease}></button>

          </div>
        </div>
      </div>

      {/* Pass the "document" prop to EmpPayrollTable */}
      <EmpPayrollTable 
        document="payslip" 
        list={documentListsDownload} 
        
        hookProps={{
          currentPage,
          setCurrentPage,
          pageCounts,
          setPageCounts,
          isLoading,
        }}  />
    </div>
  );
};

export default DownLoadPayslip;
