import React, { useState } from "react";
import "./styles.css";
import useEmployeeAndSettings from "../../hooks/useEmployeeAndSettings";
import SearchableDropdown from "../../components/common/SearchableDropdown";
import { useNavigate } from "react-router-dom";
import MyTable from "./Table";
const FormComponent = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    personalEmail: "",
    jobTitle: "",
    joiningDate: "",
    managerName: "",
    shiftType: "",
    shiftTiming: "",
    fixedCTC: "",
    variableCTC: "",
    compensation: ""
  });
  const {
    employeeValues,
    cancleEmployeeAdd,
    employeeErrors,
    employeeID,
    handleManagerIds,
    employeesList,
    selectedOptions,
    setSelectedOptions,
    selectedGroups,
    setSelectedGroups,
    handleGroup,
  } = useEmployeeAndSettings();

  const navigate=useNavigate();

  const managers = employeesList;
  console.log("manager", managers)
  const handleChange = (e) => {
    const formFieldName = e.target.name;
    const formFieldValue = e.target.value;

    setFormData({
      ...formData,
      [formFieldName]: formFieldValue,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };
  const Handlecancel = (e) => {
    navigate("/ResourceManagement/employeesettings")
    console.log(formData);
  };

  return (
    <>
    <div className="shift-form-container" >
      <h2 className="offer-head">Send Offer</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row input-spacing">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="middleName"
            placeholder="Middle Name"
            value={formData.middleName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="form-row input-spacing">
          <input
            type="email"
            name="personalEmail"
            placeholder="Personal Email"
            value={formData.personalEmail}
            onChange={handleChange}
          />
          <input
            type="text"
            name="jobTitle"
            placeholder="Job Title"
            value={formData.jobTitle}
            onChange={handleChange}
          />
        </div>
        <div className="form-row input-spacing">
          <input
            type="date"
            name="joiningDate"
            value={formData.joiningDate}
            onChange={handleChange}
          />
          <select
            name="managerName"
            value={formData.managerName}
            onChange={handleChange}
            
          >
            <option value="">Manager</option>
            {managers.map((manager, index) => (
              <option key={index} value={manager.name}>
                {manager.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <select
            name="shiftType"
            value={formData.shiftType}
            onChange={handleChange}
          >
            <option value="">Shift Type</option>
            <option value="hybrid">Hybrid</option>
            <option value="day">Day</option>
            <option value="night">Night</option>
          </select>
          <select
            name="shiftTiming"
            value={formData.shiftTiming}
            onChange={handleChange}
          >
            <option value="">Shift Timing</option>
            <option value="9-7">9:00am to 7:00pm</option>
            <option value="1-10">1:00pm to 10:00pm</option>
            <option value="3-12">3:00pm to 12:00am</option>
            <option value="8-5">8:00pm to 5:00am</option>
          </select>

        </div>
        <div className="form-row input-spacing">
          <input
            type="text"
            name="managerName"
            placeholder="Compensation"
            value={formData.compensation}
            onChange={handleChange}
          />
          <input
            type="text"
            name="fixedCTC"
            placeholder="Fixed CTC"
            value={formData.fixedCTC}
            onChange={handleChange}
            style={{ width: "calc(50% - 35px)", marginRight: "10px" }} // Decreased width and added margin
          />
          <input
            type="text"
            name="variableCTC"
            placeholder="Variable CTC"
            value={formData.variableCTC}
            onChange={handleChange}
            style={{ width: "calc(50% - 5px)" }} // Decreased width
          />
        </div>
         <MyTable/>
       
        <div className="btn-container">
          <button className="offer-btn">Send offer</button>
          <button className="cancel-user-btn" onClick={Handlecancel}>Cancel</button>
        </div>
      </form>
    </div>
    </>
  );
};

export default FormComponent;
