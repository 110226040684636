import React from "react";
import usePublicHoliday from "../../hooks/usePublicHoliday";
import PublicHolidayTable from "./PublicHolidayTable";
import { MonthPicker } from "../common/DatePickers";
import ApplyPublicHoliday from "./ApplyPublicHoliday";
import { useSelector } from "react-redux";

const PublicHoliday = () => {
  const {
    publicHolidayList,
    currentPage,
    setCurrentPage,
    pageCounts,
    userInfo,
    currentTab,
    handleDecrease,
    calendarRef,
    startMonth,
    setStartMonth,
    calendarIsOpen,
    handleIncrease,
    startDate,
    setCalendarIsOpen,
    wrapperRef,
    setModalOpen,
    setStartDate,
    handleSubmit,
    modalOpen,
    setHolidayName,
    holidayName,
    removeHoliday,
    editPublicHoliday,
    cancleModal,
    calendarOpen,
    setCalendarOpen,
    handleDecrease_year,
    handleIncrease_year,
    selectedYear,
    inProgress,
    setInProgress,
    exportState,
    handleHolidayExport,
    editId
  } = usePublicHoliday();
  
  const { userPermissions } = useSelector((store) => store.auth);

  return (
    <div className="leave-status">
      <div className="public-header-btn">
        <div className="public-holiday-year-timeline">
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              src="/images/icons/Icon - Calender.png"
              alt="calendar"
              onClick={() => setCalendarIsOpen(!calendarIsOpen)}
              ref={wrapperRef}
            />
            <button>{selectedYear.format("YYYY")}</button>
          </div>

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <button className="prev-month" onClick={handleDecrease_year}></button>
            <button className="next-month" onClick={handleIncrease_year}></button>
          </div>
        </div>
        <div className="button-container mt-0">
          {userPermissions.includes("Can view public holiday") && (
            <div className={publicHolidayList.length === 0 ? "export-btn holiday-hidden" : "export-btn"}>
              <button
                onClick={() => handleHolidayExport(selectedYear)}
                disabled={exportState}
              >
                Export CSV
                {exportState && (
                  <span className="spinner-grow spinner-grow-sm"></span>
                )}
                <img src="/images/icons/download.png" alt="" />
              </button>
            </div>
          )}
          {userPermissions.includes("Can add public holiday") && (
            <button
              className="add-public-holiday-btn"
              type="submit"
              onClick={() => setModalOpen(true)}
            >
              <img src="/images/icons/add-icon.png" alt="add" style={{ height: "18px", marginRight: "5px" }} />
              Add Public Holiday
            </button>
          )}
        </div>
      </div>

      <PublicHolidayTable
        hookProps={{
          publicHolidayList,
          currentPage,
          setCurrentPage,
          pageCounts,
          removeHoliday,
          editPublicHoliday,
          inProgress,
        }}
      />
      <ApplyPublicHoliday
        open={modalOpen}
        onClose={cancleModal}
        hookProps={{
          startDate,
          startMonth,
          currentTab,
          handleDecrease,
          handleIncrease,
          calendarRef,
          wrapperRef,
          calendarIsOpen,
          setCalendarIsOpen,
          setStartDate,
          handleSubmit,
          setHolidayName,
          holidayName,
          editId,
        }}
      />
    </div>
  );
};

export default PublicHoliday;
