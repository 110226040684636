import React from "react";

export default function UbuntuSteps() {
  return (
    <>
      <p style={{fontWeight:"bold", fontSize: "28px"}}> <span className="download-underline"> For Ubuntu </span></p>
      <ol className="custom-ol">
        <li>Download the .deb file from the above link.</li>
        <li>
          After downloading the application install the Application to your
          system.
        </li>
        <li>
          Search for the icon in the installed location or just search for
          Alpha Desktop application and press Enter.
        </li>
        <li>Then login to the signup screen and run the tracker.</li>
      </ol>
    </>
  );
}
