import React, { useState, useRef, useEffect } from "react";
import Modal from "../common/Modal";
import "./style.css";
import moment from "moment";
import SelectLeaveStatus from "../SelectType/leavestatus";

const EditLEaveModal = ({ open, onClose, hookProps }) => {
  const {
    editData,
    leaveOptions,
    handleSelectStatus,
    leaveStatus,
    managerComment,
    handleUpdateLeave,
    handleEditReasonChange,
    editLeaveModal,
    startMonth,
    setLeaveStatus,
    isEscalted
  } = hookProps;

  const [isLeaveDateVisible, setLeaveDateVisible] = useState(false);
  const wrapperRefs = useRef(null);
  const wrapperRef = useRef(null);
  console.log("edda", editData);
  const toggleLeaveDateVisibility = () => {
    setLeaveDateVisible(!isLeaveDateVisible);
  };

  const appliedleavedates = [
    { date: "2024-05-13", day: "Mon", isHalfDay: true },
    { date: "2024-05-14", day: "Tue", isHalfDay: false },
    { date: "2024-05-15", day: "Wed", isHalfDay: false },
  ];

  const getFormattedDate = (date) => {
    const dateObject = new Date(date);
    const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
    return dateObject.toLocaleDateString('en-US', options);
  };
  const calculateLeaveDates = (startDate, endDate) => {
    const leaveDates = [];
    let currentDate = moment(startDate);
    while (currentDate <= moment(endDate)) {
      leaveDates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }
    return leaveDates;
  };

  const leaveDates = calculateLeaveDates(editData?.leave_start_date, editData?.leave_end_date);
  console.log("leavesdates", leaveDates);

  const leaveCount = leaveDates.length;
  const formatDateRange = (startDate, endDate) => {
    const formattedStartDate = getFormattedDate(startDate);
    const formattedEndDate = getFormattedDate(endDate);

    if (parseFloat(leaveCount) > 1) {
      return `${formattedStartDate} to ${formattedEndDate}`;
    } else {
      return formattedStartDate;
    }
  };
  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setLeaveDateVisible(false);
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }




  useOutsideAlerter(wrapperRefs);
  useEffect(() => {
    // Reset the leave date visibility when the modal is closed
    if (!open) {
      setLeaveDateVisible(false);
    }
  }, [open]);
  return (
    <Modal open={open} onClose={onClose}>
      <div className="leave-container">
        <div className="header-empmodal">
          <div>
            <p style={{ fontSize: "22px" }}> Employee Leave</p>
          </div>
          <div onClick={() => onClose()} style={{ cursor: "pointer" }}>
            <img src="/images/icons/clear-icon.svg" alt="" />
          </div>
        </div>
        <div className="row-leave mb-2" style={{ marginTop: "16px" }}>
          <label htmlFor="u-access" className="col-sm-3 col-form-label">
            Employee Name
          </label>
          <div className="col-sm-9 col-width">
            <p className="leave-username">{isEscalted ? editData?.user_details?.name : editData?.user.name}</p>
          </div>
        </div>
        <div className={`row-leave ${isLeaveDateVisible ? 'mb-0-open' : 'mb-2-closed'}`} style={{ cursor: (editData?.half_day_leaves != null &&editData?.half_day_leaves.length >0)? "pointer" : "default" }}
        >
          <label htmlFor="u-name" className="col-sm-3 col-form-label">
          Leave  Date {(editData?.half_day_leaves != null &&editData?.half_day_leaves.length >0) && (
    <span style={{ color: "#949494" }}>(Half Day included)</span>
)}

          </label>
          <div ref={wrapperRefs} onClick={(editData?.half_day_leaves != null &&editData?.half_day_leaves.length >0) ? toggleLeaveDateVisibility : undefined} className="col-sm-9 col-width">
            <div className="no-wrap" style={{ width: "96%" }}>

              <img
                src="/images/icons/Icon - Calender.png"
                alt="calendar"
                // onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                // ref={wrapperRef}
                style={{ marginLeft: "9px", marginBottom: "3px" }}
              />
              <span style={{ marginLeft: "7px", fontSize: "15px",fontWeight:"400"}}>{formatDateRange(editData?.leave_start_date, editData?.leave_end_date, editData?.leave_count)}</span>
              {(editData?.half_day_leaves != null &&editData?.half_day_leaves.length >0)&& (<img src="/images/down-arrow-icon.svg" alt="calendar" style={{ float: 'right',marginTop:"10px" }} />)}
              {/* <div style={{borderBottom:"1px solid lightgray"}}></div> */}
              {isLeaveDateVisible && (
                <div className="emp-modal-table" onClick={(e) => e.stopPropagation()}>
                  <table className="leave-date-table" style={{ width: "100%", tableLayout: "", marginTop: "5px" }}>
                    <thead className="leave-date-th">
                      <tr>
                        <th style={{ width: "50%", paddingLeft: "29px" }}>Date</th>
                        <th style={{ width: "24%" }}>Half Day</th>
                      </tr>
                    </thead>
                    <tbody>
                      {leaveDates.map((date, index) => (
                        <tr key={index}>
                          <td>{getFormattedDate(date)}</td>
                          <td>
                            {editData.half_day_leaves && editData.half_day_leaves.
                              some(leave => moment(leave.date, "M/D/YYYY").format("YYYY-MM-DD") === date) ? (

                              <label className="switches">
                                <input type="checkbox" className="leave-custom-checkbox" checked={true} readOnly />
                                <span className="sliders round"></span>
                              </label>
                            ) : (
                              <label className="switches">
                                <input type="checkbox" className="leave-custom-checkbox" checked={false} disabled />
                                <span className="sliders round"></span>
                              </label>
                            )}
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              )}
            </div>


          </div>
        </div>


        <div className="row-leave mb-2" >
          <label htmlFor="u-access" className="col-sm-3 col-form-label">
            Status
          </label>
          <div className="col-sm-9 col-width" ref={wrapperRef}>
               <SelectLeaveStatus handleSubmit={setLeaveStatus} wrapperRef={wrapperRef} />
          </div>
        </div>
        <div className="row-leave mb-2">
          <label htmlFor="u-access" className="col-sm-3 col-form-label">
            Additional Information (Optional)
          </label>
          <div className="col-sm-3"></div> {/* Placeholder for alignment */}
          <div className="col-sm-9 col-width">
            <textarea
              rows="3"
              cols="20"
              className="form-control"
              placeholder="Write here..."
              value={managerComment}
              onChange={handleEditReasonChange}
            />
          </div>
        </div>
        <div className="row-leave mb-2 ">
          <div className="col-sm-4 col-width ">
            <div className="modal-btn">
              <button style={{ marginLeft: "40px" }} type="button" className="btn btn-primary" onClick={handleUpdateLeave}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
};

export default EditLEaveModal;
