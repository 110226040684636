import { Axios_v2 } from "../../Utils/axios";
import { autoLogoutOnTokenExpire } from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";

export const ADD_FEEDBACK = "EMPLOYEE_DETAILS";

export const postFeedBack = (req, setIsProgress,setRating,setSelectedOption) => {
  setIsProgress(true);
  return async (dispatch) => {
    Axios_v2.post("/feedback/", req, HeaderToken())
      .then((res) => {
        console.log("add new project res", res);
        toast.success("Thank you for your feedback.");
        setIsProgress(false);
        setRating(0);
        setSelectedOption(null);
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );
        setIsProgress(false);
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};
