import React from "react";
import "./style.css";

const Pagination = ({ pages, currentPage, setCurrentPage }) => (
  <div className="projects-table-footer" style={{ borderTop: "0px" }}>
    <div className="table-pagination">
      <div className="classpage-btn">
        {pages.map((page, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <span style={{ color: '#848A95' }}>,</span>} 
            <button
              className={page === currentPage ? "active-page-btn" : ""}
              onClick={() => setCurrentPage(page)}
              style={{paddingRight:"1px"}}
            >
              {page}
            </button>
          </React.Fragment>
        ))}
      </div>
      <div className="pagination-btn-arrow">
        <button
          className="prev-month"
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          disabled={currentPage === pages[0]}
        ></button>
        <button
          className="next-month"
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={currentPage === pages[pages.length - 1]}
        ></button>
      </div>
    </div>
  </div>
);

export default Pagination;
