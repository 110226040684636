import React, { useState } from "react";
import Tooltip from "../ToolTip";
import { RenderPageButtons } from "../../Utils/randerPageButtons";
import moment from "moment";
import { useSelector } from "react-redux";
import Pagination from "../common/Pagination/pagination";

export default function LeaveStatusTable({ hookProps }) {
  const {
    currentPage,
    setCurrentPage,
    leaveTransactionList,
    userInfo,
    editleave,
    leaveTransactionFilterList,
    statuspageCounts,
    inProgress,
    edituserleave,
    seeDescription,
    handleDelete_Status,
    userPermissions,
    pageCounts,
    setprevTAB,
    currentTab,
  } = hookProps;

  console.log(leaveTransactionFilterList);

  const pages = [];
  const { leaveTransactionFilterSortedList } = useSelector((store) => store.leave);
  const [sortField, setSortField] = useState("date"); // Default sort by date
  const [sortOrder, setSortOrder] = useState("asc"); // Default ascending order

  for (let i = 1; i <= statuspageCounts; i++) {
    pages.push(i);
  }
  const dataFound = leaveTransactionFilterList && leaveTransactionFilterList.length > 0;

  const toggleSortOrder = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  function formatDateRange(startDate, endDate, leaveCount) {
    const formattedStartDate = moment(startDate).format("ddd, DD MMM");
    const formattedEndDate = moment(endDate).format("ddd, DD MMM");
    if (parseFloat(leaveCount) > 1) {
      return `${formattedStartDate} to ${formattedEndDate}`;
    } else {
      return `${formattedStartDate}`;
    }
  }

  const renderPageNumbers = RenderPageButtons(
    currentPage,
    statuspageCounts,
    setCurrentPage
  );

  return (
    <div>
      <div className="projects-table" style={{marginTop: "10px"}}>
        <div className="projects-table-header"></div>

        <div className="projects-table-body" style={{padding: "12px"}}>
          <table className="leave-status-table">
           
              <tr>
                <th>Name</th>
                <th style={{display: "none"}}>Email</th>
                <th scope="col" onClick={() => toggleSortOrder("date")}>
                  Date {sortField === "date" && (sortOrder === "asc" ? "▲" : "▼")}
                 
                </th>
                <th>Type Of Leave</th>
                <th>Status</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            
            
              {inProgress ? (
                <tr className="table-Rows-loadin">
                  <td colSpan="15" style={{ textAlign: "center" }}>
                  <h3 className="custom-no-data">Loading Data...</h3>
                  </td>
                </tr>
               ) : dataFound ? (
                (sortOrder === "asc" ? leaveTransactionFilterList : leaveTransactionFilterSortedList).map((leave) => (
                  <tr key={leave.id}>
                    <td>
                      <div className="employee-name" style={{paddingLeft: "18px"}}>
                        {/* <img
                          src={leave.user.profile_image}
                          alt="profile"
                          onError={(e) => {
                            e.target.src = "/images/profilepic.png";
                          }}
                        /> */}
                        {leave.user.name}
                      </div>
                      <div className="to-hide-left-border"></div>
                    </td>
                    <td style={{display: "none"}}>
                      <div className="no-wrap">{leave.user.email}</div>
                    </td>
                    <td>
                      <div className="no-wrap">
                        {formatDateRange(
                          leave?.leave_start_date,
                          leave?.leave_end_date,
                          leave?.leave_count
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="no-wrap">{leave.leave_type}</div>
                    </td>
                    <td>
                      {/* <div className="no-wrap">{leave.status}</div> */}
                      <div
                        className={
                          leave.status === "Approved" ? "approved-status" : 
                          leave.status === "Deny" ||leave.status === "Rejected"? 
                  "not-approved-status" : "need-action"
                        }
                      >
                        {leave.status ==="Deny" ? "Rejected" : leave.status}
                      </div>
                    </td>
                    <td>
                      <div>
                        <button
                          className="des-btn"
                          onClick={() => {
                            seeDescription(leave);
                          }}
                          style={{marginLeft: "10px"}}
                        >
                          <img src="/images/description.svg" alt="edit" />
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="table-actions">
                        {userPermissions.includes(
                          "Can edit leave before approval within a certain time"
                        ) &&
                          leave.status === "Pending" && (
                            <Tooltip position="top" title="Edit Leave">
                            <button onClick={() => { edituserleave(leave); setprevTAB(currentTab); }}>                               
                             <img
                                  src="/images/icons/edit-green.png"
                                  alt="edit"
                                  style={{width:"27px"}}
                                />
                              </button>
                            </Tooltip>
                          )}

                        {leave.status === "Pending" && (
                          <Tooltip position="top" title="Delete Leave">
                            <button
                              onClick={() => handleDelete_Status(leave.id)}
                            >
                              <img src="/images/icons/delete.png" alt="edit" style={{width: "20px", height: "25px"}}/>
                            </button>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="table-Rows-loadin">
                <td colSpan="7" className="loader">
                <div style={{textAlign:"center"}}>No Data to Show!</div>
                </td>
              </tr>
              )}
           
          </table>
        </div>

       

      


      </div>
      {statuspageCounts>1&&(
           <Pagination
           pages={pages}
           currentPage={currentPage}
           setCurrentPage={setCurrentPage}
         />
        )}

        {console.log("pagesCount",pages.length)}

    </div>
  );
}
