import { Axios_v2 } from "../../Utils/axios";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";
export const DOCUMENT_LIST = "DOCUMENT_LIST";
export const DOCUMENT_LIST_DOWNLOAD="DOCUMENT_LIST_DOWNLOAD";

// Add the 'dispatch' parameter to your function
export const get_document_list = (userid, monthYearValue, typeValue,setPageCounts,setCurrentPage,setIsLoading) => {
  setIsLoading(true);
  return (dispatch) => {
   const rand = Math.random();
   const apiUrl = `/documents_upload/?month_year=${monthYearValue}&type=${typeValue}&items_per_page=${4}&page=1&rand=${rand}`; // Removed 4 from template string

    if (userid && userid.length > 0) {
      const userFilter = `&user=${userid}`;
      Axios_v2.get(apiUrl + userFilter, HeaderToken())
        .then((res) => {
          // Resolve and reject are not defined in this context, so you should dispatch your actions here.
          if (res.status === 200) {
            dispatch({ type: DOCUMENT_LIST, payload: res.data.results });
            setCurrentPage(1);
            setPageCounts(res.data.total_pages);
            console.log(res,"response123");

          }
        })
        .catch((error) => {
          // Handle errors here
          console.log("Error",error);
          toast.warn("Error in fetching data1");
        }).finally(() => {
          // Set isLoading to false after the request is completed
          setIsLoading(false)});
    } else {
      Axios_v2.get(apiUrl, HeaderToken())
        .then((res) => {
          // Resolve and reject are not defined in this context, so you should dispatch your actions here.

          if (res.status === 200) {
            // Dispatch any other actions you need here
            dispatch({ type: DOCUMENT_LIST, payload: res.data.results });
            setCurrentPage(1);
            console.log("response123",res);
            setPageCounts(res.data.total_pages);

          }
          
         
        })
        .catch((error) => {
          // Handle errors here
           console.log("Error",error);
          toast.warn("Error in fetching data");
        }).finally(() => {
          // Set isLoading to false after the request is completed
          setIsLoading(false)});
    }
    
  };
};
export const get_document_list_download = (userid, monthYearValue, typeValue,setPageCounts,setCurrentPage,setIsLoading) => {
  setIsLoading(true);
  return (dispatch) => {
   const rand = Math.random();
   const apiUrl = `/documents_upload/?month_year=${monthYearValue}&type=${typeValue}&items_per_page=${4}&page=1&rand=${rand}`; // Removed 4 from template string

    if (userid && userid.length > 0) {
      const userFilter = `&user=${userid}`;
      Axios_v2.get(apiUrl + userFilter, HeaderToken())
        .then((res) => {
          // Resolve and reject are not defined in this context, so you should dispatch your actions here.
          if (res.status === 200) {
            dispatch({ type: DOCUMENT_LIST_DOWNLOAD, payload: res.data.results });
            setCurrentPage(1);
            setPageCounts(res.data.total_pages);
            console.log(res,"response123");

          }
        })
        .catch((error) => {
          // Handle errors here
          console.log("Error",error);
          toast.warn("Error in fetching data1");
        }).finally(() => {
          // Set isLoading to false after the request is completed
          setIsLoading(false)});
    } else {
      Axios_v2.get(apiUrl, HeaderToken())
        .then((res) => {
          // Resolve and reject are not defined in this context, so you should dispatch your actions here.

          if (res.status === 200) {
            // Dispatch any other actions you need here
            dispatch({ type: DOCUMENT_LIST, payload: res.data.results });
            setCurrentPage(1);
            console.log("response123",res);
            setPageCounts(res.data.total_pages);

          }
          
         
        })
        .catch((error) => {
          // Handle errors here
           console.log("Error",error);
          toast.warn("Error in fetching data");
        }).finally(() => {
          // Set isLoading to false after the request is completed
          setIsLoading(false)});
    }
    
  };
};

export const download_pdf = (ID,NAME,TYPE) => {
 return async (dispatch) => {
           try {
                    const response = await Axios_v2(`/documents_upload/${ID}/download_document/`, {
                      headers: {
                        'Authorization': HeaderToken().headers.Authorization,
                        'Content-Type': '', 
                      },
                      responseType: 'blob', // Important for files
                    });
                
                    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = `${NAME} ${TYPE}.pdf`;
                    document.body.appendChild(a);
                
                    // Trigger the download
                    a.click();
                
                    // Clean up
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(a);
                
                    toast.success(`${NAME} ${TYPE}`, { autoClose: 4000 });
                  } catch (error) {
                    console.error("Error downloading the document:", error);
                    toast.warn("no PDF available");
                  }
  };
};

export const get_document_list_paginated = (userid, monthYearValue, typeValue,setPageCounts,currentPage,setData,) => {
  return (dispatch) => {
    const rand = Math.random();
   const apiUrl = `/documents_upload/?month_year=${monthYearValue}&type=${typeValue}&items_per_page=${4}&page=${currentPage}&rand=${rand}`; // Removed 4 from template string

    if (userid && userid.length > 0) {
      const userFilter = `&user=${userid}`;
      Axios_v2.get(apiUrl + userFilter, HeaderToken())
        .then((res) => {
          // Resolve and reject are not defined in this context, so you should dispatch your actions here.
          if (res.status === 200) {
            dispatch({ type: DOCUMENT_LIST, payload: res.data.results });
            console.log(res,"response123");
            setPageCounts(res.data.total_pages);
          }
        })
        .catch((error) => {
          // Handle errors here
          console.log("Error",error);
          toast.warn("Error in fetching data1");
        });
    } else {
      Axios_v2.get(apiUrl, HeaderToken())
        .then((res) => {
          // Resolve and reject are not defined in this context, so you should dispatch your actions here.
          if (res.status === 200) {
            // Dispatch any other actions you need here
            dispatch({ type: DOCUMENT_LIST, payload: res.data.results });
            console.log("response123",res);
            setPageCounts(res.data.total_pages);
          }
          
         
        })
        .catch((error) => {
          // Handle errors here
           console.log("Error",error);
          toast.warn("Error in fetching data");
        });
    }
  };
};

export const post_api = async (formData,  setUser,  setName,setSelectedFile, setFiletoupload,name,doc_id,) => {  
  try {
    const res = await Axios_v2.post(`/documents_upload/`, formData, HeaderToken());

    if (res.status === 201) {
      toast.success(`${name} payslip successfully updated`);
      // Perform any other necessary actions here
      setUser([]);
      setName();
      //fetchUploadedData();
      //setFileAction(!fileAction);
      setSelectedFile(null);
      setFiletoupload(null);

    } else {
      toast.warn("Not uploaded");
    }
  } catch (err) {
    if(err.response.status>=500){
      toast.warn("Not Uploaded");
       }else{ Object.keys(err.response.data).forEach((msg) =>
        toast.warn(`${msg} ${err.response.data[msg][0]}`)
      );
      
      }
    toast.warn("Not Uploaded");
    setUser([]);
    setName();
    setFiletoupload(null);
    setSelectedFile(null);
  }
}

export const delete_document = async (ID,setUser,user,name,setName) => {  
  Axios_v2.delete(`/documents_upload/${ID}/`,HeaderToken())
  .then((response) => {
    console.log(response);
    if(response.status==204){
    toast.warn("deleted old file");
    setName();
    setUser([]);
    }
 })
  .catch((error) => {
    console.error("Error downloading the document:", error);
    console.log(error);
    toast.warn("No PDF available");
  });

}

export const patch_api = async (formData,setUser,setName,setSelectedFile, setFiletoupload,name,doc_id,setapiType) => {  
  console.log(HeaderToken(),"HEADER TOKEN");
  try {
    const res = await Axios_v2.patch(`/documents_upload/${doc_id}/`, formData, HeaderToken());

     console.log(res,"patch api response");
     if(res.status==200){
      toast.success("document_updated");
     }
     setapiType("post");
     setUser([]);
     setName();
     setSelectedFile(null);
     setFiletoupload(null);
  } catch (err) {
    if(err.response.status>=500){
      toast.warn("Not Uploaded");
       }else{ Object.keys(err.response.data).forEach((msg) =>
        toast.warn(`${msg} ${err.response.data[msg][0]}`)
      );
      
      }
    toast.warn("Not Uploaded");
    console.log(err,"error from patch api");
    setapiType("post");
    setName();
    setUser([]);
    setSelectedFile(null);
    setFiletoupload(null);
  }
}


