import { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { applyLeave,deleteLeaveAllotment, getLeaveList, updayeLeave ,getAllotmentList,AddTypeofEmployee,UpdateLeaveAllotment,getLeaveType} from "../redux/Actions/leaveAction";

export default function useLeaveAllotment() {
  const dispatch = useDispatch();

  const [inProgress, setInProgress] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [leavepageCounts, setLeavePageCounts] = useState(1);
  const itemsPerPage = 5;
  const [modalOpen, setModalOpen] = useState(false);
const { leaveAllotmentList} = useSelector((store) => store.leave);
const [editLeaveID,setEditLeaveID] = useState(null)
const [employeeType, setEmployeeType] = useState(""); 
const [leaveAlloted, setLeaveAlloted] = useState(""); 
const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    console.log("leaveallotmenthook");
    
        dispatch(
          getAllotmentList({
            setInProgress,
            currentPage,
            itemsPerPage,
            setLeavePageCounts
            // setPageCounts
    
          })
        );
      }, [dispatch,   currentPage,itemsPerPage]);
      
      const handleSelectAll = () => {
        setSelectAll(!selectAll);
      
        if (!selectAll) {
          setSelectedRows(Array(leaveAllotmentList.length).fill(true));
          
          // Log the IDs of all selected employees
          const selectedEmployeeIDs = leaveAllotmentList.map((employee) => employee.id);
          console.log("Selected All Employee IDs: ", selectedEmployeeIDs);
        } else {
          setSelectedRows([]);
        }
      };
      const handleRowCheckboxChange = (index) => {
        const newSelectedRows = [...selectedRows];
        newSelectedRows[index] = !newSelectedRows[index];
        setSelectedRows(newSelectedRows);
      
        if (newSelectedRows[index]) {
          console.log("Selected Employee ID: ", leaveAllotmentList[index].id);
        }
      };
      const callAllotmentList = useCallback(() => {
        return dispatch(
          getAllotmentList({
            setInProgress,
            currentPage,
            itemsPerPage,
            setLeavePageCounts,
          })
        );
      },[dispatch, currentPage, itemsPerPage]);
    
      const openModal=()=>{
        setModalOpen(true);
        setEmployeeType("");
        setLeaveAlloted("");
        setEditLeaveID(null);
    
    
      }
      const cancleModal = () => {
        setModalOpen(false);
        setEditLeaveID(null);
      };
      const handleDeleteLeave = (ID) => {
        console.log(ID);
         dispatch(deleteLeaveAllotment(ID,callAllotmentList));
       };
       const handleLeaveSubmit = () => {
        let payload ={
            "category_name": employeeType,
            "monthly_allotted_leaves": leaveAlloted,
            
        }
        if (editLeaveID) {
          dispatch(UpdateLeaveAllotment(payload,editLeaveID, cancleModal, callAllotmentList))
      
        } else {
          dispatch(AddTypeofEmployee(payload,  cancleModal, callAllotmentList))
        }
        
        }
        const handleEmployeeChange = (event) => {
   
            setEmployeeType(event.target.value);
          };
          const handleLeaveAllotmentChange = (event) => {
            setLeaveAlloted(event.target.value);
          };
          const editLeaveAllotment = (value) => {
            if(value.id){
              setEmployeeType(value.category_name);
              setLeaveAlloted(value.monthly_allotted_leaves)
              setEditLeaveID(value.id);
              setModalOpen(true);
            }
          }; 
    return{
        leaveAllotmentList,
        editLeaveAllotment,
        modalOpen,
        setModalOpen,
        editLeaveID,
        employeeType,
        handleEmployeeChange,
        leaveAlloted,
        handleLeaveAllotmentChange,
        handleDeleteLeave,
        selectAll,
        selectedRows,
        handleSelectAll,
        handleRowCheckboxChange,
        handleLeaveSubmit,
        openModal,
        currentPage,
        setCurrentPage,
        leavepageCounts,
        inProgress

    }
}