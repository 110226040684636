import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { localStorage_userData } from "../constants";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./UnAuthenticatedRoutes";
import PlanExpiredRoutes from "./PlanExpiredRoutes";
import { useLocation } from "react-router-dom";
import { get_active_plan_features } from "../redux/Actions/subscription";

export default function AppRoutes() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.auth);

  const { isLoggedIn } = useSelector((store) => store.auth)
  const [activeSubscriptionList, setActiveSubscriptionList] = useState([]);
  const { feature_list } = useSelector((store) => store.subPlan);

 
  let userData = JSON.parse(localStorage.getItem(localStorage_userData));
 
  useEffect(() => {
    let subscriptions = JSON.parse(localStorage.getItem("activeSubscriptions"));
    console.log("SDSAD",subscriptions);
    if (userData && subscriptions && subscriptions.length > 0){ 
      dispatch({ type: "LOG_IN", payload: userData });
    }

  }, [dispatch]);

useEffect(()=>{
 dispatch(get_active_plan_features(userData?.organisation?.name));
},[dispatch]);

  console.log("planex", activeSubscriptionList); 
  console.log("list", feature_list); 
  console.log("state", isLoggedIn); 
  if (isLoggedIn) {
    if (feature_list && feature_list.length > 0) {
      return <AuthenticatedRoutes />;
    } else {
      return <PlanExpiredRoutes />;
    }
  } else {
    return <UnAuthenticatedRoutes />;
  }
}
