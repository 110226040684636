import { ALL_PLAN_LIST, USER_LIMIT, feature_list, active_subscription_list } from "../Actions/subscription";

const initialState = {
  ClockSession_PlanList: [],
  feature_list: [],
  active_subscription_list: [],
  userlimit:(1),
};

const subscriptionReducer = (store = initialState, action) => {
  switch (action.type) {
    case ALL_PLAN_LIST: {
      return {
        ...store,
        ClockSession_PlanList: action.payload,
      };
    }
    case feature_list: {
      return {
        ...store,
        feature_list: action.payload,
      };
    }
    case active_subscription_list: {
      console.log("harsh_userlimit_123");

      return {
        ...store,
        active_subscription_list: action.payload,
      };
    }
    case USER_LIMIT: {
      console.log("harsh_userlimit");
      return {
        ...store,
        userlimit: action.payload, // Correctly update userlimit here
      };
    }
    default: {
      return { ...store };
    }
  }
};

export default subscriptionReducer;
