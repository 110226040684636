import React, { useEffect, useState, useRef, useCallback } from 'react'; // Importing necessary hooks from React
import { useSelector, useDispatch } from "react-redux"; // Importing useSelector and useDispatch from react-redux
import { getworkinghourList } from '../redux/Actions/workinghoursAction'; // Importing the action creator function
import moment from "moment"; // Importing the moment library for date/time manipulation
import{ getActivityBar} from'../redux/Actions/workinghoursAction';

// Creating the custom hook function named useTimelineLogs
export default function useTimelineLogs() {
  // useSelector to get the required state from Redux store
  const { workinghoursList } = useSelector((store) => store.WH);
  const { userInfo } = useSelector((store) => store.auth);
  const { attendanceForAllUsers, allempAlltendence } = useSelector((store) => store.ReportR);
  
  // useDispatch to dispatch actions to Redux store
  const dispatch = useDispatch();

  // Initializing state variables using useState hook
  const itemsperpage = 10;
  const calendarRef = useRef(null);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [dateRangeStart, setDateRangeStart] = useState();
  const [dateRangeEnd, setDateRangeEnd] = useState();
  const [user, setUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [project, setProject] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total_hors, setTotalhors] = useState("");
  const [worked, setWorked] = useState("");
  const [shiftstart, setshiftstart] = useState("");
  const [shiftend, setshiftend] = useState(" ");
  const [totalworkinday, setworkinday] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(1);
  const [exportState, setExportState] = useState(false);
  const [weekDate, setWeekDate] = useState(new Date());
  const [startWeekDay, setStartWeekDay] = useState("");
  const [endWeekDay, setEndWeekDay] = useState("");
  const [startMonth, setStartMonth] = useState(new Date());
  const [totalValues, setTotalValues] = useState(0);
  const [selectedProject, setSelectedProject] = useState([]);
  const [currentTab, setCurrentTab] = useState("daily");
  const[delay,setdelay]=useState(1);


  // Ref for outside click detection
  const wrapperRef = useRef(null);
  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      setdelay(prevDelay => prevDelay + 1);
      console.log("DELAY_COUNTER", delay);
    }, 3000); return () => clearTimeout(timeoutId);
  
  }, [user]);

  // useEffect to dispatch getLeaveType action on component mount

 

  // Function to handle outside click detection
  function useOutsideAlerter(ref, calendarRef) {
    function handleClickOutside(event) {
      if (calendarIsOpen) {
        // if (
        //   ref.current &&
        //   !ref.current.contains(event.target) &&
        //   calendarRef &&
        //   !calendarRef.current.contains(event.target)
        // ) {
        //   setCalendarIsOpen(!calendarIsOpen);
        // }
        setCalendarIsOpen(!calendarIsOpen);
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef, calendarRef);

  // Function to handle change in date range
  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setDateRangeStart(start);
    setDateRangeEnd(end);
  };

  // Function to get week number from date
  function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return [d.getUTCFullYear(), weekNo];
  }

  // Function to get date of the week based on week number and year
  function getDateOfWeek(weekNumber, year) {
    return new Date(year, 0, 1 + (weekNumber - 1) * 7);
  }

  // Function to get first date of a week
  function getFirstDateOfWeeks(weekNumber, year) {
    var firstDayOfYear = new Date(year, 0, 1);
    var firstMondayOfYear = new Date(year, 0, 1 + ((8 - firstDayOfYear.getDay()) % 7));
    var dateOfFirstMondayOfWeek = new Date(firstMondayOfYear.getFullYear(), 0, firstMondayOfYear.getDate() + ((weekNumber - 1) * 7));
    return dateOfFirstMondayOfWeek;
  }

  // Function to get last date of a week
  function getLastDateOfWeek(weekNumber, year) {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const lastDayOfWeek = date.getDate() + 7;
    const lastDateOfWeek = new Date(date.setDate(lastDayOfWeek));
    return lastDateOfWeek;
  }

  // useEffect to set start and end week dates
  useEffect(() => {
    var curr = new Date(weekDate);
    var result = getWeekNumber(weekDate);
    var first_day = getFirstDateOfWeeks(result[1], result[0]);
    var last_day = getLastDateOfWeek(result[1], result[0]);
    last_day.setDate(last_day.getDate() - 1);

    setStartWeekDay(first_day);
    setEndWeekDay(last_day);
  }, [weekDate]);

  // Function to handle tab change
  const handleTabTypeChange = (e) => {
    const { value } = e.target;
    setCurrentTab(value);
  };

  // useEffect to fetch data based on selected tab

  useEffect(() => {

    const timeoutId = setTimeout(() => {

      let data;
      let endDate = new Date();
  
      switch (currentTab) {
        case "daily":
          data = {
            report_type: currentTab,
            start_date: moment(startDate).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
            end_date: moment(startDate).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
          };
          break;
        case "weekly":
          data = {
            report_type: currentTab,
            start_date: moment(startWeekDay).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
            end_date: moment(endWeekDay).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
          };
          break;
        case "monthly":
          const lastDayOfMonth = moment(startMonth).endOf('month').format('YYYY-MM-DD');
          const firstDayOfMonth = moment(startMonth).startOf('month').format('YYYY-MM-DD');
          data = {
            report_type: currentTab,
            start_date: firstDayOfMonth + "T00:00:00" + moment().format("Z"),           
            end_date: lastDayOfMonth + "T23:59:59" + moment().format("Z"),
            };
          break;
        case "dateRange":
          data = {
            report_type: currentTab,
            start_date: moment(dateRangeStart).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
            end_date: moment(dateRangeEnd).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
          };
          break;
        default: {
          data = {
            report_type: currentTab,
            start_date: startDate,
            end_date: endDate,
          };
        }
      }
      // Dispatch action to fetch data
      dispatch(getworkinghourList(
        data,
        user,
        project,
        setLoading,
        itemsperpage,
        setPageCounts,
        currentPage,
        dateRangeStart,
        dateRangeEnd
        )
      );


    }, 3000); return () => clearTimeout(timeoutId);
  
  }, [dispatch,delay,project,currentPage,dateRangeStart,dateRangeEnd, startDate,
  currentTab,
  startWeekDay,
  endWeekDay,
  startMonth,
  dateRangeStart,
  dateRangeEnd,]
  );

  useEffect(()=>{
    setLoading(true);
  }, [startDate,startWeekDay,startMonth,dateRangeStart,currentTab]);

  
  // Function to get data based on current page for pagination
  useEffect(() => {
    let data;
    let endDate = new Date();

    switch (currentTab) {
      case "daily":
        data = {
          report_type: currentTab,
          start_date: moment(startDate).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
          end_date: moment(startDate).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
        };
        break;
      case "weekly":
        data = {
          report_type: currentTab,
          start_date: moment(startWeekDay).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
          end_date: moment(endWeekDay).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
        };
        break;
      case "monthly":
        const lastDayOfMonth = moment(startMonth).endOf('month').format('YYYY-MM-DD');
        const firstDayOfMonth = moment(startMonth).startOf('month').format('YYYY-MM-DD');
        data = {
          report_type: currentTab,
          start_date: firstDayOfMonth + "T00:00:00" + moment().format("Z"),           
          end_date: lastDayOfMonth + "T23:59:59" + moment().format("Z"),
          };
        break;
      case "dateRange":
        data = {
          report_type: currentTab,
          start_date: moment(dateRangeStart).format("YYYY-MM-DD") + "T00:00:00" + moment().format("Z"),
          end_date: moment(dateRangeEnd).format("YYYY-MM-DD") + "T23:59:59" + moment().format("Z"),
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }
    }
    // Dispatch action to fetch data

   if (user.length === 1 && currentTab === "daily") {
  dispatch(
    getActivityBar(
      data,
      user,
      project,
      setLoading,
      itemsperpage,
      setPageCounts,
      currentPage,
      dateRangeStart,
      dateRangeEnd
    )
  );
}
},[dispatch,user, startDate,
  
  ]);
  const getData = () => {
    let data;
    let endDate = new Date();

    switch (currentTab) {
      case 'daily':
        data = {
          report_type: currentTab,
          start_date: moment(startDate).format('YYYY-MM-DD') + 'T00:00:00' + moment().format('Z'),
          end_date: moment(endDate).format('YYYY-MM-DD') + 'T23:59:59' + moment().format('Z'),
        };
        break;
      case 'weekly':
        data = {
          report_type: currentTab,
          start_date: moment(startWeekDay).format('YYYY-MM-DD') + 'T00:00:00' + moment().format('Z'),
          end_date: moment(endWeekDay).format('YYYY-MM-DD') + 'T23:59:59' + moment().format('Z'),
        };
        break;
      case 'monthly':
        data = {
          report_type: currentTab,
          start_date: moment(startMonth).format('YYYY-MM-DD') + 'T00:00:00' + moment().format('Z'),
          end_date: moment(endDate).format('YYYY-MM-DD') + 'T23:59:59' + moment().format('Z'),
        };
        break;
      case 'dateRange':
        data = {
          report_type: currentTab,
          start_date: moment(dateRangeStart).format('YYYY-MM-DD') + 'T00:00:00' + moment().format('Z'),
          end_date: moment(dateRangeEnd).format('YYYY-MM-DD') + 'T23:59:59' + moment().format('Z'),
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }
    }

    return data;
  };

  // useEffect to fetch data based on current page for pagination
  useEffect(() => {
    const data = getData();
    dispatch(getworkinghourList(data, user,  currentPage, itemsperpage, setPageCounts, setTotalValues));
  }, [currentPage]);

  // useCallback to memoize function
  const callAttendanceList = useCallback(() => {
    const data = getData();
    return dispatch(
      workinghoursList(
        data,
        user,
        
        currentPage,
        itemsperpage,
        setPageCounts,
        setTotalValues
      )
    );
  }, [dispatch, startDate, currentPage, itemsperpage, setPageCounts, setTotalValues]);

  // Function to handle decrease in date
  const handleDecrease = () => {
    if (currentTab === "daily") {
      const Decrement = startDate;
      Decrement.setDate(Decrement.getDate() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }

    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() - 7);
      setWeekDate(newWeekDate);
    }

    if (currentTab === "monthly") {
      const Decrement = startMonth;
      Decrement.setMonth(Decrement.getMonth() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]");
      setStartMonth(new Date(op));
    }
  };

  // Function to handle increase in date
  const handleIncrease = () => {
    if (currentTab === "daily") {
      const Increment = startDate;
      Increment.setDate(Increment.getDate() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }

    if (currentTab === "weekly") {
      const newWeekDate = new Date(weekDate);
      newWeekDate.setDate(newWeekDate.getDate() + 7);
      setWeekDate(newWeekDate);
    }

    if (currentTab === "monthly") {
      const Increment = startMonth;
      Increment.setMonth(Increment.getMonth() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]");
      setStartMonth(new Date(op));
    }
  };

  // Function to handle export of all data
 

  // Returning all necessary states and functions from the hook
  return {
    workinghoursList,
    dateRangeStart,
    dateRangeEnd,
    setTotalhors,
    setWorked,
    setshiftstart,
    setshiftend,
    setworkinday,
    setTotalhors,
    setLoading,
    startDate,
    itemsperpage,
    currentPage,
    setPageCounts,
    pageCounts,
    setLoading,
    setUser,
    setStartDate,
    currentPage,
    setCurrentPage,
    setDateRangeStart,
    setDateRangeEnd,
    user,
    setUser,
    project,
    setProject,
    calendarRef,
    calendarIsOpen,
    setCalendarIsOpen,
    exportState,
    loading,
    total_hors,
    worked,
    totalworkinday,
    shiftstart,
    shiftend,
    startDate,
    setStartDate,
    user,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    setStartWeekDay,
    endWeekDay,
    setEndWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    currentTab,
    setCurrentTab,
    onChangeDateRange,
    handleTabTypeChange,
    handleDecrease,
    handleIncrease,
    // attendanceForAllUsers,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    exportState,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    allempAlltendence,
    selectedProject,
    setSelectedProject,
    callAttendanceList
  };
};
