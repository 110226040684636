import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";


export const LEAVE_TRANSACTION_LIST = "LEAVE_TRANSACTION_LIST";
export const LEAVE_TRANSACTION_LIST_SORTED = "LEAVE_TRANSACTION_LIST_SORTED";
export const LEAVE_TRANSACTION_FILTER_LIST = "LEAVE_TRANSACTION_FILTER_LIST";
export const LEAVE_TRANSACTION_FILTER_SORTED_LIST = "LEAVE_TRANSACTION_FILTER_SORTED_LIST";
export const PUBLIC_HOLIDAY_LIST = "PUBLIC_HOLIDAY_LIST";
export const Leave_Allotment_List = "Leave_Allotment_List";
export const LEAVE_TYPE_LIST = "LEAVE_TYPE_LIST";
export const ALL_LEAVE_USER = "ALL_LEAVE_USER";
export const APPLIED_LEAVES ="APPLIED_LEAVES";
export const LEAVE_COUNT="LEAVE_COUNT";



export const getLeaveList = ({
           setInProgress,
            currentPage,
            itemsPerPage,
            setPageCounts,
            user,
            startMonth,
            setCurrentPage,
            setTotalpages,
            data,
            isEscalted,
            userInfo
}) => {
  console.log(startMonth, "beforecheck");

  console.log(user);
  const searchUserString = user
    ?.map((id) => `&user=${id}`)
    .toString()
    .replace(/,/g, "");

  const monthValue = startMonth?.getMonth() + 1;
  const yearValue = startMonth?.getFullYear();
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;
  console.log("datasee", data);

  const start_date = moment(data.start_date).format("YYYY-MM-DD");
  const end_date = moment(data.end_date).format("YYYY-MM-DD");

  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    const endpoint = isEscalted
      ? "/leave-transaction-history/escalated_leaves/"
      : "/leave-transaction-history/";
      const isHr = userInfo.groups.some((item) => item.name === "HR");
      const issuperuser= userInfo.is_superuser;
    const escalatedUserFilter = isEscalted && (!isHr&&!issuperuser) ? `&escalated_user_id=${userInfo.id}` : '';
    Axios_v2.get(
      `${endpoint}?items_per_page=${itemsPerPage}&rand=${rand}&ordering=${`leave_start_date`}&page=${1}${
        user?.length > 0 ? searchUserString : ""
      }&start_date=${start_date}&end_date=${end_date}${escalatedUserFilter}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          setCurrentPage(1);

          console.log("resres.data", res.data);
          // console.log("resres.data",res.data)

          dispatch({ type: LEAVE_TRANSACTION_LIST, payload: res.data.results });
          setPageCounts(res.data.total_pages);
          setInProgress(false);
        } else {
          setInProgress(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};
export const getSortedLeaveList = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  user,
  startMonth,
  setCurrentPage,
  data,
  isEscalted,
  userInfo
}) => {
  console.log(startMonth, "beforecheck");

  console.log(user);
  const searchUserString = user
    ?.map((id) => `&user=${id}`)
    .toString()
    .replace(/,/g, "");

  const monthValue = startMonth?.getMonth() + 1;
  const yearValue = startMonth?.getFullYear();
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;
  console.log("month", month);
  console.log("yearValue",yearValue);

  const start_date = moment(data.start_date).format("YYYY-MM-DD");
  const end_date = moment(data.end_date).format("YYYY-MM-DD");
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    const endpoint = isEscalted
    ? "/leave-transaction-history/escalated_leaves/"
    : "/leave-transaction-history/";
    const isHr = userInfo.groups.some((item) => item.name === "HR");
    const issuperuser= userInfo.is_superuser;
    const escalatedUserFilter = isEscalted && (!isHr&&!issuperuser)  ? `&escalated_user_id=${userInfo.id}` : '';
    Axios_v2.get(
      `${endpoint}?items_per_page=${itemsPerPage}&rand=${rand}&ordering=${`-leave_start_date`}&page=${1}${
        user?.length > 0 ? searchUserString : ""
      }&start_date=${start_date}&end_date=${end_date}${escalatedUserFilter}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          setCurrentPage(1);

          console.log("resres.data", res.data);
          // console.log("resres.data",res.data)

          dispatch({ type: LEAVE_TRANSACTION_LIST_SORTED, payload: res.data.results });
          setPageCounts(res.data.total_pages);
          setInProgress(false);
        } else {
          setInProgress(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};
export const getLeaveList_paginated = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  user,
  startMonth,
  data,
  setTotalpages,
  isEscalted,
  userInfo
}) => {
  const searchUserString = user
    ?.map((id) => `&user=${id}`)
    .toString()
    .replace(/,/g, "");

  const monthValue = startMonth?.getMonth() + 1;
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;

  const start_date = moment(data.start_date).format("YYYY-MM-DD");
  const end_date = moment(data.end_date).format("YYYY-MM-DD");

  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    // Determine the endpoint based on the isEscalted flag
    const endpoint = isEscalted ? "/leave-transaction-history/escalated_leaves/": "/leave-transaction-history/";
    const isHr = userInfo.groups.some((item) => item.name === "HR");
    const issuperuser= userInfo.is_superuser;
    const escalatedUserFilter = isEscalted && (!isHr&&!issuperuser) ? `&escalated_user_id=${userInfo.id}` : '';

    Axios_v2.get(
      `${endpoint}?items_per_page=${itemsPerPage}&rand=${rand}&ordering=${`leave_start_date`}&page=${currentPage}${
        user?.length > 0 ? searchUserString : ""
      }&start_date=${start_date}&end_date=${end_date}${escalatedUserFilter}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: LEAVE_TRANSACTION_LIST, payload: res.data.results });
          setPageCounts(res.data.total_pages);
          setInProgress(false);
          setTotalpages(res.total_pages);
        } else {
          setInProgress(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};

export const getSortedLeaveList_paginated = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  user,
  startMonth,
  data,
  setTotalpages,
  isEscalted,
  userInfo
}) => {
  console.log(startMonth, "beforecheck");

  console.log(user);
  const searchUserString = user
    ?.map((id) => `&user=${id}`)
    .toString()
    .replace(/,/g, "");

  const monthValue = startMonth?.getMonth() + 1;
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;
  
  const yearValue = startMonth?.getFullYear();
  const start_date = moment(data.start_date).format("YYYY-MM-DD");
  const end_date = moment(data.end_date).format("YYYY-MM-DD");

  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    const endpoint = isEscalted
    ? "/leave-transaction-history/escalated_leaves/"
    : "/leave-transaction-history/";
    const isHr = userInfo.groups.some((item) => item.name === "HR");
    const issuperuser= userInfo.is_superuser;
    const escalatedUserFilter = isEscalted && (!isHr&&!issuperuser)  ? `&escalated_user_id=${userInfo.id}` : '';
    Axios_v2.get(
      `${endpoint}?items_per_page=${itemsPerPage}&rand=${rand}&ordering=${`-leave_start_date`}&page=${currentPage}${
        user?.length > 0 ? searchUserString : ""
      }&start_date=${start_date}&end_date=${end_date}${escalatedUserFilter}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          console.log("resres.data", res.data);
          // console.log("resres.data",res.data)
          dispatch({ type: LEAVE_TRANSACTION_LIST_SORTED, payload: res.data.results });
          setPageCounts(res.data.total_pages);
          setInProgress(false);
          setTotalpages(res.total_pages);
        } else {
          setInProgress(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };

};

export const getFilterLeaveList = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setStatusPageCounts,
  userInfo,
  startMonth_status,
  setCurrentPage,
  isupdated,
  setIsupdated,
  isEscalted,
  data
}) => {
  const monthValue = startMonth_status?.getMonth() + 1;
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;
  console.log("month", month);
  // console.log("startWeekDay", startWeekDay);

  
    const start_date = moment(data.start_date).format("YYYY-MM-DD");
    const end_date = moment(data.end_date).format("YYYY-MM-DD");
    
  

  
  
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    const yearValue = startMonth_status?.getFullYear();
    const endpoint = isEscalted
    ? "/leave-transaction-history/escalated_leaves/"
    : "/leave-transaction-history/";
    const isHr = userInfo.groups.some((item) => item.name === "HR");
    const issuperuser= userInfo.is_superuser;
    const escalatedUserFilter = isEscalted && (!isHr&&!issuperuser) ? `&escalated_user_id=${userInfo.id}` : '';
    // Define the base URL and common query parameters
    const baseUrl = `${endpoint}?items_per_page=${itemsPerPage}&page=${1}&user=${
      userInfo.id}&ordering=leave_start_date&start_date=${start_date}&end_date=${end_date}${escalatedUserFilter}`;

    // Check if isupdated is true and add the rand parameter accordingly
    const urlWithRand = isupdated ? `${baseUrl}&rand=${rand}` : baseUrl;

    Axios_v2.get(urlWithRand, HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);

        console.log("res.data", res.data);
        dispatch({
          type: LEAVE_TRANSACTION_FILTER_LIST,
          payload: res.data.results,
        });
        setStatusPageCounts(res.data.total_pages);
        setInProgress(false);
        setCurrentPage(1);
        setIsupdated(false);
      })
      .catch((err) => {
        // toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
        setIsupdated(false);
      });
  };
};
export const getFilterLeaveList_Sorted = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setStatusPageCounts,
  userInfo,
  startMonth_status,
  setCurrentPage,
  isupdated,
  setIsupdated,
  data,
  isEscalted,
}) => {
  const monthValue = startMonth_status?.getMonth() + 1;
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;
  console.log("month", month);

    const start_date = moment(data.start_date).format("YYYY-MM-DD");
    const end_date = moment(data.end_date).format("YYYY-MM-DD");
   
  
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    const yearValue = startMonth_status?.getFullYear();
    const isHr = userInfo.groups.some((item) => item.name === "HR");
    const issuperuser= userInfo.is_superuser;
    const escalatedUserFilter = isEscalted && (!isHr&&!issuperuser) ? `&escaled_user=${userInfo.id}` : '';
    const endpoint = isEscalted
    ? "/leave-transaction-history/escalated_leaves/"
    : "/leave-transaction-history/";
    // Define the base URL and common query parameters

    const baseUrl = `${endpoint}?items_per_page=${itemsPerPage}&page=${1}&user=${
      userInfo.id
    }&ordering=-leave_start_date&start_date=${start_date}&end_date=${end_date}${escalatedUserFilter}`;

    // Check if isupdated is true and add the rand parameter accordingly
    const urlWithRand = isupdated ? `${baseUrl}&rand=${rand}` : baseUrl;

    Axios_v2.get(urlWithRand, HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);

        console.log("res.data", res.data);
        dispatch({
          type: LEAVE_TRANSACTION_FILTER_SORTED_LIST,
          payload: res.data.results,
        });
        setStatusPageCounts(res.data.total_pages);
        setInProgress(false);
        setCurrentPage(1);
        setIsupdated(false);
      })
      .catch((err) => {
        // toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
        setIsupdated(false);
      });
  };
};
export const getFilterLeaveList_paginated = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setStatusPageCounts,
  userInfo,
  startMonth_status,
}) => {
  const monthValue = startMonth_status?.getMonth() + 1;
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;
  console.log("month", month);

  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    const yearValue = startMonth_status?.getFullYear();


    Axios_v2.get(
      `/leave-transaction-history/?items_per_page=${itemsPerPage}&page=${currentPage}&user=${
        userInfo.id
      }&ordering=${`leave_start_date`}
      &rand=${rand}${monthParam}&year=${yearValue}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);

        console.log("res.data", res.data);
        dispatch({
          type: LEAVE_TRANSACTION_FILTER_LIST,
          payload: res.data.results,
        });
        setStatusPageCounts(res.data.total_pages);
        setInProgress(false);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};
export const getSortedLeaveListofUser_paginated =({
  setInProgress,
  currentPage,
  itemsPerPage,
  setStatusPageCounts,
  userInfo,
  startMonth_status,
  data
}) => {
  const monthValue = startMonth_status?.getMonth() + 1;
  const month = Number.isInteger(monthValue)
    ? monthValue.toString().padStart(2, "0")
    : undefined;
  console.log("month", month);
  const start_date = moment(data.start_date).format("YYYY-MM-DD");
    const end_date = moment(data.end_date).format("YYYY-MM-DD");
   
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    const monthParam = typeof month !== "undefined" ? `&month=${month}` : "";
    const yearValue = startMonth_status?.getFullYear();


    Axios_v2.get(
      // `/leave-transaction-history/?items_per_page=${itemsPerPage}&page=${currentPage}&user=${
      //   userInfo.id
      // }&ordering=${`-leave_start_date`}
      // &rand=${rand}$${start_date}&end_date=${end_date}`,
      `/leave-transaction-history/?items_per_page=${itemsPerPage}&page=${currentPage}&user=${
        userInfo.id
      }&ordering=${`-leave_start_date`}
      &rand=${rand}${monthParam}&year=${yearValue}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);

        console.log("res.data", res.data);
        dispatch({
          type: LEAVE_TRANSACTION_FILTER_SORTED_LIST,
          payload: res.data.results,
        });
        setStatusPageCounts(res.data.total_pages);
        setInProgress(false);
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};
export const getAllLeavesofUser = ( userInfo ) => {
  

  const rand = Math.random();
  return async (dispatch) => {
    const itemsPerPage = 1000;

    Axios_v2.get(`/leave-transaction-history/?items_per_page=${itemsPerPage}&user=${userInfo.id}&rand=${rand}`, HeaderToken())
      .then((res) => { 
        SiteUpdatehandler(res, dispatch);
        dispatch({ type: ALL_LEAVE_USER, payload: res.data.results });
      })
      .catch((err) => {
        toast.warn(err.message); 
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      });
  };
};

export const applyLeave = (data, cancelModal, callFilteredLeaveList,leaveUserList,callLeaveUserList,setHalfday_json, isSubmitting, setIsSubmitting,setiseditoradd) => {
  console.log("__data",data);
  const isValidLeaveType = data.leave_type !== "select reason";

  const isValidComment = data.comment !== "";
  const Start_Date=new Date(data.leave_start_date);
  const End_Date=new Date(data.leave_end_date);

  console.log("start_month_mm",Start_Date);
  console.log("end_month_mm",End_Date);
  if(Start_Date.getMonth()!==End_Date.getMonth()){
    toast.warn("Please apply leave within same month");
    return;
  }
  
 
  if (!isValidLeaveType && !isValidComment) {
    toast.warn("Please enter valid leave type and comment");
    return;
  } else if (!isValidLeaveType) {
    toast.warn("Please enter a valid leave type");
    return;
  } else if (!isValidComment) {
    toast.warn("Please add a comment");
    return;
  }

  const currentDate = moment();
  const DATE = currentDate.format("YYYY-MM-DD") + "T00:00:00Z";
  console.log("123", DATE);
  if (data.leave_start_date < DATE) {
    return () => {
      toast.warn("Can't apply previous days leave");
    };
  }

  if (leaveUserList.some((item) => {
    // Extracting date portion from leave_start_date and leave_end_date strings
    const leaveStartDate = new Date(item.leave_start_date.split('T')[0]);
    const leaveEndDate = new Date(item.leave_end_date.split('T')[0]);
    const dataStartDate = new Date(data.leave_start_date.split('T')[0]);
    
    // Check if dataStartDate falls within the range of leaveStartDate and leaveEndDate
    return dataStartDate >= leaveStartDate && dataStartDate <= leaveEndDate;
})) {
    toast.warn("Leave already applied for this date");
    return;
}

  
  return async (dispatch) => {
    try {

      setIsSubmitting(true)
      const res = await Axios_v2.post(
        "/leave-transaction-history/request_leave/",
        data,
        HeaderToken()
      );

      cancelModal();

      if (res.status === 201) {
        toast.success("Leave Applied");
      }

      console.log("Apply_Leave", res);
      setHalfday_json([]);
      callFilteredLeaveList();
      callLeaveUserList();

      setIsSubmitting(false);
      setiseditoradd(true);
    } catch (err) {
      console.error(err);

      if (err.response) {
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );
      }

      autoLogoutOnTokenExpire(err, dispatch);
      setIsSubmitting(false);
    }
  };
};

export const updayeLeave = (
  data,
  id,
  setEditLeaveModal,
  callLeaveList,
  setLeaveStatus,
  setManagerComment
) => {
  let Status = null;
  if (data.status == "Approved") {
    Status = "approve";
  } else if (data.status == "Rejected") {
    Status = "deny";
  }
  if (Status == null) {
    toast.warn("Please Select a valid status");
    return;
  }
  return async (dispatch) => {
    Axios_v2.post(
      `/leave-transaction-history/${id}/${Status !== null ? `${Status}/` : ""}`,
      data,
      HeaderToken()
    )
      .then((res) => {
        console.log("res", res);
        toast.success("Successfuly Updated");
        setLeaveStatus("Select Status");
        setManagerComment("");
        setEditLeaveModal(false);
        callLeaveList();
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};
export const UpdateUserLeave = (
  data,
  id,
  cancleModal,
  callFilteredLeaveList,
  callLeaveUserList,
  setiseditoradd
  
) => {
  return async (dispatch) => {
    const currentDate = moment();
    const DATE = currentDate.format("YYYY-MM-DD") + "T00:00:00Z";
    
      if (data.leave_start_date < DATE) {
        toast.warn("Can't  Edit previous days leave");
        return;
      }
  
    Axios_v2.put(
      `/leave-transaction-history/${id}/edit_leave_request/`,
      data,
      HeaderToken()
    )
      .then((res) => {
        console.log("res", res);
        callFilteredLeaveList();
        callLeaveUserList();
        setiseditoradd(true);
        toast.success("Successfully Updated");
        cancleModal();
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const getAllotmentList = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setLeavePageCounts,
}) => {
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    Axios_v2.get(
      `/employee-band-category/?items_per_page=${10}&page=${currentPage}&rand=${rand}&ordering=${`leave_start_date`}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: Leave_Allotment_List, payload: res.data.results });
          setInProgress(false);

          setLeavePageCounts(res.data.total_pages);
          console.log(res);
        } else {
          setInProgress(false);
        }
      })
      .catch((error) => {
        console.log("Error", error.message);
        toast.error(error.message);
        dispatch(autoLogoutOnTokenExpire(error, dispatch));
      });
  };
};

export const AddTypeofEmployee = (data, cancleModal, callAllotmentList) => {
  return async (dispatch) => {
    Axios_v2.post("/employee-band-category/", data, HeaderToken())
      .then((res) => {
        cancleModal();
        callAllotmentList();
        toast.success("Category Added");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const UpdateLeaveAllotment = (
  data,
  id,
  cancleModal,
  callAllotmentList
) => {
  return async (dispatch) => {
    Axios_v2.put(`/employee-band-category/${id}/`, data, HeaderToken())
      .then((res) => {
        console.log("res", res);
        callAllotmentList();
        toast.success("Successfully Updated");
        cancleModal();
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const deleteLeaveAllotment = (ID, callAllotmentList) => {
  return async (dispatch) => {
    Axios_v2.delete(`/employee-band-category/${ID}/`, HeaderToken())
      .then((res) => {
        callAllotmentList();
        toast.success("Successfully Deleted ");
      })
      .catch((err) => {
        console.log(err);
        if (err.status <= 500) {
          Object.keys(err.response.data).forEach((msg) =>
            toast.warn(`${msg} ${err.response.data[msg][0]}`)
          );
        } else {
          toast.warn(`Error in delete of document`);
        }
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const getPublicHoliday = ({
  setInProgress,
  currentPage,
  itemsPerPage,
  setPageCounts,
  selectedYear,
}) => {
  return async (dispatch) => {
    setInProgress(true);
    const rand = Math.random();
    Axios_v2.get(
      `/public-holiday/?items_per_page=${10}&page=${currentPage}&year=${selectedYear.format(
        "YYYY"
      )}&ordering=${`date`}&rand=${rand}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          console.log("getPublicHoliday", res.data);
          dispatch({ type: PUBLIC_HOLIDAY_LIST, payload: res.data.results });
          setPageCounts(res.data.total_pages);
          setInProgress(false);
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        dispatch(autoLogoutOnTokenExpire(err, dispatch));
      })
      .finally(() => {
        // Set isLoading to false after the request is completed
        setInProgress(false);
      });
  };
};

export const addPublicHoliday = (data, cancleModal, callPublicLeaveList) => {
  return async (dispatch) => {
    Axios_v2.post("/public-holiday/", data, HeaderToken())
      .then((res) => {
        cancleModal();
        callPublicLeaveList();
        toast.success("Public holiday has been added");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const deleteHoliday = (ID, callPublicLeaveList) => {
  return async (dispatch) => {
    Axios_v2.delete(`/public-holiday/${ID}/`, HeaderToken())
      .then((res) => {
        callPublicLeaveList();
        toast.success("Successfully Deleted");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};
export const deleteLeave_Status = (ID, callFilteredLeaveList,callLeaveUserList) => {
  return async (dispatch) => {
    Axios_v2.delete(`/leave-transaction-history/${ID}/`, HeaderToken())
      .then((res) => {
        callFilteredLeaveList();
        callLeaveUserList();
        toast.success("Successfully Deleted");
      })
      .catch((err) => {
        console.log("error", err);
        if (err.response.status >= 500) {
          toast.warn("Not Deleted");
        } else {
          Object.keys(err.response.data).forEach((msg) =>
            toast.warn(`${msg} ${err.response.data[msg][0]}`)
          );
        }
      });
      
  };
};

export const updateHoliday = (
  editId,
  data,
  cancleModal,
  callPublicLeaveList
) => {
  return async (dispatch) => {
    Axios_v2.put(`/public-holiday/${editId}/`, data, HeaderToken())
      .then((res) => {
        cancleModal();
        callPublicLeaveList();
        toast.success("Successfully Updated");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );
      });
  };
};

export const getLeaveType = () => {
  return async (dispatch) => {
    const rand = Math.random();
    Axios_v2.get(
      `/leave-types/?items_per_page=20&page=1&rand=${rand}&ordering=${`reason`}`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          console.log("getLeaveType", res.data);
          dispatch({ type: LEAVE_TYPE_LIST, payload: res.data.results });
        } else {
          console.log("somthings wrong");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const addLeaveType = (data, callLeaveTypeList) => {
  return async (dispatch) => {
    Axios_v2.post("/leave-types/", data, HeaderToken())
      .then((res) => {
        callLeaveTypeList();
        toast.success("Successfuly added");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const deleteLeaveType = (id, callLeaveTypeList) => {
  return async (dispatch) => {
    Axios_v2.delete(`/leave-types/${id}/`, HeaderToken())
      .then((res) => {
        callLeaveTypeList();
        toast.success("Successfully Deleted");
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
};

export const updateLeaveType = (id, data, callLeaveTypeList, setEditTask,cancleModal) => {
  return async (dispatch) => {
    setEditTask();

    Axios_v2.put(`/leave-types/${id}/`, data, HeaderToken())
      .then((res) => {
        callLeaveTypeList();
        toast.success("Successfully Updated");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );
      });
  };
};
export const ExportLeave = (setExportState, data , selectedUsers = []) => {
  
  return (dispatch) => {
    setExportState(true);

    const apiEndpoint =
      selectedUsers.length > 0
        ? `/leave-transaction-history/export_leave/?user=${selectedUsers.join(
            "&user="
          )}&start_date=${data.start_date}&end_date=${data.end_date}`
        : `/leave-transaction-history/export_leave/?start_date=${data.start_date}&end_date=${data.end_date}`;

    Axios_v2.get(apiEndpoint, HeaderToken())
      .then((res) => {
        if (res.data.status === "Completed") {
          window.location.href = res.data.file;
        }
        setExportState(false);
      })
      .catch((err) => {
        setExportState(false);
        toast.error("Export failed");
      });
  };
};
export const ExportHolidayLeave = (setExportState, selectedYear) => {
  console.log("seletedyear",selectedYear);
  return (dispatch) => {
    setExportState(true);

    const year = selectedYear.year();
    Axios_v2.get(`public-holiday/export_public_holiday/?year=${year}&ordering=date`, HeaderToken())
      .then((res) => {
        if (res.status === 200) {
         console.log("res_public",res);
         window.location.href = res.data.file;
        }
        setExportState(false);
      })
      .catch((err) => {
        setExportState(false);
      });
  };
};
// Action function
export const sendemailofleave = (payload,setLoading,onClose) => {
  setLoading(true);
  return () => {
    Axios_v2.post(`leave-ledger/send_monthly_report/`, payload, HeaderToken())
    .then((res) => {
      if (res.status === 200) {
  setLoading(false);

        toast.success("Mail sent");
        onClose();
      }
    })
    .catch((err) => {
  setLoading(false);
      
      console.error('Error sending email of leave:', err);
    });
  };
};

export const getSortedLeaveList_approved = (
  user,currentYEAR,currentMonth
) => {

  console.log("USER_ID",user);
  const newMonth = new Date();
  const monthNumber = newMonth.getMonth() + 1;  
  const start_date = `${currentYEAR}-01-01`;
  const end_date = `${currentYEAR}-12-31`;
  return async (dispatch) => {
    const rand = Math.random();
    Axios_v2.get(
      `/leave-transaction-history/?items_per_page=${1000}&rand=${rand}&ordering=${`leave_start_date`}&page=${1}
      &start_date=${start_date}&end_date=${end_date}&user=${user.id}`,
      HeaderToken()
    )
    .then((res) => {
      SiteUpdatehandler(res, dispatch);
      if (res.status === 200) {

        console.log("Leave_count_Raw", res.data.results);
        function calculatePaidLeavePerMonth(apiResponse, monthNumber) {
          const result = {};
          
          // Initialize result object with all months set to 0
          const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
          for (let i = 0; i < monthNumber-1; i++) {
            result[monthNames[i]] = 0;
          }
        
          // Process the API response to calculate paid leave per month
          apiResponse.forEach(leave => {
            const leaveDate = new Date(leave.leave_start_date);
            const leaveMonth = leaveDate.getMonth() + 1; // getMonth() returns month index from 0 to 11, so adding 1 to make it 1 to 12
        
            if (leaveMonth < monthNumber && leave.status === "Approved") {
              const leaveCount = parseFloat(leave.leave_count) || 0;
              const unpaidLeaveCount = parseFloat(leave.unpaid_leave_count) || 0;
              const paidLeaveApproved = Math.max(0, leaveCount - unpaidLeaveCount);
        
              const monthName = leaveDate.toLocaleString('default', { month: 'long' });
        
              result[monthName] += paidLeaveApproved;
            }
          });
        
          // Convert result object to an array of { month, paidLeaveApproved }
          return Object.entries(result).map(([month, paidLeaveApproved]) => ({ month, paidLeaveApproved }));
        }
        
        // Example usage:
        const apiResponse = [
          // Example leave data
          { leave_start_date: "2024-01-15", status: "Approved", leave_count: "2", unpaid_leave_count: "0" },
          { leave_start_date: "2024-02-20", status: "Approved", leave_count: "1", unpaid_leave_count: "0" },
          { leave_start_date: "2024-03-10", status: "Approved", leave_count: "3", unpaid_leave_count: "1" },
          // Add more leave data as needed
        ];
        
        
        
        
        const paidLeavePerMonth = calculatePaidLeavePerMonth(res.data.results,monthNumber);
        console.log(paidLeavePerMonth,"paid_leaves_per_month");
        // Initialize variables
        const leaveLimitPerMonth = 2; // Assuming the leave limit is 2 per month      
        let leaveBalance = 0;
 
// Array of objects containing leave data for each month

// Iterate through each month
for (let i = 0; i < paidLeavePerMonth.length; i++) {
    // Assuming leaveLimitPerMonth, leaveBalance, and leaveData.paid_leave_approved are variables
    console.log(i,"i123")
leaveBalance = Math.max(leaveLimitPerMonth + leaveBalance - paidLeavePerMonth[i].paidLeaveApproved, 0);
 
  }
 
// Determine leave balance for June
const leaveBalance_rem = Math.max(leaveBalance,0);
console.log(`Leave_over_flow: ${leaveBalance}`);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

// Your existing code to calculate leave balance...

// Conditionally dispatch LEAVE_COUNT action
const payload = currentYEAR == currentYear ? leaveBalance_rem : 0;
dispatch({ type: LEAVE_COUNT, payload });
        
      }
        
      dispatch({ type: APPLIED_LEAVES, payload: res.data.results });
      // dispatch({ type: LEAVE_COUNT, payload: res.data.results });


     
      
  
    })
    .catch((err) => {
      toast.error("holla");
      dispatch(autoLogoutOnTokenExpire(err, dispatch));
    });
};
};

