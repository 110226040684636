import {
  WORK_SCHEDULE_LIST,
  ADD_NEW_WORKSCHEDULE,
  REMOVE_WORK_SCHEDULE,
  SAVE_WORK_SCHEDULE
} from "../Actions/workScheduleActions";

const initialState = {
  workSchedules: [],
  save:[]
};

export default function workScheduleReduser(store = initialState, action) {
  switch (action.type) {
    case WORK_SCHEDULE_LIST: {
      return {
        ...store,
        workSchedules: action.payload,
      };
    }
    case SAVE_WORK_SCHEDULE: {
      return {
        ...store,
        save: action.payload,
      };
    }
    
    case ADD_NEW_WORKSCHEDULE: {
      return {
        ...store,
        workSchedules: [action.payload, ...store.workSchedules],
      };
    }
    case REMOVE_WORK_SCHEDULE: {
      return {
        ...store,
        workSchedules: store.workSchedules.filter(
          (WS) => WS.id !== action.payload.id
        ),
      };
    }
    default: {
      return { ...store };
    }
  }
}
