import React from "react";

export default function SearchInput(props) {
  return (
    <div className="search-container">
      <img src="/images/icons/search.png" alt="search" />
      <input type="text"  placeholder="Search..." {...props} style={{width:"170px"}} />
    </div>
  );
}
