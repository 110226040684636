import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { getAllGroups} from "../redux/Actions/authAction";
import {
  getAllEmployes,
  getTotalEmployeeCount,
  deleteEmployee,
  getEmployeeDetails,
  addNewEmployee,
  updateEmployee,
  resendInvite,
  cancelInvite,
  getEmployeeList,
} from "../redux/Actions/employeesAction";
import { emailValidator } from "../Utils/fieldValidator";

export default function useEmployeeAndSettings(
  employeeID,
  setModalOpen,
  setEmployeeID
) {
  const dispatch = useDispatch();

  //for employee table
  const [tableType, setTableType] = useState("active"); // "active" or "archived"
  const [searchValue, setSearchValue] = useState("");
  const { allEmployees, employeeDetails, employeesList } = useSelector((store) => store.ER);
  const [inProgress, setInProgress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [pageCounts, setPageCounts] = useState(1);
  const [totalpages, setTotalPages] = useState(1);
  const [totalValues, setTotalValues] = useState(0);
  const { leaveTypeList } = useSelector((store) => store.leave);


  const [TotalCount_Active, setTotalCount_Active] = useState(0);
  const [TotalCount_Archived, setTotalCount_Archived] = useState(0);
  const [TotalCount_Pending, setTotalCount_Pending] = useState(0);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedGroups,  setSelectedGroups] = useState([]);
  const { userInfo } = useSelector((store) => store.auth);
  let isActive = tableType === "active";

  console.log(leaveTypeList,"leaveTypeList");

  console.log("allEmployees",allEmployees)
  // modal state
  const [employeeValues, setEmployeeValues] = useState({
    name: "",
    email: "",
    group: [],
    manager_id: [],
    webcam: false,
    screenshots:null,
  });

  const [employeeErrors, setEmployeeErrors] = useState({});

  const handleSubmit = () => {
    const error = {};

    if (employeeValues.name.length < 1) {
      error["name"] = "Name Required";
    }

    if (!emailValidator(employeeValues.email)) {
      error["email"] = "Not a Valid email";
    }
    
    if (employeeValues.group.length==0) {
      error["group"] = "Please Select One ";
    }
    if (employeeValues.screenshots<1||employeeValues.screenshots>60) {
      error["screenshots"] = "Please Enter value between 1 to 60 ";
    }

    if (!error.email && !error.name && !error.group && !error.screenshots) {
      const req = {
        name: employeeValues.name.trim(),
        email: employeeValues.email.trim(),
        screenshots:employeeValues.screenshots,
        groups_ids: employeeValues.group.map(str => parseInt(str)),
  
        manager_id: employeeValues.manager_id,
        enable_webcam_for_user: employeeValues.webcam,
      };

      console.log("Request", req);
      if (employeeID) {
        dispatch(
          updateEmployee(req, employeeID, cancleEmployeeAdd, callEmployeeList)
        );
      } else {
        dispatch(addNewEmployee(req, cancleEmployeeAdd, callEmployeeList));
      }
      setEmployeeErrors({});
    } else {
      setEmployeeErrors(error);
    }
  };

  const handleChange = (e) => {
    
    const { name, value } = e.target;
    setEmployeeValues((prevValue) => ({ ...prevValue, [name]:value }));
  };

  // get employee details form api
  useEffect(() => {
    if (employeeID !== null || employeeID !== undefined) {
      dispatch(getEmployeeDetails(employeeID));
    }
  }, [dispatch, employeeID]);

  useEffect(() => {
    console.log("Employee Details", employeeDetails);
    if (employeeDetails.id) {
      setEmployeeValues({
        name: employeeDetails?.name,
        email: employeeDetails?.email,
        group: employeeDetails?.groups_ids,
        webcam: employeeDetails?.enable_webcam_for_user,
        manager_id: employeeDetails?.manager_id,
        screenshots:employeeDetails?.screenshots,
        monthly_allotted_leaves:employeeDetails?.employee_band_deatils?.monthly_allotted_leaves,
        category_name:employeeDetails?.employee_band_deatils?.category_name,
      });
      setSelectedOptions(employeeDetails.managers);
      setSelectedGroups(employeeDetails.groups);
      
    }
    
  }, [employeeDetails]);

  useEffect(() => {
    dispatch(getEmployeeList());
    dispatch(getAllGroups());
  }, [dispatch]);
 
  const cancleEmployeeAdd = () => {
    setEmployeeValues({
      name: "",
      email: "",
      group: [],
      manager_id: [],
    });
    setEmployeeErrors({});
    setModalOpen(false);
    setEmployeeID(null);
    setSelectedOptions([]);
    setSelectedGroups([]);
    
  };
  const handleGroup = (option) => {
    if (employeeValues.group.includes(option.id)) {
      setEmployeeValues((prev) => ({
        ...prev,
        group: prev.group.filter(
          (group) => group !== option.id
        ),
      }));
    } else {
      setEmployeeValues((prev) => ({
        ...prev,
        group: [...prev.group, option.id],
      }));
    }
  };

  const handleManagerIds = (option) => {
    if (employeeValues.manager_id.includes(option.id)) {
      setEmployeeValues((prev) => ({
        ...prev,
        manager_id: prev.manager_id.filter(
          (managerId) => managerId !== option.id
        ),
      }));
    } else {
      setEmployeeValues((prev) => ({
        ...prev,
        manager_id: [...prev.manager_id, option.id],
      }));
    }
  };

  const TabChange = (data) => {
    console.log("data",data)
    setCurrentPage(1);
    // setTableType((prevValue) =>
    //   prevValue === "active" ? "archived" : "active"
    // );
    setTableType(data)
  };

  const getTotalProgectTypeCount = useCallback(() => {
    dispatch(getTotalEmployeeCount(setTotalCount_Active, "active"));
    dispatch(getTotalEmployeeCount(setTotalCount_Pending, "pending"));
    dispatch(getTotalEmployeeCount(setTotalCount_Archived, "archived"));
  }, [dispatch]);

  const callEmployeeList = useCallback(() => {
    return dispatch(
      getAllEmployes(
       { 
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
        setTotalValues,
        isActive,
        tableType,
        setTotalPages
      }
      )
    );
  }, [dispatch, currentPage, itemsPerPage, isActive, tableType]);

  useEffect(() => {
    callEmployeeList();
    getTotalProgectTypeCount();
  }, [callEmployeeList, getTotalProgectTypeCount]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {

      if (searchValue.length > 0) {
        dispatch(
          getAllEmployes(
           { 
            setInProgress,
            currentPage,
            itemsPerPage,
            setPageCounts,
            setTotalValues,
            isActive,
            searchValue,
            tableType,
            setTotalPages
          }
          )
        );
      } else {
        dispatch(
          getAllEmployes(
           { 
            setInProgress,
            currentPage,
            itemsPerPage,
            setPageCounts,
            setTotalValues,
            isActive,
            tableType,
            setTotalPages
          }
          )
        );
      }

    }, 3000);
  
    return () => clearTimeout(timeoutId);
  
  }, [dispatch, searchValue, currentPage, isActive]);

  useEffect(()=>{
    setInProgress(true);
  },[searchValue]);


  const refreshTable = () => {
    getTotalProgectTypeCount();
    if (
      currentPage === pageCounts &&
      allEmployees.length === 1 &&
      currentPage !== 1
    ) {
      setCurrentPage((prevValue) => (prevValue === 1 ? 1 : prevValue - 1));
    } else {
      return dispatch(
        getAllEmployes(
         { 
          setInProgress,
          currentPage,
          itemsPerPage,
          setPageCounts,
          setTotalValues,
          isActive,
          tableType
        }
        )
      );
    }
  };

  const RemoveUser = (ID) => {
    dispatch(deleteEmployee(ID, refreshTable));
  };

  const ResendInvite = (ID) => {
    dispatch(resendInvite(ID));
  };
  const CancelInvite = (ID) => {
    dispatch(cancelInvite(ID), refreshTable);
  };

  return {
    searchValue,
    setSearchValue,
    allEmployees,
    TabChange,
    inProgress,
    currentPage,
    setCurrentPage,
    pageCounts,
    totalValues,
    tableType,
    setTableType,
    TotalCount_Archived,
    TotalCount_Active,
    RemoveUser,
    employeeDetails,
    handleChange,
    handleSubmit,
    employeeValues,
    cancleEmployeeAdd,
    employeeErrors,
    ResendInvite,
    CancelInvite,
    handleManagerIds,
    employeesList,
    selectedOptions,
    setSelectedOptions, 
    TotalCount_Pending,
    selectedGroups, 
    setSelectedGroups,
    handleGroup,
    userInfo,
    totalpages
  };
}
