import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddButton from "../AddButton";
import AddUser from "../common/AddUser";
import ScreenShortCard from "../ScreenShotCard";
import ScreenShortModal from "../common/ScreenShotModal";
import useEmployeeAndSettings from "../../hooks/useEmployeeAndSettings";
import useLatestScreenShorts from "../../hooks/useLatestScreenShorts";
import theme from "../../theme/theme";
import { Link } from "react-router-dom";

export default function LatestScreenShorts() {
  const { userPermissions, userInfo } = useSelector((store) => store.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);
  const {
    handleChange,
    handleSubmit,
    employeeValues,
    cancleEmployeeAdd,
    employeeErrors,
    allEmployees,
    selectedGroups,
    setSelectedGroups,
    handleGroup,
    employeesList,
    handleManagerIds,
    setSelectedOptions,
    selectedOptions,
  } = useEmployeeAndSettings(employeeID, setModalOpen, setEmployeeID);

  const {
    openSSModal,
    setOpenSSModal,
    openedSS,
    LatestScreenshots,
    inProgress,
    currentPage,
    setCurrentPage,
    totalValues,
    openModalAndSetSS,
    hendleNextImg,
    handlePreviousImg,
    pages,
    renderPageButtons,
    pageCounts,
  } = useLatestScreenShorts();

  return (
    <>
      <div className="dashboard-screenshort-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="screenshots-section-left">
            <p
             className="gustavo"
              style={{
                fontSize: theme.fontSizes.small,
                alignSelf: "center",
                fontWeight:'bold'
              }}
            >
              Latest Screenshots
            </p>
          </div>
          <div className="screenshots-section-right">
            <Link to="/WorkDiary">
              <span
               className="gustavo"
                style={{
                  color: theme.colors.primary,
                  fontSize: theme.fontSizes.xxSmall,
                }}
              >
                View All
              </span>
            </Link>
          </div>
        </div>
        {inProgress && <h1>Loading Screen Shorts</h1>}

        {!inProgress && (
          <>
            <div className="screenshort-table-body">
              {LatestScreenshots.length > 0 ? (
                LatestScreenshots.map((screenshort) => (
                  <ScreenShortCard
                    key={screenshort.id}
                    data={screenshort}
                    openScreenShort={openModalAndSetSS}
                    showDelecteIcon={false}
                  />
                ))
              ) : (
                <h4  style={{
                  color: theme.colors.primary,
                  fontSize: theme.fontSizes.small,
                }}>No Screenshots to show</h4>
              )}
            </div>

            {/* <div className="screenshort-table-footer">
              <p>Showing 4 of {totalValues}</p>

              <div className="table-pagination">
                <button
                  className="prev-scr"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  {" PREV"}
                </button>
                {renderPageButtons}
                <button
                  className="next-scr"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  {"NEXT "}
                </button>
              </div>
            </div> */}
          </>
        )}
      </div>
      <AddUser
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hookProps={{
          handleChange,
          handleSubmit,
          employeeValues,
          cancleEmployeeAdd,
          employeeErrors,
          selectedGroups,
          setSelectedGroups,
          handleGroup,
          employeesList,
          handleManagerIds,
          setSelectedOptions,
          selectedOptions,
        }}
      />

      <ScreenShortModal
        open={openSSModal}
        onClose={() => setOpenSSModal(false)}
        setOpenModal={setModalOpen}
        hendleNextImg={hendleNextImg}
        SS={openedSS}
        screenshots={LatestScreenshots}
        handlePreviousImg={handlePreviousImg}
        currentPage={currentPage}
        pageCounts={pageCounts}
      />
    </>
  );
}
