import React, { useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Alert from "./common/Alert";
import { deleteScreenShot } from "../redux/Actions/screenShortsAction";
import theme from "../theme/theme";

const ScreenShotCard = ({ data, openScreenShort, showDelecteIcon }) => {
  const { userPermissions, userSubordinates, userInfo } = useSelector(
    (store) => store.auth
  );

  const dispatch = useDispatch();
  const [ids, setID] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const handleDelete = async (id) => {
    dispatch(deleteScreenShot(id));
    setIsOpen(false);
  };
  const delAlert = (id) => {
    setIsOpen(true);
    setID(id);
  };
  return (
    <>
      <Alert
        message="delete this ScreenShot"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        setOpenModal={setIsOpen}
        handleDelete={(id) => handleDelete(id)}
        id={ids}
      />
      <div key={data.id} className="screenshort-card">
        <img
          onClick={() => {
            openScreenShort(data);
          }}
          src={
            data.userScreenshot ? data.userScreenshot : "/images/ss-demo.png"
          }
          alt="ss"
        />
        <div style={{ display: "flex" }}>
          <div className="screenshort-card-info">
            <div className="card-info-text">
              <p className="card-info-name">{data.user.name}</p>
              <p className="card-info-time" style={{color: theme.colors.primary}}>
                {moment(data.datetime_of_capture).format("DD-MM-YYYY")}
              </p>
            </div>
            <div className="card-info-text">
              <p>{data.task_name}</p>
              <p className="card-info-time" style={{color: theme.colors.primary}}>
                {moment(data.datetime_of_capture).format("hh:mm A")}
              </p>
            </div>
            <progress className="custom-progress-bar"  style={{color: theme.colors.primary}} value={20} max={100} />
          </div>

          {(() => {
            const canDelete_All = userPermissions.includes(
              "Delete all users' screenshots/webcam"
            );
            const canDelete_own = userPermissions.includes(
              "Can delete own users' screenshots/webcam"
            );

            const isUserSubordinate =
              userSubordinates?.some(
                (subordinate) => subordinate.id === data.user.id
              ) || data.user.id == userInfo.id;

            return canDelete_All || (canDelete_own && isUserSubordinate);
          })() && (
            <span className="delete-screen" onClick={() => delAlert(data.id)}>
              <img
                src="/images/icons/delete-icon.svg"
                style={{ height: "20px", width: "20px" }}
                alt=""
              />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default ScreenShotCard;
