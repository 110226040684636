import React from "react";

export default function MacSteps() {
  return (
    <>
      <p style={{fontWeight:"bold", fontSize: "28px"}}><span className="download-underline"> For Mac OS </span></p>
      <ol className="custom-ol">
        <li>Download the dmg file from the above link.</li>
        <li>After Download Drag the app into the application.</li>
        <li>
          {" "}
          Then the icon will appear on the application. Click on it to open the
          application.
        </li>
      </ol>
    </>
  );
}
