import React from "react";
import "./styles.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/common/Header";

export default function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userPermissions } = useSelector((store) => store.auth);
  const [activeTab, setActiveTab] = useState("Role");
  const resourcePath = [
    {
      title: "Role",
      link: "/Settings",
    },
    {
      title: "Leave",
      link: "/Settings/leave",
    },
    {
      title: "User Limit",
      link: "/Settings/limit",
    }
  ];
  const handleClick = (path) => {
    setActiveTab(path.title); // Update active tab
    navigate(path.link);
  };
  return (
    <div>
      <div className="dashboard-header">
        <Header title="Settings" style={{ fontWeight: "bold" }} />
      </div>
      <div className="wer">
        <div className="settings-tab-container">
          {resourcePath.map((path) => (
            <div
              className={`tab-button ${
                (pathname === "/Settings" && path.title === "Role") ||
                (pathname.startsWith("/Settings/leave") && path.title === "Leave")||
                (pathname.startsWith("/Settings/limit") && path.title === "User Limit")

                  ? "active-tab"
                  : ""
              }`}
              key={path.title}
              onClick={() => navigate(path.link)}
            >
              <p>{path.title}</p>
            </div>
          ))}
        </div>

        <div className="settings-context-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
