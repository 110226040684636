import { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllSecrrenShots } from "../redux/Actions/screenShortsAction";
import { getWorkedTimeDetails } from "../redux/Actions/reportAction";
import moment from "moment";
 
export default function useScreenshots() {
  const dispatch = useDispatch();
  const { AllScreenshots } = useSelector((store) => store.SS);
  const { workedTime } = useSelector((store) => store.ReportR);
  const { userInfo } = useSelector((store) => store.auth);
  const [users, setUsers] = useState([userInfo.id]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
 
  const [inProgress, setInProgress] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(1);
  const [totalValues, setTotalValues] = useState(1);
 
  const [openSSModal, setOpenSSModal] = useState(false);
  const [openedSS, setOpenedSS] = useState("");
  const [showWebcam, setShowWebcam] = useState(false);
  const[showScreenshot,setShowSceenShot]=useState(false);
  const [showallscreenshot,setallscreenshot]=useState(false);
  const { userPermissions } = useSelector((store) => store.auth);
  const[delay,setdelay]=useState(1);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);

  function useOutsideAlerter() {
    function handleClickOutside() {
      if (calendarIsOpen) {
        setCalendarIsOpen(!calendarIsOpen);
      }
    }

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter();
 
 
  useEffect(() => {
    setInProgress(true);
 
    const timeoutId = setTimeout(() => {
      setdelay(prevDelay => prevDelay + 1);
      console.log("DELAY_COUNTER", delay);
    }, 3000);
    return () => clearTimeout(timeoutId);
 
  }, [users]);

  useEffect(() => {

    const timeoutId = setTimeout(() => {

    const canScreenShots =
      userPermissions.includes("View all users' screenshots/webcam") ||
      userPermissions.includes("View own users' screenshots/webcam");
   
      if (canScreenShots) {
        dispatch(
          getAllSecrrenShots({
            date: moment(startDate).format("YYYY-MM-DD"),
            setInProgress,
            currentPage,
            itemsPerPage,
            setPageCounts,
            setItemsPerPage,
            setTotalValues,
            users,
            selectedProject,
            webcam:showWebcam,
            screenshot:showScreenshot,
            all:showallscreenshot,

          })
        );
      }

    }, 3000);

    return () => clearTimeout(timeoutId);
 
  }, [
    dispatch,
    currentPage,
    itemsPerPage,
    totalValues,
    startDate,
    delay,
    selectedProject,
    showScreenshot,
    showWebcam,
    showallscreenshot,
 
  ]);

  useEffect(()=>{
    setInProgress(true);
  },[startDate])
 
  useEffect(() => {
    let time = `${moment().format("YYYY-MM-DDT")}00:00:00${moment().format(
      "Z"
    )}`;
    dispatch(getWorkedTimeDetails(time));
  }, [dispatch]);
 
  const openModalAndSetSS = (SS) => {
    setOpenedSS(SS);
    setOpenSSModal(true);
    return;
  };
 
  const hendleNextImg = () => {
    const indexOf = AllScreenshots.findIndex((item) => item.id === openedSS.id);
    if (indexOf !== -1 && indexOf !== AllScreenshots.length - 1) {
      setOpenedSS(AllScreenshots[indexOf + 1]);
    }
  };
 
  const handlePreviousImg = () => {
    const indexOf = AllScreenshots.findIndex((item) => item.id === openedSS.id);
    console.log("ioff", indexOf);
    if (indexOf !== -1 && indexOf !== 0) {
      setOpenedSS(AllScreenshots[indexOf - 1]);
    }
  };
 
  const cancleAddManualTime = () => {
    setModalOpen(false);
  };
  const handleDecrease = () => {
    const newDate = moment(startDate).subtract(1, 'days').toDate();
    setStartDate(newDate);
  };
 
  const handleIncrease = () => {
    const newDate = moment(startDate).add(1, 'days').toDate();
    setStartDate(newDate);
  };
  return {
    AllScreenshots,
    users,
    setUsers,
    selectedProject,
    setSelectedProject,
    modalOpen,
    setModalOpen,
    startDate,
    setStartDate,
    inProgress,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    pageCounts,
    setPageCounts,
    totalValues,
    setTotalValues,
    openSSModal,
    setOpenSSModal,
    openedSS,
    setOpenedSS,
    openModalAndSetSS,
    hendleNextImg,
    handlePreviousImg,
    cancleAddManualTime,
    workedTime,
    showWebcam,
    showScreenshot,
    setShowWebcam,
    setShowSceenShot,
    showallscreenshot,
    setallscreenshot,
    handleDecrease,
    handleIncrease,
    setCalendarIsOpen,
    calendarIsOpen,
  };
}
