import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./style1.css";
import { useNavigate } from "react-router-dom";
export default function DownloadApplication1() {
  const location = useLocation();
  const { data } = location.state;
  const dispatch = useDispatch();
  const { DownloadApplication } = useSelector((store) => store.downloadapp);
  console.log("dataarray", data);
const navigate=useNavigate();

  return (
    <div className="parent-box1" style={{ border: '0px solid black', marginTop: '3%', marginLeft: '3%', marginRight: '3%' }}>
      <div className="download-heading">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h3>Previous Tracker Version</h3>
            </div>
          </div>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ textAlign: "left", paddingLeft: "15px", border: '0px solid blue', width: '5%' }}>
              Version
            </th>
            <th scope="col" style={{ textAlign: "left", border: '0px solid blue', width: '10%' }}>
              Status
            </th>
            <th scope="col" style={{ textAlign: "left", border: '0px solid blue', width: '10%' }}>
              Release Date
            </th>
            <th scope="col" style={{ textAlign: "left", border: '0px solid blue', width: '10%' }}>
              Architecture
            </th>
            <th scope="col" style={{ textAlign: "left", border: '0px solid blue', width: '10%' }}>Download link</th>
          </tr>
        </thead>
        <tbody>
          {data.map((data) => (
            <tr className="table-Rows" key={data.sNo}>
              <td>
                <div className="emp-name" style={{ border: '0px solid blue', marginLeft: '8px' }}>
                  {data.version}
                </div>
              </td>
              <td>
                <div
                  className="emp-name"
                  style={{
                    marginLeft: '0px',
                    border: '0px solid blue',
                    color: data.is_active ? 'green' : 'red'
                  }}
                >
                  {data.is_active ? 'active' : 'not active'}
                </div>
              </td>
              <td>
                <div className="emp-document" style={{ marginTop: '8px', marginLeft: '8px', border: '0px solid blue', textAlign: "left" }}>{data.release_date}</div>
              </td>
              <td>
                <div className="emp-document" style={{ marginTop: '8px', marginLeft: '8px', border: '0px solid blue', textAlign: "left" }}> {`${data.operatingSystem} ${data.architecture}`}</div>
              </td>
              {data.is_active ? <td className="download-link" style={{ border: '0px solid blue' }}>
                <a href={data.file} className="btn btn-success" target="_blank" rel="noopener noreferrer">
                  Download
                </a>
              </td> : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
