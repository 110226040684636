import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";

export const WORK_SCHEDULE_LIST = "WORK_SCHEDULE_LIST";
export const ADD_NEW_WORKSCHEDULE = "ADD_NEW_WORKSCHEDULE";
export const SAVE_WORKSCHEDULE = "SAVE_WORKSCHEDULE";
export const REMOVE_WORK_SCHEDULE = "REMOVE_WORK_SCHEDULE";
export const SAVE_WORK_SCHEDULE = "SAVE_WORK_SCHEDULE";

export const getWorkScheduleList = (
  setInProgress,
  pageNo,
  item_per_page,
  setPaageCounts,
  setTotalValues,
  users,
  createSchedule
) => {
  return async (dispatch) => {
    setInProgress && setInProgress(true);
    const isNotNullArray = users && Array.isArray(users) && users.every((user) => user !== null);

    const searchUserString = isNotNullArray
      ? users.map((id) => `&user=${id}`).toString().replace(/,/g, "")
      : "";
    
    await Axios_v2.get(
      `/workschedules/?page=${pageNo}&items_per_page=${item_per_page}${
        isNotNullArray ? searchUserString : ""
      }`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: WORK_SCHEDULE_LIST, payload: res.data.results });
          setPaageCounts(res.data.total_pages);
          setTotalValues(res.data.count);
          setInProgress(false);
          if (res.data.count === 0 && users.length > 0) {
            createSchedule(users[0]);
          }
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const addNewShift = (shiftData, workSchedule, setWorkSchedule) => {
  return async (dispatch) => {
    const req = (({ id, ...o }) => o)(shiftData);
    await Axios_v2.post(`/shifts/`, req, HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 201) {
          let newShifts = [...workSchedule.shifts];
          let index = newShifts.findIndex((shift) => shift.id === shiftData.id);
          newShifts.splice(index, 1, res.data);
          let newWorkSchedule = {
            ...workSchedule,
            shifts: newShifts,
          };
          setWorkSchedule(newWorkSchedule);
          toast.success("New Shift Added");
        }
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
        Object.keys(err.response.data).forEach((msg) =>
        toast.warn(`${msg} ${err.response.data[msg][0]}`)
      );
      });
  };
};

export const updateShift = (
  req,
  workSchedule,
  setWorkSchedule,
  setEditFalse
) => {
  return async (dispatch) => {
    await Axios_v2.put(`/shifts/${req.id}/`, req, HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          let newShifts = [...workSchedule.shifts];
          let index = newShifts.findIndex((shift) => shift.id === req.id);
          newShifts.splice(index, 1, req);
          let newWorkSchedule = {
            ...workSchedule,
            shifts: newShifts,
          };
          setEditFalse();
          setWorkSchedule(newWorkSchedule);
          toast.success("Shift Updated!");
        }
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const deleteShift = (ID, workSchedule, setWorkSchedule) => {
  return async (dispatch) => {
    await Axios_v2.delete(`/shifts/${ID}/`, HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          let newShifts = workSchedule.shifts.filter(
            (shifts) => shifts.id !== ID
          );

          let newWorkSchedule = {
            ...workSchedule,
            shifts: newShifts,
          };
          setWorkSchedule(newWorkSchedule);
          toast.error("Shift Deleted");
        }
        console.log("shift delete ", res);
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const AddNewWorkSchedule = (newWS) => {
  return async (dispatch) => {
    dispatch({ type: ADD_NEW_WORKSCHEDULE, payload: newWS });
  };
};

export const SaveNewWorkSchedule = (newWS) => {
  return async (dispatch) => {
    const request = {
      user: {
        id: newWS.user.id,
        name: newWS.user.name,
        email: newWS.user.email,
      },
      user_id: newWS.user_id,
      shifts: newWS.shifts.map((shift) => ({
        day_start: shift.day_start,
        start_time: shift.start_time,
        day_end: shift.day_end,
        end_time: shift.end_time,
        minimum_shift_hours: shift.minimum_shift_hours,
      })),
    };
    await Axios_v2.post(`/workschedules/`, request, HeaderToken())
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 201) {
          dispatch({ type: SAVE_WORK_SCHEDULE, payload: res.data });
          toast.success("Work Schedule Created");
        }

        console.log("ws created ", res);
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
        console.log(err.response);
        const data = err.response.data;

        Object.keys(data).forEach((key) => {
          if (key === "user_id") {
            toast.warn("User must be Unique!");
          }
        });
      });
  };
};

export const RemoveNewWorkSchedule = (workSchedule) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_WORK_SCHEDULE, payload: workSchedule });
  };
};
