import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles.css";
import { CustomSingleDatePicker } from "./CustomDatePicker";
import theme from "../../theme/theme";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export default function SelectCalenderType({ handleSubmit ,wrapperRef,selectedReason,showDropdown,setShowDropdown}) {
  const [selectedOption, setSelectedOption] = useState(selectedReason);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [endCalendarIsOpen, setEndCalendarIsOpen] = useState(false);
  // const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const endCalendarRef = useRef(null);
  const [options] = useState([
    {  value: "daily" },
    {  value: "weekly" },
    {  value: "dateRange" },

  ]);

  const handleOptionSelect = (value) => {
    console.log("av",value);
    setSelectedOption(value);
    handleSubmit(value);
    setShowDropdown(false);

  };

  console.log("selected_options",selectedOption);

  const handleClearFilters = () => {
      setShowDropdown(false);

  };

  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
      }
    }
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  

   

  useOutsideAlerter(wrapperRef);


  return (
    <div >
    <Dropdown id="leave-status-drop"  show={showDropdown} >
      <Dropdown.Toggle
        // variant="success"
        id="dropdown-basic"
        as="div"
        // className="custom-dropdown"
        style={{backgroundColor:'white' ,color:'black', height: "auto"}}
        onClick={() => setShowDropdown(!showDropdown)}
      >
       <div className="select-status-style"style={{width:"210px"}} >
       <span style={{
    marginLeft: "13px",
    marginRight: "auto",
    marginTop: "3px", 
    color: selectedReason ? "#292929" : selectedOption ? "#292929" : "#949494",
    fontFamily: "FK Grotesk",
    color:"#292929", // Adjust the value to make the font thicker
}}>
   


  {selectedOption === "daily" ? "Daily" : selectedOption === "weekly" ? "Weekly" : selectedOption === "dateRange" ? "Date Range" : selectedOption || "Daily"}
  </span>
  <img
    src="/images/down-arrow-icon.svg"
    alt="Download Arrow"
    className="download-arrow"
    style={{marginRight:"4px"}}
  />
</div>

      </Dropdown.Toggle > 

      <Dropdown.Menu id="leave-status-drop" style={{marginTop:"7px"}}>
        <div className="status-dropdown-container" style={{border:"0px solid blue" ,width:"210px"}}>

          <div className="filter-container-leave custom-style-filter">
          <p className="mx-2" style={{ color: "#292929", fontSize: "13px", paddingLeft: "7px", fontFamily: "FK Grotesk", fontWeight: 500 }}>Calender Type</p>

            <div style={{ marginLeft: "auto" }} onClick={handleClearFilters}>
              <img
                src="/images/icons/clear-icon.svg"
                alt="Clear Searh"
                className="clear-icon"
                style={{marginRight:"11px", marginBottom:"11px"}}
              />
            </div>
          </div>

          {/* <hr style={{ margin: "0px" }} /> */}

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "5px 0px 0px 8px ",
            }}
          >
            <p className="mx-2 custom-font gustavo">Select Calendar</p>
          </div> */}

          <div className="dropdown-filter-container">
            {options.map((option, index) => {
           
                return (
                    
            
                    <DropdownItem
                    key={index}
                    label={option.value === "daily" ? "Daily" : option.value === "weekly" ? "Weekly" : option.value === "dateRange" ? "Date Range" : option.value || "Daily"}
                    value={option.value}
                    isSelected={selectedOption === option.value}
                    onSelect={handleOptionSelect}
                />
                
                );
              
            })}
            
          </div>
        </div>
      </Dropdown.Menu>

    </Dropdown>
    </div>
  );
}

function DropdownItem({ label, value, isSelected, onSelect }) {
  return (
    <Dropdown.Item onClick={() => onSelect(value)}>
     <div
  className={`dropdown-user-list-item ${
    isSelected ? "selected-employee" : ""
  }`}
  style={{
    margin: "0px",
    ...(isSelected && {
      // Add your internal styles here when isSelected is true
      textcolor: "black",
      // Add more styles as needed
    })
  }}
><input
          type="checkbox"
          className="checkbox"
          style={{ display: "none" }}
          checked={isSelected}
        />
        <label htmlFor={`select-checkbox-${value}`} >
          <img src={ isSelected ? "/images/icons/checked-icon.svg" : "/images/icons/uncheck-icon.svg" }
            className="checkbox-image"
            alt={isSelected ? "Checked" : "Unchecked"}
          />
        </label>
        <div className={`dropdown-right-item gustavo`}>
        <p
  className={`status-font gustavo ${
    isSelected ? "selected-employee" : ""
  }`}
  style={{
    // Add your internal styles here when isSelected is true
    ...(isSelected && {
      color: "black",
      fontWeight:600
      // Add more styles as needed
    })
  }}
>
            {label}
          </p>
        </div>
      </div>
    </Dropdown.Item>
  );
}
