import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { passwordValidator } from "../../Utils/fieldValidator";
import { changePassword, updateProfile } from "../../redux/Actions/authAction";
import { DatePickerSingle } from "../../components/common/DatePickers";
import { localStorage_userData } from "../../constants";
import {
  getTimeZone,
  getSubordinates,
  updateTimeZone,
} from "../../redux/Actions/authAction";
import Header from "../../components/common/Header";
import moment from "moment";
 
function Profile() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { userInfo } = useSelector((store) => store.auth);
  const [ImageToUpload, setImageToUpload] = useState(null);
  const [profileImg, setprofileImg] = useState(userInfo?.profile_image);
  const [name, setName] = useState(userInfo?.name);
  const [ispasswordValid, setisPasswordValid] = useState(true);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [isSameAsCurrent, setIsSameAsCurrent] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [confirmPasswordMatchError, setConfirmPasswordMatchError] = useState(false);

  const [isNewPassValid,setIsNewPassValid]=useState(true);
  const [isConfirmPassValid,setIsConfirmPassValid]=useState(true);
  const [old_new_confirmPassChanged,setOld_new_confirmPassChanged] = useState(false);
 
  const eyeOpenIcon = '/images/Eye-icon.svg';
  const eyeClosedIcon = '/images/close-eye-icon.svg';
 const naviagte=useNavigate();
  const [visibleCurrentPassword, setVisibleCurrentPassword] = useState(false);
  const [visibleNewPassword, setVisibleNewPassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
 
 
  const calendarRef = useRef(null);
  const wrapperRef = useRef(null);
  const { userPermissions } = useSelector((store) => store.auth);
  const { timeZones } = useSelector((store) => store.auth);
  const [TimeZone, setTimeZone] = useState(userInfo?.timezone);
 
  useEffect(() => {
    dispatch(getTimeZone());
    dispatch(getSubordinates(userInfo.id));
  }, [dispatch]);
 
  console.log("TimeZone", TimeZone);
  useEffect(() => {
    let id = userInfo?.id;
    let timezone = {
      timezone: TimeZone,
    };
    if (userInfo?.id) {
      dispatch(updateTimeZone(timezone, id, userInfo));
    }
  }, [TimeZone, userInfo?.timezone]);
 
 
 
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem(localStorage_userData));
    if (data !== null) {
      let newData = { ...data, timezone: TimeZone };
      localStorage.setItem(localStorage_userData, JSON.stringify(newData));
      if (userInfo?.timezone !== TimeZone) {
        window.location.reload();
      }
    }
  }, [TimeZone, userInfo?.timezone]);
 
 
  useEffect(() => {
    if (userInfo.date_of_birth) {
      const apiDate = new Date(userInfo.date_of_birth);
      setStartDate(apiDate);
    } else {
      // If date_of_birth is null, set today's date
      setStartDate(new Date());
    }
  }, [userInfo]);
 
  const [values, setValues] = useState({
    currentPassword: "",
    showCurrentPassword: false,
    newPassword: "",
    showNewPassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
  });

  useEffect(()=>{
    if(values.newPassword.length > 0 && values.confirmPassword.length > 0){
      setOld_new_confirmPassChanged(true);
    }
  },[values.newPassword,values.confirmPassword]);

  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case 'currentPassword':
        setValues((prev) => ({
          ...prev,
          showCurrentPassword: !prev.showCurrentPassword,
        }));
        break;
      case 'newPassword':
        setValues((prev) => ({
          ...prev,
          showNewPassword: !prev.showNewPassword,
        }));
        break;
      case 'confirmPassword':
        setValues((prev) => ({
          ...prev,
          showConfirmPassword: !prev.showConfirmPassword,
        }));
        break;
      default:
        break;
    }
  };
  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setprofileImg(reader.result);
        setImageToUpload(e.target.files[0]);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const handleSubmit = () => {
    let newPassValid = true;
    let confirmPassValid = true;
    let confirmPasswordMatchError = false;
    let isSameAsCurrent = false;

    if (old_new_confirmPassChanged) {
      newPassValid = passwordValidator(values.newPassword);
      confirmPassValid = passwordValidator(values.confirmPassword);

      if (values.confirmPassword !== values.newPassword) {
        confirmPasswordMatchError = true;
      }

      if (values.newPassword === values.currentPassword || values.confirmPassword === values.currentPassword) {
        isSameAsCurrent = true;
      }
    }

    setIsNewPassValid(newPassValid);
    setIsConfirmPassValid(confirmPassValid);
    setConfirmPasswordMatchError(confirmPasswordMatchError);
    setIsSameAsCurrent(isSameAsCurrent);

    const changePassData = {
      current_password: values.currentPassword,
      password: values.newPassword,
      confirm_password: values.confirmPassword,
    };

    if (
      values.newPassword.length > 0 &&
      newPassValid &&
      confirmPassValid &&
      values.newPassword === values.confirmPassword &&
      values.newPassword !== values.currentPassword &&
      values.confirmPassword !== values.currentPassword
    ) {
      dispatch(changePassword(changePassData, navigation));
    }
 
    if (name.length > 0) {
      let employeeID = userInfo.id;
      let req = {
        name: name,
        email: userInfo.email,
        date_of_birth: moment(startDate).format("YYYY-MM-DD"),
      };
      let formData = new FormData();
      Object.keys(req).forEach((key) => {
        formData.append(key, req[key]);
      });
      if (ImageToUpload !== null) {
        formData.append("profile_image", ImageToUpload, ImageToUpload.name);
      }
      dispatch(updateProfile(formData, employeeID));
    }
  };
  const handlePasswordChange = (prop) => (event) => {
    // setisPasswordValid(passwordValidator(event.target.value));
    setValues({ ...values, [prop]: event.target.value });
    // if (
    //   event.target.value === values.currentPassword ||
    //   event.target.value === values.newPassword
    // ) {
    //   setIsSameAsCurrent(true);
    // } else {
    //   setIsSameAsCurrent(false);
    // }

    // if (
    //   event.target.value !== values.confirmPassword &&
    //   values.confirmPassword.trim() !== ""
    // ) {
    //   setConfirmPasswordMatchError(true);
    // } else {
    //   setConfirmPasswordMatchError(false);
    // }
  };
const handlecancel=()=>{
  naviagte("/")
}
  const handleConformPasswordChange = (prop) => (event) => {
    // setisPasswordValid(passwordValidator(event.target.value));
    setValues({ ...values, [prop]: event.target.value });

    // if (event.target.value === values.currentPassword) {
    //   setIsSameAsCurrent(true);
    // } else {
    //   setIsSameAsCurrent(false);
    // }

    // if (event.target.value !== values.newPassword) {
    //   setConfirmPasswordMatchError(true); // Add state for confirmPassword match error
    // } else {
    //   setConfirmPasswordMatchError(false);
    // }
  };
 
  const handleDecrease = () => {
    const Decrement = startDate;
    Decrement.setDate(Decrement.getDate() - 1);
    const output = Decrement.toLocaleString();
    const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
    setStartDate(new Date(op));
  };
 
  const handleIncrease = () => {
    const Increment = startDate;
    Increment.setDate(Increment.getDate() + 1);
    const output = Increment.toLocaleString();
    const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
    setStartDate(new Date(op));
  };
 
  function useOutsideAlerter(ref, calendarRef) {
    function handleClickOutside(event) {
      if (calendarIsOpen) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          calendarRef &&
          !calendarRef.current.contains(event.target)
        ) {
          setCalendarIsOpen(!calendarIsOpen);
        }
      }
    }
 
    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef, calendarRef);
 
  return (
    <>
      <div className="dashboard-header">
        <Header title='Profile Setting' style='font-weight: bold;' />
      </div>
 
      <div className="profile-main-conatainer">
 
        <div className="profile-context-container">
          <div className="container1" style={{ display: "flex", height: "100%" }}>
 
            <div
              className="profile-main-container"
               style={{ borderRight: "1px solid lightgray" }}
            >
 
              <div
                className="profile-pic-header"
                style={{
                  borderBottom: "1px solid lightgray",
                  width: "91%"
                }}
              >
                <div className="d-flex align-items-center">
                  <h4 style={{ borderBottom: "1px solid lightgray", marginRight: "1rem" }}>
                    User Profile
                  </h4>
                </div>
                <div class="mb-3 row align-items-center">
                  <label class="col-form-label col-sm-3">
                    <img
                      src={profileImg}
                      class="img-thumbnail rounded-circle"
                      alt="..."
                      onError={(e) => {
                        e.target.src = "/images/profilepic.png";
                      }}
                      style={{height: "110px", width: "110px"}}
                    />
                  </label>
                  <div className="col-sm-9">
                    <div className="profile-name">{userInfo?.name}</div>
                    <input
                      type="file"
                      accept="image/*"
                      name="image-upload"
                      id="imageinput"
                      onChange={imageHandler}
                    />
                    <label htmlFor="imageinput" style={{ cursor: "pointer", color: "#007EC5" }}>
                      Change profile Photo
                    </label>
                  </div>
                </div>
              </div>
              <div className="info-div" style={{ marginTop: "18px" }}>
                <div style={{borderBottom: "1px solid lightgray", marginBottom: "20px", paddingBottom:"15px", width: "91%"}}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between"  }}>
                  <div class="one-row col-sm-5" style={{width: "48%"}}>
                    <label htmlFor="u-access" className="col-sm-6 col-form-label label-color">
                      Name
                    </label>
 
                    <div classname="col-sm-6">
                      <input
                        style={{ borderRadius: "4px" }}
                        type="text"
                        className="form-control"
                        placeholder="Enter full name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
 
                      />
                    </div>
                  </div>
                  <div className="one-row col-sm-5" style={{width: "48%"}}>
                    <label htmlFor="u-access" className="col-sm-6 col-form-label label-color">
                      Date of birth
                    </label>
 
                    <div
                      className="employee1-timeline"
                      style={{ border: "1px solid #ced4da" }}
                    >
                    
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <img
                          src="/images/icons/Icon - Calender-Dark.svg"
                          alt="calendar"
                          onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                          ref={wrapperRef}
                        />
  
                        <div>
                          <DatePickerSingle
                            ref={calendarRef}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            calendarIsOpen={calendarIsOpen}
                            setCalendarIsOpen={setCalendarIsOpen}
                          ></DatePickerSingle>
                        </div>
                      </div>


                      <div style={{display: "flex", marginTop: "5px"}}>
                        <button
                          className="prev-month"
                          onClick={() => {
                            handleDecrease();
                          }}
                        ></button>
  
                        <button
                          className="next-month"
                          onClick={() => {
                            handleIncrease();
                          }}
                        ></button>
                      </div>
                     

                    </div>
                  </div>
                </div>
 
                <div class="mb-3 ml-3">
                  <label class="col-sm-3 col-form-label label-color">Email Address:</label>
                  <div class="col-sm-11" style={{width: "100%"}}>
                    <input
                      readOnly
                      type="email"
                      class="form-control input-field"
                      placeholder="Enter Email Address"
                      value={userInfo?.email}
                    />
                  </div>
                </div>

                </div>
 
                <div style={{ display: "flex", justifyContent: "space-between", width: "91%" }}>
                  <div className="one-row col-sm-5" style={{ position: "relative", width: "48%" }}>
                    <label className="col-sm-3 col-form-label label-color">Current Password</label>
                    <div className="col-sm-12" style={{ position: "relative" }}>
                      <input
                        type={values.showCurrentPassword ? "text" : "password"}
                        className="form-control input-field"
                        placeholder="Enter current password"
                        name="currentPassword"
                        value={values.currentPassword}
                        onChange={handlePasswordChange("currentPassword")}
                        style={{ paddingRight: "40px" ,borderRadius:"4px"}}
                      />
                      <img
                        src={values.showCurrentPassword ? eyeOpenIcon : eyeClosedIcon}
                        alt="Toggle password visibility"
                        className="eye-icon"
                        onClick={() => togglePasswordVisibility('currentPassword')}
                        style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                      />
                    </div>
                  </div>
                  <div className="one-row col-sm-5" style={{  position: "relative", width: "48%" }}>
                    <label
                      className="col-sm-3 col-form-label label-color"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      New Password
                    </label>
                    <div className="col-sm-12" style={{ position: "relative" }}>
                      <input
                        type={values.showNewPassword ? "text" : "password"}
                        className="form-control input-field"
                        placeholder="Enter new password"
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handlePasswordChange("newPassword")}
                        style={{ paddingRight: "40px",borderRadius:"4px" }}
                      />
                      <img
                        src={values.showNewPassword ? eyeOpenIcon : eyeClosedIcon}
                        alt="Toggle password visibility"
                        className="eye-icon"
                        onClick={() => togglePasswordVisibility('newPassword')}
                        style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                      />
                    </div>
                    {isSameAsCurrent ? (
                <p style={{ color: "red" }}>
                  New password cannot be the same as the current password
                </p>
              ) : null}
               {confirmPasswordMatchError?(
             <p style={{ color: "red" }}>Password do not match</p>
              ):null}
                  </div>
                </div>
 
 
                <div class="mb-3">
                  <label class="col-sm-3 col-form-label label-color">
                    Re-enter New Password
                  </label>
                  <div className="col-sm-11" style={{ position: "relative" }}>
                    <input
                      type={values.showConfirmPassword ? "text" : "password"}
                      className="form-control input-field"
                      placeholder="Re-enter New Password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleConformPasswordChange("confirmPassword")}
                      style={{ paddingRight: "40px" }}
                    />
                    <img
                      src={values.showConfirmPassword ? eyeOpenIcon : eyeClosedIcon}
                      alt="Toggle password visibility"
                      className="eye-icon"
                      onClick={() => togglePasswordVisibility('confirmPassword')}
                      style={{borderRadius:"4px", position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                    />
                   
                  </div>
                  {!isNewPassValid || !isConfirmPassValid ? (
                <p style={{ color: "red" }}>
                  Password should contain at least 1 Uppercase, 1 lowercase, 1
                  Special Character in (!@#$%^&*),1 Digit, and min 8 Characters{" "}
                </p>
              ) : null}
              {confirmPasswordMatchError && (
             <p style={{ color: "red" }}>Password do not match</p>
              )}
                </div>
 
                {userPermissions.includes("Can update own profile") ? (
                  <div
                    className=" col-sm-11 profile-btn-main"
                    style={{ display: "flex", justifyContent: "flex-end", marginRight: "54px" }}
                  >
                    <button
                      className="save-profile-btn"
                      onClick={handleSubmit}
                      // disabled={isSameAsCurrent || confirmPasswordMatchError}
                      style={{color: "white"}}
                    >
                      Save
                    </button>
                    <button className="cancel-profile-btn" onClick={handlecancel}>
                      Cancel
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="selectdiv" style={{ marginLeft: "14px" }}>
  <div className="d-flex align-items-center">
    <h4 style={{ borderBottom: "1px solid lightgray" }}>
      Time Zone
    </h4>
  </div>
  <div className="timezone">
    <select
      value={TimeZone}
      onChange={(e) => setTimeZone(e.target.value)}
      className="select-dropdown"
    >
      <option>Select TimeZone</option>
      {timeZones.map((timezone) => (
        <option value={timezone} key={timezone} className="custom-option">
          {timezone}
        </option>
      ))}
    </select>
  </div>
</div>
 
          </div>
        </div>
      </div>
    </>
  );
}
 
export default Profile;