import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppRoutes from "./Routes/AppRoutes";
import ThemeProviderWrapper from "./theme/ThemeProvider";

function App() {
  return (
    <>
    <ThemeProviderWrapper>
      <BrowserRouter basename="/app" >
        <Provider store={store}>
          <AppRoutes />
        </Provider>
      </BrowserRouter>
      <ToastContainer position="top-right" autoClose={5000} />
      </ThemeProviderWrapper>
    </>
  );
}

export default App;
