import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import { useSelector } from "react-redux";

export default function Reports() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userPermissions } = useSelector((store) => store.auth);
  const { feature_list } = useSelector((store) => store.subPlan);
  console.log("features_list",feature_list);


  const array = [
    {
      title: "Attendance",
      link: "/Reports",
      permission:
        (userPermissions.includes("View all attendance reports"
        ) || userPermissions.includes("View own users attendance reports"))&&feature_list.includes("Attendence"),
    },
    {
      title: "Project and Task Report",
      link: "/Reports/project-and-task",
      permission:feature_list.includes("Project & Task Report"),
    },
    {
      title: (
        <span>
          Timeline Logs{" "}
          <span className="progress_badge"></span>
        </span>
      ),
      link: "/Reports/timelinelogs",
      permission:feature_list.includes("Time Line LOGS"),

      isWorkInProgress: false,
    },
  ];
 
 
  const resourcePath = array.filter(item => item.permission);

 const showAllTabs =
    userPermissions.includes("Can view all reports")||userPermissions.includes("Can view reports for users added by or assigned to self");
    useEffect(() => {
      // Check if the current path is accessible; if not, find the first accessible path
      const currentTab = resourcePath.find((path) => pathname === path.link);
      if (!currentTab || !currentTab.permission) {
        const defaultTab = resourcePath.find((path) => path.permission);
        if (defaultTab) {
          navigate(defaultTab.link);
        }
      }
    }, [pathname, navigate, resourcePath]);
   

  return (
    <div>
      <div className="resource-header">
        <h1>Reports</h1>
        <div className="resource-tab-container">
          {resourcePath.map(
            (path) =>
              (showAllTabs || path.permission) && (
                <div
                  className={`tab-button ${
                    pathname === path.link && "active-tab"
                  } `}
                  key={path.title}
                  onClick={() => navigate(path.link)}
                >
                  <p>{path.title}</p>
                </div>
              )
          )}
        </div>
      </div>

      <div className="resource-context-container">
        <Outlet />
      </div>
    </div>
  );
}