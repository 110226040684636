import "./style.css";
import React, { useEffect, useState } from "react";
import { updateLeaveType } from "./../../redux/Actions/leaveAction";
import { useDispatch, useSelector } from "react-redux";
import useLeaveType from "./../../hooks/useLeaveType";
import Add_Leave_type from "./Add_Leave_Holiday";
import useLeaveManagement from "../../hooks/useLeaveManagement";
const LeaveType = () => {
  const dispatch = useDispatch();
  
 
  const {
    leaveTypeList,
    addUser,
    setAddUser,
    inputValue,
    setInputValue,
    handleEdit, 
    handleDelete,
    handleInputChange,
    handleAddUser,
    callLeaveTypeList,
    userInfo,
    userPermissions,
    editLeave,
    leave_id,
    ismodalopen,
    setIsModalopen,
    setLeave_id
    
  } = useLeaveType();

  const {
    startDate,
    startMonth,
    setStartMonth,
    currentTab,
    handleDecrease,
    handleIncrease,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    // currentPage,
    // setCurrentPage,
    pageCounts,
    leaveTransactionList,
    editEmployee,
    modalOpen,
    setModalOpen,
    setStartDate,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    dateRangeStart,
    onChangeDateRange,
    dateRangeEnd,
    handleTabTypeChange,
    handleSelectChange,
    handleTextareaChange,
    selectedReason,
    text,
    handleSubmit,
    options,
    editLeaveModal,
    setEditLeaveModal,
    editData,
    leaveOptions,
    handleSelectStatus,
    leaveStatus,
    managerComment,
    handleUpdateLeave,
    handleEditReasonChange,
    user,
    setUser,
    edituserLeaveID,
    edituserleave,
    openModal,
    seeDescription,
    descriptionModal,
    setDescriptionModal,
    selectedEmployee,
    startMonth_status,
    setStartMonth_status,
     handleDecrease_status,
    handleIncrease_status,
    inProgress,
    statuspageCounts,
    currentPage,
    setCurrentPage,
    handleDelete_Status,
    leaveTransactionFilterList,
    setIsupdated,
    ModalCalender,
    setModalCalender,
    handleClick,
    exportState,
    handleChange,
   
  } = useLeaveManagement();


  const userlimit  = useSelector((store) => store.subPlan);
  console.log("userLimitVazlue",userlimit);


  const cancleModal = () => {
    setModalOpen(false);
  };
  console.log("is_open",ismodalopen);
  console.log("leaveTypeList", leaveTypeList);
  return (
    <div className="leave-allotement" style={{ textAlign: 'center',border: '4px solid #E8E8E8',height: "100%"}}>
    <div className="headerleave-btn" style={{ border: '0px solid pink', marginBottom: '0', paddingBottom: '0', textAlign: 'left', marginLeft: '0%',  marginRight: '2%', height:"4%"}}>      
    <p style={{fontWeight: 'bold'}}>Leave Type</p>        <div className="button-container mt-0">
            <div className="export-btn">
                <button style={{ backgroundColor: 'transparent', color: 'transparent', border: 'none' }}>
                    Export CSV
                    <img src="/images/icons/download.png" alt=""/>
                </button>
            </div>
            <button
                className="add-public-holiday-btn"
                // onClick={() => openModal()}
                onClick={() => {
                  openModal();
                  setLeave_id(null);
                }}

                type="submit"
            >
                <img src="/images/icons/add-icon.png"
                alt="add"  
                style={{ height: "30px", marginRight: "5px" }}/>
                Add Leave Type
            </button>
        </div>
    </div>
     
    <div className="row leaves" style={{ borderTop: '4px solid #E8E8E8', marginTop: '1%', paddingTop: '2%',textAlign: 'center', marginLeft: '1%', marginRight: '0', width: '97%' }}>     {leaveTypeList.map((data, id) => (
            <LeaveTypeChip
                value={data}
                hooksProps={{
                    dispatch,
                    updateLeaveType,
                    handleEdit,
                    handleDelete,
                    callLeaveTypeList,
                    userInfo,
                    userPermissions,
                    editLeave,
                    openModal,

                    
                    
                }}
            />

            
        ))}
    </div> 
    <Add_Leave_type
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hookProps={{
          startDate,
          startMonth,
          setStartMonth,
          currentTab,
          handleDecrease,
          handleIncrease,
          calendarRef,
          wrapperRef,
          calendarIsOpen,
          setCalendarIsOpen,
          setStartDate,
          weekDate,
          setWeekDate,
          startWeekDay,
          endWeekDay,
          dateRangeStart,
          onChangeDateRange,
          dateRangeEnd,
          handleTabTypeChange,
          handleSelectChange,
          handleTextareaChange,
          selectedReason,
          text,
          handleSubmit,
          options,
          edituserLeaveID,
          setIsupdated,
          ModalCalender,
          setModalCalender,
          setInputValue,
          handleAddUser,
          editLeave,
          leave_id,
          inputValue,
          setModalOpen,
          cancleModal,
          
          


        }}
      />
</div>

  );
};

const LeaveTypeChip = ({ value, hooksProps })=>{
  // let name=value.reason;

  const {
    dispatch,
    updateLeaveType,
    handleEdit,
    handleDelete,
    callLeaveTypeList,
    userInfo,
    userPermissions,
    editLeave,
    openModal,
                    
  } = hooksProps;
  let imageSrc =`/images/Polygon.svg`
  if(value.reason === "Sick leave" || value.reason === "Maternity leave" || value.reason === "Annual leave" || value.reason === "Personal leave" || value.reason === "Birthday leave" || value.reason === "Study leave") {
    imageSrc = `/images/${value.reason}.svg`;
    console.log("if_part");
  } else {
    imageSrc = `/images/Personal_leave.svg`;
    console.log("else_part");
  }
  


  let background;
  switch (value.reason) {
    case "Sick leave":
      background = "red"
      break;
    case "Maternity leave":
      background = "#007EC5";
      break;
    case "Personal leave":
      background = "#563E9A";
      break;
    case "Annual leave":
      background = "#3F8A9A";
      break;
    case "Birthday leave":
      background = "#B6446D";
      break;
    case "Study leave":
      background = "#B96247";
      break;
    default:
      background = "#3F8A9A"; // Default background color
  }
  



  return (
    <>
      <div className="musho-capturing-moments" style={{ background }}>
        <div className="sick-leave-parent">
          <h3 className="sick-leave" style={{ border: '0px solid blue', marginTop: '11%', fontFamily: 'FK Grotesk', color: '#FFFFFF' }}>         
            <p className="sick">{value.reason}</p>
          </h3>
          <div className="edit-wrapper" style={{ border: '2px solid #FFFFFF',borderRadius:"5px", marginTop: '4%', textAlign: "center", display: "flex", justifyContent: "center",height:"29px" }}>
          <div 
    className="edit" 
    onClick={() => { editLeave(value); openModal(); }} 
    style={{ 
        border: '0px solid black', 
        fontFamily: 'FK Grotesk', 
        color: '#FFFFFF', 
        marginTop: '1%', 
        cursor: 'pointer' // Add cursor property
    }}
>
    Edit
</div>

</div>

        </div>
        <div className="frame-parent">
          <div className="frame-wrapper">
            <div className="polygon-parent">
              <img className="polygon-icon" alt="" src="/images/polygon.png" />
              {imageSrc && (
  <img
    className="vector-icon"
    loading="lazy"
    alt=""
    src={imageSrc || "/images/Personal_leave.svg"}  />
)}

            </div>
          </div>
          <div className="frame-container">
          <div className="delete-wrapper" style={{ display: 'flex', justifyContent: 'center',height:'28px'}}>              
          <div 
    className="delete" 
    onClick={() => {handleDelete(value.id)}} 
    style={{ 
        border: '0px solid #FFFFFF', 
        fontFamily: 'FK Grotesk', 
        color: '#FFFFFF', 
        marginTop: '0%', 
        cursor: 'pointer' // Add cursor property
    }}
>
    Delete
</div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};
export default LeaveType;