import React from "react";
import { useState } from "react";
import LeaveAllotmentTable from "./LeaveAllotmentTable";
import userLimitTable from "./UserAllotmentTable";
import { useSelector } from "react-redux";
import EmpTypeModal from "./EmpTypeModal";
import useLeaveAllotment from "../../hooks/useLeaveAllotment";
import { Axios_v2 } from "../../Utils/axios";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";
import { userLimit_function } from "../../redux/Actions/subscription";
import { useDispatch } from "react-redux";

const Userlimit = () => {
 
  const { userPermissions } = useSelector((store) => store.auth);
  const {userlimit}  = useSelector((store) => store.subPlan);
  console.log("userLimitVazlue",userlimit);

  const dispatch = useDispatch();

 
  const [actionInput, setActionInput] = useState(userlimit);

  const handleInputChange = (event) => {
    setActionInput(event.target.value);
  };

  const handleSave = () => {
    // Logic to handle saving the input data
    console.log('Saved_input:', actionInput);
   dispatch(userLimit_function(actionInput,dispatch));
  };
  return (
    <div className="leave-allotement">
      <div className="headerleave-btn">
      <p >User Limit</p>
      
      </div>
       
      <div className="leaves-table-body" >
    <table >
     
        <tr>
         
          <th scope="col" style={{textAlign:"justify", paddingLeft:"38px"}}>
            UserLimit
          </th>
          <th scope="col" className="emp-no">
           
          </th>
          <th scope="col"></th>
          <th scope="col" style={{ textAlign: "justify", border: "0px solid pink" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <input
                type="number" // Changed to number
                value={actionInput}
                onChange={handleInputChange}
                placeholder={`Enter your limit`}
                style={{ marginRight: "10px" }} // Optional: adds some space between input and button
              />
              <button onClick={handleSave}>Save</button>
            </div>
          </th>
 


         
        </tr>
        
      
      
      
          
            <tr className="table-row">
              <td>
                <div style={{textAlign:"justify" ,paddingLeft:"44px"}}>{""}</div>
              </td>
              <td>
                <div> {"          "}</div>
              </td>
              <td>
                <div>{""}</div>
              </td>

            
                <td>
                  <div className="table-actions">
                
                   
                 
                  </div>
                </td>
             


            </tr>
        
        
     
    </table>

   

   
    
  </div>
    </div>
  );
};
export default Userlimit;