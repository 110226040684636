import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postFeedBack } from "../redux/Actions/feedbackAction";
import { toast } from "react-toastify";

export default function useFeedBack() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.auth);
  const [isProgress, setIsProgress] = useState(false);
  const [feedbackValue, setFeedbackvalue] = useState({
    bugtypes: "",
    usercomments: "",
  });
  const [statisfactorylevel, setStatisfactorylevel] = useState("");
  const [file, setFile] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackvalue((precValue) => ({ ...precValue, [name]: value }));
  };

  const imageHandler = (e) => {
    setFile((prevValue) => [...prevValue, e.target.files[0]]);
  };

  const handleUpload = (setRating,setSelectedOption) => {
    console.log("bugtypes",feedbackValue);
    if (statisfactorylevel.length < 1) {
      toast.warn("Please Select Rating");
    }
    if(!feedbackValue.usercomments) {
      toast.warn("Please Enter Comment");
    }
    if(file.length < 1) {
      toast.warn("Please upload attachment");
    }
    if (!feedbackValue.bugtypes || !feedbackValue.bugtypes.trim()) {
      toast.warn("Please Select Any Feedback Type");
    }
    if (
      feedbackValue.bugtypes &&
      statisfactorylevel &&
      feedbackValue.usercomments
    ) {
      let req = {
        bug_types: feedbackValue.bugtypes,
        statisfactory_level: statisfactorylevel,
        user_comments: feedbackValue.usercomments,
        user: {
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
        },
        attachments: file[0],
      };
      let formData = new FormData();
      Object.keys(req).forEach((key) => {
        formData.append(key, req[key]);
      });
      if(req.attachments){
      dispatch(postFeedBack(formData, setIsProgress,setRating,setSelectedOption));
      

      setFeedbackvalue({
        bugtypes: "",
        usercomments: "",
      });
      setStatisfactorylevel("");
      setFile([]);
    }
    }
  };
  const removeImage = (ind) => {
    const newimage = file.filter((_, index) => index !== ind);
    setFile(newimage);
  };

  return {
    handleChange,
    imageHandler,
    removeImage,
    handleUpload,
    setStatisfactorylevel,
    statisfactorylevel,
    feedbackValue,
    file,
    isProgress,
  };
}
