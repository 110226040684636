import { Axios_v2 } from "../../Utils/axios";
import {
  useSiteUpdatehandler as SiteUpdatehandler,
  autoLogoutOnTokenExpire,
} from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";
import { toast } from "react-toastify";

export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_DETAILS = "PROJECT_DETAILS";

export const getTotalCount = (setTotalCount, isActive) => {
  return async (dispatch) => {
    await Axios_v2.get(
      `/projects/?is_active=${isActive}&page=1&items_per_page=1`,
      HeaderToken()
    )
      .then((res) => {
        setTotalCount(res.data.count);
      })
      .catch((err) => {});
  };
};

// to fetch project data for Project dropdown
export const getProjectList = (search, setProjects) => {
  return async (dispatch) => {
    await Axios_v2.get(
      `/projects/?is_active=true&page=1&items_per_page=20${
        search ? "&search=" + search : ""
      }`,
      HeaderToken()
    )
      .then((res) => {
        setProjects(res.data.results);
      })
      .catch((err) => {});
  };
};

export const ProjectsLIstPagination = (
  setInProgress,
  pageNo,
  item_per_page,
  setPaageCounts,
  setTotalValues,
  isActive,
  searchQuery
) => {
  return async (dispatch) => {
    setInProgress && setInProgress(true);
    await Axios_v2.get(
      `/projects/?ordering=-id&is_active=${isActive}&page=${pageNo}&items_per_page=${item_per_page}${
        searchQuery ? "&search=" + searchQuery : ""
      }`,
      HeaderToken()
    )
      .then((res) => {
        SiteUpdatehandler(res, dispatch);
        if (res.status === 200) {
          dispatch({ type: PROJECT_LIST, payload: res.data.results });
          setPaageCounts(res.data.total_pages);
          setTotalValues(res.data.count);
          setInProgress(false);
        } else {
          setInProgress && setInProgress(false);
        }
      })
      .catch((err) => {
        // setInProgress(false)
        autoLogoutOnTokenExpire(err, dispatch);
        toast.error("Network Error");
      });
  };
};

export const getProjectDetails = (ID) => {
  return async (dispatch) => {
    await Axios_v2.get(`/projects/${ID}/`, HeaderToken())
      .then((res) => {
        dispatch({ type: PROJECT_DETAILS, payload: res.data });
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
        toast.error("Network Error");
      });
  };
};

export const deleteProject = (ID, refreshTable) => {
  return async (dispatch) => {
    Axios_v2.delete(`/projects/${ID}/`, HeaderToken())
      .then((res) => {
        toast.success("Successfully Added to Archived");
        refreshTable();
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const restoreProject = (ID, refreshTable) => {
  return async (dispatch) => {
    Axios_v2.get(`/projects/${ID}/restore/`, HeaderToken())
      .then((res) => {
        toast.success("Project restored");
        refreshTable();
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const addProjectTask = (taskname, setTasks) => {
  return async (dispatch) => {
    Axios_v2.post(
      "/tasks/",
      {
        name: taskname,
      },
      HeaderToken()
    )
      .then((res) => {
        const response = res.data;
        setTasks((prevValue) => [
          { id: response.id, name: response.name },
          ...prevValue,
        ]);
      })
      .catch((err) => {
        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const addNewproject = (req, handleCancle, callProjectsList) => {
  return async (dispatch) => {
    Axios_v2.post("/projects/", req, HeaderToken())
      .then((res) => {
        handleCancle();
        callProjectsList();
        toast.success("Project Added");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const updateProject = (req, ID, handleCancle, callProjectsList) => {
  return async (dispatch) => {
    Axios_v2.put(`/projects/${ID}/`, req, HeaderToken())
      .then((res) => {
        handleCancle();
        callProjectsList();
        toast.success("project updated successfully");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const UpdateTask = (value, ID, tasks, setTasks) => {
  return async (dispatch) => {
    Axios_v2.put(
      `/tasks/${ID}/`,
      {
        name: value,
      },
      HeaderToken()
    )
      .then((res) => {
        let newTasksArray = [...tasks];
        const Index = tasks.findIndex((task) => task.id === ID);
        newTasksArray.splice(Index, 1, { id: ID, name: value });
        setTasks(newTasksArray);
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};

export const DeleteTask = (ID, tasks, setTasks) => {
  return async (dispatch) => {
    Axios_v2.delete(`/tasks/${ID}/`, HeaderToken())
      .then((res) => {
        let newTasksArray = [...tasks];
        const filteredTasks = newTasksArray.filter((t) => t.id !== ID);
        setTasks(filteredTasks);
        toast.success("task Deleted");
      })
      .catch((err) => {
        console.log(err);
        Object.keys(err.response.data).forEach((msg) =>
          toast.warn(`${msg} ${err.response.data[msg][0]}`)
        );

        autoLogoutOnTokenExpire(err, dispatch);
      });
  };
};
