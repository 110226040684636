import React, { useState ,useRef,useEffect} from "react";
import "./style.css";
import { RenderPageButtons } from "../../../../Utils/randerPageButtons";
import { download_pdf } from "../../../../redux/Actions/payrollAction";
import { delete_document } from "../../../../redux/Actions/payrollAction";
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import Pagination from "../../Pagination/pagination";


const EmpPayrollTable = ({ 
  document,
   list,
   hookProps, 
   monthYearValue,
   setUser,
   setName,
   handleFileChange,
   handleLabelClick,
   setapiType,
   setDoc_id,
}) => {
  const {
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    isLoading,

  } = hookProps;
  const pages = [];

 
 
 

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  console.log("document",document);
  const { userPermissions } = useSelector((store) => store.auth);



   for (let i = 1; i <= pageCounts; i++) {
      pages.push(i);
    }


  const renderPageButtons = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );
   const function1 = (document_id,name,user_id) =>{
    setapiType("patch");
    setName(name)
    setDoc_id(document_id);
    setUser([user_id]);
   }
  const handleClick = (document_id,name,user_id) => {
    // Call both functions
    function1(document_id,name,user_id);
    handleLabelClick();
  };
  
 
 
 
  

 
  

  


 

  return (
    <div style={{ border: "0px solid #000" }}>
      
      <table className="table">
        <thead>
          <tr>
           
            <th scope="col" style={{ textAlign: "left", paddingLeft: "15px" }}>
              Name 
            </th>
            <th scope="col" style={{ textAlign: "left" }}>
              Email
            </th>
            <th scope="col" style={{ textAlign: "left" }}>
              Document
            </th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
  {isLoading ? (
    <tr className="table-Rows-loading">
      <td colSpan="4">Loading data...</td>
    </tr>
  ) : (
    list.map((emp, index) => (
      <tr className="table-Rows" key={index}>
        <td>
          <div className="emp-name">
            <img src="/images/icons/prof.png" alt="profile" />
            {emp.name}
          </div>
        </td>
        <td>
          <div className="emp-email">{emp.email}</div>
        </td>
        <td>
          <div className="emp-document">{`${emp.name}_${document}`}</div>
        </td>
        <td>
          <button className="send-btn" onClick={() => dispatch(download_pdf(emp.id, emp.name, emp.type))}>
            <img src="/images/icons/export.png" alt="resend" />
          </button>
          {((document === "payslip" && userPermissions.includes("Can reshare payslip")) ||
  (document === "form16" && userPermissions.includes("Can reshare payslip"))) && (
  <button className="send-btn" onClick={() => handleClick(emp.id, emp.name, emp.user)} title="Resend">
    <img src="/images/icons/send.png" alt="Resend" />
  </button>
)}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
           {((document === "payslip" && userPermissions.includes("Can delete payslip")) ||
            (document === "form16" && userPermissions.includes("Can delete Form 16"))) && (
          <button className="send-btn" onClick={() => dispatch(delete_document(emp.id, setUser, emp.user, emp.name, setName))}>
            <img src="/images/icons/delete.png" alt="resend" />
          </button>
          )}
        </td>
      </tr>
    ))
  )}
</tbody>

      </table>
      <div className="projects-table-footer">

      {
      pageCounts>1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )
    }



    {/* <div className="table-pagination">
      <button
        className="prev-scr"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === pages[0] ? true : false}
      >
        {"PREV"}
      </button>
      
      {renderPageButtons}

      <button
        className="next-scr"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === pages[pages.length - 1] ? true : false}
      >
        {"NEXT"}
      </button>
    </div> */}
  </div>
    </div>
  );
};

export default EmpPayrollTable;
