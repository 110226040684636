import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";

const ExampleCustomInput = ({ value, onClick }) => {
  return (
    <div>
      <input
        readOnly
        className="example-custom-input"
        onClick={(e) => onClick(e.preventDefault())}
        value={moment(value).format("DD-MMM-yyyy")}
        style={{ width: "98px", fontSize: "12px", height: "30px !important"}}
      />
    </div>
  );
};

export const CustomSingleDatePicker = forwardRef(
  (
    {
      startDate,
      setStartDate,
      calendarRef,
      calendarIsOpen,
      closeCalendar, 
      showMaxDate,
    },
    ref
  ) => {
    return (
      <div ref={ref}>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            closeCalendar(); 
          }}
          format="DD-MM-YYYY"
          peekNextMonth={false}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<ExampleCustomInput />}
          maxDate={showMaxDate ? "" : new Date()}
          open={calendarIsOpen}
        />
      </div>
    );
  }
);
