// import React from "react";
import { useEffect, useState, useRef } from "react";
import "./style.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { HeaderToken } from "../../Utils/headerToken";

import { Axios_v2 } from "../../Utils/axios";
import SelectSingleEmployee from "../../components/SelectSingleEmployee";
import SelectSingleProject from "../SelectSingleProject/SelectSingleProject";
import { useSelector } from "react-redux";
import { RenderPageButtons } from "../../Utils/randerPageButtons";
import SelectAllProject from "../SelectProjectsDD/SelectAllProject";
import SelectAllEmployee from "../SelectEmployeDD/SelectAllEmployee";
import {
  DatePickerSingle,
  WeekPicker,
  MonthPicker,
  DateRangePicker,
} from "../common/DatePickers";
import useTimelineLogs from "../../hooks/useTimelineLogs";
import { toast } from "react-toastify";


const TimelineLogs = () => {
  const{
    workinghoursList,
    setTotalhors,
    setWorked,
    setshiftstart, 
    setshiftend,
    setworkinday,
    itemsperpage,
    setLoading, 
    project,
    setProject,
    total_hors,
    worked, 
    totalworkinday,
    shiftstart,
    shiftend, 
    startDate,
    setStartDate,
    user,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    setStartWeekDay,
    endWeekDay,
    setEndWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    currentTab,
    setCurrentTab,
    handleTabTypeChange,
    handleDecrease,
    handleIncrease,
    // attendanceForAllUsers,
    inProgress,
    calendarRef,
    calendarIsOpen,
    setCalendarIsOpen,
    handleExportAll,
    exportState,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    allempAlltendence,
    selectedProject,
    setSelectedProject,
    callAttendanceList,
    loading,
  }=useTimelineLogs();

  const { activityProgressBar } = useSelector((store) => store.WH);
  console.log("activity_progress_bar",activityProgressBar);
 
  function ActivityBar({ logs }) {
    return (
      <div className="activity_bar">
        {logs.map((log, index) => (
          <div
            key={index}
            style={{
              backgroundColor: log.color,
              width: log.percentage,
              height: "100%",
              display: "inline-block"
            }}
            title={`${log.color === 'gray' ? 'Offline' : log.color === 'green' ? 'Working' : log.color === 'orange' ? 'Break' : ''}\nTotal Time: ${log.Total_Time}\nStart Time: ${log.Start_Time}\nEnd Date: ${log.End_Time}`}
            />
        ))}
      </div>
    );
  }



  const Export_Report = () => {
    console.log("function is here",currentTab);
  
    
      let req = {};
  
      if (currentTab == "daily") {
        console.log("current tab is daily",)
        req = {
          report_type: currentTab,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(startDate).format("YYYY-MM-DD"),
        };
      }
  
      if (currentTab == "weekly") {
        req = {
          report_type: currentTab,
          start_date: moment(startWeekDay).format("YYYY-MM-DD"),
          end_date: moment(endWeekDay).format("YYYY-MM-DD"),
        };
      }
  
      if (currentTab == "monthly") {
        const lastDayOfMonth = moment(startMonth).endOf('month').format('YYYY-MM-DD');
        const firstDayOfMonth = moment(startMonth).startOf('month').format('YYYY-MM-DD');
        req = {
          report_type: currentTab,
          start_date: firstDayOfMonth ,         
          end_date: lastDayOfMonth,
          };
      }
  
      if (currentTab == "dateRange") {
        req = {
          report_type: currentTab,
          start_date: moment(dateRangeStart).format("YYYY-MM-DD") ,
          end_date: moment(dateRangeEnd).format("YYYY-MM-DD") ,
        };
      }
      let queryParams = `?start_date=${req.start_date}&end_date=${req.end_date}`;

      const selectedUserIds = user.length > 0 ? user.join('&user=') : null;
      const userQueryParam = selectedUserIds ? `&user=${selectedUserIds}` : ``;
      
      const selectedProjectIds = project.length > 0 ? project.join('&project=') : null;
      const projectQueryParam = selectedProjectIds ? `&project=${selectedProjectIds}` : ``;
      
      const finalQuery = queryParams + userQueryParam + projectQueryParam;
      
    
      // Make sure to import Axios_v2 and HeaderToken if they are not already imported
      Axios_v2.get(`/working-hour-logs/exportworktimelog/${finalQuery}`, HeaderToken())
      .then((res) => {
        console.log("res_exp",res.data.file);

          window.location.href = res.data.file;
      })
      .catch((err) => {
        toast.warn("No available data")
      });
    
    
  };
  
  
  

  const { userPermissions } = useSelector((store) => store.auth);
  console.log(workinghoursList,"workinghourlist")
  
  
  const getEmployeedAttendanceData = async () => {
    const user_date = moment(startDate).format("YYYY-MM-DD");
    const type = "daily";
    try {
      const response = await Axios_v2.get(
        `/attendance/?user=${user}&date_after=${user_date}&items_per_page=${itemsperpage}&report_type=${type}&date_before=${user_date}`
      );

      console.log(response.data, "resyyy");
      if (response.data["results"][0].minimum_shift_hours == "-1 23:59:59") {
        setTotalhors("0:0:00");
        setWorked(
          response.data["results"][0].hours_worked_within_shift.time_string
        );
        setshiftstart(
          moment(response.data["results"][0].shift_start).format(
            "DD-MM-YYYY, h:mm A"
          )
        );
        setshiftend(
          moment(response.data["results"][0].actual_shift_end).format(
            "DD-MM-YYYY, h:mm A"
          )
        );
        setworkinday(
          response.data["results"][0].total_hours_worked.time_string
        );
      } else {
        setTotalhors(response.data["results"][0].minimum_shift_hours);
        setWorked(
          response.data["results"][0].hours_worked_within_shift.time_string
        );
        setshiftstart(
          moment(response.data["results"][0].actual_shift_start).format(
            "DD-MM-YYYY, h:mm A"
          )
        );
        setshiftend(
          moment(response.data["results"][0].actual_shift_end).format(
            "DD-MM-YYYY, h:mm A"
          )
        );
        setworkinday(
          response.data["results"][0].total_hours_worked.time_string
        );
      }
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  

  const handleEmployeeChange = (e) => {
    
    setUser(e);
    setProject([]);

    // setApiData([]); // Clear the logs when user is

    setStartDate(new Date());
  };

  const ChangeDate = (e) => {
    setStartDate(e);
  };

  const handleProjectChange = (e) => {
    setProject(e);
  };

  const pages = [];

  for (let i = 1; i <= pageCounts; i++) {
    pages.push(i);
  }

  const renderPageButtons = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );
  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setDateRangeStart(start);
    setDateRangeEnd(end);
    
  };
  const wrapperRef = useRef(null);
  return (
    <>
      <div id="Attendances">
        <div className="header-btn">
          {userPermissions.includes("Can view users added by or assigned to self") ||
          userPermissions.includes("Can view all users in the organization") ? (
            <div className="mem-btn">
              <SelectAllEmployee selectedUsers={user} setSelectedUsers={setUser} />
            </div>
          ) : null}
          <div className="mem-btn mx-2">
          <SelectAllProject
            selectedProject={project}
            setSelectedProject={setProject}
          />
          </div>
          <div className="employee-timeline" style={{marginLeft: "100px"}}>

            <img
              src="/images/icons/Icon - Calender.png"
              alt="calendar"
              onClick={() => setCalendarIsOpen(!calendarIsOpen)}
              ref={wrapperRef}
            />



          {currentTab === "daily" && (
            <DatePickerSingle
              startDate={startDate}
              ref={calendarRef}
              setStartDate={setStartDate}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}
          {currentTab === "weekly" && (
            <WeekPicker
              ref={calendarRef}
              weekDate={weekDate}
              setWeekDate={setWeekDate}
              startWeekDay={startWeekDay}
              endWeekDay={endWeekDay}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}

          {currentTab === "monthly" && (
            <MonthPicker
              ref={calendarRef}
              startMonth={startMonth}
              setStartMonth={setStartMonth}
              calendarIsOpen={calendarIsOpen}
              setCalendarIsOpen={setCalendarIsOpen}
            />
          )}
          {currentTab === "dateRange" && (
            <DateRangePicker
              ref={calendarRef}
              dateRangeStart={dateRangeStart}
              onChangeDateRange={onChangeDateRange}
              dateRangeEnd={dateRangeEnd}
              calendarIsOpen={calendarIsOpen}
            />
          )}

          {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly"? (
            <button
              className="prev-month"
              onClick={() => {
                handleDecrease();
              }}
            >
              {""}
            </button>
          ) : null}


          {currentTab === "daily" || currentTab === "monthly" || currentTab === "weekly"? (
            <button
              className="next-month"
              onClick={() => {
                handleIncrease();
              }}
              disabled={
                (currentTab === "daily" && startDate.toDateString() === new Date().toDateString()) ||
                (currentTab === "weekly" && 
                (startWeekDay.toDateString() === new Date().toDateString() || 
                (startWeekDay.getDate() + 6 >= new Date().getDate() && 
                startWeekDay.getMonth() === new Date().getMonth()))
              ) ||
                (currentTab === "monthly" && startMonth.getMonth() === new Date().getMonth())
              }
            >
               {""}
            </button>
          ) : null}


        </div>
        <div style={{ display: "flex", justifyContent: "end", width: "20%" }}>
          <div>
            <select
              id="u-access"
              name="group"
              className="form-control"
              value={currentTab}
              onChange={(e) => handleTabTypeChange(e)}
            >
              <option value="daily"> Daily</option>
              <option value="weekly"> Weekly</option>
              <option value="monthly"> Monthly</option>
              <option value="dateRange">Date-Range</option>
            </select>
          </div>
        </div>
        <button
  className={workinghoursList.length === 0 ? "exp-btn holiday-hidden" : "exp-btn"}
  type="submit"
  onClick={Export_Report} // Uncomment this line to add onClick functionality
  // disabled={exportState}
>
  Export
  {exportState && (
    <span className="spinner-grow spinner-grow-sm"></span>
  )}
  {/* <img src="/images/icons/download.png" alt="" /> */}
</button>
        </div>
      </div>
    

      {user.length === 1 && currentTab === "daily" && workinghoursList && workinghoursList.length > 0 && (  <div>
    <ActivityBar logs={activityProgressBar} />
  </div>
)}



     

      <div className="mt-5">
        {loading ? (
          <h4 className="custom-no-data" style={{textAlign: "center"}}>Loading data...</h4>
        ) : workinghoursList?.length === 0 ? (
          <h3 className="" style={{textAlign: "center"}}>No Data to Show!</h3>
        ) : (
          <>
            <table className="table">
              
              <thead className="bg-white rounded">
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Start</th>
                  <th scope="col">End</th>
                  <th scope="col">Worked</th>
                  <th scope="col">Project</th>
                  <th scope="col">Task</th>

                  <th scope="col">Activity performed by</th>
                </tr>
              </thead>
              <tbody>
                {workinghoursList?.map((s, index) => {
                  const startDate = moment(s.logStartdateandTime).format(
                    "DD-MM-YYYY, h:mm A"
                  );
                  const endDate = moment(s.logEnddateandTime).format(
                    "DD-MM-YYYY, h:mm A"
                  );

                  return (
                    <tr key={s.id}>
                      <td>{s.user.name}</td>

                      <td>{startDate}</td>
                      <td>{endDate}</td>
                      <td>{s.time_worked_in_this_log}</td>
                      <td>{s.project_name}</td>
                      <td>{s.task_name}</td>
                      <td>{s.activity_performed_by_str}</td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div
              className="projects-table-footer"
              style={{ overflow: "hidden" }}
            >
              <div className="table-pagination">
                <button
                  className="prev-scr"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === pages[0] ? true : false}
                >
                  {"PREV"}
                </button>

                {renderPageButtons}
                <button
                  className="next-scr"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    currentPage === pages[pages.length - 1] ? true : false
                  }
                >
                  {"NEXT"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TimelineLogs;
