import { ATTENDENCE_FOR_ALL, WORK_TIME, ALL_ATTENDENCE, PROJECT_TASK_REPORT } from "../Actions/reportAction";

const initialState = {
  attendanceForAllUsers: [],
  workedTime: {},
  allempAlltendence: [],
  projectAndTaskReport:[]
};

export default function reporReducer(store = initialState, action) {
  switch (action.type) {
    case ATTENDENCE_FOR_ALL: {
      return {
        ...store,
        attendanceForAllUsers: action.payload,
      };
    }
    case ALL_ATTENDENCE: {
      return {
        ...store,
        allempAlltendence: action.payload,
      };
    }
    case WORK_TIME: {
      return {
        ...store,
        workedTime: action.payload,
      };
    }
    case PROJECT_TASK_REPORT: {
      return {
        ...store,
        projectAndTaskReport: action.payload
      }
    }
    default: {
      return { ...store };
    }
  }
}
