import React, { useEffect, useRef } from "react";
import moment from "moment";
import { getWorkedTimeDetails } from "../../redux/Actions/reportAction";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import theme from "../../theme/theme";

export default function TotalTimeWorked() {
  const dispatch = useDispatch();
  const { workedTime } = useSelector((store) => store.ReportR);
  const containerRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  useEffect(() => {
    let time = `${moment().format("YYYY-MM-DDT")}00:00:00${moment().format(
      "Z"
    )}`;
    dispatch(getWorkedTimeDetails(time));
  }, [dispatch]);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollLeft.current;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    const x = e.pageX - startX.current;
    containerRef.current.scrollLeft = scrollLeft.current - x;
  };

  return (
    <>
      <div
        ref={containerRef}
        className="dashboard-total-time-container m-1"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
      >
        <div className="form" style={{marginTop:"4px"}}>
          <div className="dashboard-time">
            <div className="today-section">
              <p
                className="today-time-head gustavo"
                style={{
                  fontSize: theme.fontSizes.small,
                }}
              >
                Time Worked Today
              </p>
              <p
                className="ss-total-time gustavo"
                style={{
                  fontSize: theme.fontSizes.xxLarge,
                  fontWeight: theme.fonts.sansSerif.fontWeight500,
                }}
              >
                {workedTime.today_time
                  ? `${workedTime.today_time.hours}H  ${workedTime.today_time.minutes}M`
                  : "0:00:00"}
              </p>
              <p
                className="today-time-head gustavo"
                style={{
                  fontSize: theme.fontSizes.small,
                  color: theme.colors.greyShingle
                }}
              >
                {moment().format("LL")}
              </p>
            </div>

            <div className="today-section week-time-dash">
              <p
                className="today-time-head gustavo"
                style={{
                  fontSize: theme.fontSizes.small,
                }}
              >
                Time worked This Week
              </p>
              <p
                className="ss-total-time gustavo"
                style={{
                  fontSize: theme.fontSizes.xxLarge,
                  fontWeight: theme.fonts.sansSerif.fontWeight500,
                }}
              >
                {workedTime.current_week
                  ? `${workedTime.current_week.hours}H  ${workedTime.current_week.minutes}M`
                  : ""}
              </p>

              <p
                className="today-time-head gustavo"
                style={{
                  fontSize: theme.fontSizes.small,
                  color: theme.colors.greyShingle
                }}
              >
                {workedTime.current_week
                  ? `${moment(workedTime.current_week.start_date).format(
                      "MMMM DD"
                    )}-${moment(workedTime.current_week.end_date).format(
                      "MMMM DD, YYYY"
                    )} `
                  : "0:00:00"}
              </p>
            </div>

            <div className="today-section last-week-time">
              <p
                className="today-time-head gustavo"
                style={{
                  fontSize: theme.fontSizes.small,
                }}
              >
                Time Worked Last Week
              </p>
              <p
                className="ss-total-time gustavo"
                style={{
                  fontSize: theme.fontSizes.xxLarge,
                  fontWeight: theme.fonts.sansSerif.fontWeight500,
                }}
              >
                {workedTime.last_week
                  ? `${workedTime.last_week.hours}H  ${workedTime.last_week.minutes}M`
                  : ""}
              </p>

              <p
                className="today-time-head gustavo"
                style={{
                  fontSize: theme.fontSizes.small,
                  color: theme.colors.greyShingle
                }}
              >
                {workedTime.last_week
                  ? `${moment(workedTime.last_week.start_date).format(
                      "MMMM DD"
                    )}-${moment(workedTime.last_week.end_date).format(
                      "MMMM DD, YYYY"
                    )} `
                  : "0:00:00"}
              </p>
            </div>

            <div className="today-section this-month">
              <p
                className="today-time-head gustavo"
                style={{
                  fontSize: theme.fontSizes.small,
                }}
              >
                Time Worked This Month
              </p>
              <p
                className="ss-total-time gustavo"
                style={{
                  fontSize: theme.fontSizes.xxLarge,
                  fontWeight: theme.fonts.sansSerif.fontWeight500,
                }}
              >
                {workedTime.current_month
                  ? `${workedTime.current_month.hours}H  ${workedTime.current_month.minutes}M`
                  : "0:00:00"}
              </p>

              <p
                className="today-time-head"
                style={{
                  fontSize: theme.fontSizes.small,
                  color: theme.colors.greyShingle
                }}
              >
                {moment().format("MMMM, YYYY")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
