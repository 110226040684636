import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getRoleList,
  addRole,
  deleteRole,
  editRole,
  getAllPermissionList,
  updatePermission,
} from "../redux/Actions/roleAction";
import { toast } from "react-toastify";


export default function useRoleAndSettings() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.auth);
  const { AllRoleList, AllPermissionList } = useSelector((store) => store.role);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState("All permission");
  const [inProgress, setInProgress] = useState(true);
  const [editId, setEditId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCounts, setPageCounts] = useState(1);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [assignedPermissions, setAssignedPermissions] = useState([]);
  const[selectedPermissions,setSelectedPermissions]=useState([]);
  const[editedPermision,setEditedPermission]=useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState({});

  
  const itemsPerPage = 5;
  const itemsonPage = 1000;
  const [roleValue, setRoleValue] = useState({
    id: "",
    name: "",
    description: "",
    selectedFunction: "All permission",
  });
  useEffect(() => {
    const updatedGroupedPermissions = {};
  
    AllPermissionList.forEach((permission) => {
      const { modules_str, name, id } = permission;
      if (!updatedGroupedPermissions[modules_str]) {
        updatedGroupedPermissions[modules_str] = [];
      }
      updatedGroupedPermissions[modules_str].push({ id, name });
    });
  
    // After processing, you can update the state
    setGroupedPermissions(updatedGroupedPermissions);
  }, [AllPermissionList]);

  useEffect(() => {
    dispatch(
      getRoleList({
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
      })
    );
  }, [setInProgress, currentPage, itemsPerPage, setPageCounts]);

  useEffect(() => {
    dispatch(
      getAllPermissionList({
        itemsonPage,
        setInProgress,
      })
    );
  }, [itemsonPage, setInProgress]);


  useEffect(() => {
    setSelectedPermissions(
      assignedPermissions.map((id) => ({
        id,
        }))
    );
  }, [assignedPermissions, AllPermissionList]);

  const emptyState = () => {
    setRoleValue("");
  };

  const callRoleList = useCallback(() => {
    return dispatch(
      getRoleList({
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
      })
    );
  }, [setInProgress, currentPage, itemsPerPage, setPageCounts]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleValue((prevValue) => ({ ...prevValue, [name]: value }));
  };

  const handleFunctionChange = (e) => {
    const { value } = e.target;
    setRoleValue((prevValue) => ({
      ...prevValue,
      selectedFunction: value,
    }));
  };

  const determineScope = (selectedFunction) => {
    return selectedFunction === "All permission"
      ? "ALL_MODULE"
      : "DEFINE_MODULE";
  };
  const reversedetermineScope = (selectedFunction) => {
    return selectedFunction === "ALL_MODULE"
      ? "All permission"
      : "define permission";
  };
  const cancleModal = () => {
    console.log("cancleModal");
    setEditId("");
    setModalOpen(false);
  };
  const openModal = () => {
    console.log("openModal");
    setEditId("");
    setRoleValue("");
    setModalOpen(true);
    setDescriptionError("");
    setNameError("");
  };
  const validateForm = () => {
    let isValid = true;

    if (!roleValue.name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!roleValue.description) {
      setDescriptionError("Description is required");
      isValid = false;
    } else {
      setDescriptionError("");
    }

    return isValid;
  };

  const handleSave = () => {
    let data = {
      name: roleValue.name,
      role_description: roleValue.description,
      scope: determineScope(roleValue.selectedFunction),
      permissions : [],
    };
    if (roleValue.selectedFunction === "define permission") {
      data.permissions = [];
    }
    if (editId) {
       data.permissions=editedPermision;
      dispatch(editRole(data, editId, setModalOpen, callRoleList, emptyState));
    } else if (validateForm()) {
      dispatch(addRole(data, setModalOpen, callRoleList, emptyState));
    }
  };

  const handleDelete = (id) => {
    if (id) {
      dispatch(deleteRole(id, callRoleList));
    }
  };

  const openPermissionModal = (data) => {
    console.log("data.id",data.id)
    setShowPermissionModal(true);
    if (data.id) {
      setRoleValue({
        id: data.id,
        name: data.name,
        description: data.role_description,
        selectedFunction: reversedetermineScope(data.scope),
      });
      setAssignedPermissions(data.permissions);
    }
  };

  const handleEdit = (data) => {
    setModalOpen(true);
    if (data.id) {
      setEditId(data.id);
      setRoleValue({
        name: data.name,
        description: data.role_description,
        selectedFunction: reversedetermineScope(data.scope),
      });
      setEditedPermission(data.permissions);
    }
  };

  const handleSavePermissions = (id) => {
    const ids = selectedPermissions.map(item => item.id);
    let data = {
      permissions: ids,
    };
    dispatch(updatePermission(id, data, callRoleList, setShowPermissionModal, setSelectedPermissions ))
  };

  const handlePermissionSelection = (id, name) => {
    const permission = { id, name };
    setSelectedPermissions((prevPermissions) => {
      // Check if the permission is already selected, if yes, remove it.
      const isAlreadySelected = prevPermissions.some((p) => p.id === id);
      if (isAlreadySelected) {
        return prevPermissions.filter((p) => p.id !== id);
      } else {
        return [...prevPermissions, permission];
      }
    });
  };

  const handleSelectAllModuleItems = (module, isChecked) => {
    if (isChecked) {
      // If the module checkbox is checked, select all items in the module
      const modulePermissions = groupedPermissions[module];
      const selectedItems = modulePermissions
        .filter((item) => !selectedPermissions.some((p) => p.id === item.id))
        .map(({ id, name }) => ({ id, name }));
      setSelectedPermissions((prevPermissions) => [
        ...prevPermissions,
        ...selectedItems,
      ]);
    } else {
      // If the module checkbox is unchecked, deselect all items in the module
      setSelectedPermissions((prevPermissions) =>
        prevPermissions.filter(
          (p) => !groupedPermissions[module].some((item) => item.id === p.id)
        )
      );
    }
  };


  // Group permissions by module
  
   
  
  
console.log("selectedPermissions",roleValue, selectedPermissions)
  return {
    userInfo,
    modalOpen,
    setModalOpen,
    roleValue,
    handleChange,
    selectedFunction,
    setSelectedFunction,
    handleFunctionChange,
    handleSave,
    AllRoleList,
    AllPermissionList,
    currentPage,
    setCurrentPage,
    pageCounts,
    handleDelete,
    showPermissionModal,
    setShowPermissionModal,
    openPermissionModal,
    handleEdit,
    editId,
    openModal,
    nameError,
    descriptionError,
    inProgress,
  assignedPermissions,
   setAssignedPermissions,
   selectedPermissions,
   setSelectedPermissions,
    handlePermissionSelection,
    handleSelectAllModuleItems,
    selectedPermissions,
    setSelectedPermissions,
    groupedPermissions,
    handleSavePermissions,
  };
}
