import Modal from "../common/Modal";
import "./style.css";

const EmpTypeModal = ({ open, onClose ,hookProps}) => {
    const{
        employeeType,
        handleEmployeeChange,
        leaveAlloted,
        handleLeaveAllotmentChange,
        handleLeaveSubmit,
        editLeaveID
    }=hookProps;
  return (
    <Modal open={open} onClose={onClose}>
       <div className="holiday-edit-apply-container">
          <div className="edit-apply-holiday-header">
            <div>

             
            {editLeaveID?( <h4>Edit Tenure</h4>):
              (<h4>Add Tenure</h4>)}              
              


            </div>

            <div className="">
              <button className="close-btn" onClick={() => onClose()}>
                <img
                  height="25px"
                  width="25px"
                  src="/images/icons/Icon - Close.png"
                  alt="Project-info-icon"
                />
              </button>
            </div>
          </div>

          <div className="mb-2 mt-1">
            <label for="u-access" className="col-sm-4 col-form-label">
            Tenure Type :
            </label>
            <div className="">
              <input
                type="text"
                id="p-name"
                className="form-control"
                placeholder="Enter tenure type"
                value={employeeType}
                onChange={handleEmployeeChange}
                required
                style={{fontSize: "15px"}}
              />
            </div>
          </div>

          <div className="mb-2 mt-1">
            <label for="u-access" className="col-sm-4 col-form-label">
            No of leaves alloted :
            </label>
            <div className="">
              <input
                type="text"
                id="p-name"
                className="form-control"
                placeholder="Enter no. of days"
                value={leaveAlloted}
                 onChange={handleLeaveAllotmentChange}
                required
                style={{fontSize: "15px"}}
              />
            </div>
          </div>

         

          <div className="row mb-2 align-self-center">
            <div className="col-sm-9 offset-sm-3">
              <div className="modal-btn">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{marginLeft: "20px"}}
                  onClick={handleLeaveSubmit}                >
                  {/* {holidayName === "" ? 'Add' : 'Update'} */}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
    </Modal>
  );
};

export default EmpTypeModal;