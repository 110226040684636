import { DOWNLOAD_APPLICATION } from "../Actions/downloadAction";

const initialState = {
  DownloadApplication: [],
};

const downloadReduser = (store = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_APPLICATION: {
      return {
        ...store,
        DownloadApplication: action.payload,
      };
    }

    default: {
      return { ...store };
    }
  }
};

export default downloadReduser;
