import { Axios_v2 } from "../../Utils/axios";
import { autoLogoutOnTokenExpire } from "../../Utils/updateControler";
import { HeaderToken } from "../../Utils/headerToken";

export const DOWNLOAD_APPLICATION = "DOWNLOAD_APPLICATION";

export const getDownloadApplication = (env) => {
  let apiUrl = 'https://mechlintech.dev.clocksession.com/gateway/api/tracker-releases/?ordering=-version&items_per_page=2';

  if (env && env['x-api-environment']) {
    console.log("Environment", env['x-api-environment']);
    if (env['x-api-environment'] === "dev") {
      apiUrl = 'https://mechlintech.dev.clocksession.com/gateway/api/tracker-releases/?ordering=-version&items_per_page=2';
    } else if (env['x-api-environment'] === "stage") {
      apiUrl = 'https://mechlintech.stage.clocksession.com/gateway/api/tracker-releases/?ordering=-version&items_per_page=2';
    }
    else if (env['x-api-environment'] === "production") {
      apiUrl = 'https://mechlintech.clocksession.com/gateway/api/tracker-releases/?ordering=-version&items_per_page=2';
    }
  } else {
    console.log("No 'x-api-environment' found in env");
  }

  return async (dispatch) => {
    try {
      const response = await Axios_v2.get(apiUrl, HeaderToken());
      dispatch({ type: DOWNLOAD_APPLICATION, payload: response.data.results });
    } catch (error) {
      autoLogoutOnTokenExpire(error, dispatch);
    }
  };
};
