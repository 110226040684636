import React, { useState } from "react";
import "./styles.css";
import Modal from "../Modal";
import AddButton from "../../AddButton";
import Tooltip from "../../ToolTip";
import ToolTip from "../../ToolTip";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SearchInput from "./SearchInput";


export default function AddProject({ open, onClose, projectId, hookProps }) {
  console.log("harsh", projectId);
  const { userPermissions } = useSelector((store) => store.auth);
  const { userInfo } = useSelector((store) => store.auth);

  const {
    projectName,
    setProjectName,
    taskName,
    setTaskName,
    tasks,
    handleProjectSubmit,
    addTask,
    editTask,
    handleCancle,
    employeesList,
    users,
    selectEmployee,
    searchEmployee,
    setSearchEmployee,
    projectErrors,
    taskErrors,
    deleteTask,
    oldTasks,
    selectAll,
    isOwnProject,
  } = hookProps;
  console.log("employeesList", employeesList);

  const sortedEmp = [...employeesList].sort((a, b) => {
    if (users.includes(a.id) && !users.includes(b.id)) {
      return -1;
    } else if (!users.includes(a.id) && users.includes(b.id)) {
      return 1; // b should come before a
    } else {
      return 0;
    }
  });

  const TaskChip = ({ value, isOwnProject }) => {
    const [Edittask, setEditTask] = useState(false);
    const [name, setName] = useState(value.name);
    const [error, setError] = useState(false);

    const update = () => {
      if (name.length < 1) {
        setError(true);
      } else {
        editTask(name, value.id);
        setError(false);
      }
    };

    return (
      <div className="chip">
        {Edittask ? (
          <>
            <input
              type="text"
              id="p-task"
              style={{ border: error && "2px solid red" }}
              className="form-control"
              key={value.id}
              placeholder="Task Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <ToolTip position="top" title="Save Task">
              <button onClick={() => update()}>✔</button>
            </ToolTip>
            {/* <ToolTip position="top" title="Cancle"> */}
            <button onClick={() => setEditTask(false)}>❌</button>
            {/* </ToolTip> */}
          </>
        ) : (
          <>
            <p style={{fontSize:'12px'}}>{value.name}</p>
             <div style={{marginLeft:"2px"}} >
            {userPermissions.includes("Can change task") &&
              oldTasks.includes(value.id) ? (
              <>
                <ToolTip position="top" title="Edit Task">
                  <img
                    src="/images/icons/edit-green.png"
                    alt="edit"
                    onClick={() => setEditTask(true)}
                  />
                </ToolTip>
                {userPermissions.includes("Can delete task in all projects") ||
                  (userPermissions.includes("Can delete task in own project") &&
                    (isOwnProject)) ? (
                  <>
                    <ToolTip position="right" title="delete Task">
                      <img
                        src="/images/icons/delete.png"
                        alt="edit"
                        onClick={() => deleteTask(value.id)}
                      />
                    </ToolTip>
                  </>
                ) : null}
              </>
            ) : (
              // <ToolTip position="right" title="Update Project First">
              //   <img src="/images/icons/arrow-filled-right.png" alt="info" />
              // </ToolTip> 
              " "
            )}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="add-project-container">
        <div className="project-header">
          <div style={{borderBottom:'2px solid lightgrey',marginTop:'5px',marginBottom:"5px", display:'flex'}}>
          <h3 style={{ marginLeft: '30px' }}>
  {projectId ? 'Edit Project & Task' : 'Add Project & Task'}
</h3>
            <img style={{marginLeft:'290px'}} src="/images/icons/close.svg" alt="Image" class="header-image"   onClick={handleCancle}></img>
          </div>
          <div className="project-input-container" style={{marginLeft:'30px',width:'750px'}}>
            <div >
              <label for="p-name">
                Project Name
               
              </label>
             
              {/* <div className="col-md-5"> */}
              <input
                type="text"
                id="p-name"
                className="form-control"
                placeholder="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                style={{border:'2px solid lightgrey',borderRadius:"7px",width:"75%"}}
                required
              />
              {projectErrors.name && (
                <p className="error-text">{projectErrors.name}</p>
              )}
            </div>
          </div>
          <div  style={{marginLeft:'30px'}} >
            <label for="p-task" >
              Add Task
            </label>
            <div >

            </div>
          </div>

          <div >

            <>
              {userPermissions.includes("Can add task to all projects") ||
                (userPermissions.includes("Can add task to own project") &&
                  (isOwnProject)) ? (
                <>
                  <div >

                    <div class="task-input-container"  style={{marginLeft:'30px',width:'92%',borderRadius:'7px'}}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add new task here"
                        value={taskName}
                        onChange={(e) => setTaskName(e.target.value)}
                      />
                      <AddButton style={{height:'20px',width:'30px'}} title="Add Task" onClick={addTask} />
                    </div>



                    {taskErrors.name && (
                      <p className="error-text">{taskErrors.name}</p>
                    )}

                  </div>


                </>
              ) : null}
              <div className="project-input">
                {userPermissions.includes("Can view task") &&
                  tasks.map((task) => <TaskChip value={task} isOwnProject={isOwnProject} />)}
              </div>
            </>
          </div>
          {/* </div> */}
        </div>
        <div className="p-user">
        <h6>Users</h6>
        </div>
    
        <div className="project-employee-list">
          <div className="project-action">
            <div style={{border:'2px solid lightgrey',borderRadius:'7px',height:'35px',width:"200px",borderRight:'2ps soild grey'}}>
           <SearchInput value={searchEmployee}
              onChange={(e) => setSearchEmployee(e.target.value)} />
            </div>
            <div className="select-all">
              <input type="checkbox" onChange={() => selectAll()} /> Select All
            </div>

          </div>

          <div className="employee-list-grid overflow-auto">
  {sortedEmp.map((employee) => {
    console.log("chk", employee);
    return (
      (userPermissions.includes("Can assign project to all users") ||
        (userPermissions.includes("Can assign project to own users") &&
          employee.manager_id.includes(userInfo.id))) ? (
        <div className="employee-list-name" key={employee.id}>
          <img
            src={users.includes(employee.id) ? "/images/icons/checked-icon.svg" : "/images/icons/uncheck-icon.svg"}
            alt={users.includes(employee.id) ? "Checked" : "Unchecked"}
            onClick={() => {
              selectEmployee(employee.id);
            }}
          />
          <span style={{marginLeft:'5px'}}>{employee.name}</span>
        </div>
      ) : null
    );
  })}
</div>
        </div>
        <div className="footer">
          <button
            className="project-action-save"
            onClick={() => {
              handleProjectSubmit();
              // resetTable();
            }}
          >
            {projectId ? "Update" : "Save"}
          </button>

          <button className="project-action-dlt" onClick={handleCancle}>
            Cancel
          </button>
        </div >
      </div>

    </Modal>
  );
}