import { React, useEffect, useState } from "react";
import Modal from "../common/Modal";
import "./style.css";
import useLeaveManagement from "../../hooks/useLeaveManagement";                        
import {
  DatePickerSingle,
  DateRangePicker,
  MonthPicker,
  WeekPicker,
} from "../common/DatePickers";

const Add_Leave_type = ({ open, onClose, hookProps }) => {
  
  const {
    startDate,
    startMonth,
    setStartMonth,
    currentTab,
    handleDecrease,
    handleIncrease,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    setStartDate,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    dateRangeStart,
    onChangeDateRange,
    dateRangeEnd,
    handleTabTypeChange,
    handleSelectChange,
    handleTextareaChange,
    selectedReason,
    text,
    handleSubmit,
    options,
    edituserLeaveID,
    setIsupdated,
    ModalCalender,
    setModalCalender,
    setInputValue,
    handleAddUser,
    leave_id,
    inputValue,
    setModalOpen,
    cancleModal,
    

  } = hookProps;

  useEffect(() => {
    setModalCalender(false);
  }, [startWeekDay, startDate]);

  useEffect(() => {
    if(dateRangeEnd) {
      setModalCalender(false);
    }
  }, [dateRangeEnd]);

  const handleClose = () => {
    onClose();
    setInputValue("");
  }
  
  console.log("leave_id",leave_id);
  return (
    <>
      <Modal open={open} onClose={()=>{
        onClose();
        setInputValue("");
      }}>
        <div className="holiday-edit-apply-container">
          <div className="edit-apply-holiday-header" style={{border:"0px solid black"}}>
            <div>
            {!leave_id && <h4>Add Leave Type</h4>}
            {leave_id && <h4>Edit Leave Type</h4>}</div>

            <div className="">
              <button className="close-btn" onClick={handleClose}>
                <img
                  height="25px"
                  width="25px"
                  src="/images/icons/Icon - Close.png"
                  alt="Project-info-icon"
                />
              </button>
            </div>
          </div>

          <div className="mb-2 mt-1" style={{ border: "0px solid black", textAlign: "left" }}>  
    <label
    htmlFor="u-access"
    className="col-sm-4 col-form-label left-aligned-label"
    style={{ border: "0px solid black", marginLeft:"10px" }}
  >
    Leave Type
  </label>

            <div className="">
              <input
                type="text"
                id="p-name"
                className="form-control"
                placeholder="Enter your Leave type"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{fontSize: "15px"}}
              />
            </div>
          </div>

         

          <div className="row mb-2 align-self-center">
            <div className="col-sm-9 offset-sm-3">
              <div className="modal-btn">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{marginLeft: "20px"}}
                  onClick={() => {
                    handleAddUser();
                    setModalOpen(false);
                  }}
                                  >
 {!leave_id && <span>Add Leave</span>}
{leave_id && <span>Edit Leave</span>}
               </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Add_Leave_type;
