import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logIn } from "../../redux/Actions/authAction";
import { emailValidator, passwordValidator } from "../../Utils/fieldValidator";
import { localstorage_userInfo } from "../../constants";
import PrivacyTermComp from "./PrivacyTermComp";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState(false);
  const [isEmailvalid, setIsEmailvalid] = useState(true);
  const [ispasswordValid, setisPasswordValid] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [LoginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
    showPassword: false,
    rememberMe: true,
    deviceType: "web",
    deviceUuid: "string",
  });

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem(localstorage_userInfo));
    if (data !== null) {
      setLoginDetails((prevValue) => ({
        ...prevValue,
        email: data.email,
        password: data.password,
        rememberMe: data.rememberMe,
      }));
    }
  }, []);

  const emailHandler = (value) => {
    // setIsEmailvalid(emailValidator(value));
    setLoginDetails((prevValue) => ({ ...prevValue, email: value }));
  };

  const passwordHandler = (value) => {
    // setisPasswordValid(passwordValidator(value));
    setLoginDetails((prevValue) => ({ ...prevValue, password: value }));
  };

  const handleLogin = () => {
    const email = LoginDetails.email;
    const password = LoginDetails.password;

    const isEmailValid = emailValidator(email);
    const isPasswordValid = passwordValidator(password);

    if (!email.length > 0) {
      setErrors(true);
    } else {
      setIsEmailvalid(isEmailValid);
    }
    if (!password.length > 0) {
      setErrors(true);
    } else {
      setisPasswordValid(isPasswordValid);
    }

    if ( email.length && password.length && isEmailvalid && ispasswordValid ) {
      dispatch(logIn(LoginDetails, navigate, setIsProcessing));
    }
    setTimeout(() => {
      setIsEmailvalid(true);
      setisPasswordValid(true);
    }, 6000);
  }

  const handleSignUp = () => {
    window.open('https://clocksession.com/app/signup', '_blank');
  };

  return (
    <div style={{display: "flex", minHeight: "100vh"}}>
    <div className="left-login-container" style={{position: "relative"}}>

      {/* <div >

        <div style={{textAlign: "center", position: "relative", top: "80px", left: "16px"}}>
          <img src="/images/login_img_1.png" alt="" style={{width: "550px"}} />
        </div>

        <div style={{textAlign: "center"}}>
          <img src="/images/login_img_2.png" alt="" style={{width: "548px"}}/>
        </div>
      </div> */}

      <div style={{textAlign: "center"}}>
          <img src="/images/login-bg.png" alt="" style={{height: "100vh", width: "100%"}}/>
      </div>


      <div style={{position: "absolute", width: "100%", bottom: "3%"}}>
      <p className="company-footer">
        ©{new Date().getFullYear()} All Rights Reserved by Mechlin
        Technologies
      </p>
      </div>

    </div>



    <div className="auth-screen-form-card">
      <div className="">
        <img src="/images/clocksession-logo-new.png" alt="clocksession-logo" />
      </div>

      <div className="clock-login-header">
        <div>LOGIN</div>
        <div>Welcome back to clock session</div>
      </div>

      <div style={{marginTop: "20px"}}>

      <div className="auth-input">
        <label>Email</label>
        <input
          autoComplete="off"
          type="email"
          name="email"
          placeholder="Enter your email address"
          value={LoginDetails.email}
          onChange={(e) => emailHandler(e.target.value)}
        />
        {errors && isEmailvalid ? (
          !LoginDetails.email ? (
            <p>Email is required</p>
          ) : null
        ) : null}
        {!isEmailvalid ? <p>Please enter a valid email.</p> : null}
      </div>

      <div className="auth-input">
        <label>Password</label>
        <div className="auth-password">
          <input
            autoComplete="off"
            type={LoginDetails.showPassword ? "text" : "password"}
            name="password"
            placeholder="Enter your password"
            value={LoginDetails.password}
            onChange={(e) => passwordHandler(e.target.value)}
          />
          <button
            onClick={() =>
              setLoginDetails((prev) => ({
                ...prev,
                showPassword: !prev.showPassword,
              }))
            }
            type="button"
            style={{ border: "none", backgroundColor: "#DADADA", borderRadius: '4px'}}
          >
            <img
              src={
                LoginDetails.showPassword === true
                  ? "/images/icons/eye-open.png"
                  : "/images/icons/eye-close.png"
              }
              alt="visible"
              style={{ marginRight: "5px", width: "15px" }}
            />
          </button>
        </div>

        {errors && ispasswordValid ? (
          !LoginDetails.password ? (
            <p>Password is required</p>
          ) : null
        ) : null}
        {!ispasswordValid ? (
          <p>
            {/* Password should contain at least 1 Uppercase, 1 lowercase, 1 Special
            Character in (!@#$%^&*),1 Digit, and min 8 Characters{" "} */}
            Please enter valid password
          </p>
        ) : null}
      </div>


      </div>

      <div className="auth-input-remember-me">
        <input
          checked={LoginDetails.rememberMe}
          className="form-check-input"
          type="checkbox"
          id="remember-me"
          value={LoginDetails.rememberMe}
          onChange={() =>
            setLoginDetails((prevValue) => ({
              ...prevValue,
              rememberMe: !prevValue.rememberMe,
            }))
          }
          style={{marginTop:"6px"}}
        />
        <label className="form-check-label" htmlFor="remember-me" style={{paddingLeft: '6px'}}>
          Remember me
        </label>
      </div>
      <button
        className="auth-btn"
        onClick={handleLogin}
        disabled={isProcessing}
      >
        {isProcessing ? "Processing ..." : "Log in"}
      </button>
      <div className="forgot-password">
        <p>
          Forgot your password?{" "}
          <span onClick={() => navigate("/ForgotPassword")}>Click to reset</span>{" "}
        </p>
      </div>

      <div className="login-signup">
        <div>Don't have an account?</div>
        <div style={{height: "100%", width: "33%"}}>
          <button onClick={handleSignUp}>Sign Up</button>
        </div>
      </div>
      
      <PrivacyTermComp/>

    </div>

    </div>
  );
}
