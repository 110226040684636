import React from "react";
import useEmployeeList from "../../hooks/useEmployeeList";
import EmployeeListTable from "../common/EmployeeListTable";
import ToolTip from "../ToolTip";
import "./style.css";

import SelectEmployeeDD from "../SelectEmployeDD";
//Date Pickers
import {
  DatePickerSingle,
  WeekPicker,
  MonthPicker,
  DateRangePicker,
} from "../common/DatePickers";
import SelectAllEmployee from "../SelectEmployeDD/SelectAllEmployee";
import SelectAttendenceType from "../SelectType";

export default function EmployeeList() {
  const {
    startDate,
    setStartDate,
    setUser,
    weekDate,
    setWeekDate,
    startWeekDay,
    endWeekDay,
    startMonth,
    setStartMonth,
    dateRangeStart,
    dateRangeEnd,
    currentTab,
    onChangeDateRange,
    handleTabTypeChange,
    handleDecrease,
    handleIncrease,
    attendanceForAllUsers,
    inProgress,
    user,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    currentPage,
    setCurrentPage,
    pageCounts,
    setPageCounts,
    allempAlltendence,
    setDateRangeEnd,
    setDateRangeStart,
    handleChange,
  } = useEmployeeList();

  return (
    <div className="employee-list-container m-2">
      <div className="employee-top-container">
        <div className="employee-list-action-left">
          <div className="employee-dropbtn-custom">
            <SelectAllEmployee
              selectedUsers={user}
              setSelectedUsers={setUser}
              tab="dashboard"
            />
          </div>

          <div className="employee-timeline">
            <img
              src="/images/icons/Icon - Calender-Dark.svg"
              alt="calendar"
              onClick={() => setCalendarIsOpen(!calendarIsOpen)}
              ref={wrapperRef}
            />

            <div>
              {currentTab === "daily" && (
                <DatePickerSingle
                  ref={calendarRef}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  calendarIsOpen={calendarIsOpen}
                  setCalendarIsOpen={setCalendarIsOpen}
                />
              )}
              {currentTab === "weekly" && (
                <WeekPicker
                  weekDate={weekDate}
                  setWeekDate={setWeekDate}
                  startWeekDay={startWeekDay}
                  endWeekDay={endWeekDay}
                  calendarIsOpen={calendarIsOpen}
                  ref={calendarRef}
                  startDate={startDate}
                  setCalendarIsOpen={setCalendarIsOpen}
                />
              )}

              {currentTab === "monthly" && (
                <MonthPicker
                  startMonth={startMonth}
                  setStartMonth={setStartMonth}
                  ref={calendarRef}
                  calendarIsOpen={calendarIsOpen}
                  startDate={startDate}
                  setCalendarIsOpen={setCalendarIsOpen}
                />
              )}
              {currentTab === "dateRange" && (
                <DateRangePicker
                  dateRangeStart={dateRangeStart}
                  onChangeDateRange={onChangeDateRange}
                  dateRangeEnd={dateRangeEnd}
                  ref={calendarRef}
                  calendarIsOpen={calendarIsOpen}
                  startDate={startDate}
                  setDateRangeStart={setDateRangeStart}
                  setDateRangeEnd={setDateRangeEnd}
                />
              )}
            </div>

            {currentTab === "daily" ||
            currentTab === "monthly" ||
            currentTab === "weekly" ? (
              <button
                className="prev-month"
                onClick={() => {
                  handleDecrease();
                }}
              ></button>
            ) : null}

            {currentTab === "daily" ||
            currentTab === "monthly" ||
            currentTab === "weekly" ? (
              <button
                className="next-month"
                onClick={() => {
                  handleIncrease();
                }}
                disabled={
                  (currentTab === "daily" &&
                    startDate.toDateString() === new Date().toDateString()) ||
                  (currentTab === "weekly" &&
                    (startWeekDay.toDateString() ===
                      new Date().toDateString() ||
                      (startWeekDay.getDate() + 6 >= new Date().getDate() &&
                        startWeekDay.getMonth() === new Date().getMonth()))) ||
                  (currentTab === "monthly" &&
                    startMonth.getMonth() === new Date().getMonth())
                }
              ></button>
            ) : null}
          </div>
        </div>
        <div className="employee-list-action-right">
          <ToolTip position="top" title="Select Attendance Type">
            {/* <div style={{ position: "relative" }}>
              <select
                id="u-access"
                name="group"
                className="form-control custom-select"
                value={currentTab}
                onChange={(e) => handleTabTypeChange(e)}
                style={{
                  paddingRight: "30px",
                  WebkitAppearance: "none",
                  MozAppearance: "none",
                  appearance: "none",
                }}
              >
                <option selected> Select Calendar</option>
                <option value="daily"> Daily</option>
                <option value="weekly"> Weekly</option>
                <option value="monthly"> Monthly</option>
                <option value="dateRange">Date-Range</option>
              </select>
              <img
                src="images/down-arrow-icon.svg"
                alt="Dropdown Icon"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "20px",
                  transform: "translateY(-50%)",
                  width: "12px",
                  height: "12px",
                }}
              />
            </div> */}
            <SelectAttendenceType handleSubmit={handleChange} />
          </ToolTip>
        </div>
      </div>

      <EmployeeListTable
        data={allempAlltendence}
        currentTab={currentTab}
        inProgress={inProgress}
        hookProps={{
          currentPage,
          setCurrentPage,
          pageCounts,
          setPageCounts,
        }}
      />
    </div>
  );
}
