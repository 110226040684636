import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../common/Alert";
import "./style.css";
import theme from "../../theme/theme";

// Action
import { logOut } from "../../redux/Actions/authAction";
import { useState } from "react";

export default function NavigtionBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo, userPermissions } = useSelector((store) => store.auth);
  const [subMenu, setSubMenu] = useState(false);
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const pathname = location.pathname;
  const wrapperRef = useRef(null);
  const { env } = useSelector((store) => store.auth);
  const { feature_list } = useSelector((store) => store.subPlan);


  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (subMenu) {
        if (
          wrapperRef.current &&
          !wrapperRef.current.contains(event.target) &&
          !event.target.closest(".user-menu-dropdown-profile")
        ) {
          setSubMenu(false);
        }
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }
  useOutsideAlerter(wrapperRef);

  const navigationPaths = [
    {
      title: "Dashboard",
      path: "/",
      icon: "/images/icons/nav-images/dashboard.png",
      activeIcon: "/images/icons/nav-images/dashboard-active.png",
      permission: true,
    },
    {
      title: "Work Diary",
      path: "/WorkDiary",
      icon: "/images/icons/nav-images/work-diary.png",
      activeIcon: "/images/icons/nav-images/work-diary-active.png",
      permission: true,
    },
    {
      title: "Resource Management",
      path: "/ResourceManagement",
      icon: "/images/icons/nav-images/resource-management.png",
      activeIcon: "/images/icons/nav-images/resource-management-active.png",
      permission: true,
    },
    {
      title: "Reports",
      path: "/Reports",
      icon: "/images/icons/nav-images/reports.png",
      activeIcon: "/images/icons/nav-images/reports-active.png",
      permission:
        userPermissions.includes("Can view all reports") ||
        userPermissions.includes(
          "Can view reports for users added by or assigned to self"
        ) ||
        userPermissions.includes("View own users attendance reports") ||
        userPermissions.includes("View all attendance reports"),
    },
    {
      title: "Leave",
      path: "/Leave",
      icon: "/images/icons/nav-images/leave.png",
      activeIcon: "/images/icons/nav-images/leave-active.png",
      permission:  
        (userPermissions.includes("View own users attendance reports") ||
          userPermissions.includes("View all attendance reports")) 
    },
    
    {
      title: "Payroll",
      path: "/Payroll",
      icon: "/images/icons/nav-images/payroll.png",
      activeIcon: "/images/icons/nav-images/payroll-active.png",
      permission:
        feature_list.includes("Payroll") &&
        (userPermissions.includes("Can view Form 16") ||userPermissions.includes("Can view payslip"))
        &&env['x-api-environment'] !== "production",
    },

    {
      title: "Settings",
      path: "/Settings",
      icon: "/images/icons/nav-images/setting.png",
      activeIcon: "/images/icons/nav-images/setting-active.png",
      permission:
        userPermissions.includes("Can view role custom") ||
        userPermissions.includes("Can add role custom") ||
        userPermissions.includes("Can change role custom") ||
        userPermissions.includes("Can delete role custom"),
    },
    {
      title: "Feedback",
      path: "/Feedback",
      icon: "/images/icons/nav-images/feedback.png",
      activeIcon: "/images/icons/nav-images/feedback-active.png",
      permission: userPermissions.includes("View custom feedback")&&env['x-api-environment'] !== "production",
    },
    {
      title: "Download",
      path: "/DownloadApplication",
      icon: "/images/icons/nav-images/download.png",
      activeIcon: "/images/icons/nav-images/download-active.png",
      permission: userPermissions.includes("Can view tracker download links"),
    },
    {
      title: "Subscription",
      path: "/Subscription",
      icon:"/images/icons/nav-images/subscription-icon.png",
      activeIcon: "/images/icons/nav-images/subscription-active.png",
      permission:( userPermissions.includes("View Subscriptions") || userPermissions.includes("View Subscription"))&&env['x-api-environment'] !== "production",
    },
  ];
  //subscription is hidden for production pls reenable it once module work is over

  const handleLogOut = async () => {
    dispatch(logOut());
    navigate("/");
  };

  const checkActivePath = (path) => {
    return pathname === path
      ? true
      : path.length > 1
      ? pathname.includes(path)
      : false;
  };
  const LogoutAlert = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Alert
        message="Logout"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        setOpenModal={setModalOpen}
        handleDelete={handleLogOut}
      />
      <div className="nav-logo">
        <img src="/images/clocksession-logo-new.png" alt="clocksession-logo" />
      </div>

      <div className="nav-menu-container">
        <div className="nav-menu-list">
          {navigationPaths.map((navigation) => (
            <>
              {navigation.permission === true && (
                <Link to={navigation.path} key={navigation.path}>
                  <div
                    className="nav-menu"
                    style={{
                      backgroundColor:
                        checkActivePath(navigation.path) &&
                        "var(--silver-phoenix)",
                      }}
                  >
                    <div
                      className="customLeftBorder"
                      style={{
                        backgroundColor:
                          checkActivePath(navigation.path) &&
                          "var(--silver-phoenix)",
                        borderLeft:
                          checkActivePath(navigation.path) &&
                          "5px solid var(--active-color)",
                      }}
                    />
                    <img
                      src={
                        checkActivePath(navigation.path)
                          ? navigation.activeIcon
                          : navigation.icon
                      }
                      alt="logo"
                    />
                    <p
                      style={{
                        color: theme.colors.black,
                        fontFamily: theme.fonts.sansSerif,
                      }}
                      className={`nav-link ${
                        checkActivePath(navigation.path) && "active-color active-font-weight"
                      }`}
                    >
                      {navigation.title}
                    </p>
                  </div>
                </Link>
              )}
            </>
          ))}
          <div className="profile-section-outer">
            <Link to="/" className="sub-nav-menu-logout" onClick={() => LogoutAlert()}>
              <img
                src="/images/icons/logout-icon.svg"
                alt="logout"
                height={theme.fontSizes.large}
                width={theme.fontSizes.large}
              />
              <span
                style={{
                  color: theme.colors.hotCortal,
                  paddingLeft: theme.fontSizes.small,
                  fontFamily: theme.fonts.sansSerif,
                }}
              >
                Logout
              </span>
            </Link>
          </div>
        </div>

        <div className="nav-copyright-container">
          <p className="label-name">ClockSession</p>
          <p>
            ©{new Date().getFullYear()} All Rights Reserved by Mechlin
            Technologies
          </p>
        </div>
      </div>
    </>
  );
}
