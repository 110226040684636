import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlanList } from "../../../redux/Actions/subscription";
import { get_active_plan } from "../../../redux/Actions/subscription";
import AvailablePlan from "./AvailablePlan";
import useSubscription from "../../../hooks/useSubscription";
import "./style.css";

const AvilableSubscription = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.auth);
  const { env } = useSelector((store) => store.auth);
  const { setCurrentPlan_Tab, currentPlan_Tab } = useSubscription();
  const [daysLeft, setDaysLeft] = useState(null);
  const { active_subscription_list } = useSelector((store) => store.subPlan);

  // Fetch subscription plans
  useEffect(() => {
    dispatch(getAllPlanList(env));
    dispatch(get_active_plan(userInfo.organisation.name));
  }, [dispatch, env]);

  // Fetch data for days left in trial
  useEffect(() => {
    if (active_subscription_list && active_subscription_list.length > 0) {
      const expiryDate = new Date(active_subscription_list[0].expiry_date);
      const currentDate = new Date();
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const daysLeft = Math.ceil((expiryDate - currentDate) / millisecondsPerDay);
      setDaysLeft(daysLeft);
    }
  }, [active_subscription_list]); // Update daysLeft whenever active_subscription_list changes

  return (
    <>
      <div className="subscription-header">
        <p style={{fontSize: "22px", fontWeight: "bold"}}>Subscription</p>
      </div>

      <div className="billing-currentplan-container">

        <div className="billing-alert">
            {daysLeft !== null && daysLeft > 0 ? (
              <>
                {daysLeft>5 ? (
                  <>
                    <span><img src="/images/icons/alert-icon.png" alt="" style={{height: '20px', marginBottom: "3px", marginRight: "10px"}}/></span>
                    <span className="days">{`${daysLeft} Days`} Left in Your Subscription. Enjoy the Services now! </span>
                  </>
                ):
                (
                  <>
                    <span><img src="/images/icons/red-alert.png" alt="" style={{height: '20px', marginBottom: "3px", marginRight: "10px"}}/></span>
                    <span className="days" style={{color: "red"}}>{`${daysLeft} Days`} Left in Your Subscription. Enjoy the Services now! </span>
                  </>
                )

                }
                

                
              </>
            ) : (
              <span className="days">Your subscription has expired. Renew now! </span>
            )}
          </div>
           <AvailablePlan />

      </div>

    </>
  );
};

export default AvilableSubscription;