import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadApplication } from "../../redux/Actions/downloadAction";
import "./style.css";
import WindowsSteps from "./WindowsStep";
import UbuntuSteps from "./UbuntuSteps";
import MacSteps from "./MacSteps";
import DownloadApplication1 from "./table";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header";
export default function DownloadApplication() {
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const { DownloadApplication } = useSelector((store) => store.downloadapp);
  const [isWindowEnabled, setIsWindowEnabled] = useState(true);
  const [isMacEnabled, setIsMacEnabled] = useState(false);
  const [isUbuntuEnabled, setIsUbuntuEnabled] = useState(false);
  const [downloadLink, setDownloadLink] = useState(
    DownloadApplication[0]?.windows_x86_64
  );
  const [windowsData, setWindowsData] = useState([]);
  const [macData, setMacData] = useState([]);
  const [linuxData, setLinuxData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const { env } = useSelector((store) => store.auth);

  

  console.log("DownloadApplication", DownloadApplication);
  console.log("windows", windowsData);
  console.log("mac", macData);
  console.log("linux", linuxData);

  const handleButtonClick = () => {
    const dataToPass = {
      data: isWindowEnabled ? windowsData : isMacEnabled ? macData : linuxData
    };
  
    navigate("/DownloadApplication/Previous-Tracker-Versions", { state: dataToPass });
  };
  // useEffect(() => {
  //   tracklist[]=
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getDownloadApplication(env));
  }, [dispatch]);
  // let filteredDownloadLinks;
  // useEffect(() => {
  //    filteredDownloadLinks = windowsData.filter(app => app.architecture === 'x64');
  //   if (filteredDownloadLinks.length > 0) {
  //     setDownloadLink(filteredDownloadLinks[0]?.file);
  //   }
  // }, [windowsData],[macData],[linuxData]);
  useEffect(() => {
    if (isWindowEnabled) {
      setDownloadLink(windowsData[0]?.file);
    } else if (isMacEnabled) {
      setDownloadLink(macData[0]?.file);
    } else if (isUbuntuEnabled) {
      setDownloadLink(linuxData[0]?.file);
    }
  }, [windowsData, macData, linuxData]);
  useEffect(() => {
    const processedData = DownloadApplication.flatMap((element) => {
      const { id, version, release_date, artifacts,is_active } = element;

      return artifacts.map((artifact, index) => ({
        id,
        sNo: artifact.id,
        version,
        release_date,
        operatingSystem: artifact.operating_system,
        file: artifact.file,
        architecture: artifact.architecture,
        is_active,
      }));
    });

    const windowsData = processedData.filter((item) => item.operatingSystem === "Windows");
    const macData = processedData.filter((item) => item.operatingSystem === "macOS");
    const linuxData = processedData.filter((item) => item.operatingSystem === "Linux");

    setWindowsData(windowsData);
    setMacData(macData);
    setLinuxData(linuxData);
    console.log("downloadapplication", DownloadApplication);
  }, [DownloadApplication]);

  const handleWindows = () => {
    setIsWindowEnabled(true);
    setIsMacEnabled(false);
    setIsUbuntuEnabled(false);
    setDownloadLink(windowsData[0]?.file);
  };

  const handleApple = () => {
    setIsWindowEnabled(false);
    setIsMacEnabled(true);
    setIsUbuntuEnabled(false);
    setDownloadLink(macData[0]?.file);
  };

  const handleUbuntu = () => {
    setIsWindowEnabled(false);
    setIsMacEnabled(false);
    setIsUbuntuEnabled(true);
    setDownloadLink(linuxData[0]?.file);
  };

  const WindowStyle = {
    border: isWindowEnabled ? "2px solid #007EC5": "2px solid #A2A2A2" 
  };

  const AppleStyle = {
    border: isMacEnabled ?  "2px solid #007EC5": "2px solid #A2A2A2"
  };

  const UbuntuStyle = {
    border: isUbuntuEnabled ? "2px solid #007EC5":  "2px solid #A2A2A2" 
  };

  React.useEffect(() => {
    setIsWindowEnabled(true);
  }, []);

  return toggle ? (
    <DownloadApplication1
      data={isWindowEnabled ? windowsData : isMacEnabled ? macData : linuxData}
    />
  ) : (
    <div style={{ border: "0px solid black", backgroundColor: "#F2F5FA" }}>
      <div className="dashboard-header">
        <Header title="Download Application" />
      </div>
      <div className="download-content">
        <div className="container" style={{padding: "0 25px", border: "2px solid #E8E8E8", borderRadius: "4px", minHeight: "100vh"}}>
          <div>
            <div>
              <div className="commbox-container d-flex">
                <div
                  onClick={handleWindows}
                  className="common-box  text-center"
                  style={WindowStyle}
                >
                  <img className="w-img" src="/images/windows.png" alt="" />
                  <p >For Windows</p>

                  <div className="Download File">
                    <a href={downloadLink} className="btn btn-success">
                      Download
                    </a>
                  </div>

                </div>
                <div
                  onClick={handleApple}
                  className="common-box text-center"
                  style={AppleStyle}
                >
                  <img className="ap-img" src="/images/apple.png" alt="" />
                  <p >For Mac OS</p>

                  <div className="Download File">
                    <a href={downloadLink} className="btn btn-success">
                      Download
                    </a>
                  </div>
                </div>
                <div
                  onClick={handleUbuntu}
                  className="common-box  text-center"
                  style={UbuntuStyle}
                >
                  <img className="u-img" src="/images/ubuntu.png" alt="" />
                  <p >For Ubuntu</p>

                  <div className="Download File">
                    <a href={downloadLink} className="btn btn-success">
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row123">
            <div className="Download File">
              <a href={downloadLink} className="btn btn-success">
                Download File
                <span className="d-btn">
                  <img src="/images/download.png" alt="" />
                </span>
              </a>
            </div>
            <div className="all_tracker">
              <a
                className="btn btn-success"
                style={{ backgroundColor: 'red' }}
                onClick={handleButtonClick}
              >
                Previous Version
                <span className="d-btn">
                  <img src="/images/download.png" alt="" />
                </span>
              </a>
            </div>
          </div> */}

          {console.log("toggle", toggle)}
          <div className="row" style={{padding: "0px 12px"}}>
            <div className="col-sm-12">
              {isWindowEnabled && <WindowsSteps />}
              {isMacEnabled && <MacSteps />}
              {isUbuntuEnabled && <UbuntuSteps />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
