import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

import {
  ExportHolidayLeave,
  addPublicHoliday,
  deleteHoliday,
  getPublicHoliday,
  updateHoliday,
} from "../redux/Actions/leaveAction";

export default function usePublicHoliday() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((store) => store.auth);
  const { publicHolidayList } = useSelector((store) => store.leave);
  const [inProgress, setInProgress] = useState(true);
  const [weekDate, setWeekDate] = useState(new Date());
  const [startWeekDay, setStartWeekDay] = useState("");
  const [endWeekDay, setEndWeekDay] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startMonth, setStartMonth] = useState(new Date());
  const [dateRangeStart, setDateRangeStart] = useState();
  const [dateRangeEnd, setDateRangeEnd] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedYear, setselectedYear] = useState(moment());
  const currentYear = moment().year();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const [exportState, setExportState] = useState(false);


  const itemsPerPage = 5;
  const wrapperRef = useRef(null);
  const calendarRef = useRef(null);
  const [pageCounts, setPageCounts] = useState(1);
  const [currentTab, setCurrentTab] = useState("daily");
  const [modalOpen, setModalOpen] = useState(false);
  const [holidayName, setHolidayName] = useState("");
  const [editId, setEditId] = useState();

  useEffect(() => {
    dispatch(
      getPublicHoliday({
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
        selectedYear,
      })
    );
  }, [setInProgress, currentPage, itemsPerPage, setPageCounts,selectedYear]);

  function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  function getDateOfWeek(weekNumber, year) {
    //Create a date object starting january first of chosen year, plus the number of days in a week multiplied by the week number to get the right date.
    return new Date(year, 0, 1 + (weekNumber - 1) * 7);
  }
  function getFirstDateOfWeeks(weekNumber, year) {
    // Get the first day of the year
    var firstDayOfYear = new Date(year, 0, 1);
    // Get the first Monday of the year
    var firstMondayOfYear = new Date(
      year,
      0,
      1 + ((8 - firstDayOfYear.getDay()) % 7)
    );

    // Get the date of the Monday of the requested week
    var dateOfFirstMondayOfWeek = new Date(
      firstMondayOfYear.getFullYear(),
      0,
      firstMondayOfYear.getDate() + (weekNumber - 1) * 7
    );

    // Return the date of the first Monday of the requested week
    return dateOfFirstMondayOfWeek;
  }
  function getLastDateOfWeek(weekNumber, year) {
    const date = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const lastDayOfWeek = date.getDate() + 7;
    const lastDateOfWeek = new Date(date.setDate(lastDayOfWeek));
    return lastDateOfWeek;
  }

  useEffect(() => {
    var curr = new Date(weekDate); // get current date
    var result = getWeekNumber(weekDate); // get week no and year [year, weekno]
    var first_day = getFirstDateOfWeeks(result[1], result[0]);

    var last_day = getLastDateOfWeek(result[1], result[0]);

    console.log("week dates", {
      curr,
      weekDate,
      first_day,
      last_day,
      date: new Date(),
      result,
    });
    setStartWeekDay(first_day);
    setEndWeekDay(last_day);
  }, [weekDate]);

  useEffect(() => {
    let data;
    let endDate = new Date();

    switch (currentTab) {
      case "daily":
        data = {
          report_type: currentTab,
          start_date:
            moment(startDate).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(endDate).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      case "weekly":
        data = {
          report_type: currentTab,
          start_date:
            moment(startWeekDay).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(endWeekDay).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };

        break;
      case "monthly":
        data = {
          report_type: currentTab,
          start_date:
            moment(startMonth).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(endDate).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };

        break;
      case "dateRange":
        data = {
          report_type: currentTab,
          start_date:
            moment(dateRangeStart).format("YYYY-MM-DD") +
            "T00:00:00" +
            moment().format("Z"),
          end_date:
            moment(dateRangeEnd).format("YYYY-MM-DD") +
            "T23:59:59" +
            moment().format("Z"),
        };
        break;
      default: {
        data = {
          report_type: currentTab,
          start_date: startDate,
          end_date: endDate,
        };
      }
    }
  }, [dispatch, startDate, currentTab, startMonth, currentPage]);

  const handleDecrease = () => {
    if (currentTab === "daily") {
      const Decrement = startDate;
      Decrement.setDate(Decrement.getDate() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }

    if (currentTab === "weekly") {
      var curr = weekDate;
      var result = getWeekNumber(curr);

      var first = getDateOfWeek(result[1] - 1, result[0]).getDate(); // get first day of the week

      var firstdayD = new Date(curr.setDate(first));

      console.log("week decrement", {
        dN: getDateOfWeek(result[1] - 1, result[0]).getDate(),
        d: getDateOfWeek(result[1] - 1, result[0]),
        result,
        weekDate,
        firstdayD,
        reqW: result[1] - 1,
      });
      setWeekDate(firstdayD);
    }

    if (currentTab === "monthly") {
      const Decrement = startMonth;
      Decrement.setMonth(Decrement.getMonth() - 1);
      const output = Decrement.toLocaleString();
      const op = moment(output).format(
        "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
      );
      setStartMonth(new Date(op));
    }
  };

  const handleIncrease = () => {
    if (currentTab === "daily") {
      const Increment = startDate;
      Increment.setDate(Increment.getDate() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format("ddd MMM DD yyyy HH:mm:ss");
      setStartDate(new Date(op));
    }

    if (currentTab === "weekly") {
      var result = getWeekNumber(weekDate);

      var first = getDateOfWeek(result[1] + 1, result[0]).getDate(); // get first day of the week

      var firstdayD = new Date(weekDate.setDate(first + 2));
      console.log("week Inc", {
        firstdayD,
        dN: getDateOfWeek(result[1] + 1, result[0]).getDate(),
        d: getDateOfWeek(result[1] + 1, result[0]),
        result,
        reqW: result[1] + 1,
        weekDate,
      });
      setWeekDate(firstdayD);
    }

    if (currentTab === "monthly") {
      const Increment = startMonth;
      Increment.setMonth(Increment.getMonth() + 1);
      const output = Increment.toLocaleString();
      const op = moment(output).format(
        "ddd MMM DD yyyy HH:mm:ss [GMT+0530 (India Standard Time)]"
      );
      setStartMonth(new Date(op));
    }
  };
  const cancleModal = () => {
    console.log("cancleModal");
    setHolidayName("");
    setEditId("");
    setStartDate(new Date());
    setModalOpen(false);
  };
  const callPublicLeaveList = useCallback(() => {
    return dispatch(
      getPublicHoliday({
        setInProgress,
        currentPage,
        itemsPerPage,
        setPageCounts,
        selectedYear,
      })
    );
  }, [setInProgress, currentPage, itemsPerPage, setPageCounts,selectedYear]);

  const handleSubmit = () => {
    let data = {
      date: moment(startDate).format("YYYY-MM-DD"),
      name: holidayName,
    };

    if (editId) {
      dispatch(updateHoliday(editId, data, cancleModal, callPublicLeaveList));
    } 
    else {
      console.log("holidaylist",publicHolidayList);
      const isHolidayAlreadyAdded = publicHolidayList.some(
        (item) => item.date === data.date
      );
  
      if (isHolidayAlreadyAdded) {
        toast.warn("Holiday already added for this date");
        return;
      }
      dispatch(addPublicHoliday(data, cancleModal, callPublicLeaveList));
    }
  };

  const removeHoliday = (data) => {
    dispatch(deleteHoliday(data, callPublicLeaveList));
  };

  const editPublicHoliday = (data) => {
    if (data.id) {
      setStartDate(new Date(data.date));
      setHolidayName(data.name);
      setEditId(data.id);
      setModalOpen(true);
    }
  };

  // const updateHolidayLeave = () => {
  //   let data = {
  //     date: moment(startDate).format("YYYY-MM-DD"),
  //     name: holidayName,
  //   };
  //   if (editId) {
  //     dispatch(updateHoliday(editId, data, cancleModal, callPublicLeaveList));
  //   }
  // };

  const handleDecrease_year = () => {
    setselectedYear(selectedYear.clone().subtract(1, "year"));
  };

  const handleIncrease_year = () => {
    const nextMonth = selectedYear.clone().add(1, "year");
    const nextYear = nextMonth.year();
    setselectedYear(nextMonth);

    // if (nextYear <= currentYear) {
    //   setselectedYear(nextMonth);
    // } else {
    //   setIsNextButtonDisabled(true);
    // }
  };
  const handleHolidayExport = (selectedYear) => {
    dispatch(ExportHolidayLeave(setExportState, selectedYear));
  }
  return {
    publicHolidayList,
    currentPage,
    setCurrentPage,
    pageCounts,
    userInfo,
    currentTab,
    handleDecrease,
    calendarRef,
    startMonth,
    setStartMonth,
    calendarIsOpen,
    handleIncrease,
    startDate,
    setCalendarIsOpen,
    wrapperRef,
    setModalOpen,
    setStartDate,
    handleSubmit,
    modalOpen,
    setHolidayName,
    holidayName,
    removeHoliday,
    editPublicHoliday,
    cancleModal,
    calendarOpen,
    setCalendarOpen,
    handleDecrease_year,
    handleIncrease_year,
    selectedYear,
    inProgress, 
    setInProgress,
    exportState,
    handleHolidayExport,
    editId
  };
}
