import React from "react";
import "./style.css";
import theme from "../../../theme/theme";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50% ,-50%)",
  background: "white",
  zIndex: 1000,
  width: "550px",
  height: "230px",
  borderRadius: "5px",
};

const OVERLAY_STYlES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};

const clbtnStyle = {
  backgroundColor: "transparent",
  border: "none",
  cursor: " pointer",
  // padding: "16px 30px",
};
const footerbtnStyle = {
  width: "125px",
  height: "45px",
  margin: "10px",
  border: "none",
  color: " white",
  borderRadius: " 8px",
  fontSize: "16px",
  cursor: "pointer",
};

export default function Alert({
  open,
  onClose,
  children,
  setOpenModal,
  id,
  handleDelete,
  message,
}) {
  if (!open) return null;
  return (
    <>
      <div style={OVERLAY_STYlES} />
      <div class="modal-main" style={MODAL_STYLES}>

        <div className="logout-confirm-modal-header" >
          <p style={{fontSize: "22px"}}>{message}</p>
          <button
            style={clbtnStyle}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <img
              height="20px"
              width="20px"
              src="/images/icons/Icon - Close.png"
              alt="Project-info-icon"
            />
          </button>
        </div>

        <div style={{ marginTop: "7%" }}>
          <h3 style={{ textAlign: "center", fontSize: "28px"}}>
            Are you sure you want to {message}?
          </h3>
        </div>

        <div style={{ textAlignLast: "center", marginTop: "25px",fontWeight: theme.fontWeights.fontWeight600}}>
          <button
            class="yes-btn"
            style={{ ...footerbtnStyle, backgroundColor: "#007EC5" }}
            onClick={() => {
              handleDelete(id);
            }}
          >
            Yes{" "}
          </button>

          <button
            class="no-btn"
            style={{ ...footerbtnStyle, backgroundColor: "white", color: "#007EC5", border: "2px solid #007EC5" }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            No
          </button>

        </div>
        {children}
      </div>
    </>
  );
}
