import React, { useState } from 'react';

const Offer_Reject = () => {
  const [reason, setReason] = useState('');
  const [improvements, setImprovements] = useState('');
  const [reconsider, setReconsider] = useState(false);
  const [additionalComments, setAdditionalComments] = useState('');
  const [specificReasons, setSpecificReasons] = useState('');

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleImprovementsChange = (event) => {
    setImprovements(event.target.value);
  };

  const handleReconsiderChange = (event) => {
    setReconsider(event.target.checked);
  };

  const handleAdditionalCommentsChange = (event) => {
    setAdditionalComments(event.target.value);
  };

  const handleSpecificReasonsChange = (event) => {
    setSpecificReasons(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here you can send the feedback data to your backend or perform any necessary actions
    console.log({
      reason,
      improvements,
      reconsider,
      additionalComments,
      specificReasons
    });
    // You can add further logic here, such as displaying a confirmation message
  };

  return (
    <div className="feedback-form123">
      <h2>We're sorry to hear that you've decided not to accept our job offer.</h2>
      <p>We understand that making a decision about a job offer is significant, and we respect your choice not to accept ours. Your feedback is incredibly important to us as it helps us improve our processes and better understand the needs of our candidates.

We genuinely value your time and effort spent considering this opportunity, and we want to ensure that your experience with us is as positive as possible. Your insights can guide us in refining our approach and making meaningful changes to better meet the expectations of our applicants.

Please take a moment to share your thoughts with us. We're here to listen and learn from your perspective.

Thank you for considering us, and we wish you the best in your future endeavors..</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group123">
          <label htmlFor="reason123">Reason for declining the offer:</label>
          <select id="reason123" value={reason} onChange={handleReasonChange}>
            <option value="">Select a reason</option>
            <option value="Compensation package">Compensation package</option>
            <option value="Location">Location</option>
            <option value="Company culture fit">Company culture fit</option>
            <option value="Job responsibilities">Job responsibilities</option>
            <option value="Career growth opportunities">Career growth opportunities</option>
            <option value="Other">Other</option>
          </select>
          {reason === 'Other' && <input type="text" placeholder="Please specify" />}
        </div>
        <div className="form-group123">
          <label htmlFor="improvements123">How could we improve our offer or process to better meet your needs?</label>
          <textarea id="improvements123" value={improvements} onChange={handleImprovementsChange} />
        </div>
        {/* <div className="form-group123">
          <label>
            Would you be open to reconsidering your decision if we were to address your concerns?
            <input type="checkbox" checked={reconsider} onChange={handleReconsiderChange} />
          </label>
        </div> */}
        <div className="form-group123">
          <label htmlFor="additionalComments123">Additional Comments (Optional):</label>
          <textarea id="additionalComments123" value={additionalComments} onChange={handleAdditionalCommentsChange} />
        </div>
        <div className="form-group123">
          <label htmlFor="specific_reasons123">Were there any specific aspects of the role or company culture that did not align with your expectations?:</label>
          <textarea id="specific_reasons123" value={specificReasons} onChange={handleSpecificReasonsChange} />
        </div>
        <button type="submit" onClick={handleSubmit}>Submit Feedback</button>
      </form>
      <style>
        {`
          .feedback-form {
            max-width: 500px;
            margin: 0 auto;
            padding: 20px;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            border-radius: 5px;
          }
          
          .form-group {
            margin-bottom: 15px;
          }
          
          label {
            display: block;
            font-weight: bold;
          }
          
          select,
          textarea {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
            margin-top: 4px;
            margin-bottom: 10px;
          }
          
          input[type="text"],
          textarea {
            resize: vertical;
          }
          
          button {
            background-color: #4caf50;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          
          button:hover {
            background-color: #45a049;
          }
          
          button[type="submit"] {
            width: 100%;
          }
          
          button[type="submit"]:hover {
            background-color: #45a049;
          }
          
        `}
      </style>
    </div>
  );
};

export default Offer_Reject;
