import { React, useState } from "react"; 
import Modal from "../common/Modal";
import "./style.css";
import { DatePickerSingle } from "../common/DatePickers";

const ApplyPublicHoliday = ({ open, onClose, hookProps }) => {
  const {
    startDate,
    currentTab,
    calendarRef,
    wrapperRef,
    calendarIsOpen,
    setCalendarIsOpen,
    setStartDate,
    handleSubmit,
    setHolidayName,
    holidayName,
    handleIncrease,
    handleDecrease,
    editId
  } = hookProps;

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="holiday-edit-apply-container">
          <div className="edit-apply-holiday-header">
            <div>

              {editId? (
                <h4>Edit Holiday</h4>
              ) : (
                <h4>Add New Holiday</h4>
              )}


            </div>

            <div className="">
              <button className="close-btn" onClick={() => onClose()}>
                <img
                  height="25px"
                  width="25px"
                  src="/images/icons/Icon - Close.png"
                  alt="Project-info-icon"
                />
              </button>
            </div>
          </div>

          <div className="mb-2 mt-1">
            <label for="u-access" className="col-sm-4 col-form-label">
              Name of Holiday
            </label>
            <div className="">
              <input
                type="text"
                id="p-name"
                className="form-control"
                placeholder="Enter Holiday Name Here..."
                value={holidayName}
                onChange={(e) => setHolidayName(e.target.value)}
                required
                style={{fontSize: "15px"}}
              />
            </div>
          </div>

          <div className="mb-3">
            <label for="u-name" className="col-sm-4 col-form-label">
              Date of Holiday
            </label>

            <div className="">
              <div className="holiday-calendar-timeline modal-calander">
                <div style={{display:"flex", justifyContent: "center",alignItems: "center"}}>
                  <img
                    src="/images/icons/Icon - Calender.png"
                    alt="calendar"
                    onClick={() => setCalendarIsOpen(!calendarIsOpen)}
                    ref={wrapperRef}
                  />

                  {currentTab === "daily" && (
                    <DatePickerSingle
                      startDate={startDate}
                      ref={calendarRef}
                      setStartDate={setStartDate}
                      calendarIsOpen={calendarIsOpen}
                      showMaxDate={true}
                      setCalendarIsOpen={setCalendarIsOpen}
                    />
                  )}
                </div>

                <div style={{display: "flex"}}>
                    <button
                      className="prev-month"
                      onClick={() => {
                        handleDecrease();
                      }}
                    ></button>

                    <button
                      className="next-month"
                      onClick={() => {
                        handleIncrease();
                      }}
                    ></button>
                  
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-2 align-self-center">
            <div className="col-sm-9 offset-sm-3">
              <div className="modal-btn">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{marginLeft: "20px"}}
                  onClick={handleSubmit}
                >
                  {holidayName === "" ? 'Add' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ApplyPublicHoliday;
