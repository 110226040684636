import React from "react";
import Modal from "../common/Modal";
import "./style.css";

const functionTypeLabels = {
  "All permission": "Save",
  // "define permission": "Save and open permission",
  "define permission": "Save",
};

const AddRole = ({ open, onClose, hookProps }) => {
  const {
    roleValue,
    handleChange,
    selectedFunction,
    handleFunctionChange,
    handleSave,
    editId,
    nameError,
    descriptionError,
    userPermissions
  } = hookProps;

  const renderRadioButtons = () => {
    return Object.keys(functionTypeLabels).map((type) => (
      <div key={type}>
        <input
          type="radio"
          id={`option-${type}`}
          name="functionType"
          value={type}
          className="custom-radio"
          checked={roleValue.selectedFunction === type} // Use roleValue.selectedFunction
          onChange={handleFunctionChange}
        />
        <label htmlFor={`option-${type}`} className="custom-label">
          {type}
        </label>
      </div>
    ));
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="role-modal-container">
          <div
            className="header"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="modal-header">
              {editId?(<h3>Edit Role</h3>):(<h3>Add Roles</h3>)}
              
            </div>

            <div className="cross-icon">
              <button className="close-btn" onClick={onClose}>
                <img
                  height="25px"
                  width="25px"
                  src="/images/close.png"
                  alt="Project-info-icon"
                />
              </button>
            </div>
          </div>

          <div className="row mb-2">
            <label htmlFor="p-name" className="col-sm-3 col-form-label">
              Name:
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                id="p-name"
                name="name"
                className="form-control"
                placeholder="Name"
                value={roleValue?.name}
                onChange={handleChange}
               
              />
              {editId?(null):(<div className="error-message">{nameError}</div>)} 
            </div>
          </div>

          <div className="row mb-2">
            <label htmlFor="p-description" className="col-sm-3 col-form-label">
              Description:
            </label>
            <div className="col-sm-9">
              <textarea
                rows="4"
                cols="40"
                className="form-control"
                placeholder="Description"
                name="description"
                value={roleValue.description}
                onChange={handleChange}
              
              />
            {editId?(null):(<div className="error-message">{descriptionError}</div>)} 


            </div>
          </div>
        
          <div className="row mb-2">
          <label className="col-sm-3 col-form-label">Function Type:</label>
          <div className="col-sm-9">{renderRadioButtons()}</div>
           </div>


          <div className="row">
            <div className="col-sm-9 offset-sm-3 d-flex justify-content-end">
              <div className="modal-btn">
                <button onClick={onClose}
                  type="button"
                  className="btn custom-btn btn-secondary"
                  style={{ marginRight: "10px" }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn custom-btn btn-primary"
                  onClick={handleSave}
                >
                  {functionTypeLabels[selectedFunction]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddRole;
