import {
    WORKING_HOUR_LIST,ACTIVITY_BAR,
    } from "../Actions/workinghoursAction";

  const initialState = {
    workinghoursList: [],
    save:[],
    activityProgressBar:[],
  };

  export default function workingHourReducer(store = initialState, action) {
    switch (action.type) {
      case WORKING_HOUR_LIST: {
        return {
          ...store,
          workinghoursList: action.payload,
        };
      }
      case ACTIVITY_BAR: {
        return {
          ...store,
          activityProgressBar: action.payload,
        };
      }
      
      default: {
        return { ...store };
      }
    }
  }
  