import React from "react";
import Tooltip from "../ToolTip";
import { useSelector } from "react-redux";
import { RenderPageButtons } from "../../Utils/randerPageButtons";
import moment from "moment";
import Pagination from "../common/Pagination/pagination";

export default function PublicHolidayTable({ hookProps }) {
  const { userInfo } = useSelector((store) => store.auth);
  const {
    publicHolidayList,
    currentPage,
    setCurrentPage,
    pageCounts,
    removeHoliday,
    editPublicHoliday,
    inProgress,

  } = hookProps;

  const pages = [];
 const { userPermissions } = useSelector((store) => store.auth);
  for (let i = 1; i <= pageCounts; i++) {
    pages.push(i);
  }

  const renderPageNumbers = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );

  return (
    <div className="projects-table remove-border" style={{padding: "0px 17px"}}>
      <div className="projects-table-header"></div>

      <div className="projects-table-body" id="public-holiday-table-body">
        <table>
          <tr>
            <th>Holiday</th>
            <th>Date</th>
            <th>Weekdays</th>
            {userPermissions.includes("Can change public holiday") ||
             userPermissions.includes("Can delete public holiday") ? (
           <th>Action</th>
         ) : null}
          </tr>
          {inProgress ? (
  <tr>
    <td colSpan="5">Loading...</td>
  </tr>
) : (
  publicHolidayList?.map((leave, index) => (
    <tr key={leave.id} className="holiday-table-row">

      <td>
        <div className="no-wrap">{((currentPage-1)*10) + index + 1}. &nbsp;&nbsp; {leave.name}</div>
        <div className="to-hide-left-border"></div>
      </td>

      <td>
        <div className="no-wrap">
          {/* {moment(leave.date).format("ddd, D MMMM YYYY")} */}
          {moment(leave.date).format("MMMM D, YYYY")}
        </div>
      </td>
      <td>
        <div className="no-wrap">{leave.day}</div>
      </td>

      <td>
        <div className="table-actions">
          {userPermissions.includes("Can change public holiday") && (
            <Tooltip position="top" title="Edit Public Holiday">
              <button>
                <img
                  src="/images/icons/edit-green.png"
                  alt="edit"
                  onClick={() => editPublicHoliday(leave)}
                  style={{width: "27px"}}
                />
              </button>
            </Tooltip>
          )}
          {userPermissions.includes("Can delete public holiday") && (
            <Tooltip position="top" title="Remove Public Holiday">
              <button onClick={() => removeHoliday(leave.id)}>
                <img src="/images/icons/delete.png" alt="delete" style={{width: "20px", height: "25px"}}/>
              </button>
            </Tooltip>
            
            
        )}
        </div>
      </td>
    </tr>
  ))
)}

        </table>
      </div>

      {pageCounts>1&&(
           <Pagination
           pages={pages}
           currentPage={currentPage}
           setCurrentPage={setCurrentPage}
         />
      )}

      {/* {pageCounts>1&&(
               <div className="projects-table-footer">
               <div className="table-pagination">
                 <button
                   className="prev-scr"
                   onClick={() => setCurrentPage(currentPage - 1)}
                   disabled={currentPage === pages[0] ? true : false}
                 >
                   {"PREV"}
                 </button>
                 {renderPageNumbers}
                 <button
                   className="next-scr"
                   onClick={() => setCurrentPage(currentPage + 1)}
                   disabled={currentPage === pages[pages.length - 1] ? true : false}
                 >
                   {"NEXT"}
                 </button>
               </div>
             </div>
          )} */}
     
    </div>
  );
}
