import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";

import AuthenticationLayout from "../pages/Authentication/AuthenticationLayout";
import Login from "../pages/Authentication/Login";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ContactUs from "../pages/LandingPage/ContactUs";
import Blog from "../pages/LandingPage/Blog";
import Impersonation from "../pages/Authentication/Impersonation";
import Offer_Reject from "../pages/OfferLetter/offer_reject";
import EXPIRE_LINK from "../pages/Authentication/PasswordLinkExpire";


import AboutUs from "../pages/LandingPage/AboutUs";
import BlogDetails from "../pages/LandingPage/BlogDetails";
import Faq from "../pages/LandingPage/Faq";
import { PlanExpoiredScreen } from "../components/Subscription/Plan/PlanExpiration";
import AvailablePlan from "../components/Subscription/Plan/AvailablePlan";
export default function UnAuthenticatedRoutes() {
  console.log("unauth");
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<LandingPage />} />
        <Route path="/Contact" element={<ContactUs />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Blog" element={<Blog/>}/>
        <Route path="/BlogDetails" element={<BlogDetails/>}/>
        <Route path="/Faq" element={<Faq />} /> */}


        <Route
          path="/"
          element={<AuthenticationLayout component={<Login />} />}
        />
       
       <Route
          path="/LinkExpire"
          element={<AuthenticationLayout component={<EXPIRE_LINK />} />}
        />
        <Route
          path="/ForgotPassword"
          element={<AuthenticationLayout component={<ForgotPassword />} />}
        />
        <Route
          path="/ResetPassword/:token"
          element={<AuthenticationLayout component={<ResetPassword />} />}

        />
         <Route
        path="/impersonation/login/:token"
        element={
          <AuthenticationLayout component={<Impersonation/>} />
        }
      />
        <Route
          path="/RejectOffer"
          element={<AuthenticationLayout component={<Offer_Reject />} />}

        />
        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
}
