import {
  LEAVE_TRANSACTION_LIST,
  LEAVE_TRANSACTION_LIST_SORTED,
  PUBLIC_HOLIDAY_LIST,
  Leave_Allotment_List,
  LEAVE_TYPE_LIST,
  LEAVE_TRANSACTION_FILTER_LIST,
  LEAVE_TRANSACTION_FILTER_SORTED_LIST,
  ALL_LEAVE_USER,
  APPLIED_LEAVES,
  LEAVE_COUNT,
} from "../Actions/leaveAction";


const initialState = {
  leaveTransactionList: [],
  leaveTransactionListSorted: [],
  leaveTransactionFilterList:[],
  leaveTransactionFilterSortedList:[],
  publicHolidayList: [],
  leaveAllotmentList: [],
  leaveTypeList: [],
  leaveUserList:[],
  appliedLeaves:[],
  leaveCount:[],

};

const leaveReduser = (store = initialState, action) => {
  switch (action.type) {
    case LEAVE_TRANSACTION_LIST: {
      return {
        ...store,
        leaveTransactionList: action.payload,
      };
    }
    case LEAVE_TRANSACTION_LIST_SORTED: {
      return {
        ...store,
        leaveTransactionListSorted: action.payload,
      };
    }
    case APPLIED_LEAVES: {
      return {
        ...store,
        appliedLeaves: action.payload,
      };
    }
    case LEAVE_TRANSACTION_FILTER_LIST: {
      return {
        ...store,
        leaveTransactionFilterList: action.payload,
      };
    }
    case LEAVE_COUNT: {
      return {
        ...store,
        leaveCount: action.payload,
      };
    }
    case LEAVE_TRANSACTION_FILTER_SORTED_LIST: {
      return {
        ...store,
        leaveTransactionFilterSortedList: action.payload,
      };
    }
    case  Leave_Allotment_List: {
      return {
        ...store,
        leaveAllotmentList: action.payload,
      };
    }
    case PUBLIC_HOLIDAY_LIST: {
      return {
        ...store,
        publicHolidayList: action.payload,
      };
    }
    case ALL_LEAVE_USER: {
      return {
        ...store,
        leaveUserList: action.payload,
      };
    }
    case LEAVE_TYPE_LIST: {
      return {
        ...store,
        leaveTypeList: action.payload,
      };
    }
    default: {
      return { ...store };
    }
  }
};

export default leaveReduser;
