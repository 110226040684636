import React from "react";

const PrivacyTermComp = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="privacy-terms">
        <span>Privacy Policy</span>
        <span></span>
        <span>Terms of Service</span>
      </div>
    </div>
  );
};

export default PrivacyTermComp;
