import React, { useState } from "react";
import Desktopapp from "../../LandingPage/Body/DesktopApp";
import Foot from "../Layout/Footer";
import Header from "../Layout/Header";
import { useDispatch } from "react-redux";
import { contectUsApi } from "../../../redux/Actions/authAction";
import "./style.css";

const Contact = () => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [contactdata, setContactData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setContactData({ ...contactdata, [e.target.name]: e.target.value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowError(true);
    if (
      contactdata.name.length > 0 &&
      contactdata.email.length > 0 &&
      contactdata.message.length > 0
    ) {
      dispatch(contectUsApi(contactdata));
      setShowError(false);
      setContactData({
        name: "",
        email: "",
        message: "",
      });
    } else {
    }
  };
  return (
    <>
      <Header />
      <div className="contact-header contact-heading text-center ">
        ContactUs
      </div>
      <div className="container pb-5">
        <div className="row">
          <div className="col-sm-6 left-content">
            <h3 className="heading1">Send us a message</h3>
            <div className="mb-3">
              <input
                type="text"
                style={{
                  borderColor:
                    showError && contactdata.name.length <= 0 ? "red" : null,
                }}
                name="name"
                value={contactdata.name}
                onChange={(e) => handleChange(e)}
                className="form-control"
                id="name"
                placeholder="Your name"
              />
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                style={{
                  borderColor:
                    showError && contactdata.email.length <= 0 ? "red" : null,
                }}
                value={contactdata.email}
                onChange={(e) => handleChange(e)}
                className="form-control"
                id="email"
                placeholder="Your email id"
              />
            </div>

            <div className="mb-3">
              <textarea
                name="message"
                style={{
                  borderColor:
                    showError && contactdata.message.length <= 0 ? "red" : null,
                }}
                value={contactdata.message}
                onChange={(e) => handleChange(e)}
                className="form-control"
                id="msg"
                placeholder="Message"
              />
            </div>

            <button
              type="submit"
              onClick={handleSave}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
          <div className="col-sm-6 right-content">
            <h3 className="heading1">Let’s connect</h3>
            <p className="mb-4">
              Reach new levels of productivity! Do you have any pricing-related
              questions? Need help or advice on evaluating our product before
              you buy? If yes, connect with us as we are available 24/7 to
              provide hassle-free service.
            </p>
            <a
              className="btn btn-warning btn-email"
              href="mailto: support@clocksession.com"
            >
              support@clocksession.com
            </a>
            <br />
            <br />{" "}
            {/* <button type="submit" className="btn btn-primary phone">
              +91-1233455556
            </button> */}
            <a className="btn btn-primary phone" href="tel:  +1-805 776 3451">
            +1-805 776 3451
            </a>
          </div>
        </div>
      </div>

      <Desktopapp />
      <Foot />
    </>
  );
};
export default  Contact;
