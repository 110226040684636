import React, { useState } from "react";

import { Outlet, useNavigate, useLocation } from "react-router-dom";

export default function ORGANIZATION_SETTING() {
  const [activeTab, setActiveTab] = useState("Leave Management");
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const resourcePath = [
    {
      title: "Leave Management",
      link: "/Settings/leave",
    },
    {
      title: "Leave Type",
      link: "/Settings/leave/leave_type",
    },
    {
      title: "Holiday",
      link: "/Settings/leave/holiday",
    },
  ];

  return (
       <div>
       <div className="leave-tab-container-header">
          {resourcePath.map(path => (
            <div
              className={`tab-button ${pathname === path.link && "active-tab"}`}
              key={path.title}
              onClick={() => navigate(path.link)}
            >
              <p style={{fontSize:"15px"}}>{path.title}</p>
            </div>
          ))}
        </div>

      <Outlet />
      </div>
  );
}
