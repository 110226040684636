import React from "react";
import Modal from "../common/Modal";
import "./style.css";
import { useState } from "react";
import { toast } from "react-toastify";
const EditLeaveDays = ({ open, onClose, days, handleSave  }) => {
  const [newDays, setNewDays] = useState(days);
  const handleChange = (e) => {
    setNewDays(e.target.value); // Update the new days value
  };
  const handleSaveClick = () => {
    handleSave(newDays); 
    toast.success("Updated");
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div className="leave-container">
          <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="modal-header">
              <h3>Edit Days</h3>
            </div>
            
          </div>

          <div className="row mb-2">
            <label htmlFor="p-days" className="col-sm-3 col-form-label">
              Days:
            </label>
            <div className="col-sm-9">
              <input
                type="number"
                id="p-days"
                name="days"
                className="form-control"
                placeholder="Days"
                value={newDays}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-3 offset-sm-3 d-flex justify-content-end">
              <div className="modal-btn">
               
                <button
                  type="button"
                  className="btn custom-btn btn-primary"
                  onClick={handleSaveClick} 
                >
                  Save
                </button>
                <button
                  onClick={onClose}
                  type="button"
                  className="btn custom-btn btn-secondary"
                  style={{ marginRight: "10px" }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditLeaveDays;
