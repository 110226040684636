import React from "react";
import "./styles.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Header from "../../components/common/Header";



export default function ResourceManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userPermissions } = useSelector((store) => store.auth);
  const { feature_list } = useSelector((store) => store.subPlan);



  const array = [
    {
      title: "Screenshots",
      link: "/WorkDiary",
      permission: (userPermissions.includes("View own users' screenshots/webcam")||
      userPermissions.includes("View all users' screenshots/webcam"))&&feature_list.includes("Screenshot"),
    },

    {
      title: "Review Manual Time",
      link: "/WorkDiary/reviewmanual",
      permission:false,
    },
  ];
  
  
  const resourcePath = array.filter(item => item.permission);
  
  useEffect(() => {
    // Check if the current path is accessible; if not, find the first accessible path
    const currentTab = resourcePath.find((path) => pathname === path.link);
    if (!currentTab || !currentTab.permission) {
      const defaultTab = resourcePath.find((path) => path.permission);
      if (defaultTab) {
        navigate(defaultTab.link);
      }
    }
  }, [pathname, navigate, resourcePath]);


  console.log("location", location);
  return (
    <div style={{backgroundColor: "#F2F5FA"}}>
      <div className="resource-header">
      <div className="dashboard-header">
      <Header title='Work Diary' style={{ fontWeight: 1500 }} />
      </div>
        <div className="resource-tab-container" style={{paddingTop: "10px"}}>
          {resourcePath.map((path) => (
           <div
           className={`tab-button ${pathname === path.link ? "active-tab" : ""}`}
           style={{ borderBottom: '0px solid ', marginLeft: '2%' }}
           key={path.title}
           onClick={() => navigate(path.link)}
         >
{/* <p style={{ textDecoration: 'underline', color: path.titleColor, textDecorationThickness: '4px',marginTop:"10px",marginBottom:"10px"}}>{path.title}</p> */}
<p className="screenshot-nav-header">{path.title}</p>
         </div>
          ))}
        </div>
      </div>

      <div className="resource-context-container" style={{ border: '0px solid red' , marginLeft: '2%',marginRight: '2%', backgroundColor: '#FFFFFF',paddingTop:"6px", border: "2px solid #E8E8E8", borderRadius: "6px" }}>        <Outlet />
      </div>
    </div>
  );
}
