import React, { useState } from "react";
import moment from "moment";
import { RenderPageButtons } from "../../../Utils/randerPageButtons";

const EmpTableRow = ({ employeeData}) => {
  const [Expend, setExpend] = useState(false);
  const { name, projects } = employeeData;
  
  return projects.map((dayData, index) => {
    if (index === 0 || Expend) {
      return (
        <tr key={index}>
          <td>
            {index === 0 && (
              <div className="">
                {projects?.length !== 1 && (
                  <img
                    className="sort-list"
                    src="/images/icons/arrow.png"
                    alt="arrow"
                    style={{ transform: Expend ? "rotate(90deg)" : "" }}
                    onClick={() => setExpend((prevVal) => !prevVal)}
                  />
                )}
                {name}
              </div>
            )}
          </td>
          <td>{dayData?.project?.name}</td>
          <td>{dayData.task.name}</td>
          <td>{moment(dayData.date).format("ddd, DD MMM")}</td>
          <td>{`${dayData.time_worked.hours}H ${dayData.time_worked.minutes}M`}</td>
        </tr>
      );
    }
    return null;
  });
};

const ProjectReportTable = ({
  data,
  currentTab,
  inProgress,
  isActionAvailable,
  hookProps,
}) => {
  const { currentPage, setCurrentPage, pageCounts, setPageCounts } = hookProps;
  const pages = [];

  for (let i = 1; i <= pageCounts; i++) {
    pages.push(i);
  }
  const renderPageButtons = RenderPageButtons(
    currentPage,
    pageCounts,
    setCurrentPage
  );
  return (
    <>
      <table className="employee-list-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Project Name</th>
            <th>Task Name</th>
            <th>Date</th>
            <th>Total Hour Worked</th>
          </tr>
        </thead>
        <tbody>
          {inProgress && (
            <tr>
              {" "}
              <td colSpan={8}>
                <h4 className="custom-no-data">Loading Data...</h4> 
              </td>
            </tr>
          )}
          {!inProgress && data?.length > 0 ? (
            data.map((employeeData) => (
              <EmpTableRow
                employeeData={employeeData}
                currentTab={currentTab}
                isActionAvailable={isActionAvailable}
              />
            ))
          ) : (
            <tr>
              {!inProgress && (
                <td colSpan={8}>
                  <h3>No Data to Show!</h3>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default ProjectReportTable;
