import React, { useEffect } from 'react';
import Tooltip from "../ToolTip";
import { useSelector } from "react-redux";
import Pagination from '../common/Pagination/pagination';

export default function ProjectTable({ editProject, hookProps }) {
  const {
    projectsList,
    pageCounts,
    currentPage,
    setCurrentPage,
    inProgress,
    tableType,
    TabChange,
    ArchivedProject,
    RestoreProject,
    TotalCount_Archived,
    TotalCount_Active,
    userPermissions,
  } = hookProps;

  const { userInfo } = useSelector((store) => store.auth);

  // const renderPageNumbers = () => {
  //   const visiblePages = 5; // Number of visible page numbers
  //   const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  //   const endPage = Math.min(pageCounts, startPage + visiblePages - 1);

  //   const pages = [];
  //   for (let i = startPage; i <= endPage; i++) {
  //     pages.push(i);
  //   }

  //   return pages.map((number) => (
  //     <button
  //       key={number}
  //       onClick={() => setCurrentPage(number)}
  //       className={currentPage === number ? "active-page" : null}
  //     >
  //       {number}
  //     </button>
  //   ));
  // };

    const pages = [];
    for (let i = 1; i <= pageCounts; i++) {
      pages.push(i);
    }


  return (
    <div className="projects-table" style={{marginTop:"10px"}}>
      <div className="projects-table-header">
        <div
          className={`project-table-tab ${tableType === "active" && "active-table-tab"
            }`}
          onClick={() => TabChange()}
        >
          <h1>{TotalCount_Active}</h1>
          <div>
            <h5>Active Projects</h5>
          </div>
        </div>

        <div
          className={`project-table-tab ${tableType === "archived" && "active-table-tab"
            }`}
          onClick={() => TabChange()}
        >
          <h1>{TotalCount_Archived}</h1>
          <div>
            <h5>Archived Projects</h5>
          </div>
        </div>
      </div>

      <div className="projects-table-body">
        <table>
          
            <tr>
              <th>Projects</th>
              <th>Tasks</th>
              <th>Users</th>
              <th className="w-140">Actions</th>
            </tr>
          

          
            {inProgress ? (
              <tr>
                <td colSpan="4">
                  <h4 style={{textAlign: "center", fontSize: "16px"}}>Loading Data...</h4>
                </td>
              </tr>
            ) : !inProgress && projectsList.length === 0 ? (
              <tr>
                <td colSpan="4">
                  <h4>
                    No {tableType === "active" ? "Active" : "Archived"} Project{" "}
                  </h4>
                </td>
              </tr>
            ) : (
              projectsList.map((project, index) => (
                <tr key={project.id + `${index}`}>
                  <td>
                    <div className="employee-name">
                      <span class="increased-text">cs</span> {project.name}
                    </div>
                  </td>
                  <td>
                    <div className="list-number">
                      <span>{project.tasks.length}</span>Tasks in List
                    </div>
                  </td>
                  <td>
                    <div className="list-number">
                      <span>{project.assigned_to_users.length}</span>Members
                    </div>
                  </td>
                  <td>
                    <div className="table-actions">
                      {tableType === "active" ? (
                        <>
                          {userPermissions.includes("Can change all projects") ||
                            (userPermissions.includes("Can change project") &&
                              (project.assigned_to_users.includes(userInfo.id) ||
                                project.id === "01d6a5a0-62b4-4603-97d9-2711e73f8cc1")) ? (
                            <Tooltip position="top" title="Edit Project">
                              <button onClick={() => editProject(project.id, project.assigned_to_users.includes(userInfo.id))}>
                                <img className='imgedit' src="/images/icons/edit-green.png" alt="edit" />
                              </button>
                            </Tooltip>
                          ) : null}
                          {userPermissions.includes("Can delete all projects") ||
                            (userPermissions.includes("Can delete project") &&
                              (project.assigned_to_users.includes(userInfo.id) ||
                                project.id === "01d6a5a0-62b4-4603-97d9-2711e73f8cc1")) ? (
                            <Tooltip position="top" title="Archive Project">
                              <button
                                onClick={() => ArchivedProject(project.id)}
                              >
                                <img
                                  src="/images/icons/archived.png"
                                  alt="archived"
                                />
                              </button>
                            </Tooltip>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {userPermissions.includes("Can restore all projects") ? (
                            <Tooltip position="top" title="Restore Project">
                              <button onClick={() => RestoreProject(project.id)}>
                                <img
                                  src="/images/icons/restore.png"
                                  alt="restore"
                                />
                              </button>
                            </Tooltip>
                          ) : userPermissions.includes("Can restore own projects") ? (
                            project.assigned_to_users.includes(userInfo.id) ||
                              project.id.includes("01d6a5a0-62b4-4603-97d9-2711e73f8cc1") ? (
                              <Tooltip position="top" title="Restore Project">
                                <button onClick={() => RestoreProject(project.id)}>
                                  <img
                                    src="/images/icons/prev-arrow.png"
                                    alt="restore"
                                  />
                                </button>
                              </Tooltip>
                            ) : null
                          ) : null}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          
        </table>
      </div>

      {
      pageCounts>1 && (
        <Pagination
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )
    }

      {/* <div className="projects-table-footer">
        <div className="table-pagination">
          <button
            className="prev-scr"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {"PREV"}
          </button>
          {renderPageNumbers()}
          <button
            className="next-scr"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === pageCounts}
          >
            {"NEXT"}
          </button>
        </div>
      </div> */}


    </div>
  );
}
