import React, { useEffect, useRef } from "react";
import "./style.css";

const MODAL_STYLES = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50% ,-50%)",
  background: "white",
  zIndex: 1000,
  
  borderRadius: "5px",
};

const defaultModalStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50% ,-50%)",
  background: "white",
  zIndex: 1000,
  maxWidth: "80%", // Default values for responsiveness
  maxHeight: "20vh",
  // overflowY: "auto",
};

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(0, 0, 0, .7)",
  zIndex: 1000,
};

export default function Modal({ open, onClose, children, className, customStyles }) {
  const modalStyles = customStyles ? { ...defaultModalStyles, ...customStyles } : MODAL_STYLES;

  const useOutsideCloseModal = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const modalRef = useRef(null);
  useOutsideCloseModal(modalRef);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open])
  
  if (!open) return null;
  return (
    <>
      <div style={OVERLAY_STYLES} />
      <div className={`modal-main ${className}`} style={modalStyles} ref={modalRef}>
        {children}
      </div>
    </>
  );
}
