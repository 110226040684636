import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DescriptionModal from "./DescriptionModal";
import { RenderPageButtons } from "../../Utils/randerPageButtons";
import { getLeaveList, getSortedLeaveList_paginated } from "../../redux/Actions/leaveAction";

export default function EmployeeLeaveTable({ hookProps }) {
  const { userSubordinates } = useSelector((store) => store.auth);
  const { leaveTransactionListSorted } = useSelector((store) => store.leave);
  const dispatch = useDispatch(); // Fixed typo in useDispatch

  const {
    currentPage,
    setCurrentPage,
    pageCounts,
    leaveTransactionList,
    userInfo,
    seeDescription,
    inProgress,
    editEmployee,
    selectedEmployee,
    startMonth,
    userPermissions,
    activeTab,
    isManager,
    isEscalted
  } = hookProps;

  const [sortField, setSortField] = useState("date"); // Default sort by date
  const [sortOrder, setSortOrder] = useState("asc"); // Default ascending order

  const pages = Array.from({ length: pageCounts }, (_, i) => i + 1);

  const toggleSortOrder = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const formatDateRange = (startDate, endDate) => {
    const leaveDates = calculateLeaveDates(startDate, endDate);
    const leaveCount = leaveDates.length;

    const formattedStartDate = moment(startDate).format("ddd, DD MMM");
    const formattedEndDate = moment(endDate).format("ddd, DD MMM");
    return parseFloat(leaveCount) > 1
        ? `${formattedStartDate} to ${formattedEndDate}`
        : formattedStartDate;
};

const calculateLeaveDates = (startDate, endDate) => {
    const leaveDates = [];
    let currentDate = moment(startDate);
    while (currentDate <= moment(endDate)) {
        leaveDates.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
    }
    return leaveDates;
};
  const dataFound = leaveTransactionList && leaveTransactionList.length > 0;
  const isHr = userInfo.groups.some((item) => item.name === "HR");
  const isSuperUser = userInfo.is_superuser;

  return (
    <>
      <div  className={`leaves-table-body ${userSubordinates.length > 0 ||isHr||isSuperUser ? "" : "with-border-pad"}`} style={{padding:"7px"}}>
     
        <table className="leave-management-table"> 
          
            <tr style={{borderTop:"none",borderBottom:"none"}}>
              <th  style={{paddingLeft: "53px",textAlign:"left",width:"17%"}}>Name</th>
              
              <th style={{textAlign:"justify",paddingLeft:"70px"}} scope="col" onClick={() => toggleSortOrder("date")}>
              {activeTab === "Escalated Leave request" ? (
    <span>Date</span>
) : (
    <span>
        Date {sortField === "date" && (sortOrder === "asc" ? "▲" : "▼")}
    </span>
)}

</th>

              <th style={{textAlign:"justify",paddingLeft:"48px"}}>
                Type
              </th>
              <th scope="col" >
                 Count
              </th>
              <th style={{textAlign:"justify",paddingLeft:"58px"}}>
                Status
              </th>
              {activeTab === "Escalated Leave request" && ( 
                  <th  style={{textAlign:"justify",paddingLeft:"58px"}}  scope="col">Reporting Manager</th>
)}
              <th scope="col" >
                Description
              </th>
              <th scope="col" >
                {userPermissions.includes(
                  "Can approve/reject all leave requests in the organization"
                ) ||
                userPermissions.includes(
                  "Can approve/reject leaves for users added by or assigned to self"
                )
                  ? "Action"
                  : ""}
              </th>
            </tr>
         
        
            {inProgress ? (
              <tr className="table-Rows-loadin" style={{border:"none"}}>
                <td colSpan="7" className="loader">
                <h3 className="custom-no-data">Loading Data...</h3>
                </td>
              </tr>
            ) : dataFound ? (
              (sortOrder === "asc"
                ? leaveTransactionList
                : leaveTransactionListSorted
              ).map((leave, index) => (
                <tr className="table-row" key={index}>
                 <td  className="emp-name " style={{position:"relative",paddingLeft:"52px"}}>
                <div>{isEscalted ? leave.user_details?.name : leave.user.name}</div> 
                  <div className="to-hide-left-border-l"></div>

        
                    
                  </td>
                  <td >

                    <div  style={{paddingLeft:"65px" ,textAlign:"left"}}>
                      {formatDateRange(
                        leave?.leave_start_date,
                        leave?.leave_end_date,
                        
                      )}

                    </div>
                  </td>
                  <td style={{textAlign:"justify",paddingLeft:"48px"}}>
                    <div>{leave.leave_type}</div>
                  </td>
                  <td>
                    <div>{leave.leave_count}</div>
                  </td>
                  <td className={`status-data ${leave.status === "Approved" ? "approved-status" : leave.status === "Deny" ? 
                  "not-approved-status" : "need-action"}`}>
                   <div style={{textAlign:"justify",paddingLeft:"54px"}}>{leave.status === "Deny" ? "Rejected" : leave.status}</div>
                   </td>
                   {activeTab === "Escalated Leave request" && (
                <td>
                    <div style={{textAlign:"justify",paddingLeft:"53px"}}>{leave.manager_names && leave.manager_names.length > 0 ? leave.manager_names : "NA"}</div>

                </td>
            )}
                  <td>
                    <div>
                      <button
                        className="des-btn"
                        onClick={() => {
                          seeDescription(leave);
                        }}
                      >
                        <img src="/images/description.svg" alt="edit"  style={{width:"28px"}}/>
                      </button>
                    </div>
                  </td>
                  <td>
                    <div>
                      {(() => {
                        const isPending = leave.status === "Pending";
                        const canApproveAll = userPermissions.includes(
                          "Can approve/reject all leave requests in the organization"
                        );
                        const canApproveSelf = userPermissions.includes(
                          "Can approve/reject leaves for users added by or assigned to self"
                        );

                        const isUserSubordinate = userSubordinates?.some(
                          (subordinate) => subordinate.id === leave.user.id
                          
                        );

                        return (
                          isPending &&
                          ((canApproveAll && leave.user.id !== userInfo.id && leave.user_details?.id !== userInfo.id) ||
                            (canApproveSelf && isUserSubordinate ))
                        );
                      })() && (
                        <button onClick={() => editEmployee(leave)} className="empedit-btn">
                          <img
                            src="/images/icons/edit-green.png"
                            alt="edit"
                            style={{width:"28px"}}
                          />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="table-Rows-loadin">
                <td colSpan="7" className="loader">
                  <div className="center-text">No Data to Show!</div>
                </td>
              </tr>
            )}
          
        </table>
      </div>

      
    </>
  );
}  
